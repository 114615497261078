import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./SupplierVisitTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../../Assets/Svg/arrow-icon.svg";
import { tableStyles } from "../../../Utils/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "../../Imports/import";
import { fetchSupplierData } from "../../../Store/actions/dashboardAction";
import { formatNumber } from "../../../Utils/Utils";

const SupplierPerfomanceTable = () => {
  const dispatch =useDispatch();
  const {supplierData,loading,error}=useSelector((state)=>state.DashboardReducer)
  const [orderBy,setOrderBy]=useState('evaluation_date');
  const [orderDir, setOrderDir] = useState('DESC');
  
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [count,setCount]=useState(0)
  useEffect(()=>{
    dispatch(fetchSupplierData(currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues))
    
  },[currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues]);
  useEffect(()=>{
    setData(supplierData?.result?.data|| [])
   setCount(supplierData?.result?.TotalCount);
   },[supplierData,count])
  
  const columnWidths = {
    VendorCode: "12%",
    "Commercial Register Type": "20%",
    TypeofBusiness: "15%",
    "Purchasing group": "15%",
    "Register Date": "15%",
  };

  const columns = [
    {
      id:"vendor_code",
      name: "Vendor Code",
      selector: (result) => result?.vendor_code || "",
      searchable: true,
    },
    {
      id:"company_name_eng",
      name: "Company Name",
      selector: (result) => result?.company_name_eng || result?.company_name_th || "",
      searchable: true,
    },
    {
      id:"type_of_business",
      name: "Type of Business",
      selector: (result) => result?.type_of_business || "",
      searchable: true,
    },
    {
      id:"evaluation_score",
      name: "Evaluation Score(%)",
      selector: (result) => formatNumber(result?.evaluation_score) || "",
      searchable: true,
    },
    {
      id:"delivery_score",
      name: "Delivery Score(%)",
      selector: (result) => formatNumber(result?.delivery_score) || "",
      searchable: true,
    },
    {
      id:"delivery_success",
      name: "Delivery Success",
      selector: (result) => result?.delivery_success || "",
      searchable: true,
    },
    {
      id:"delivery_delay",
      name: "Delivery delay",
      selector: (result) => result?.delivery_delay || "",
      searchable: true,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      
      dispatch(fetchSupplierData(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };


  const handleTypeSelect = (selectedKey) => setType(selectedKey);


  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
    }
  };


      const displayedRange = {
        start: (currentPage - 1) * itemsPerPage + 1,
        end: Math.min(currentPage * itemsPerPage, count),
      };

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const headerNames = columns.map((column) => column.name);
  const csvData = data?.map((item) =>
      columns.reduce((row, column) => {
        row[column.name] = column.selector ? column.selector(item) : item[column.id] || '';
        return row;
      }, {})
    ) || []; 
   

  //   const getCountByStatus = (status) => {
  //     return data.filter((item) => item.status === status).length;
  //   };

  return (
    <>
    {(loading) && <PageLoader/>}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "all"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="all"
                >
                  All
                  <span
                    className={
                      type === "all"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="all"
                  >
                   {count}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <DateRangePicker
              appearance="default"
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
              disabled={loading}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>Show </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>entries</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="supplierPerfomanceTable_data.csv"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            data.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            columns={columns}
            data={data}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="table-search-div"
          >
            {columns.map((column) =>
              column.searchable !== false ? (
                <input
                key={column.id}
                type="text"
                value={searchValues[column.id] || ""}
                style={{ width: columnWidths[column.name] }}
                onChange={(event) =>
                  handleSearchChange(event, column?.id)
                }
                />
              ) : (
                // Disabled input for non-searchable columns
                <input
                  key={column.id}
                  type="text"
                  disabled
                  placeholder=""
                  style={{
                    width: columnWidths[column.name],
                    backgroundColor: "#f5f5f5", 
                    cursor: "not-allowed", 
                    color: "#999", 
                  }}
                />
              )
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center pb-4">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default SupplierPerfomanceTable;
