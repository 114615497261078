import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./SupplierVisitTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../../Assets/Svg/arrow-icon.svg";
import PoExpandedTable from "./PoExpandedTable";
import { tableStyles } from "../../../Utils/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "../../Imports/import";
import { fetchPOData, fetchPoStats } from "../../../Store/actions/dashboardAction";
import { DateGenerator, formatStringForCSV } from "../../../Utils/Utils";

const PoTable = () => {
  const dispatch =useDispatch();
  const [orderBy,setOrderBy]=useState('updatedAt');
  const [orderDir, setOrderDir] = useState('DESC');
  const [filteredData, setFilteredData] = useState([]);
  const {poStats,poData,loading,error,poStatsLoading,poStatsError}=useSelector((state)=>state.DashboardReducer)
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({
    all: [],
    confirmingPO: [],
    acknowledge: [],
  });
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [count,setCount]=useState(poStats?.data?.reduce(
    (total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0),
    0
  ) || 0)
  useEffect(()=>{
   
      dispatch(fetchPoStats())
    if(type===0){
      dispatch(fetchPOData(currentPage,itemsPerPage,orderBy,orderDir,"all",selectedDateRange[0],selectedDateRange[1],searchValues))
    }
    
    dispatch(fetchPOData(currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues))
     
    },[currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues])
   
    useEffect(()=>{
      if (type === 0) {
        setData((prevState) => ({
          ...prevState,
          all: poData?.result?.data || [],
        }));
      }
      else if (type === 1) {
        setData((prevState) => ({
          ...prevState,
          confirmingPO: poData?.result?.data || [],
        }));
      }
      else if (type === 2) {
        setData((prevState) => ({
          ...prevState,
          acknowledge: poData?.result?.data || [],
        }));
      }
      setCount(poData?.result?.TotalCount);
     },[poData,count])
     useEffect(()=>{
      type===0?setFilteredData(data?.all|| []):type===1?setFilteredData(data?.confirmingPO|| []):type===2?setFilteredData(data?.acknowledge|| []):type===3?setFilteredData(data?.rejected|| []):setFilteredData([])
     },[data,type])
    
  const ExpandedComponent = ({ data }) => (
    <pre>
      <PoExpandedTable data={data?.poPrData} PurchasingGroup
={data?.PurchasingGroup} />
    </pre>
  );

  const columnWidths = {
    Location: "14%",
    PRNo: "14%",
    PRTitle: "14%",
    POno: "14%",
    Deliverydate: "14%",
    Buyer: "12%",
    status: "14%",
  };

  const columns = [
    {
      id:"PRLocation",
      name: "Location",
      selector: (result) => result?.poPrData?.[0]?.PRLocation || "",
      searchable: true,
    },
    {
      id:"PrNumber",
      name: "PR No.",
      selector: (result) => result?.poPrData?.length>1?`{Combined (${result?.poPrData?.length})}`:result?.poPrData?.length===1? result?.poPrData[0]?.PrNumber:"",
      searchable: true,
    },
    {
      id: "PrShortText",
      name: "PR Title",
      selector: (result) => result?.poPrData?.length>1?`{Combined (${result?.poPrData?.length})}`:result?.poPrData?.length===1? result?.poPrData[0]?.PrShortText:"",
      searchable: true,
    },
    {
      id:"PONumber",
      name: "PO no.",
      selector: (result) => result?.PONumber || "",
      searchable: true,
    },
    {
      id: "PrDeliveryDate",
      name: "Delivery date(PO)",
      selector: (row) => {
        if(row?.poPrData){
          const dates = row?.poPrData.map(item => new Date(item?.PrDeliveryDate));
          const maxDate = new Date(Math.max(...dates));
          return DateGenerator(maxDate) || ""; 
        }
        return ""
        
      },
      searchable: true,
    },
    {
      id: "PCMPersonInCharge",
      name: "Buyer",
      selector: (result) => result?.PCMPersonInCharge || "",
      searchable: true,
    },
    {
      id:"VmsStatus",
      name: "Status",
      cell: (row) => (
        <div
          className={
            row?.VmsStatus === 1
              ? "table-status confirming-po"
              : row?.VmsStatus === 2
              ? "table-status approved"
              : ""
          }
        >
          {" "}
          {row?.VmsStatus === 1 ? "Confirming PO" : row?.VmsStatus === 2 ? "Acknowledge PO" : "Unknown"}
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      
      dispatch(fetchPOData(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type===0?"all":type,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };
 

  const handleTypeSelect = (selectedKey) =>{
    setCurrentPage(1);
    setType(parseInt(selectedKey));
  } 

 

  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
    }
  };



 

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
  };

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const headerNames = columns.map((column) => column.name);
 
  const csvData =
  filteredData?.map((item) =>
    columns.reduce((row, column) => {
      if (column.id === 'PrNumber') {
        if(item?.poPrData?.length>1){
        const prNumbers = item?.poPrData?.map((poPrItem) => poPrItem.PrNumber).filter(Boolean).join(', ') || '';
        row[column.name] = prNumbers ? `(${prNumbers})` : '';
      }
      else{
        row[column.name] =item?.poPrData?.length==1?item?.poPrData[0]?.PrNumber :""
      }
      }
      else if (column.id === 'PrShortText') {
        const prshortText = item?.poPrData?.map((poPrItem) => poPrItem.PrShortText).filter(Boolean).join(', ') || '';
        row[column.name] = prshortText ? `(${formatStringForCSV(prshortText)})` : '';
      }
      else if (column.id === 'VmsStatus') {
        row[column.name]= item?.VmsStatus===1?"Confirming Po": item?.VmsStatus===2?"Acknowledge":""
      }
      else {
        row[column.name] = column.selector ? column.selector(item) : item[column.id] || '';
      }
      return row;
    }, {})
  ) || [];
  return (
    <>
    {(poStatsLoading || loading) && <PageLoader/>}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 0
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="0"
                >
                  All
                  <span
                    className={
                      type ===0
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="0"
                  >
                    {type===0?count:poStats?.data?.reduce(
  (total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0),
  0
) || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 1
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="1"
                >
                  Confirming PO
                  <span
                    className={
                      type === 1
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="1"
                  >
                   {type===1?count:poStats?.data?.find(stat => stat?.VmsStatus === 1)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 2
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="2"
                >
                  Acknowledge
                  <span
                    className={
                      type === 2
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="2"
                  >
                    {type===2?count:poStats?.data?.find(stat => stat?.VmsStatus === 2)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <DateRangePicker
              appearance="default"
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
              disabled={loading}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>Show </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>entries</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="po_table"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            filteredData.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            className="po-table"
            columns={columns}
            data={filteredData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows
            expandableRowDisabled={(row) => !row || row?.poPrData?.length<2}
            expandableRowExpanded={(row) => row?.poPrData?.length>1}
            expandableRowsComponent={ExpandedComponent}
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="table-search-div"
          >
            {columns?.map((column) =>
               column.searchable === true ? (
                <input
                  key={column?.id}
                  type="text"
                  value={searchValues[column?.id] || ""}
                  style={{ width: columnWidths[column?.name] }}
                  onChange={(event) =>
                    handleSearchChange(event, column?.id)
                  }
                />
              ) : column.searchable === false ? (
                // Disabled input for non-searchable columns
                <input
                  key={column.id}
                  type="text"
                  disabled
                  placeholder=""
                  style={{
                    width: columnWidths[column.name],
                    backgroundColor: "#f5f5f5", 
                    cursor: "not-allowed", 
                    color: "#999", 
                  }}
                />
              )
              :
              null
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default PoTable;
