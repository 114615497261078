import {
  React,
  useState,
  useEffect,
  useTranslation,
  useForm,
  toast,
  useDispatch,
  Controller,
} from "../Imports/import";
import { MultiSelect } from "react-multi-select-component";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import useSetFormValues from "../../CustomHooks/useSetFormValues";
import {
  convertStringToArray,
  handleKeyDown,
  subCategoryOptions,
} from "../../Utils/Utils";
import { adminUpdateVendor } from "../../Store/actions/updateVendorAction";
import { getSingleVendorDetails } from "../../Store/Reducer/singleVendorDetails";
import useIsEditFieldMandatory from "../../CustomHooks/useIsEditFieldMandatory";

const Categories = ({ setEditMode, editMode, data, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const isFieldMandatory = useIsEditFieldMandatory();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const recongizedAsArray = data?.sub_category?.includes(",")
    ? data?.sub_category.split(",")
    : [data?.sub_category];

  const initialSelected =
    data?.sub_category?.length > 0
      ? recongizedAsArray.map((value) => ({
          label: value,
          value,
        }))
      : [];

  const [selectedSubCategories, setSelectedSubCategories] =
    useState(initialSelected);
  // Define the maximum number of allowed selections
  const maxAllowedSelections = 3;

  const userName = data?.user_name;

  const onSubmit = async (data) => {
    data.sub_category = Array.isArray(data?.sub_category)
      ? data?.sub_category?.map((option) => option?.value)
      : data?.sub_category;
    if (!showError) {
      
      loading(true);
      var formdata = new FormData();

      formdata.append("user_name", userName);
      formdata.append("type_of_business", data.type_of_business);
      formdata.append("manufacturer_specify", data.manufacturer_specify);
      formdata.append("business_specify", data.business_specify);
      formdata.append("experience", data.experience);
      formdata.append("working_day", data.working_day);
      formdata.append("working_time", data.working_time);
      formdata.append("binding_category", data.binding_category);
      formdata.append(
        "binding_category_specify",
        data.binding_category_specify
      );
      formdata.append("sub_category", data?.sub_category);
      formdata.append("sub_category_specify", data?.sub_category_specify);
      formdata.append("name_of_top3_customers", data?.name_of_top3_customers);
      formdata.append("process_litigation", data?.process_litigation);
      formdata.append("litigation", data?.litigation);

      try {
        const isSuccess = await dispatch(adminUpdateVendor(formdata));

        if (isSuccess?.data?.status === 200) {
          await dispatch(getSingleVendorDetails());
          setEditMode(false);
          loading(false);
          toast.success(isSuccess?.data?.message);
        } else {
          loading(false);
          toast.error(isSuccess?.data?.message || "Something went wrong");
        }
      } catch (error) {
        
        toast.error(error.message || "Something went wrong");
        loading(false);
      }
    } else {
      toast.warning("For SubCategory you can select maximum 3 items");
    }
  };

  const onChangeSubCategory = (value) => {
    const selectedValues = value.slice(0, maxAllowedSelections);
    setValue("sub_category", selectedValues);
    setSelectedSubCategories(selectedValues);
  };


  const setDataValues = useSetFormValues(setValue);
  useEffect(() => {
    if (data) {
      setDataValues(data);
    }
  }, [data, setDataValues]);

  useEffect(() => {
    dispatch(getSingleVendorDetails());
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
      <div className="genaral-main-div">
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Type of Business")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}{" "}
            :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("type_of_business", {
                required: isFieldMandatory,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {" "}
                {t("select business type")}
              </option>
              <option value="Manufacturer"> {t("Manufacturer")}</option>
              <option value="Service">{t("Service")}</option>
              <option value="Trading">{t("Trading")}</option>
              <option value="Transportation">{t("Transportation")}</option>
              <option value="Rawmaterial">{t("Raw material")}</option>
              <option value="other">{t("Other, Please specify")}</option>
            </select>
            {errors.type_of_business && (
              <span className="error-msg">
                {" "}
                {t("Please select the type of business")}
              </span>
            )}
            <div className="vp-input-div" style={{ display: "contents" }}>
              {watch("type_of_business") === "other" && (
                <>
                  <input
                    type="text"
                    disabled={!editMode}
                    className="vp-input mt-3"
                    {...register("business_specify", {
                      required: false,
                      maxLength: 80,
                      minLength: 2,
                    })}
                  />

                  {errors.business_specify && (
                    <span className="error-msg">
                      {" "}
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Experience")}{" "}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}{" "}
            :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("experience", {
                required: isFieldMandatory,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {t("select Experience")}{" "}
              </option>
              <option value="Less than">{t("Less than 1 year")} </option>
              <option value="Up to ">{t("Up to 3 years")}</option>
              <option value="Over">{t("Over 3 years")}</option>
            </select>
            {errors.experience && (
              <span className="error-msg">
                {t("Please select the experience.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Working Day")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}{" "}
            :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("working_day", {
                required: isFieldMandatory,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {t("select working day")}
              </option>
              <option value="1day">{t("1 day")}</option>
              <option value="2day">{t("2 day")}</option>
              <option value="3day">{t("3 day")}</option>
              <option value="4day">{t("4 day")}</option>
              <option value="5day">{t("5 day")}</option>
              <option value="6day">{t("6 day")}</option>
              <option value="7day">{t("7 day")}</option>
            </select>
            {errors.working_day && (
              <span className="error-msg">
                {" "}
                {t("Please select the working days.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {" "}
            {t("Working Time")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}{" "}
            :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("working_time", {
                required: isFieldMandatory,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {t("select working time")}
              </option>
              <option value="1hour">{t("1 hour")}</option>
              <option value="3hours">{t("3 hours")}</option>
              <option value="6hours">{t("6 hours")}</option>
              <option value="8hours">{t("8 hours")}</option>
              <option value="10hours">{t("10 hours")}</option>
              <option value="12hours">{t("12 hours")}</option>
            </select>
            {errors.working_time && (
              <span className="error-msg">
                {" "}
                {t("Please select the working Time.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Bidding Category")}
            {editMode && <span className="staricon">*</span>} :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("binding_category", {
                required: true,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {t("select bidding category")}
              </option>
              <option value="Goods">{t("Goods")}</option>
              <option value="Services">{t("Services")}</option>
              <option value="Other Please specify">
                {t("Other Please specify")}
              </option>
            </select>
            {errors.binding_category && (
              <span className="error-msg">
                {t("Please select the bidding category.")}
              </span>
            )}
            <div className="vp-input-div" style={{ display: "contents" }}>
              {watch("binding_category") &&
                watch("binding_category").includes("Other Please specify") && (
                  <>
                    <input
                      type="text"
                      disabled={!editMode}
                      className="vp-input mt-3"
                      {...register("binding_category_specify", {
                        required: false,
                        maxLength: 80,
                      })}
                    />
                    {errors.binding_category_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Sub-Category")} (maximum of select list is 3 items)
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}{" "}
            :
          </label>
          <div className="vp-input-div">
            <div
              className={editMode ? "vp-input" : "vp-input vp-multi-input-div"}
              style={{
                padding: "0px",
                width: "61%",
                minWidth: "100%",
                height: "41px",
              }}
              id={!editMode && "disabled-div"}
            >
              <Controller
                name="sub_category"
                control={control}
                rules={{
                  required: isFieldMandatory
                    ? t("Please select at least one subcategory.")
                    : false,
                }}
                render={({ field, fieldState }) => (
                  <>
                    <MultiSelect
                      className={editMode ? "" : "vp-supplier-multi-select"}
                      // isOpen={true}
                      options={subCategoryOptions}
                      {...field}
                      value={selectedSubCategories}
                      onChange={(value) => {
                        const selectedValues = value.slice(
                          0,
                          maxAllowedSelections
                        );
                        field.onChange(selectedValues);
                        onChangeSubCategory(selectedValues);
                      }}
                      labelledBy={t("select sub category")}
                      hasSelectAll={false}
                    />
                    {fieldState.error && (
                      <span className="error-msg mt-3">
                        {fieldState.error.message}
                      </span>
                    )}
                  </>
                )}
              />
            </div>

            <div className="vp-input-div" style={{ display: "contents" }}>
              {selectedSubCategories?.some(
                (option) => option?.value === "Other"
              ) && (
                <>
                  <input
                    type="text"
                    disabled={!editMode}
                    className="vp-input mt-3"
                    {...register("sub_category_specify", {
                      required: false,
                      maxLength: 80,
                    })}
                  />
                  {errors.sub_category_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Name of your top 3 customers (Reference)")}
            {editMode && <span className="staricon">*</span>} :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="text"
              className="vp-input"
              {...register("name_of_top3_customers", {
                required: false,
              })}
            ></input>
            {errors.name_of_top3_customers && (
              <span className="error-msg"> {t("Please enter the value")}</span>
            )}
          </div>
        </div>
        <div className="vp-label-input mb-5">
          <label className="vp-label">
            {t(
              "Any litigation/arbitration proceedings currently in progress or pending"
            )}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}{" "}
            :
          </label>
          <div className="vp-input-div">
            <select
              type="text"
              className="vp-input"
              disabled={!editMode}
              {...register("process_litigation", {
                required: isFieldMandatory,
              })}
            >
              <option value="" hidden>
                {" "}
                {t("select")}
              </option>
              <option value="No">{t("No")}</option>
              <option value="Yes">{t("Yes")}</option>
            </select>
            {errors.process_litigation && (
              <span className="error-msg">
                {t(
                  "Please select litigation /arbitration prceeding currently in progress or pending"
                )}
              </span>
            )}
            <div className="vp-input-div" style={{ display: "contents" }}>
              {watch("process_litigation") === "Yes" && (
                <>
                  <input
                    type="text"
                    disabled={!editMode}
                    className="vp-input mt-3"
                    {...register("litigation", {
                      required: false,
                      maxLength: 80,
                    })}
                  />
                  {errors.litigation && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <CancelAndUpdateBtn
          editMode={editMode}
          setEditMode={setEditMode}
          reset={reset}
        />
      </div>
    </form>
  );
};

export default Categories;
