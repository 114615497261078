import React from "react";

const CancelAndUpdateBtn = ({
  editMode,
  setEditMode,
  reset,
  data,
  commercialTypeCheck,
  setDocEdit,
  docEdit,
  extractedValue,
  paymentMethodUpdated,
}) => {
  const onClickCancel = () => {
    // reset();
    setEditMode(false);
    if (docEdit) {
      setDocEdit(false);
    }
  };

  return (
    <>
      {editMode && (
        <div className="my-5 vp-btns">
          <button
            type="button"
            className="um-create-btn"
            onClick={onClickCancel}
          >
            Cancel
          </button>
          <button type="submit" className="um-submit-btn">
            {(commercialTypeCheck &&
              ["L", "T", "P"].includes(extractedValue)) ||
            paymentMethodUpdated
              ? "Next"
              : "Update"}
          </button>
        </div>
      )}
    </>
  );
};

export default CancelAndUpdateBtn;
