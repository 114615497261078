import {
  React,
  useDispatch,
  useSelector,
  useForm,
  useEffect,
  useState,
  toast,
} from "../../Components/Imports/import";

import Modal from "react-modal";
import "./NextApproverPopupFlow.css";
import successIcon from "../../Assets/Svg/success-icon.svg";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import { getAllGroupList } from "../../Store/Reducer/allGroupList";
import {
  GET_USER_LIST_FOR_APPROVAL,
  VENDOR_APPROVAL,
} from "../../ApiEndPoints/ApiEndPoints";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import Http from "../../HttpClients/HttpClients";
import { getVendorListTableData } from "../../Store/Reducer/vendorTable";

const NextApprovePopup = ({
  isOpenPopup,
  closePopup,
  selectedVendorData,
  setPageLoading,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { GroupList } = useSelector((state) => state.allGroupList);
  const [showPopup, setShowPopup] = useState(false);
  const [approverList, setApproverList] = useState([]);
  const [apiCallMade, setApiCallMade] = useState(false);
  const [selectedApprover, setSelectedApprover] = useState({});
  const userToken = localStorage.getItem("vms_admin_token");
  

  const closeSuccessPopup = () => {
    setShowPopup(false);
  };

  const onClickOk = () => {
    setShowPopup(false);
    closePopup();
  };

  //   getting the object by current_group_name
  function findGroupByName(groupName) {
    return GroupList.find((e) => e.group_name === groupName) || null;
  }

  const groupNameToFind = selectedVendorData?.current_group_name;
  const foundGroup = findGroupByName(groupNameToFind);
  
  //   transforming after apporve transfer value as a array
  const transferOptions =
    foundGroup?.after_approve_transfer
      ?.split(",")
      .map((option) => option.trim()) || [];



  // if (selectedVendorData?.current_group_name === "Buyer Group") {
  //   if (
  //     selectedVendorData.binding_category == "Goods" ||
  //     selectedVendorData.binding_category == "Services"
  //   ) {
  //     transferOptions.pop();
  //   } else {
  //     transferOptions.shift();
  //   }
  // }

  
  // Fetch the list of next approvers when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      if (!apiCallMade && transferOptions.length > 0) {
      
        try {
          setApiCallMade(true); // Set the flag to true to prevent further API calls
          const authToken = localStorage.getItem("vms_admin_token");
          const firstNextApprover = transferOptions;
          const apiUrl = `${BaseUrl}${GET_USER_LIST_FOR_APPROVAL}`;
          const requestData = { group_name: firstNextApprover };

          const response = await Http.post(apiUrl, requestData, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          if (response.status === 200) {
            setApproverList(response.data);
            
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          toast.error("Failed to fetch next approvers. Please try again.");
        }
      }
    };

    fetchData();
  }, [transferOptions, isOpenPopup]);


  const onSubmit = async(data) => {
    const selectedApprovers =
      transferOptions[0] == "Admin"
        ? { user_id: 0 }
        : JSON.parse(data?.approver);
    setSelectedApprover(selectedApprovers);
  
    setPageLoading(true);
    const vendorID = selectedVendorData?.vendor_id.toString();
    const requestBody = {
      vendor_id: vendorID,
      next_group_name: selectedApprovers?.assigner_type || "Admin",
      current_group_name: selectedVendorData?.current_group_name,
      next_user_id: selectedApprovers?.user_id,
    };
 
    const url = `${BaseUrl}${VENDOR_APPROVAL}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    Http.post(url, requestBody, config)
      .then((result) => {
        setPageLoading(false);
        if (result.status === 200) {
          toast.success(result.message);
          setShowPopup(true);
          reset();   
         dispatch(getVendorListTableData({ page: 1, pageSize: 5 }));
        } else if (result.status === 401) {
          toast.error(result.message);
       } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        setPageLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };

  useEffect(() => {
    
    dispatch(getAllGroupList());
  }, [isOpenPopup]);
  return (
    <div className="d-flex justify-content-center">
      {/* this is next Approver popup */}
      <Modal
        isOpen={isOpenPopup}
        onRequestClose={closePopup}
        className="create-user-modal"
        overlayClassName="create-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="close-icon-div"></div>
        <center>
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={areIcon} alt="" />
            <h2 className="pt-2 popup-title">
              Please{" "}
              {transferOptions[0] == "Admin"
                ? "Click Activate For Final"
                : "Select Next"}{" "}
              Approver
            </h2>
            {transferOptions[0] != "Admin" && (
              <>
                <select
                  className="next-approver-dropdown"
                  {...register("approver", {
                    required: true,
                  })}
                >
                  <option value="" hidden>
                    Which would you like to assign approver?
                  </option>
                  {Array.isArray(approverList) &&
                    approverList?.map((option, index) => (
                      <option key={index} value={JSON.stringify(option)}>
                        {option?.name}
                        {/* ({option?.assigner_type}) */}
                      </option>
                    ))}
                </select>
                {errors.approver && (
                  <span className="error-msg">
                    Please select{" "}
                    {transferOptions[0] == "Admin" ? "for final" : "next"}{" "}
                    approver
                  </span>
                )}
              </>
            )}

            <div className="pt-4">
              <button className="um-submit-btn mx-2" type="submit">
                {transferOptions[0] == "Admin" ? "Activate" : "Assign"}
              </button>
            </div>
          </form>
        </center>
      </Modal>
      {/* this is success popup */}
      <Modal
        isOpen={showPopup}
        onRequestClose={closeSuccessPopup}
        className="create-user-modal"
        overlayClassName="create-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="close-icon-div">
          {/* <img
            src={closeIcon}
            alt=""
            onClick={() => onClickOk()}
            style={{ cursor: "pointer" }}
          /> */}
        </div>
        <center>
          <img src={successIcon} alt="" />
          <h2 className="pt-2 popup-title">Done!</h2>
          {transferOptions[0] !== "Admin" ? (
            <p className="pt-2 popup-text">
              Assign to {selectedApprover?.name} (
              {selectedApprover?.assigner_type}) successfully.
            </p>
          ) : (
            <p className="pt-2 popup-text">Approved successfully.</p>
          )}
          <div className="pt-3">
            <button className="um-submit-btn mx-2" onClick={onClickOk}>
              Ok
            </button>
          </div>
        </center>
      </Modal>
    </div>
  );
};

export default NextApprovePopup;
