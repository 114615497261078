import React, { useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./HistorySupplierVisitTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../Assets/Svg/arrow-icon.svg";
import HistoryStorageExpandedTable from "./HistoryStorageExpandedTable";
import { useNavigate } from "react-router-dom";
import { PageLoader, useDispatch, useSelector, useTranslation } from "../Imports/import";
import { tableStyles } from "../../Utils/TableStyles";
import { fetchHistoryPoData } from "../../Store/actions/historyStorageActions";
import { DateGenerator, formatStringForCSV } from "../../Utils/Utils";

const HistoryStorageTable = () => {
  const dispatch =useDispatch();
  
  const {data:poData,loading,error}=useSelector((state)=>state.HistoryStorageReducer)
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("History Storage");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [count,setCount]=useState((poData?.result?.TotalCount) || 0)
  const [orderBy,setOrderBy]=useState('updatedAt');
  const [orderDir, setOrderDir] = useState('DESC');

  useEffect(()=>{
    const currentHistoryPage=parseInt(localStorage.getItem("historyStoragePageSelect")) || 1;
    const itemsHistoryPerPage=parseInt(localStorage.getItem("historyStorageItemsPerPageSelect")) || 5;
    const searchHistoryValues=JSON.parse(localStorage.getItem("historyStorageSearchValues")) || {};
    setSearchValues(searchHistoryValues)
    setCurrentPage(currentHistoryPage)
    setItemsPerPage(itemsHistoryPerPage)
  },[])

  useEffect(()=>{
    dispatch(fetchHistoryPoData(currentPage,itemsPerPage,orderBy,orderDir,selectedDateRange[0],selectedDateRange[1],searchValues))
  },[currentPage,itemsPerPage,orderBy,orderDir,selectedDateRange[0],selectedDateRange[1],searchValues])

  useEffect(()=>{
    setData(poData?.result?.data|| [])
    setCount(poData?.result?.TotalCount);
   },[poData,count])
  const ExpandedComponent = ({ data }) => (
    <pre>
      <HistoryStorageExpandedTable data={data.poPrData} PurchasingGroup
={data.PurchasingGroup} />
    </pre>
  );

  const columnWidths = {
    Location: "13%",
    PRNo: "11%",
    PRTitle: "9%",
    // PRG: "8%",
    POno: "9%",
    Deliverydate: "9%",
    Buyer: "12%",
    Delivered: "10%",
  };

  const columns = [
    {
      id:"PRLocation",
      name: t("Location"),
      selector: (result) => result.poPrData?.[0]?.PRLocation || "",
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id:"PrNumber",
      name: t("PR No."),
      selector: (result) => result?.poPrData && result?.poPrData?.length>0 ?(result?.poPrData?.length>2?`Combined (${result?.poPrData.length || "0"} )`:result?.poPrData?.[0]?.PrNumber): "",
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id:"PrShortText",
      name: t("PR Title"),
      selector: (result) => result?.poPrData && result?.poPrData?.length>0 ?(result?.poPrData?.length>2?`Combined (${result?.poPrData.length || "0"} )`:result?.poPrData?.[0]?.PrShortText): "",
      searchable: true,
      width: "14%",
    },
    {
      id:"PrDeliveryDate",
      name: t("Delivery date(PO)"),
      
          selector: (row) => {
            const dates = row?.poPrData.map(item => new Date(item?.PrDeliveryDate));
            const maxDate = new Date(Math.max(...dates));
            return DateGenerator(maxDate) || ""; 
          },

      
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id:"PCMPersonInCharge",
      name: t("Buyer"),
     selector: (result) => result?.PCMPersonInCharge || "",
      searchable: true,
      width: "12%",
    },
    {
      id:"PONumber",
      name: t("PO no."),
      selector: (result) => result?.PONumber || "",
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id:"PODate",
      name: t("Delivered"),
      selector: (row) => {
        if(row?.PODate){
          const maxDate = new Date(row?.PODate);
          return DateGenerator(maxDate) ; 
        }
        return "N/A" 
      },
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id:"Action",
      name: t("Action"),
      
      cell: (result) => (
        <button
          className="action-view-table-btn"
          onClick={() => {
            handleChangeView(result?.PoDbId, result?.VendorCode,result?.evaluationData);
          }}
        >
          {t("View")}
        </button>
      ),
      searchable: false,
      width: "15%",
    },
  ];
  const handleChangeView = (id,code,evaluationData) => {
    const evaluation =evaluationData?.evaluationId || null
    navigate(`/history-storage-view/${id}/${code}/${evaluation}`);
  };

  const handlePageChange = (page) => {
    localStorage.setItem("historyStoragePageSelect",page)
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    localStorage.setItem("historyStorageItemsPerPageSelect",selectedItemsPerPage)
    setCurrentPage(1);
  };

  const handleSearchChange = (event, columnName) => {
    localStorage.setItem("historyStoragePageSelect",1)
    setCurrentPage(1)
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      localStorage.setItem("historyStorageSearchValues",JSON.stringify(updatedSearchValues))
      dispatch(fetchHistoryPoData(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };

  

  const handleTypeSelect = (selectedKey) => {
    setType(selectedKey);
    
  }
  

  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
    }
  };
  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
    
  };

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const headerNames = columns.map((column) => column.name);
  const csvData = data?.map((item) =>
    columns.reduce((row, column) => {
      if (column.id === 'PrNumber') {
        if(item?.poPrData?.length>1){
          const prNumbers = item?.poPrData?.map((poPrItem) => poPrItem.PrNumber).filter(Boolean).join(', ') || '';
          row[column.name] = prNumbers ? `(${prNumbers})` : '';
        }
        else{
          row[column.name] =item?.poPrData?.length==1? parseInt(item?.poPrData[0]?.PrNumber) : "";
        }
        
      }
      else if (column.id === 'PrShortText') {
        const prshortText = item?.poPrData?.map((poPrItem) => poPrItem.PrShortText).filter(Boolean).join(', ') || '';
        row[column.name] = prshortText ? `(${formatStringForCSV(prshortText)})` : '';
      }
      else {
        row[column.name] = column.selector ? column.selector(item) : item[column.id] || '';
      }
      return row;
    }, {})
  ) || [];
  return (
    <>
    {( loading) && <PageLoader/>}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="history-table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "History Storage"
                      ? "history-supplier-visit-table-active"
                      : "history-supplier-visit-table"
                  }
                  eventKey="History Storage"
                >
                  {t("History Storage")}
                  <span
                    className={
                      type === "History Storage"
                        ? "history-supplier-visit-table-badge-active"
                        : "history-supplier-visit-table-badge"
                    }
                    eventKey="History Storage"
                  >
                    {(poData?.result?.TotalCount) || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <DateRangePicker
              appearance="default"
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
              disabled={loading}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>{t("Show")} </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>{t("entries")}</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="po_table"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            data.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            className="history-table"
            columns={columns}
            data={data}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows={true}
            expandableRowDisabled={(row) => !row?.poPrData || row?.poPrData?.length<2}
            expandableRowExpanded={(row) => row?.poPrData && row?.poPrData?.length>1}
            expandableRowsComponent={ExpandedComponent}
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="history-table-search-div"
          >
            <div style={{ marginLeft: "15px" }}>
              {columns.map((column) =>
                column.searchable !== false ? (
                  <input
                    key={column?.id}
                    type="text"
                    value={searchValues[column.id] || ""}
                style={{ width: column.width, marginRight:"4px"}}
                onChange={(event) =>
                  handleSearchChange(event, column?.id)
                }
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default HistoryStorageTable;
