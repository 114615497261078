import {
  React,
  useEffect,
  useForm,
  useDispatch,
  useSelector,
  toast,
  useTranslation,
} from "../../Imports/import";
import { updatedDataFourth } from "../../../Store/Reducer/registrationReducers";
import {
  formatNumberWithCommas,
  handleKeyDown,
  handleOnWheel,
} from "../../../Utils/Utils";
import { useState } from "react";

const CommercialEvalutaion = ({ setType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vendorData = useSelector((state) => state.registration.fourthObject);
  const onClickBack = () => {
    setType("Financial");
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [formattedMinJobsOrder, setFormattedMinJobsOrder] = useState(
    vendorData.min_jobs_order || ""
  );
  const [formattedMaxJobsOrder, setFormattedMaxJobsOrder] = useState(
    vendorData.max_jobs_order || ""
  );

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      min_jobs_order: data?.min_jobs_order?.replace(/,/g, ""),
      max_jobs_order: data?.max_jobs_order?.replace(/,/g, ""),
    };
    const min = parseInt(formattedData?.min_jobs_order);
    const max = parseInt(formattedData?.max_jobs_order);
    if (min >= max) {
      toast.error(
        "Invalid input. Please ensure minimum value is lower than maximum value."
      );

      return;
    }

    dispatch(updatedDataFourth(formattedData));
   
    setType("Supplier");
  };

  
  useEffect(() => {
    Object.entries(vendorData).forEach(([name, value]) => {
      setValue(name, value);
      setFormattedMinJobsOrder(
        formatNumberWithCommas(vendorData?.min_jobs_order) || ""
      );
      setFormattedMaxJobsOrder(
        formatNumberWithCommas(vendorData?.max_jobs_order) || ""
      );
    });
  }, [vendorData, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="pb-5">
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t(
                  "Maximum and Minimum Value of jobs/order that you can accept?"
                )}
              </label>
              <br />
              <div className="maximum-minimum-tags">
                <p className="minimum">{t("Minimum")}</p>
                <p className="maximum">{t("Maximum")}</p>
              </div>
              <div className="maximum-minimum">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    className="input-border registration-max"
                    {...register("min_jobs_order", {
                      required: false,
                      maxLength: 11,
                    })}
                    value={formattedMinJobsOrder}
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                      const formattedValue = formatNumberWithCommas(rawValue);
                      setFormattedMinJobsOrder(formattedValue);
                      setValue("min_jobs_order", rawValue);
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  ></input>
                  {errors.min_jobs_order && (
                    <span className="error-msg">
                      {t("Please enter the correct value")}{" "}
                    </span>
                  )}
                </div>
                <p>{t("To")}</p>

                <br />
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    className="input-border registration-max"
                    {...register("max_jobs_order", {
                      required: false,
                      maxLength: 11,
                    })}
                    value={formattedMaxJobsOrder}
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                      const formattedValue = formatNumberWithCommas(rawValue);
                      setFormattedMaxJobsOrder(formattedValue);
                      setValue("max_jobs_order", rawValue);
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  ></input>
                  {errors.max_jobs_order && (
                    <span className="error-msg">
                      {t("Please enter the correct value")}{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Does your company limit on the size of works or projects?")}
              </label>
              <br />

              <select
                type="text"
                className="input-border doesyourcompany custom-select"
                {...register("company_limit_size_project", {
                  required: false,
                  maxLength: 80,
                })}
              >
                <option value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="no">{t("No")}</option>
                <option value="yes">{t("Yes, in value....")}</option>
              </select>
              {errors.company_limit_size_project && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
            </div>
          </div>
          {watch("company_limit_size_project") === "yes" && (
            <div className="textbox-container-specify">
              <div className="right-container-specify">
                <br />

                <input
                  type="number"
                  className="input-border registration-left "
                  placeholder="please enter a value as a number"
                  {...register("size_specify", {
                    required: false,
                    maxLength: 80,
                  })}
                  onWheel={handleOnWheel}
                />

                {errors.size_specify && (
                  <span className="error-msg">
                    {t("Please enter other specific data")}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t(
                  "Does your company have the procedure to analyses the late delivery of goods and/or service failure"
                )}
                <span className="staricon">*</span>
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("analyses_process_for_late_delivery", {
                  required: false,
                })}
              >
                {" "}
                <option value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="yes">{t("Yes")}</option>
                <option value="no">{t("No")}</option>
              </select>
              {errors.analyses_process_for_late_delivery && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label-financial-project-ref">
                {t("Project reference / Work reference")}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("project_reference", {
                  required: false,
                  maxLength: 80,
                })}
              >
                {" "}
                <option value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="no">{t("No")}</option>
                <option value="yes">{t("Yes, please specify")}</option>
              </select>
              {errors.project_reference && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
            </div>
          </div>
          {watch("project_reference") === "yes" && (
            <div className="textbox-container-specify">
              <div className="right-container-specify">
                <br />

                <input
                  type="text"
                  className="input-border registration-left "
                  {...register("specify_project_reference", {
                    required: false,
                    maxLength: 80,
                  })}
                />
                {errors.specify_project_reference && (
                  <span className="error-msg">
                    {t("Please enter other specific data")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="buttons pt-5">
          <div className="button-submit-container-back">
            <input
              className="button-submit"
              type="submit"
              value={t("Back")}
              onClick={onClickBack}
            />
          </div>
          <div className="button-submit-container">
            <input className="button-submit" type="submit" value={t("Next")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default CommercialEvalutaion;
