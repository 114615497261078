import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./CreateAndEditUser.css";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
import editIcon from "../../Assets/Svg/edit-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  editParticularUser,
} from "../../Store/actions/userManagementAction";
import { handleKeyDown } from "../../Utils/Utils";
import showPass from "../../Assets/Images/show-password.png";
import hidePassword from "../../Assets/Images/hide-password.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { getAllGroupListForUser } from "../../Store/Reducer/allGroupListForUser";
import { useTranslation } from "../../Components/Imports/import";

const CreateAndEditUser = ({
  isOpen,
  closeModal,
  formTitle,
  scenarioType,
  userData,
}) => {
  const { GroupListForUser } = useSelector(
    (state) => state.allGroupListForUser
  );
  const { currentRow } = useSelector((state) => state.getUserManagementInfo);
  const [areYouSurePopup, showAreYouSurePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    setShowPassword(false);
    //This condition for create new user data dispatching
    if (scenarioType === "createUser") {
      data["confirm_password"] = data.password;

      dispatch(createUser(data));
      showAreYouSurePopup(true);
      setIsEditMode(true);
    } else if (scenarioType === "editUser") {
      // This condition for dispatching the edit user scenario
      data.admin_user_id = data.user_id;
      const {
        confirm_password,
        created_at,
        updated_at,
        user_name,
        user_id,
        newpassword,
        ...newEdit
      } = data;



      const dispatchData = { ...newEdit };

      // Check if newpassword exists, and if so, assign it as password
      if (newpassword !== undefined) {
        dispatchData.password = newpassword;
      }

      showAreYouSurePopup(true);
      dispatch(editParticularUser(dispatchData));
      setIsEditMode(true);
    }
  };

  const closeAreYouSure = () => {
    showAreYouSurePopup(false);
    if (scenarioType !== "createUser") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  };

  const onClickEdit = () => {
    setIsEditMode(false);
  };

  const onClickCancel = () => {
    if (scenarioType !== "createUser") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
      reset();
    }
    closeModal();
  };
  const strongPasswordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const weakPasswordPattern = /^[A-Za-z\d@$!%*#?&]{6,}$/;

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // phone number validations
  const validatePhoneNumber = (value) => {
    if (!value) {
      return t("Please enter the mobile phone number.");
    }

    if (value && !isValidPhoneNumber(value)) {
      return t("Mobile number is invalid.");
    }

    return true; // Return true to indicate validation success
  };

  useEffect(() => {
    if (currentRow && scenarioType == "editUser") {
      const updatedRow = { ...currentRow }; // Create a copy of currentRow
      delete updatedRow.password;
      Object.keys(updatedRow).forEach((key) => {
        setValue(key, updatedRow[key]);
      });
    }
  }, [currentRow, setValue]);

  useEffect(() => {
    if (scenarioType === "createUser") {
      setIsEditMode(false);
      reset();
    } else {
      setIsEditMode(true);
    }
  }, [scenarioType]);

  useEffect(() => {
    dispatch(getAllGroupListForUser());
  }, [isOpen]);

  return (
    <>
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className="create-user-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="create-user-title">
            <div className="d-flex">
              <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
              {isEditMode && (
                <button
                  type="button"
                  onClick={onClickEdit}
                  className="user-edit-btn"
                >
                  <img src={editIcon} alt="" /> Edit
                </button>
              )}
            </div>
            <img src={closeIcon} alt="" onClick={onClickCancel} />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="create-user-form"
            onKeyDown={handleKeyDown}
          >
            <label>Full Name</label> <br />
            <input
              type="text"
              {...register("name", {
                required: "Please enter a full name",
                pattern: {
                  value:
                    /^[A-Za-z\sก-๙เแ่้๊๋า-ู็ิ์ํฺ\[\]{}()\-_.,:;'"!@#$%^&*+=<>?~\d]+$/,
                  message: "Please enter a valid name",
                },
                maxLength: {
                  value: 50,
                  message: "Name cannot exceed 50 characters",
                },
              })}
              disabled={isEditMode}
            />
            <br />
            {errors.name && (
              <span className="error-msg">{errors.name.message}</span>
            )}
            <br />
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {scenarioType === "editUser" ? (
                  <div>
                    <label>Email</label>
                    <br />
                    <input
                      type="email"
                      style={{ width: "28vw" }}
                      {...register("email", {
                        required: "Please enter the email",
                        pattern: {
                          value:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                          message: "Please enter a valid email address.",
                        },
                      })}
                      disabled={isEditMode}
                    />
                    <br />
                    {errors.email && (
                      <span className="error-msg">{errors.email.message}</span>
                    )}
                  </div>
                ) : (
                  <div>
                    <label>Email</label>
                    <br />

                    <input
                      type="email"
                      autocomplete="off"
                      name="user_name_user"
                      style={{ width: "28vw" }}
                      {...register("email", {
                        required: "Please enter the email",
                        pattern: {
                          value:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                          message: "Please enter a valid email address.",
                        },
                      })}
                      disabled={isEditMode}
                    />
                    <br />
                    {errors.email && (
                      <span className="error-msg">{errors.email.message}</span>
                    )}
                  </div>
                )}
              </div>
              <div>
                <label>Group</label>
                <br />
                <div className="group-dropdown">
                  <select
                    {...register("assigner_type", { required: true })}
                    disabled={isEditMode}
                    style={{ width: "28vw" }}
                  >
                    <option value="" hidden>
                      Which group would you like to assign user?
                    </option>

                    {GroupListForUser?.length > 0 &&
                      GroupListForUser?.map((e, index) => {
                        return (
                          <option key={index} value={e?.group_name}>
                            {e?.group_name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.assigner_type && (
                    <span className="error-msg">Please select the group</span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <label>Password</label>
                <br />
                {/* {scenarioType === "editUser" ? (
                  <div>
                    <Controller
                      disabled
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="password"
                          style={{ width: "28vw" }}
                          {...field}
                          disabled
                        />
                      )}
                    />
                    <br />

                    <br />
                  </div>
                ) : ( */}
                <div>
                  <Controller
                    disabled={isEditMode}
                    name={
                      scenarioType === "editUser" ? "newpassword" : "password"
                    }
                    control={control}
                    rules={{
                      required:
                        scenarioType === "editUser"
                          ? false
                          : "Password is required.",
                      validate: (value) => {
                        if (!value && scenarioType === "editUser") {
                          return true;
                        } else if (value && strongPasswordPattern.test(value)) {
                          return true;
                        } else if (value && weakPasswordPattern.test(value)) {
                          return "Password is weak. Use at least 8 characters with a combination of letters at least one CAPITAL LATTER, numbers, and special characters.";
                        } else if (value) {
                          return "Invalid password format.";
                        }
                      },
                    }}
                    render={({ field }) => (
                      <div
                        style={{
                          width: "28vw",
                          border: "1px solid #ebebeb",
                          borderRadius: "5px",
                          height: "45px",
                        }}
                        className="d-flex justify-content-between"
                      >
                        <input
                          style={{ border: "0px" }}
                          autocomplete="new-password"
                          type={showPassword ? "text" : "password"}
                          maxLength={16}
                          disabled={isEditMode}
                          placeholder={
                            scenarioType === "editUser" && isEditMode
                              ? "**********"
                              : ""
                          }
                          {...field}
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          style={{ padding: "5px" }}
                          disabled={isEditMode}
                        >
                          <img
                            src={showPassword ? hidePassword : showPass}
                            alt=""
                          />
                        </button>
                      </div>
                    )}
                  />
                  <br />
                  {errors[
                    scenarioType === "editUser" ? "newpassword" : "password"
                  ] && (
                    <span className="error-msg">
                      {
                        errors[
                          scenarioType === "editUser"
                            ? "newpassword"
                            : "password"
                        ].message
                      }
                    </span>
                  )}
                </div>
                {/* )} */}
              </div>
              <div>
                <label>Phone Number</label>
                <br />
                <Controller
                  name="phone_number"
                  control={control}
                  rules={{
                    validate: (value) => validatePhoneNumber(value),
                  }}
                  render={({ field }) => {
                    // const initialValue = "+66"; // Thailand's country code
                    // const formattedInitialValue =
                    //   initialValue + (field.value || "");
                    return (
                      <PhoneInput
                        {...field}
                        defaultCountry={"TH"}
                        style={{ width: "28vw" }}
                        disabled={isEditMode}
                      />
                    );
                  }}
                />
                <br />
                {errors.phone_number && (
                  <span className="error-msg">
                    {errors.phone_number.message}
                  </span>
                )}
              </div>
            </div>
            {!isEditMode && scenarioType === "editUser" && (
              <p style={{ flexWrap: "nowrap", color: "gray" }}>
                <strong>Note :</strong> If you want to change password please
                enter the password. For continue with old password put password
                field as empty.{" "}
              </p>
            )}
            <br />
            <div className="pt-3 create-btns-div">
              <button
                type="button"
                className="um-create-btn"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="um-submit-btn"
                disabled={isEditMode}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </div>

      <CustomPopup
        isOpenPopup={areYouSurePopup}
        closePopup={closeAreYouSure}
        image={areIcon}
        title="Are you sure!"
        text={
          userData
            ? "You want to Update this user ?"
            : "You want to Create this User ?"
        }
        btn1="Cancel"
        btn2="Confirm"
        successTitle="Done!"
        successText={
          userData
            ? "User Details Updated Successfully"
            : "Create User Successfully"
        }
        successIcon={successIcon}
        btn3="Ok"
        closeFormPopup={closeModal}
        reset={reset}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        scenarioType={userData ? "editUser" : "createUser"}
      />
    </>
  );
};

export default CreateAndEditUser;
