import React, { useEffect, useState } from "react";
import "./EvaluatedPage.css";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader, useDispatch, useSelector, useTranslation } from "../../../src/Components/Imports/import";
import VectorArrow from "../../Assets/Images/backArrow.png";
import { getEvaluationFormData, submitEvaluation, updateEvaluation } from "../../Store/actions/supplierManagementActions";
import { formatNumber } from "../../Utils/Utils";

function GoodsEvaluation({evaluationFormById,isReadOnly = false,isEmailUser=false,id}) {
  const [ratings, setRatings] = useState([]);
  const [ratingsData, setRatingsData] = useState([]);
  const [scores,setScores]=useState({});
  const dispatch=useDispatch();
    const [data,setData]=useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const {loading,error } = useSelector(
      (state) => state.getSupplierManagementInfo
    );
    const [topic1Score,setTopic1Score]=useState(evaluationFormById?.topic1Score || 0)
    const [topic2Score,setTopic2Score]=useState(evaluationFormById?.topic2Score || 0)
    const [topic3Score,setTopic3Score]=useState(evaluationFormById?.topic3Score || 0)
    useEffect(() => {
      setTopic1Score(evaluationFormById?.topic1Score || 0);
      setTopic2Score(evaluationFormById?.topic2Score || 0);
      setTopic3Score(evaluationFormById?.topic3Score || 0);
  
      if (evaluationFormById?.evaluationRatings) {
        const initialRatingsData = evaluationFormById.evaluationRatings.map(({ evaluationRatingId, rating }) => ({
          evaluationRatingId,
          rating,
        }));
        setRatingsData(initialRatingsData);
      } else {
        setRatingsData([]);
      }
          if (evaluationFormById?.evaluationRatings) {
      const groupedTopics = evaluationFormById.evaluationRatings.reduce((acc, curr) => {
        const { topic, subTopic, evaluationRatingId, rating } = curr;
        const { topicId, topicName, topicWeight } = topic;
        const existingTopic = acc.find(t => t.topicId === topicId);
    
        const subTopicData = {
          subTopicId: subTopic.subTopicId,
          subTopicName: subTopic.subTopicName,
          evaluationRatingId,
          rating,
        };
    
        if (existingTopic) {
          existingTopic.subTopics.push(subTopicData);
        } else {
          acc.push({
            topicId,
            topicName,
            topicWeight,
            subTopics: [subTopicData],
          });
        }
        return acc;
      }, []);
      
      setData(groupedTopics);
    } else {
      setData([]);
    }
    if (evaluationFormById?.evaluationRatings) {
      const groupedTopics = evaluationFormById.evaluationRatings.reduce((acc, curr) => {
        const { topic, subTopic, evaluationRatingId, rating } = curr;
        const { topicId, topicName, topicWeight } = topic;
        const existingTopic = acc.find(t => t.topicId === topicId);
    
        const subTopicData = {
          subTopicId: subTopic.subTopicId,
          subTopicName: subTopic.subTopicName,
          evaluationRatingId,
          rating,
        };
    
        if (existingTopic) {
          existingTopic.subTopics.push(subTopicData);
        } else {
          acc.push({
            topicId,
            topicName,
            topicWeight,
            subTopics: [subTopicData],
          });
        }
        return acc;
      }, []);
      setData(groupedTopics);
    } else {
      setData([]);
    }
    const checkboxData=initializeSelectedCheckboxes(evaData)
    
    setSelectedCheckboxes(checkboxData)
    const formattedObject = groupedTopics?.reduce((acc, topic) => {
      const subTopicRatings = topic?.subTopics.reduce((subAcc, subTopic) => {
        subAcc[subTopic.subTopicId] = subTopic?.rating;
        return subAcc;
      }, {});
      acc[topic.topicId] = subTopicRatings;
      return acc;
    }, {});
    setScores(formattedObject)

      if (isEmailUser && evaluationFormById?.deliveryStatus === 1) {
        const updateScoresState = (prevScores, key1, key2, value) => {
          return {
            ...prevScores,
            [key1]: {
              ...prevScores[key1],
              [key2]: value
            }
          };
        };
        setScores((prevScores) => updateScoresState(prevScores, 1, 1, 4));        
        
        setRatingsData(prevRatingsData => {
          const filteredRatingsData = prevRatingsData.filter(
            item => item.evaluationRatingId !== prevRatingsData[0].evaluationRatingId
          );
          return [
            { 
              evaluationRatingId: prevRatingsData[0].evaluationRatingId, 
              rating: 4 
            },
            ...filteredRatingsData
          ];
        });
      }
    },[evaluationFormById,id])
    //function for grouping the data by topics
    const groupedTopics = evaluationFormById?.evaluationRatings?.reduce((acc, curr) => {
        const { topic, subTopic, evaluationRatingId, rating } = curr;
        const { topicId, topicName, topicWeight } = topic;
        const existingTopic = acc.find(t => t.topicId === topicId);
    
        const subTopicData = {
            subTopicId: subTopic.subTopicId,
            subTopicName: subTopic.subTopicName,
            evaluationRatingId,
            rating,
        };
    
        if (existingTopic) {
            existingTopic.subTopics.push(subTopicData);
        } else {
            acc.push({
                topicId,
                topicName,
                topicWeight,
                subTopics: [subTopicData],
            });
        }
    
        return acc;
    }, []);
    

  

  const vendorInfo = [
    {
      label: "PO Number",
      value: evaluationFormById?.poNo,
    },
    {
      label: "Vendor Code",
      value: evaluationFormById?.vendorCode,
    },
   
  ];

  useEffect(() => {
    if (groupedTopics) {
      setData(groupedTopics);
    }
  }, [evaluationFormById]);
  
  const evaHeaders = ["No.", "Evaluation", "(%)", "4", "3", "2", "1"];
  const evaHeadersWidths = ["6%", "47%", "10%", "10%", "8%", "10%", "10%"];
  
  
  
  const evaData = groupedTopics?.flatMap((topic, index) => {
    let labels;
    if (index === 1) {
      labels = ["Complete", "", "", "Incomplete"];
    } else if (index === 2) {
      labels = ["Very Good", "", "", "Bad"];
    } else {
      labels = ["OnTime", "", "", "Delayed"];
    }

  
    //structure for the topicRow
    const topicRow = {
      isTopic: true, 
      topicId: topic.topicId,
      subTopicId: null, 
      cells: [
        `${index + 1}.`,
        topic.topicName,
        `${topic.topicWeight}%`,
        ...labels
      ]
    };
  
   
    const subTopicRows = topic.subTopics.map((subTopic) => ({
      isTopic: false,
      topicId: topic?.topicId,
      subTopicId: subTopic?.subTopicId,
      rating:subTopic?.rating,
      evaluationRatingId:subTopic?.evaluationRatingId,
      cells: [
        " ",
        subTopic.subTopicName,
        "", "", "", "", ""
      ]
    }));
  
    return [topicRow, ...subTopicRows];
  }) ||[];
  
  evaData.push({
    isTopic: false,
    cells: [
      "",              
      "Column Totals", 
      "100%",          
      "", "", "", "",  
    ]
  });
  evaData.push({
    isTopic: false,
    cells: [
      "",              
      "Grand Total", 
      "",          
      "", "", "", Math.ceil((topic1Score+topic2Score+topic3Score)*100),  
    ]
  });

  //function to get sum of keys
  function getSumOfValuesForKey(key){
   
    const valuesObject = scores[key];
    if (valuesObject) {
        return Object.values(valuesObject).reduce((sum, value) => sum + value, 0);
    }
    return 0;
};
function calculateScore (index){
    
    const sumForKey = getSumOfValuesForKey(index);
    
    const numOfSubTopics = Object.keys(scores[index] || {}).length;
    const topicWeight = groupedTopics[index-1]?.topicWeight || 1; 
    const score1 = numOfSubTopics ? ((sumForKey )/ (numOfSubTopics * 4)) * (topicWeight/100) : 0;
    return score1?score1:0;
}

  //function for marking selected checkboxes 
  function initializeSelectedCheckboxes (data) {
    return data.reduce((acc, row, index) => {
      if (!row.isTopic && row.rating !== undefined) {
        acc[index] = row.rating;
      }
      return acc;
    }, {});
  };
  useEffect(()=>{
   
  },[evaluationFormById,id])
  useEffect(() => {
    const score2=calculateScore(2)
    const score1=calculateScore(1);
    const score3=calculateScore(3);
    setTopic1Score(score1)
    setTopic2Score(score2);
    setTopic3Score(score3);
  },[scores])
  
  //function for the rating 
  function getRating(percentage) {
    switch (true) {
      case (percentage > 90):
        return 'excellent';
      case (percentage > 80):
        return 'good according to standard';
      case (percentage > 70):
        return 'fair level';
      case (percentage > 60):
        return 'acceptable';
      case (percentage > 50):
        return 'should improve';
      default:
        return 'below standard'; 
    }
  }
//function to send the data
  function handleSend(){
const scoreLevel=getRating((topic1Score+topic2Score+topic3Score)*100)
      const bodyData={
  vendorCode:evaluationFormById?.vendorCode,
  poNo: evaluationFormById?.poNo,
  evaluatorEmail: evaluationFormById?.evaluatorEmail,
  evaluatedDate: evaluationFormById?.evaluatedDate,
  topic1Score: topic1Score, 
  topic1Percantages: topic1Score*100, 
  topic2Score: topic2Score,
  topic2Percantages: topic2Score*100,
  topic3Score: topic3Score,
  topic3Percantages: topic3Score*100,
  overAllPercantages: (topic1Score+topic2Score+topic3Score)*100,
  overAllScore: topic1Score+topic2Score+topic3Score,
  scoreLevel: scoreLevel,
  evaluationFormId: evaluationFormById?.evaluationFormId,
  PoPrDbId: evaluationFormById?.PoPrDbId,
  PoDbId: evaluationFormById?.PoDbId,
  vendorId: evaluationFormById?.vendorId,
  ratingsData:ratingsData,
      }
  isEmailUser?dispatch(submitEvaluation(id,bodyData)):dispatch(updateEvaluation(id,bodyData))  
  
  

  }


  //function to handle checkbox click
  const handleCheckboxClick = (topicId, subTopicId, rowIndex, cellIndex, evaluationRatingId) => {
    const rating = parseInt(evaHeaders[cellIndex]);
    const isAlreadySelected = selectedCheckboxes[rowIndex] === rating;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [rowIndex]: isAlreadySelected ? 0 : rating,
    }));
  
    
    setRatingsData((prevRatings) => {
      const index = prevRatings.findIndex(
        (item) => item.evaluationRatingId === evaluationRatingId
      );
      const updatedRatings = [...prevRatings];
  
      if (index !== -1) {
        updatedRatings[index] = {
          ...updatedRatings[index],
          rating: isAlreadySelected ? 0 : rating,
        };
      } else {
        updatedRatings.push({
          evaluationRatingId,
          rating,
        });
      }
  
      return updatedRatings;
    });
    

    setScores((prevScores) => {
      const updatedScores = JSON.parse(JSON.stringify(prevScores));
      if (updatedScores[topicId] && updatedScores[topicId][subTopicId] !== undefined) {
        updatedScores[topicId][subTopicId] = isAlreadySelected ? 0 : rating;
      }
      return updatedScores;
    });

  };
  
  const TOTAL_ROW_INDEX = evaData.length;
  const GRAND_TOTAL_ROW_INDEX = evaData.length;

  // Functions
  const shouldHideBorder = (cellIndex, rowIndex) => {
    return (
      (rowIndex === evaData.length && cellIndex >= 0 && cellIndex <= 1) ||
      (rowIndex === evaData.length+2 && cellIndex >= 0 && cellIndex <= 5)
    );
  };

  const getTextAlignStyle = (cellIndex, rowIndex) => {
    return (rowIndex === TOTAL_ROW_INDEX ||
      rowIndex === GRAND_TOTAL_ROW_INDEX) &&
      cellIndex >= 0
      ? "end"
      : "";
  };

  const isSpecialCell = (cellIndex, rowIndex, isTopic) => {
    if (isTopic) {
      return true;
    } else if (
      cellIndex === 2 && [1, 2, 3].includes(rowIndex)
    ) 
    return false;
  };

  const getBackgroundColor = (cellContent, cellIndex, rowIndex) => {
    if (
      cellContent === "" &&
      [2, 4, 5].includes(cellIndex) && //create an array of evaData length
      [1,2, 3, 4, 5, 6,7, 8, 9, 10,11,12,13,14,15,16].includes(rowIndex)
    ) {
      return "#ADB2B9";
    } else if (rowIndex === evaData.length-2 && cellIndex >= 0 && cellIndex <= 1) {
      return "#adb2b9"; // Background color for column totals row
    } else if (rowIndex === evaData.length-1 && cellIndex >= 0 && cellIndex <= 5) {
      return "#adb2b9"; // Background color for grand total row
    } else if (rowIndex === 1 && cellIndex >= 6 && cellIndex <= 6) {
      return ""; // Background color for grand total row
    }
    return "";
  };

  const getCellStyle = (cellContent, cellIndex, rowIndex) => {
    if (rowIndex === TOTAL_ROW_INDEX && cellContent === "100%") {
      return {
        color: "#103a5d",
        textAlign: getTextAlignStyle(cellIndex, rowIndex),
        width: evaHeadersWidths[cellIndex],
        backgroundColor: getBackgroundColor(cellContent, cellIndex, rowIndex),
        border: shouldHideBorder(cellIndex, rowIndex) ? "none" : "",
      };
    } else {
      return {
        width: evaHeadersWidths[cellIndex],
        backgroundColor: getBackgroundColor(cellContent, cellIndex, rowIndex),
        border: shouldHideBorder(cellIndex, rowIndex) ? "none" : "",
        textAlign: getTextAlignStyle(cellIndex, rowIndex),
      };
    }
  };


  const EvaluatedTableRow = ({ rowIndex, row, topicId, subTopicId,rating,isTopic,evaluationRatingId }) => (
    <div
      className={`evaluated-table-row ${
        rowIndex === TOTAL_ROW_INDEX || rowIndex === GRAND_TOTAL_ROW_INDEX
          ? "eva-tbl-total-row"
          : ""
      }`}
    >
      {row?.map((cellContent, cellIndex) => (
        <EvaluatedTableCell
          key={cellIndex}
          cellContent={cellContent}
          cellIndex={cellIndex}
          rowIndex={rowIndex}
          topicId={topicId} 
          subTopicId={subTopicId} 
          rating={rating}
          isTopic={isTopic}
          evaluationRatingId={evaluationRatingId}
        />
      ))}
    </div>
  );
  const EvaluatedTableCell =({ cellContent, cellIndex, rowIndex, topicId, subTopicId, isTopic,rating,evaluationRatingId }) => {
      const specialCell = isSpecialCell(cellIndex, rowIndex,isTopic);
      const style = getCellStyle(cellContent, cellIndex, rowIndex);
  
      const isCheckbox = !isTopic && cellContent === "" &&
      
        ((evaHeaders[cellIndex] === "4" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||
         (evaHeaders[cellIndex] === "1" &&
          rowIndex >= (data[0]?.subTopics?.length + 1 || 0) &&
          rowIndex <= evaData.length - 3));
          const isLockedCheckbox = isEmailUser && 
          evaluationFormById?.deliveryStatus === 1 && 
          rowIndex === data[0]?.subTopics?.length && 
          evaHeaders[cellIndex] === "4";

           const isChecked = isLockedCheckbox || selectedCheckboxes[rowIndex] === parseInt(evaHeaders[cellIndex]);

  
      return (
        <div
          className={`evaluated-table-cell ${specialCell ? "special-cell" : ""}`}
          style={style}
        >
          {isCheckbox ? (
            <input
              className="eva-table-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheckboxClick(topicId, subTopicId, rowIndex, cellIndex,evaluationRatingId)}
              disabled={isReadOnly || isLockedCheckbox}
            />
          ) : (
            
            <p style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{cellContent}</p>
          )}
        </div>
      );
    };
  
  
  //small table
  const EvaSmallHeaders = ["grade level", "criterion"];
  const EvaSmallData = [
    ["more than 90%", "excellent"],
    ["more than 80%", "good according to standard"],
    ["more than 70%", "fair level"],
    ["more than 60%", "acceptable"],
    ["more than 50%", "should improve"],
  ];

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/Supplier-Management");
  };


  return (
  
      <section className={`${isEmailUser?"um-not-main":"um-main"}`}>
        {loading && <PageLoader/>}
        <div className="um-title-btn">
          {!isEmailUser &&<div className="back-button" onClick={handleGoBack}>
            <img src={VectorArrow} alt="Back Arrow" />
            {t("back")}
          </div> }
          
        </div>
        <br></br>
        <h1 className="hs-title">{t("Evaluated")}</h1>
        <br></br>

        <div className="evaluated-info-container">
          <input className="evaluated-info-box" type="text" value={evaluationFormById?.evaluatorEmail} readOnly/>
          
          <input className="evaluated-info-box" type="date" value={evaluationFormById?.evaluatedDate ? evaluationFormById.evaluatedDate.split('T')[0] : ''}  readOnly/>
        </div>

        <br></br>

        <div className="evaluated-section">
          <div className="evaluated-vendor-title">
            <div className="evaluated-vendor-title-text">
              {t("Vendor/ Supplier Information")}
            </div>
          </div>
          <div className="evaluated-vendor-info-container">
            {vendorInfo.map((item) => (
              <div key={item.label} className="evaluated-vendor-info">
                <div className="evaluated-vendor-label">{item.label}:</div>
                <div className="evaluated-vendor-value">{item.value}</div>
              </div>
            ))}
          </div>
        </div>
        <br></br>
        {/* Table code */}
        
        <div  className={` ${isReadOnly ? "eva-read-only disabled-table" : ""} evaluated-table `}>
          <div className="evaluated-table-row eva-tbl-header-row">
            {evaHeaders.map((header, index) => (
              <div
                key={index}
                className={`evaluated-table-header-cell ${
                  index === 0 ? "header-no-cell" : ""
                } eva-tbl-header-textt`}
                style={{
                  width: evaHeadersWidths[index],
                }}
              >
                {header}
              </div>
            ))}
          </div>
          {evaData.map((row, rowIndex) => (
            
  <EvaluatedTableRow
    key={rowIndex}
    rowIndex={rowIndex}
    row={row?.cells}
    topicId={row?.topicId}
    subTopicId={row?.subTopicId}
    isTopic={row?.isTopic} 
    rating={row?.rating}
    evaluationRatingId={row?.evaluationRatingId}
  />
))}
        </div>
        <br></br>
        <br></br>
        {/* small table */}
        <div className="EvaSmall-table-container">
          <table className="eva-table">
            <thead>
              <tr>
                {EvaSmallHeaders.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {EvaSmallData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {rowData.map((cellData, cellIndex) => (
                    <td key={cellIndex}>{cellData}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br></br>
        {/* Note code */}
        <div className="eva-note-container">
          <label className="eva-note-title">{t("Note")}</label>
          <p className="eva-note-content">
            Consideration of trading partners whose assessment results are below
            the criteria by partners who have an assessment score of less than
            50 points for the first time Will be notified by a letter from the
            procurement manager via VMS system to let the trader know and
            improve by continuing CA / PA and if the score is less than 50
            points in the next assessment cycle procurement will be presented to
            consider stopping the work and/or entering the process of reviewing
            supplier information (Black List).
          </p>
        </div>
        <br></br>
        {!isReadOnly && <div style={{display:"flex",justifyContent:"flex-end"}}> <button  className="table-btn" onClick={handleSend}>Send</button></div>}
        
        <br></br>
      
        <br></br>
       
      </section>
   
  );
}

export default GoodsEvaluation;
