export const COUNTRY_LIST = "statecity-common/Get-Country";
export const STATE_LIST = "statecity-common/Get-State-By-Country";
export const CITY_LIST = "statecity-common/Get-City-By-Country-State";
export const CHECK_TAX_NO = "vendor/check-tax-Registration";
export const ADD_VENDOR = "admin/Add-Vendor";
export const ADMIN_LOGIN = "admin/Admin-login";
export const ADD_PRESS_RELEASE = "press/Add-Press";
export const DELETE_FILE = "admin/Admin-Delete-Document-For-Vendor";
export const ADMIN_UPDATE_VENDOR = "admin/Admin-Update-Vendor";
export const LOGIN_DETAILS_UPDATE = "userdashboard/login-User-Profile-Update";
export const GET_LOGIN_DETAILS = "admin/Admin-Details";

// vendor management Api's
export const GET_USER_LIST_FOR_APPROVAL = "userdashboard/Get-Users-By-Group";
export const GET_ALL_VENDORS = "admin/Get-All-Vendors";
export const GET_SINGLE_VENDOR = "admin/Get-Vendor-Profile?vendor_id=";
export const VENDOR_APPROVAL = "userdashboard/Vendor-Aprroval-By-User";
export const VENDOR_REJECT = "userdashboard/Vendor-Rejection-By-User";
export const GET_VENDOR_HISTORY_BY_ID =
  "userdashboard/Get-VendorApproval-History";
export const DELETE_VENDOR_BY_ADMIN ="userdashboard/Admin-Delete-Vendor?vendor_id=";
export const VENDOR_CHANGES_LOG="AdminDashboard/Admin-Dashboard-Vendors-Changes-Logs";
// user management Api s
export const GET_ALL_GROUPS_FOR_USER_CREATION =
  "admin/Get-All-Groups-For-Creation-User";
export const GET_ALL_USERS = "admin/Get-All-Users";
export const CREATE_USER = "admin/User-Add";
export const EDIT_USER = "admin/User-Update";
export const DELETE_USER = "admin/Admin-Delete-User-Profile?user_id=";
export const GET_ALL_GROUPS = "admin/Get-All-Groups";

// press release end points

export const CREATE_PRESS_RELEASE = "press/Add-Press";
export const EDIT_PRESS_RELEASE = "press/Update-Press";
export const DELETE_PRESS_RELEASE = "press/Delete-Press";
export const GET_ALL_PRESS = "press/Get-All-Press";
export const RELEASE_PRESS = "press/Released-Press";
export const DELETE_PRESS_IMAGE = "press/Delete-Press-Image";
export const GET_PRESS_BY_ID = "press/Get-Press";

// purchase group end points

export const CREATE_PURCHASE_GROUP = "purchasegroup/Create-Purchasing-Group";
export const EDIT_PURCHASE_GROUP = "purchasegroup/Purchasing-Group-Update";
export const DELETE_PURCHASE_GROUP = "purchasegroup/Delete-Purchasing-Group";
export const GET_ALL_PURCHASE_GROUP = "purchasegroup/Get-All-Purchase-Group";
export const RELEASE_PURCHASE_GROUP = "purchasegroup/Purchase-Group-Releasing";
export const GET_PURCHASE_GROUP_BY_ID =
  "purchasegroup/Get-PurchaseGroup-Details";

// pr release end points

export const GET_ALL_PR_RELEASE_GROUP_COUNT =
  "PrService/Get-All-Pr-Company-With-GorupCount";

export const GET_ALL_PR_RELEASE = "PrService/Get-All-Pr-List";

// below api need to integrate component level

export const UPDATE_PR_DETAILS_ATTACHMENT =
  "PrService/Update-Pr-Details-with-Attachment";

export const GET_SINGLE_PR_DETAILS = "PrService/Get-Single-Pr-Details";

export const DELETE_PR = "PrService/Pr-Delete-By-PrCreatedId";

export const GET_ALL_PR_ATTACHMENTS = "PrService/Get-All-AttachmentList-By-Pr";

export const RELEASE_PR = "PrService/Pr-Release";

export const DELETE_PR_ATTACHMENT = "PrService/Pr-Attachment-Delete";

export const GET_PR_STATUS_COUNT = "PrService/Get-Pr-Status-Count";

export const GET_PR_FILTER_DROPDOWN= "PrService/Get-Pr-Groups-List-For-Filter-DropDown"
// job management end points

export const GET_ALL_COMPANY_JOB_STATUS_COUNT =
  "JobService/Get-All-Company-Job-Status-Count";

export const GET_ALL_JOB_LIST = "JobService/Get-All-Job-List";

export const GET_ALL_QUOTATIONS_LIST =
  "JobService/Get-All-Quotation-List-By-Job";

export const GET_SINGLE_JOB_DETAILS = "JobService/Get-Single-Job-Details";

export const UPDATE_JOB_DETAILS = "JobService/Update-Job-Details";

export const UPDATE_JOB_DETAILS_ATTACHMENT =
  "JobService/Upload-Job-Attachments";

export const GET_ALL_JOB_ATTACHMENTS =
  "JobService/Get-Job-All-AttachmentList-By-Job";

export const DELETE_JOB_ATTACHMENT = "JobService/Job-Attachment-Delete";

export const GET_JOB_TABLE_STATUS_COUNT = "JobService/Get-Job-Status-Count";

// supplier managment end points

export const GET_SUPPLIER_TABLE_STATUS_COUNT =
  "PurchaseOrder/Get-Pr-Status-Count-For-Supplier-Managment";

export const GET_ALL_SUPPLIER_LIST =
  "PurchaseOrder/Get-All-PR-Data-For-Suppliers-Management";

// below apis are not integrated

// evaluation end points

export const SAVE_EVALUTATION = "Evaluation/Evaluation-Save";

export const GET_EVALUTATION_BY_ID =
  "Evaluation/Evaluation-Get-Data-By-Evaluation-Id";

export const GET_ALL_EVALUTATION_LIST = "Evaluation/Evaluation-Get-All-Data";

export const GET_EVALUTATION_TABLE_STATUS_COUNT =
  "Evaluation/Evaluation-Get-Status-Count";

export const UPDATE_EVALUTATION_BY_ID = "Evaluation/Evaluation-Update";

export const EVALUATION_SUBMIT_BY_EMAIL_URL =
  "Evaluation/Evaluation-Submit-By-Email-Url";

export const DELETE_EVALUATION = "Evaluation/Evaluation-Delete";

export const EVALUATION_FORM_DROPDOWN =
  "EvaluationForm/Evaluation-Form-GetAllForms-ForDropDown";

export const EVALUATION_FORM_GET_BY_ID =
  "EvaluationForm/Evaluation-Form-GetById";

  //Api endpoints for penalty module
  export const PENALTY_SEND="Penalty/Penalty-Send";
  export const GET_PENALTY_BY_ID="Penalty/Penalty-Get-ById";
  export const ACCEPT_PENALTY_REJECT_BY_ID="Penalty/Penalty-Rejection-Admin-Accept";
  export const REJECT_PENALTY_REJECT_BY_ID="Penalty/Penalty-Rejection-Admin-Reject";

  //Api endpoints for Send PO (Over 1 Million, Excl. Vat)
  export const GET_PO_DATA_OVER_1MILLION="PurchaseOrder/Get-PO-Data-Over1Million";
  export const SEND_PO_ATTACHMENT="PurchaseOrder/Send-Po-By-AttachmentAndId"
  export const DELETE_PO_ATTACHMENT="PurchaseOrder/Delete-Po-AttachmentBy-AttachmentId"


  //Api endpoints for admin dashboard
  export const DASHBOARD_VENDOR_STATUS_COUNT ="AdminDashboard/Admin-Dashboard-Get-All-Vendor-Status-Count";
  export const DASHBOARD_VENDOR_REGISTER_DATA="AdminDashboard/Admin-Dashboard-Get-All-Vendor-Data";
  export const DASHBOARD_PR_STATS_COUNT="AdminDashboard/Admin-Dashboard-Pr-Data-Status-Count";
  export const DASHBOARD_PR_DATA = "AdminDashboard/Admin-Dashboard-Get-All-Pr-Data";
  export const DASHBOARD_PO_STATS_COUNT="AdminDashboard/Admin-Dashboard-Po-Data-Status-Count";
  export const DASHBOARD_PO_DATA="AdminDashboard/Admin-Dashboard-Get-All-Po-Data";
  export const SUPPLIER_PERFORMANCE_DATA="AdminDashboard/Admin-Dashboard-Suppliers-Performance-Data";


  //Api endpoints for history Storage 
  export const HISTORY_STORAGE_PO_DATA="HistoryService/History-Get-All-Po-Data";
  export const HISTORY_STORAGE_VENDOR_DATA="HistoryService/History-Get-All-Vendor-Data-With-PoId";

  //Get Quotation data
  export const QUOTATION_BY_ID="JobService/Job-Quotation-Get-ById";

  //Get PO Data
  export const GET_PO_DATA="PurchaseOrder/Get-Po-ById";

  //Get Penalty Attachments
  export const GET_PENALTY_ATTACHMENTS="Penalty/Penalty-Get-All-Attachments-By-Po-Id"

// Purchasing Group completed

// Press Release paritally completed 50%

// PR release paritally completed 70%

// Job Managment paritally completed 50%

// Supplier management paritally completed 30%

// History Storage 0%

// Customer Dashboard(admin portal) 0%

// Vendor Dash board 0%

// Vendor Follow 0%

// PR+PO for Backend 0%

// Login and Usermanagment completed 100%

// Vendor profile completed 100%
