import TYPES from "../constants";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import { DASHBOARD_PO_DATA, DASHBOARD_PO_STATS_COUNT, DASHBOARD_PR_DATA, DASHBOARD_PR_STATS_COUNT, DASHBOARD_VENDOR_REGISTER_DATA, DASHBOARD_VENDOR_STATUS_COUNT, SUPPLIER_PERFORMANCE_DATA } from "../../ApiEndPoints/ApiEndPoints";
import { cleanObject, convertToQueryString, formatDate } from "../../Utils/Utils";


export const fetchVendorStats=()=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.ADMIN_DASHBOARD_STATUS_COUNT_LOADING
      });
      try {
        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(`${BaseUrl}${DASHBOARD_VENDOR_STATUS_COUNT}`, { headers });
       
        if (response.status === 200){
          dispatch({
            type: TYPES.ADMIN_DASHBOARD_STATUS_COUNT_SUCCESS,
            payload: response.data.data,
          })
        }
        
        else{
          dispatch({
            type: TYPES.ADMIN_DASHBOARD_STATUS_COUNT_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_STATUS_COUNT_FAILURE,
          error:error.message,
        })
      }
    }
  };
  
  // Function to build query params
const buildQueryParams = ({
    page,
    pageSize,
    orderBy,
    orderDir,
    FilterStatus,
    startDate,
    endDate,
    searchByColumNameValue,
  }) => {
    const queryParams = {
      page,
      pageSize,
      orderBy,
      orderDir,
    };
  
    if (FilterStatus && FilterStatus !== "all") {
      queryParams.filterStatus = FilterStatus;
    }
  
    if (startDate && endDate) {
      queryParams.startDate =formatDate(startDate);
      queryParams.endDate = formatDate(endDate);
    }

  
    if (searchByColumNameValue) {
      const cleanedSearchValues = cleanObject(searchByColumNameValue);
      if (Object.keys(cleanedSearchValues).length > 0) {
        queryParams.searchByColumNameValue = JSON.stringify(cleanedSearchValues);
      }
    }
  
    return queryParams;
  };
  
  // Function to build URL with query params
  const buildUrlWithQueryParams = (queryParams, endpoint) => {
    const queryString = convertToQueryString(queryParams);
    return `${BaseUrl}${endpoint}${queryString}`;
  };
  
export const fetchVendorData=(
    page,
    pageSize,
    orderBy,
    orderDir,
    FilterStatus,
    
    startDate,
    endDate,
    searchValues,
    
)=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.ADMIN_VENDOR_REGISTER_LOADING
      });
      try {
        const queryParams = buildQueryParams({
            page,
            pageSize,
            orderBy,
            orderDir,
            FilterStatus,
            startDate,
            endDate,
            searchByColumNameValue: searchValues,
          });
          const url = buildUrlWithQueryParams(
            queryParams,
            DASHBOARD_VENDOR_REGISTER_DATA
          );  
         
          
        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(url, { headers });
    
        if (response.status === 200){
          dispatch({
            type: TYPES.ADMIN_VENDOR_REGISTER_SUCCESS,
            payload: response.data.data,
          })
        }
        
        else{
          dispatch({
            type: TYPES.ADMIN_VENDOR_REGISTER_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.ADMIN_VENDOR_REGISTER_FAILURE,
          error:error.message,
        })
      }
    }
  };



  export const fetchPrStats=()=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.ADMIN_DASHBOARD_PR_STATS_LOADING
      });
      try {
        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(`${BaseUrl}${DASHBOARD_PR_STATS_COUNT}`, { headers });
       
        if (response.status === 200){
          dispatch({
            type: TYPES.ADMIN_DASHBOARD_PR_STATS_SUCCESS,
            payload: response.data.data,
          })
        }

        
        else{
          dispatch({
            type: TYPES.ADMIN_DASHBOARD_PR_STATS_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_PR_STATS_FAILURE,
          error:error.message,
        })
      }
    }
  };

    
export const fetchPRData=(
  page=1,
  pageSize=5,
  orderBy="PrCreatedId",
  orderDir="DESC",
  FilterStatus,
  startDate,
  endDate,
  searchValues,
)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.ADMIN_DASHBOARD_PR_DATA_LOADING
    });
    try {
      const queryParams = buildQueryParams({
          page,
          pageSize,
          orderBy,
          orderDir,
          FilterStatus,
          startDate,
          endDate,
          searchByColumNameValue: searchValues,
        });
        const url = buildUrlWithQueryParams(
          queryParams,
          DASHBOARD_PR_DATA
        );  
       

      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      
      const response = await axios.get(url, { headers });
  
      if (response.status === 200){
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_PR_DATA_SUCCESS,
          payload: response.data.data,
        })
      }
      
      else{
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_PR_DATA_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.ADMIN_DASHBOARD_PR_DATA_FAILURE,
        error:error.message,
      })
    }
  }
};



export const fetchPoStats=()=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.ADMIN_DASHBOARD_PO_STATS_LOADING
    });
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      
      const response = await axios.get(`${BaseUrl}${DASHBOARD_PO_STATS_COUNT}`, { headers });

      if (response.status === 200){
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_PO_STATS_SUCCESS,
          payload: response.data.data,
        })
      }

      
      else{
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_PO_STATS_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.ADMIN_DASHBOARD_PO_STATS_FAILURE,
        error:error.message,
      })
    }
  }
};

  
export const fetchPOData=(
page=1,
pageSize=5,
orderBy="PoDbId",
orderDir="DESC",
FilterStatus,
startDate,
endDate,
searchValues,

)=>{
return async (dispatch)=>{
  dispatch({
    type: TYPES.ADMIN_DASHBOARD_PO_DATA_LOADING
  });
  try {
    const queryParams = buildQueryParams({
        page,
        pageSize,
        orderBy,
        orderDir,
        FilterStatus,
        startDate,
        endDate,
        searchByColumNameValue: searchValues,
      });
      const url = buildUrlWithQueryParams(
        queryParams,
        DASHBOARD_PO_DATA
      );  
     

    const token = localStorage.getItem("vms_admin_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(url, { headers });

    if (response.status === 200){
      dispatch({
        type: TYPES.ADMIN_DASHBOARD_PO_DATA_SUCCESS,
        payload: response.data.data,
      })
    }
    
    else{
      dispatch({
        type: TYPES.ADMIN_DASHBOARD_PO_DATA_FAILURE,
        error: "API request failed",
      })
    }
  } catch (error) {
    dispatch({
      type: TYPES.ADMIN_DASHBOARD_PO_DATA_FAILURE,
      error:error.message,
    })
  }
}
};

 
export const fetchSupplierData=(
  page,
  pageSize,
  orderBy="evaluation_date",
  orderDir="DESC",
  FilterStatus="all",
  startDate,
  endDate,
  
  searchValues,
  
  )=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_LOADING
    });
    try {
      const queryParams = buildQueryParams({
          page,
          pageSize,
          orderBy,
          orderDir,
          FilterStatus,
          startDate,
          endDate,
          searchByColumNameValue: searchValues,
        });
        const url = buildUrlWithQueryParams(
          queryParams,
          SUPPLIER_PERFORMANCE_DATA
        );  
       
  
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
  
      if (response.status === 200){
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_SUCCESS,
          payload: response.data.data,
        })
      }
      
      else{
        dispatch({
          type: TYPES.ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_FAILURE,
        error:error.message,
      })
    }
  }
  };
  
  
  
  
    


  