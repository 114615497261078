import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./CustomPopup.css";
import NextApprovePopup from "../NextApproverPopupFlow/NextApproverPopupFlow";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewUser,
  createUser,
  editUser,
  getAllUser,
} from "../../Store/actions/userManagementAction";
import { toast } from "react-toastify";
import {
  createNewPressRelease,
  createPressRelease,
  editPressRelease,
  getAllPressRelease,
} from "../../Store/actions/pressReleaseAction";
import { deleteVendor } from "../../Store/actions/vendorMangementAction";
import { getVendorListTableData } from "../../Store/Reducer/vendorTable";
import {
  createNewPurchaseGroup,
  createPurchaseGroup,
  editPurchaseGroup,
  getAllPurchaseGroup,
} from "../../Store/actions/purshaseGroupAction";
import {
  deletePr,
  getAllPrRelease,
  releasePr,
} from "../../Store/actions/prReleaseActions";
import Http from "../../HttpClients/HttpClients";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import {
  DELETE_PRESS_RELEASE,
  DELETE_PURCHASE_GROUP,
  DELETE_USER,
} from "../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import { deleteEvaluation } from "../../Store/actions/supplierManagementActions";

const CustomPopup = ({
  isOpenPopup,
  closePopup,
  title,
  text,
  image,
  btn1,
  btn2,
  successTitle,
  successText,
  successIcon,
  btn3,
  closeFormPopup,
  reset,
  handleChangeColumn,
  scenarioType,
  userData,
  setPageLoading,
  selectedRows,
  rowData,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showNextApproverPopup, setShowNextApproverPopup] = useState(false);
  const dispatch = useDispatch();
  const { currentUserData, particularEditUser } = useSelector(
    (state) => state.getUserManagementInfo
  );

  const { currentVendorRowData } = useSelector(
    (state) => state.deleteParticularVendor
  );

  const { currentPressReleaseData, particularEditPressRelease } = useSelector(
    (state) => state.getPressReleaseInfo
  );

  const { currentPurchaseGroupData, particularEditPurchaseGroup } = useSelector(
    (state) => state.getPurchaseGroupInfo
  );
  const { currentPrReleaseData } = useSelector(
    (state) => state.getPrReleaseInfo
  );
  const userToken = localStorage.getItem("vms_admin_token");

  useEffect(() => {
    dispatch(createUser());
    dispatch(createPressRelease());
    dispatch(createPurchaseGroup());
  }, []);

  const handleCreateUser = async () => {
    try {
      const isSuccess = await dispatch(createNewUser(currentUserData));

      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditUser = async () => {
    try {
      const isSuccess = await dispatch(editUser(particularEditUser));
      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreatePressRelease = async () => {
    try {
      const isSuccess = await dispatch(
        createNewPressRelease(currentPressReleaseData)
      );
      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditPressRelease = async () => {
    try {
      const isSuccess = await dispatch(
        editPressRelease(particularEditPressRelease)
      );
      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleApproveVendor = () => {
    setShowNextApproverPopup(true);
  };

  const handleDeleteVendor = async () => {
    try {
      const isSuccess = await dispatch(deleteVendor(currentVendorRowData));

      if (isSuccess) {
        dispatch(getVendorListTableData());
        closePopup();
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreatePurchaseGroup = async () => {
    try {
      const isSuccess = await dispatch(
        createNewPurchaseGroup(currentPurchaseGroupData)
      );
      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditPurchaseGroup = async () => {
    try {
      const isSuccess = await dispatch(
        editPurchaseGroup(particularEditPurchaseGroup)
      );
      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleReleasePr = async () => {
    try {
      const isSuccess = await dispatch(releasePr(currentPrReleaseData));
      
      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleReleaseMultiplePr = async () => {
    try {
      const prCreatedIds = selectedRows?.map((row) => row.PrCreatedId);
      const prCompany = selectedRows?.[0]?.PR_Company;
      const prData = {
        ...currentPrReleaseData,
        PrCreatedId: prCreatedIds,
        PR_Company: prCompany,
      };
      dispatch(releasePr(prData));
      setShowPopup(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeletePr = async () => {
    try {
      const isSuccess = await dispatch(deletePr(rowData?.PrCreatedId));
      
   
      if (isSuccess) {
        setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
      closePopup();
    }
  };
  const handleDeleteEvaluation = async () => {
    try {
     
        const isSuccess = await dispatch(deleteEvaluation(rowData));
       
        if (isSuccess) {
        
          setShowPopup(true);
      }
    } catch (error) {
      toast.error(error.message);
      closePopup();
    }
  };

  const handleDeletePress = async () => {
    const data = { press_id: rowData?.id };
    try {
      const response = await Http.post(
        `${BaseUrl}${DELETE_PRESS_RELEASE}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        setShowPopup(true);
        toast.success(response.message);
      } else {
        closePopup();
        toast.error("Something went wrong");
      }
    } catch (err) {
      closePopup();
      toast.error(err.message);
    }
  };

  const handleDeletePurchaseGroup = async () => {
    try {
      const queryParams = {
        purchasinggroup_id: rowData?.id,
      };
      // Use the Http.get method to make the GET request
      const response = await Http.get(
        `${BaseUrl}${DELETE_PURCHASE_GROUP}`,
        queryParams,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200 && response.message !== "Not available") {
        setShowPopup(true);
        toast.success(response.message);
      } else if (
        response.status === 401 ||
        response.message == "Not available"
      ) {
        toast.error(response.message);
      } else {
        closePopup();
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleDeleteUser = async () => {
    const userId = rowData?.user_id;
    axios
      .get(`${BaseUrl}${DELETE_USER}${userId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setShowPopup(true);
        } else if (res.data.status === 401) {
          toast.error(res.data.message);
          closePopup();
        } else {
          toast.error("Something went wrong, Please try again.");
          closePopup();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const onClickConfirm = async () => {
    // Check which scenario you are dealing with based on the `scenarioType`
    switch (scenarioType) {
      case "createUser":
        // Call the function to create a new user
        await handleCreateUser();
        break;
      case "editUser":
        // Call the function to edit the user
        await handleEditUser();
        break;
      case "createPressRelease":
        // Call the function to create a new press release
        await handleCreatePressRelease();
        break;
      case "editPressRelease":
        await handleEditPressRelease();
        break;
      case "approveVendor":
        await handleApproveVendor();
        break;
      case "deleteVendor":
        await handleDeleteVendor();
        break;
      case "createPurchasingGroup":
        await handleCreatePurchaseGroup();
        break;
      case "editPurchasingGroup":
        await handleEditPurchaseGroup();
        break;
      case "releasePr":
        await handleReleasePr();
        break;
      case "releaseMultiplePr":
        await handleReleaseMultiplePr();
        break;
      case "deletePR":
        await handleDeletePr();
        break;
      case "deleteEvaluation":
        await handleDeleteEvaluation();
        break;
      case "deletePress":
        await handleDeletePress();
        break;
      case "deletePurchaseGroup":
        await handleDeletePurchaseGroup();
        break;
      case "deleteUser":
        await handleDeleteUser();
        break;
      default:
        // Handle any other scenario or provide a default action
        break;
    }
  };

  const closeSuccessPopup = () => {
    setShowPopup(false);
  };

  const closeNextApproverPopup = () => {
    setShowNextApproverPopup(false);
    closePopup();
  };

  const onClickOk = () => {
    switch (scenarioType) {
      case "deleteVendor":
        setShowPopup(false);
        break;
      case "deletePR":
        setShowPopup(false);
        closePopup();
        break;
      case "deleteEvaluation":
      
        setShowPopup(false);
        closePopup();
        break;
      case "deletePress":
        setShowPopup(false);
        closePopup();
        break;
      case "deletePurchaseGroup":
        setShowPopup(false);
        closePopup();
        break;
      case "deleteUser":
        setShowPopup(false);
        closePopup();
        break;
      case "editUser":
      case "createUser":
        setShowPopup(false);
        dispatch(getAllUser()).then(() => {
          toast.success("User updated successfully!!");
          reset();
          closeFormPopup();
          closePopup();
          setShowPopup(false);
        });
        break;
      case "createPressRelease":
      case "editPressRelease":
        setShowPopup(false);
        dispatch(getAllPressRelease()).then(() => {
          toast.success("Press Release updated successfully!!");
          reset();
          closeFormPopup();
          closePopup();
          setShowPopup(false);
        });
        break;
      case "createPurchasingGroup":
      case "editPurchasingGroup":
        setShowPopup(false);
        dispatch(getAllPurchaseGroup()).then(() => {
          toast.success("Purchase Group updated successfully!");
          reset();
          closeFormPopup();
          closePopup();
          setShowPopup(false);
        });
        break;

      case "releasePr":
        setShowPopup(false);
        toast.success("PR updated successfully!");
        reset();
        closeFormPopup();
        closePopup();
        setShowPopup(false);
        break;
      case "releaseMultiplePr":
        setShowPopup(false);
        reset();
        closeFormPopup();
        closePopup();
        setShowPopup(false);
        break;
      default:
        // For all other scenarios, we assume it's a successful update
        reset();
        closeFormPopup();
        closePopup();
        setShowPopup(false);
        break;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        {/* this is are you sure popup */}
        <Modal
          isOpen={isOpenPopup}
          onRequestClose={closePopup}
          className="create-user-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="close-icon-div">
            <img
              src={closeIcon}
              alt=""
              onClick={() => closePopup()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <center>
            <img src={image} alt="" />
            <h2 className="pt-2 popup-title">{title}</h2>
            <p className="pt-2 popup-text">{text}</p>
            <div className="pt-4">
              <button onClick={() => closePopup()} className="um-create-btn">
                {btn1}
              </button>
              <button className="um-submit-btn mx-2" onClick={onClickConfirm}>
                {btn2}
              </button>
            </div>
          </center>
        </Modal>
        {/* this is success popup */}
        <Modal
          isOpen={showPopup}
          onRequestClose={closeSuccessPopup}
          className="create-user-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="close-icon-div">
            <img
              src={closeIcon}
              alt=""
              onClick={() => onClickOk()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <center>
            <img src={successIcon} alt="" />
            <h2 className="pt-2 popup-title">{successTitle}</h2>
            <p className="pt-2 popup-text">{successText}</p>
            <div className="pt-3">
              <button className="um-submit-btn mx-2" onClick={onClickOk}>
                {btn3}
              </button>
            </div>
          </center>
        </Modal>
      </div>

      {showNextApproverPopup && (
        <NextApprovePopup
          isOpenPopup={showNextApproverPopup}
          closePopup={closeNextApproverPopup}
          selectedVendorData={userData}
          setPageLoading={setPageLoading}
        />
      )}
    </>
  );
};

export default CustomPopup;
