import {
  React,
  useEffect,
  useForm,
  useDispatch,
  useSelector,
  useState,
  Controller,
  toast,
  useTranslation,
} from "../../Imports/import";
import "./GeneralInfo.css";
import { updatedDataFirst } from "../../../Store/Reducer/registrationReducers";
import { getAllCountryList } from "../../../Store/Reducer/countryList";
import { BaseUrl } from "../../../ApiBaseUrl/ApiBaseUrl";
import {
  CITY_LIST,
  STATE_LIST,
  CHECK_TAX_NO,
} from "../../../ApiEndPoints/ApiEndPoints";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import Http from "../../../HttpClients/HttpClients";
import { handleKeyDown, handleOnWheel } from "../../../Utils/Utils";
import hidePassword from "../../../Assets/Images/hide-password.png";
import showPassword from "../../../Assets/Images/show-password.png";

const GeneralInfo = ({ setType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vendorData = useSelector((state) => state.registration.firstObject);
  const { CountryList } = useSelector((state) => state.countryList);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  // password matching
  const firstPassword = watch("password");
  const confirmPassword = watch("confirm_password");
  const bothPasswordsMatch = firstPassword !== confirmPassword;
  const selectedCountry = watch("country");
  const selectedState = watch("district");

  const formatContactName = (name) => {
    // Match words (alphabetic characters) using regex and capitalize the first letter of each word
    return name.replace(/\b\w/g, (char) => char.toUpperCase());
  };



  // Custom formatting function to add commas to the revenue value
  const formatRevenue = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, "");

    const [integerPart, decimalPart] = numericValue.split(".");

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    let formattedValue = formattedIntegerPart;

    if (decimalPart !== undefined) {
      formattedValue += "." + decimalPart?.slice(0, 2);
    }

    return formattedValue;
  };

  const handleChangeReg = (e) => {
    const { value } = e.target;

    setValue("registration_capital", formatRevenue(value));
  };

  // checking registration number
  const checkRegistrationNo = async (registrationNo) => {
    try {
      const queryParams = { registration_no: registrationNo };
      const response = await Http.get(`${BaseUrl}${CHECK_TAX_NO}`, queryParams);
      return response.data.registation_status;
    } catch (error) {
    
      return false; // Assuming "registation_status" is a boolean
    }
  };

  const onSubmit = async (data) => {
    const registration_no = watch("registration_no");

    try {
      const registrationStatus = await checkRegistrationNo(registration_no);

      if (!registrationStatus || !registration_no) {
        if (!bothPasswordsMatch) {
          dispatch(updatedDataFirst(data));
          setType("categories");
        } else {
          toast.error("Please Match the password");
        }
      } else {
        toast.error(
          "This registration number already exists, please change and try again"
        );
      }
    } catch (error) {
      // Error
    }
  };

  // thai language validation
  const validateThaiInput = (value) => {
    // regex to accept Thai characters, numeric values, and special characters
    const thaiCharactersWithSpecial =
      /^[\u0E00-\u0E7F\d\s!@#$%^&*()_+{}\[\]:;"'<>,.?/|\\\-]*$/;

    if (!thaiCharactersWithSpecial.test(value)) {
      return "Please enter the input in Thai.";
    }

    return true;
  };

  // phone number validations
  const validatePhoneNumber = (value, isFieldMandatory) => {
    if (isFieldMandatory && !value) {
      return t("Please enter the mobile phone number.");
    }

    if (value && !isValidPhoneNumber(value)) {
      return t("Mobile number is invalid.");
    }

    return true; // Return true to indicate validation success
  };
  const selectedCommercialType = watch("commercial_reg_type");

  // Check if any of the specified values are selected for commercial type
  const extractedValue =
    selectedCommercialType && selectedCommercialType.match(/\((.*?)\)/)?.[1];

  const isFalse =
    extractedValue &&
    ["O", "U", "P", "G", "N", "V", "C", "A"].includes(extractedValue);

  const isFalseThaiName = extractedValue && ["O", "U"].includes(extractedValue);

  const isFieldMandatory = extractedValue === "L";

  // function to find out country id by country name
  const getCountryIdByName = (selectedCountry) => {
    const country = CountryList?.reduce((acc, curr) => {
      if (curr.country === selectedCountry) {
        return curr;
      }
      return acc;
    }, null);

    return country ? country.id : null;
  };

  const countryId = getCountryIdByName(selectedCountry);

  // function to find out state id by state name
  const getStateIdByName = (selectedState) => {
    const state =
      stateList.length > 0 &&
      stateList.find((s) => s.state_name === selectedState);
    return state ? state.id : null;
  };

  const stateId = getStateIdByName(selectedState);

  // state api by country list
  useEffect(() => {
    const fetchStateList = async () => {
      try {
        const queryParams = { country_id: countryId };
        const response = await Http.get(`${BaseUrl}${STATE_LIST}`, queryParams);
   
        setStateList(response.data);
      } catch (error) {
        toast.error(error?.message || 'Something went wrong!');
      }
    };

    fetchStateList();
  }, [countryId]);

  // city api by country and state list
  useEffect(() => {
    const fetchCityList = async () => {
      try {
        const queryParams = { state_id: stateId, country_id: countryId };
        const response = await Http.get(`${BaseUrl}${CITY_LIST}`, queryParams);
        setCityList(response.data);
      } catch (error) {
        toast.error(error?.message || 'Something went wrong !');
      }
    };

    fetchCityList();
  }, [stateId, countryId]);

  // country list api calling
  useEffect(() => {
    dispatch(getAllCountryList());
  }, []);

  // geo locations api to get country code for mobile fields
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("https://geolocation-db.com/json/");
        const data = await response.json();
        setCountryCode(data?.country_code);
      } catch (error) {
         toast.error(error.message || 'Something went wrong!')
      }
    };

    fetchCountry();
  }, []);

  useEffect(() => {
    Object.entries(vendorData).forEach(([name, value]) => {
      setValue(name, value);
    });

  }, [vendorData]);



  // vendor type declaration by country
  useEffect(() => {
    var domestic = watch("register_country");
    if (domestic === "Thailand") {
      setValue("vendor_type", "Domestic");
    } else {
      setValue("vendor_type", "Oversea");
    }
  }, [watch("register_country")]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="pb-5">
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Registered Country")}
              </label>
              <br />

              <select
                type="text"
                id="register_country"
                style={{ width: "max-content", minWidth: "100%" }}
                className="input-border registration custom-select"
                {...register("register_country", {
                  required: true,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select country")}
                </option>
                {CountryList?.map((country, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={country.country}
                    >
                      {country.country}
                    </option>
                  );
                })}
              </select>
              {errors.register_country && (
                <span className="error-msg">
                  {t("Please select the register country.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">{t("Vendor Type")}</label>
              <br />

              <select
                type="text"
                className="input-border registration-left custom-select"
                {...register("vendor_type", {
                  required: true,
                })}
              >
                <option value="Domestic">{t("Domestic")} </option>

                <option value="Oversea">{t("Oversea")}</option>
              </select>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Commercial Register Type")}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("commercial_reg_type", {
                  required: false,
                })}
              >
                <option value="" hidden>
                  {t("select commerical register type")}
                </option>
                <option value="(L) Domestic Legal Entity">
                  (L) Domestic Legal Entity(นิติบุคคล/บริษัทในประเทศ){" "}
                </option>

                <option value="(P) Domestic Individual person">
                  (P) Domestic Individual person(บุคคลธรรมดาในประเทศ){" "}
                </option>

                <option value="(O) Oversea">
                  (O) Oversea(บริษัทต่างประเทศ){" "}
                </option>

                <option value="(T) Transportation Domestic">
                  (T) Transportation Domestic(บริษัทขนส่งภายในประเทศ){" "}
                </option>
                <option value="(U)Transportation Oversea">
                  (U) Transportation Oversea(บริษัทขนส่งต่างประเทศ){" "}
                </option>
                <option value="(G) Government">
                  (G) Government(หน่วยงานรัฐบาล){" "}
                </option>
                <option value="(N) Non-Government">
                  (N) Non-Government(องค์กรไม่แสวงหากำไร เช่น สโมสร,สมาคม){" "}
                </option>
                <option value="(V) VAT report only">
                  (V) VAT report only(ใช้เฉพาะฝ่ายบัญชีเท่านั้น){" "}
                </option>
                <option value="(C) Customer Claim">(C) Customer Claim </option>
                <option value="(A) Internal">
                  (A) Internal(บริษัทในเครือ AGC)
                </option>
              </select>
              {errors.commercial_reg_type && (
                <span className="error-msg">
                  {t("Please select the commercial register type.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Registration No. (TAX Number)")}
              </label>
              <br />

              <input
                type="text"
                className="input-border registration-left"
                {...register("registration_no", {
                  required: false,
                })}
                onWheel={handleOnWheel}
              />
              {errors.registration_no && (
                <span className="error-msg">
                  {t("Please enter the registration no.(Tax number)")}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Registered Capital (THB)")}
              </label>
              <br />

              <input
                type="text"
                className="input-border registration"
                {...register("registration_capital", {
                  required: isFieldMandatory,
                  maxLength: 20,
                  onChange: handleChangeReg,
                })}
                onWheel={handleOnWheel}
              />
              {errors.registration_capital && (
                <span className="error-msg">
                  {t("Please enter the registered capital.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Established Date")}
              </label>
              <br />

              <input
                type="Date"
                className="input-border registration-left-date"
                max={new Date().toISOString().split("T")[0]}
                {...register("established_date", {
                  required: isFieldMandatory,
                  maxLength: 80,
                })}
              />
              {errors.established_date && (
                <span className="error-msg">
                  {t("Please enter the establishment date.")}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container" style={{ alignItems: "unset" }}>
            <div className="left-Username-container ">
              <label className="vendor-input-label">{t("Username")}</label>
              <br />
              <input
                type="email"
                autocomplete="off"
                name="user_name_12345"
                placeholder={t("Please enter your email address")}
                className="input-border registration"
                {...register("user_name", {
                  required: true,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.user_name && errors.user_name.type === "pattern" && (
                <span className="error-msg">{t("Invalid email address.")}</span>
              )}
              {errors.user_name && (
                <span className="error-msg">
                  {t("Please enter the username.")}
                </span>
              )}
            </div>
            <div className="right-container-box">
              <div className="rightside-container">
                <label className="vendor-input-label">{t("Password")}</label>

                <br />
                <div className="input-border registration-left d-flex justify-content-between align-items-center">
                  <input
                    type={showPasswords ? "text" : "password"}
                    style={{ width: "100%", height: "38px" }}
                    autocomplete="new-passwords"
                    {...register("password", {
                      required: true,
                      pattern: {
                        value: new RegExp(
                          "^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[#@$%^&+=]).*$"
                        ),
                        message: "invalid password",
                      },
                    })}
                  />
                  <button
                    type="button"
                    className="toggle-password-button"
                    onClick={() => setShowPasswords(!showPasswords)}
                  >
                    <img
                      src={showPasswords ? hidePassword : showPassword}
                      alt={showPasswords ? "Hide Password" : "Show Password"}
                    />
                  </button>
                </div>
                {errors.password && (
                  <span className="error-msg">
                    {t("Please enter the verification code.")}
                  </span>
                )}
                <p className="mini">
                  {t(
                    "Mininum length: 8, Minimum non alphanumeric charecters: 1"
                  )}
                  , Minimum capital case characters:1 , Minimum lower case
                  characters:1 ,Sample : AGC@officer1
                </p>
              </div>
              <div className="leftside-container">
                <label className="vendor-input-label">
                  {t("Confirm password")}
                </label>
                <br />
                <div className="input-border registration-left d-flex justify-content-between align-items-center">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    style={{ width: "100%", height: "38px" }}
                    {...register("confirm_password", {
                      required: true,
                      minLength: 8,
                    })}
                  />
                  <button
                    type="button"
                    className="toggle-password-button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <img
                      src={showConfirmPassword ? hidePassword : showPassword}
                      alt={
                        showConfirmPassword ? "Hide Password" : "Show Password"
                      }
                    />
                  </button>
                </div>
                {errors.confirm_password && (
                  <span className="error-msg">
                    {t("Incorrect verification code. Please try again.")}
                  </span>
                )}
                {bothPasswordsMatch && (
                  <span className="error-msg">
                    {t("Please match the password")}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Company Name (TH)")}(กรุณาระบุเป็นภาษาไทย)
              </label>
              <br />

              <input
                type="text"
                placeholder={
                  watch("vendor_type") === "Oversea" ? "" : "Enter Thai text"
                }
                disabled={watch("vendor_type") === "Oversea"}
                className="input-border registration"
                {...register("company_name_th", {
                  required:
                    watch("vendor_type") !== "Oversea" && !isFalseThaiName,
                  maxLength: 200,
                  // validate: validateThaiInput,
                })}
              />
              {errors.company_name_th && (
                <span className="error-msg">
                  {t("Please enter the company name in Thai.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Company Name (EN)")}
              </label>
              <br />

              <input
                type="text"
                className="input-border registration-left "
                {...register("company_name_eng", {
                  required: isFieldMandatory,
                  maxLength: 200,
                  minLength: 1,
                })}
              />
              {errors.company_name_eng && (
                <span className="error-msg">
                  {t("Please enter the company name in English.")}
                </span>
              )}
            </div>
          </div>

          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">{t("Title Name")}</label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("title_name", {
                  required: isFieldMandatory,
                })}
              >
                <option value="" hidden>
                  {t("select")}
                </option>
                <option value="Public Company Limited">
                  {t("Public Company Limited")}
                </option>
                <option value="Company Limited">{t("Company Limited")}</option>
                <option value="partnership">{t("Partnership")}</option>
                <option value="miss">{t("MR./MS/MISS/ Etc.")}</option>
                <option value="other">{t("Other , please specify")}</option>
              </select>
              {errors.title_name && (
                <span className="error-msg">
                  {t("Please select the company title prefix or suffix.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Sale or Marketing Contact email")}
              </label>
              <br />

              <input
                type="text"
                placeholder={t("Enter contact email address")}
                className="input-border registration-left "
                {...register("sales_contact", {
                  required: isFieldMandatory
                    ? t(
                        "Please enter the contact person's name, sales or marketing department."
                      )
                    : false,
                  maxLength: 80,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("Invalid email address."),
                  },
                })}
              />

              {errors.sales_contact && (
                <span className="error-msg">
                  {errors.sales_contact.message}
                </span>
              )}
            </div>
          </div>
          {watch("title_name") === "other" && (
            <div className="textbox-container">
              <div className="left-container-hidden-input">
                <br />

                <input
                  type="text"
                  className="input-border registration-left "
                  {...register("title_specify", {
                    required: true,
                    maxLength: 80,
                  })}
                />
                {errors.title_specify && (
                  <span className="error-msg">
                    {t("Please enter other specific data")}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">{t("Tel.")}</label>
              <br />

              <input
                type="number"
                className="input-border registration"
                {...register("telephone", {
                  required: isFieldMandatory,
                  maxLength: 20,
                  minLength: 9,
                })}
                onWheel={handleOnWheel}
              />
              {errors.telephone && (
                <span className="error-msg">
                  {t("Please enter the telephone number.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">{t("Mobile phone")}</label>
              <br />

              <Controller
                name="mobile_phone"
                control={control}
                rules={{
                  validate: (value) =>
                    validatePhoneNumber(value, isFieldMandatory),
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    defaultCountry={countryCode ? countryCode : "TH"}
                    className="input-border registration"
                  />
                )}
              />

              {errors.mobile_phone && (
                <span role="alert" className="error-msg">
                  {errors.mobile_phone.message}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">{t("Fax Number")}</label>
              <br />

              <input
                type="number"
                className="input-border registration"
                {...register("fax", {
                  pattern: {
                    value: /^[0-9]{9,20}$/, // Added a pattern to ensure only digits between 9 to 20 are allowed
                    message:
                      "Please enter a valid fax number with 9 to 20 digits",
                  },
                })}
                onWheel={handleOnWheel}
              />
              {errors.fax && (
                <span className="error-msg">{errors.fax.message}</span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Web Site (If any)")}
              </label>
              <br />

              <input
                type="url"
                className="input-border registration-left "
                {...register("website", {
                  pattern: {
                    value: /^(https?:\/\/)?[a-z0-9\-._~:/?#[\]@!$&'()*+,;=]+$/i,
                    message: "Invalid website URL",
                  },
                })}
              />
              {errors.website && errors.website.type === "pattern" && (
                <span className="error-msg">{errors.website.message}</span>
              )}
            </div>
          </div>

          <br />
          <div>
            <h4 className="contact">{t("Contact person for (Account)")}</h4>
            <hr className="horziantal"></hr>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">{t("Name")}</label>
              <br />

              <input
                type="text"
                className="input-border registration"
                {...register("contact_name", {
                  required: isFieldMandatory,
                  maxLength: 50,
                  pattern:
                    /^[A-Za-z\sก-๙เแ่้๊๋า-ู็ิ์ํฺ\[\]{}()\-_.,:;'"!@#$%^&*+=<>?~]+$/,
                  onChange: (e) => {
                    const enteredName = e.target.value;
                    // const formattedName = formatContactName(enteredName);
                    // e.target.value = formattedName;
                  },
                })}
              />
              {errors.contact_name && (
                <span className="error-msg">
                  {t(
                    "Please enter the correct contact person name for account"
                  )}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">{t("Mobile phone")}</label>
              <br />

              <Controller
                name="contact_phone"
                control={control}
                rules={{
                  validate: (value) =>
                    validatePhoneNumber(value, isFieldMandatory),
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    // country={countryCode ? countryCode.toLowerCase() : "th"}
                    defaultCountry={countryCode ? countryCode : "TH"}
                    className="input-border registration"
                  />
                )}
              />
              {errors.contact_phone && (
                <span className="error-msg">
                  {errors.contact_phone.message}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("E-mail address")}
              </label>
              <br />

              <input
                type="email"
                className="input-border registration"
                {...register("contact_email", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.contact_email &&
                errors.contact_email.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.contact_email && (
                <span className="error-msg">
                  {t(
                    "Please enter the contact person email address for account."
                  )}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("E-mail address 2")}
              </label>
              <br />

              <input
                type="email"
                className="input-border registration-left "
                {...register("contact_email2", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.contact_email2 &&
                errors.contact_email2.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.contact_email2 && (
                <span className="error-msg">
                  {t(
                    "Please enter the contact person email2 address for account."
                  )}
                </span>
              )}
            </div>
          </div>
          <div>
            <h4 className="contact-two">
              {t("Email Contact person for vendor management system (VMS)")}
            </h4>
            <hr className="horziantal"></hr>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("E-mail address")}
              </label>
              <br />

              <input
                type="email"
                className="input-border registration"
                {...register("email_for_vms", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email_for_vms &&
                errors.email_for_vms.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.email_for_vms && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the contact person email address for VMS")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("E-mail address 2")}
              </label>
              <br />

              <input
                type="email"
                className="input-border registration-left "
                {...register("email2_for_vms", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email2_for_vms &&
                errors.email2_for_vms.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.email2_for_vms && (
                <span className="error-msg">
                  {t("Please enter the contact person email2 address for VMS")}
                </span>
              )}
            </div>
          </div>
          <div>
            <h4 className="registeredaddress">{t("Registered Address")}</h4>
            <hr className="horziantal"></hr>
          </div>
          <div>
            <label className="vendor-input-label">
              {t("Street/House Number")}{" "}
            </label>
            <br />
            <input
              type="text"
              className="input-border registration"
              {...register("street_house_no", {
                required: isFieldMandatory,
                maxLength: 200,
              })}
            />
            {errors.street_house_no && (
              <span className="error-msg">{t("Please enter the value")}</span>
            )}
          </div>

          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">{t("Country")}</label>
              <br />
              <select
                type="text"
                id="country"
                className="input-border registration custom-select"
                {...register("country", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="">
                  {" "}
                  {t("select country")}
                </option>
                {CountryList?.map((country, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={country.country_id}
                      value={country.country}
                    >
                      {country.country}
                    </option>
                  );
                })}
              </select>
              {errors.country && (
                <span className="error-msg">
                  {t("Please select the country of registered of the company.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Province/State/City")}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("district", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                {stateList.length > 0 &&
                  stateList.map((state, index) => {
                    return (
                      <option
                        className="dropdown-country"
                        key={state.id}
                        value={state.state_name}
                      >
                        {state.state_name}
                      </option>
                    );
                  })}
              </select>
              {errors.district && (
                <span className="error-msg">
                  {t(
                    "Please select the district/state/province of registered of the company."
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">{t("District")}</label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("city", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="">
                  {" "}
                  {t("select")}
                </option>
                {cityList.length > 0 &&
                  cityList.map((city, index) => {
                    return (
                      <option
                        className="dropdown-country"
                        value={city.city_name}
                      >
                        {city.city_name}
                      </option>
                    );
                  })}
              </select>
              {errors.city && (
                <span className="error-msg">
                  {t("Please select the city of registered of the company.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">{t("Postal Code")}</label>
              <br />

              <input
                type="text"
                className="input-border registration-left "
                {...register("postal_code", {
                  required: isFieldMandatory,
                  maxLength: 12,
                  minLength: 4,
                })}
                onWheel={handleOnWheel}
              />
              {errors.postal_code && (
                <span className="error-msg">
                  {t(
                    "Please enter the postal code of registered of the company."
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="button-submit-container pt-5">
          <button className="button-submit" type="submit">
            {t("Next")}
          </button>
        </div>
      </form>
    </>
  );
};

export default GeneralInfo;
