import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import {
  PageLoader,
  useDispatch,
  useNavigate,
  useSelector,
  useTranslation,
} from "../../../../src/Components/Imports/import";
import EvaluationFormSelect from "../../../Models/EvaluationFormSelect/EvaluationFormSelect";
import PenaltyPopup from "../../../Models/PenaltyPopup/PenaltyPopup";
import PenaltyRejectPopup from "../../../Models/PenaltyRejectPopup/PenaltyRejectPopup";
import "./SupplierManagementTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { tableStyles } from "../../../Utils/TableStyles";
import {
  compareDates,
  DateGenerator,
  formatDate,
  formatNumber,
  formatNumberWithCommasandDecimals,
  formatStringForCSV,
} from "../../../Utils/Utils";
import {
  getAllSupplierMananagment,
  getAllSupplierTableStatusCount,
  getEvaluationFormDropDown,
} from "../../../Store/actions/supplierManagementActions";

import DownloadPopup from "../../../Models/DownloadPopup/DownloadPopup";
import ViewPopup from "../../../Models/ViewPopup/ViewPopup";

const SupplierManagementTable = () => {

  const dispatch = useDispatch();

  const { evaluationFormDropDown,supplierList, supplierTableStatusCount,loading } = useSelector(
    (state) => state.getSupplierManagementInfo
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState({
    all:[],
    delivering:[],
    delivered:[],
    delayed:[],
    penaltyReject:[],
    penaltyConfirm:[],
  });
  const [filteredData,setFilteredData]=useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [orderDir, setOrderDir] = useState('DESC');
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [count,setCount]=useState(supplierTableStatusCount?.reduce((total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0), 0) ||0)
  const [isReadyToOpenModal, setIsReadyToOpenModal] = useState(false);
  const [isReadyPenaltyModal,setIsReadyPenaltyModal]=useState(false);
  const [isReadyPenaltyRejectModal,setIsReadyPenaltyRejectModal]=useState(false)
  const [isReadyDownloadModal,setIsReadyDownloadModal]=useState(false);
  const [isReadyViewModal,setIsReadyViewModal]=useState(false);
  const [penaltyId,setPenaltyId]=useState(null);
  const [id,setId]=useState(null);
  const [poData,setPoData]=useState({});
  const [evaluationIdData, setEvaluationIdData] = useState({
    vendorCode: "",
    poNo: "",
    PoPrDbId: null,
    PoDbId: null,
    vendorId: null,
  });
  useEffect(() => {
  dispatch(
        getAllSupplierMananagment(
          currentPage,
          itemsPerPage,
          orderBy,
          orderDir,
          type===0?"all":type,
          selectedDateRange[0],selectedDateRange[1],searchValues
        )
      );
    dispatch(getAllSupplierTableStatusCount());
    dispatch(getEvaluationFormDropDown());
  
  }, [itemsPerPage, currentPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues]);


  useEffect(()=>{
    if(type===0){
      setData(prevState => ({
        ...prevState,
        all: supplierList?.data || [],
      }));
    }
    else if (type===1){
      setData(prevState => ({
        ...prevState,
        delivering: supplierList?.data || [],
      }));
    }
    else if (type===2){
      setData(prevState => ({
        ...prevState,
        delivered: supplierList?.data || [],
      }));
    }
    else if (type===3){
      setData(prevState => ({
        ...prevState,
        delayed: supplierList?.data || [],
      }));
    }
    else if (type===4){
      setData(prevState => ({
        ...prevState,
        penaltyReject: supplierList?.data || [],
      }));
    }
    else if (type===5){
      setData(prevState => ({
        ...prevState,
        penaltyConfirm: supplierList?.data || [],
      }));
    }
    setCount(supplierList?.TotalCount);
   },[supplierList,count])

   useEffect(()=>{
    type===0?setFilteredData(data?.all|| []):type===1?setFilteredData(data?.delivering|| []):type===2?setFilteredData(data?.delivered|| []):type===3?setFilteredData(data?.delayed|| []):type===4?setFilteredData(data?.penaltyReject|| []):type===5?setFilteredData(data?.penaltyConfirm|| []):setFilteredData([])
   },[data,type])

   useEffect(() => {
    if (isReadyToOpenModal) {
      openModal();
      setIsReadyToOpenModal(false); 
    }
  }, [isReadyToOpenModal]);
  useEffect(() => {
    if (isReadyPenaltyModal) {
      penaltyOpenModal();
      setIsReadyPenaltyModal(false); 
    }
  }, [isReadyPenaltyModal]);
  useEffect(() => {
    if (isReadyPenaltyRejectModal) {
      PenaltyRejectOpenModal();
      setIsReadyPenaltyRejectModal(false); 
    }
  }, [isReadyPenaltyRejectModal]);
  useEffect(() => {
    if (isReadyDownloadModal) {
      DownloadOpenModal();
      setIsReadyDownloadModal(false); 
    }
  }, [isReadyDownloadModal]);
  useEffect(()=>{
    if(isReadyViewModal){
      ViewOpenModal();
      setIsReadyViewModal(false);
    }
  },[isReadyViewModal])

  const handlePenalty = (data) => {
    
    setEvaluationIdData({
      vendorCode: data?.poData?.VendorCode,
      poNo: data?.poData?.PONumber,
      PoPrDbId: data?.PoPrDbId,
      PoDbId: data?.poData?.PoDbId,
      vendorId: data?.poData?.VendorId,
      GrDate: data?.GRDate,
      PrDeliveryDate:data?.PrDeliveryDate,
      PoAmount:data?.poData?.POTotalAmount,
      PoCurrency:data?.poData?.POCurrency,
    });
    setIsReadyPenaltyModal(true);
  };

  const handleView=(data)=>{
    setPoData(data?.poData);
    navigate(`/po-details/${data?.poData?.PoDbId}`)
    // commenting on the approval of kunh ball sir
    // if(data?.poData?.IsPoOver1Million===1){
    //   setIsReadyViewModal(true);
    // }
    // else{
    //   if(data?.poData?.PoDbId){
    //     navigate(`/po-details/${data?.poData?.PoDbId}`)
    //   }
      
    // }
    
  } 
  const handlePenaltyReject=(id)=>{
    setPenaltyId(id)
    setIsReadyPenaltyRejectModal(true);
  }
  const handleDownload=(id)=>{
    setId(id)
    setIsReadyDownloadModal(true);
  }

  const handleEvaluationClick = (data) => {
    const date=data?.VmsStatus===3?0:compareDates(data?.GRDate,data?.PrDeliveryDate)
    setEvaluationIdData({
      vendorCode: data?.poData?.VendorCode,
      poNo: data?.poData?.PONumber,
      PoPrDbId: data?.PoPrDbId,
      PoDbId: data?.poData?.PoDbId,
      vendorId: data?.poData?.VendorId,
      dateStatus:date||0,
    });
    
    setIsReadyToOpenModal(true);
  };

  const columnWidths = {
    VendorCode: "4%",
    PONo: "5%",
    Description: "9%",
    DeliveryDate: "9%",
    Qty: "5%",
    Unit: "5%",
    POAmount: "8%",
  };

  const columns = [
    {
      id: "VendorCode",
      selector: (result) => result?.poData?.VendorCode || "",
      name: t("Vendor Code"),
      searchable: true,
      sortable: true,
      width: "10%",
    },
    {
      id: "PONumber",
      name: t("PO No."),
      selector: (result) => result.poData?.PONumber || "",
      searchable: true,
      sortable: true,
      width: "10%",
    },
    {
      id: "PrShortText",
      name: t("Description"),
      selector: (result) => result?.PrShortText ,
      searchable: true,
      width: "8%",
    },
    {
      id: "PrDeliveryDate",
      name: t("Delivery Date(PO)"),
      selector: (result) => DateGenerator(result.PrDeliveryDate),
      searchable: true,
      sortable: true,
      width: "10%",
    },

    {
      id: "PrQTY",
      name: t("Qty"),
      selector: (result) => formatNumber(result?.PrQTY),
      searchable: true,
      width: "6%",
    },
    {
      id: "PRUnit",
      name: t("Unit"),
      selector: (result) => result?.PRUnit || "",
      searchable: true,
      width: "7%",
    },
    {
      id: "POTotalAmount",
      name: t("PO Amount"),
      selector: (result) =>
        formatNumberWithCommasandDecimals(result?.poData?.POTotalAmount),
      searchable: true,
      sortable: true,
      width: "9%",
    },

    {
      id: "Status",
      name: t("Status"),
      width: "12%",
      searchable: false,
      cell: (result) => (
        <div
          className={
            result.VmsStatus === 2
              ? "table-status delivered"
              : result.VmsStatus === 3
              ? "table-status delayed"
              : result.VmsStatus === 1
              ? "table-status delivering"
              : result.VmsStatus === 4
              ? "table-status penalty-reject"
              : result.VmsStatus === 5
              ? "table-status penalty-confirm"
              : ""
          }
        >
          {result.VmsStatus === 2
            ? "Delivered"
            : result.VmsStatus === 3
            ? "Delayed"
            : result.VmsStatus === 1
            ? "Delivering"
            : result.VmsStatus === 4
            ? "Penalty-reject"
            : result.VmsStatus === 5
            ? "Penalty-confirm"
            : ""}
        </div>
      ),
    },
    {
      id: "Action",
      width: "37%",
      name: t("Action"),
      cell: (result) => (
        <div className="d-flex">
          <button
                  className="table-btn mx-2"
                  onClick={() => {
                    handleView(result)
                  }}
                >
                  {t("View")}
                </button>
          {(result.VmsStatus !==1) &&
            (
              <>
                  <button className={`${result?.poData?.AvailableForEvaluation==0?"eva-tbl-btn":"eva-tbl-btn pointer pointer-none disabled"}`} onClick={()=>{
                  handleEvaluationClick(result)
                }}>
                  {t("Evaluation")}{" "}
                </button>
               
              </>
            )}{" "}
          {result.status === 1 && (
            <></>
          )}{" "}
          {result?.VmsStatus === 3 && (
            <>
              {" "}
              <button 
              className={`pen-tbl-btn ${result?.penalty===null ? "" :"pointer pointer-none disabled"}`}
               onClick={()=>{
                  handlePenalty(result)
                }}>
                {t("Penalty")}
              </button>
            </>
          )}
          {result.VmsStatus === 4 &&(
            <>
              
             { result?.penalty!==null &&
              <button className="vpr-tbl-btn" onClick={()=>{
                handlePenaltyReject(result?.penalty?.penaltyId)
              }}>
                
                {t("View Penalty Reject")}
              </button>
    }
            </>
          )}
          {result.VmsStatus === 5 && (
            <button
              className="dp-tbl-btn"
              onClick={()=>{
                handleDownload(result?.penalty?.penaltyId)
              }}
            >
              {t("Download Penalty")}
            </button>
          )}
        </div>
      ),
      searchable: false,
    },
  ];

  //Evaluation popup
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  //penalty popup
  const [penaltyIsmodalOpen, setPenaltyIsModalOpen] = useState(false);
  const penaltyOpenModal = () => {
    setPenaltyIsModalOpen(true);
  };
  const penaltyCloseModal = () => {
    setPenaltyIsModalOpen(false);
  };

  //Penalty reject  popup
  const [penaltyRejectModalOpen, setPenaltyRejectIsModalOpen] = useState(false);
  const PenaltyRejectOpenModal = () => {
    setPenaltyRejectIsModalOpen(true);
  };
  const PenaltyRejectCloseModal = () => {
    setPenaltyRejectIsModalOpen(false);
  };

  const [downloadModalOpen,setDownloadIsModalOpen]=useState(false);
  const DownloadOpenModal=()=>{
    setDownloadIsModalOpen(true);
  }
  const DownloadCloseModal=()=>{
    setDownloadIsModalOpen(false);
  }

  const [viewModalOpen,setViewModalOpen]=useState(false);
  const ViewOpenModal=()=>{
    setViewModalOpen(true);
  }
  const viewCloseModal=()=>{
    setViewModalOpen(false);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      
      dispatch(getAllSupplierMananagment(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type===0?"all":type,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };
 

  const handleTypeSelect = (selectedKey) => {
    setCurrentPage(1);
    setType(parseInt(selectedKey));
   
  }
  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };
  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange?.length > 0) {
      setSelectedDateRange([]);
    }
  };


 

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
    
  };

  const headerNames = columns?.map((column) => column.name);

  const csvData = filteredData?.map((item) =>
    columns.reduce((row, column) => {
      if(column.id==="Status"){
        row[column.name]=item?.VmsStatus === 2
        ? "Delivered"
        : item?.VmsStatus === 3
        ? "Delayed"
        : item?.VmsStatus === 1
        ? "Delivering"
        : item?.VmsStatus === 4
        ? "Penalty-reject"
        : item?.VmsStatus === 5
        ? "Penalty-confirm"
        : ""
      }
      else if(column.id==="PrQTY"){
        row[column.name]=item?.PrQTY.toLocaleString()
      }
      else if(column.id==="PrShortText"){
        row[column.name] = formatStringForCSV(item?.PrShortText);
      }
      else{
        row[column.name] = column.selector ? column.selector(item) : item[column.name] || '';
      }

      return row;
    }, {})
  ) || []; 

  return (
    <>
     {( loading) && <PageLoader/>}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
          <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 0
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="0"
                >
                  All
                  <span
                    className={
                      type === 0
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="0"
                  >
                   {type===0?count: supplierTableStatusCount?.reduce((total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0), 0) ||0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 1
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="1"
                >
                  Delivering
                  <span
                    className={
                      type === 1
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="1"
                  >
                     {type===1?count:supplierTableStatusCount?.find(stat => stat?.VmsStatus === 1)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 2
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="2"
                >
                  Delivered
                  <span
                    className={
                      type === 2
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="2"
                  >
                    {type===2?count:supplierTableStatusCount?.find(stat => stat?.VmsStatus === 2)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 3
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="3"
                >
                  Delayed
                  <span
                    className={
                      type === 3
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="3"
                  >
                     {type===3?count:supplierTableStatusCount?.find(stat => stat?.VmsStatus === 3)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 4
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="4"
                >
                  Penalty Rej.
                  <span
                    className={
                      type === 4
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="4"
                  >
                     {type===4?count:supplierTableStatusCount?.find(stat => stat?.VmsStatus === 4)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 5
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="5"
                >
                  Penalty Conf.
                  <span
                    className={
                      type === 5
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="5"
                  >
                    {type===5?count:supplierTableStatusCount?.find(stat => stat?.VmsStatus === 5)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            

            <DateRangePicker
              appearance="default"
              //   style={{width:"200px"}}
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>{t("Show")} </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>{t("entries")}</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="pr_table"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={filteredData?.length ? "um-data-table" : "um-empty-data-table"}
        >
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
          <div
    style={{
      position: "absolute",
      top: "50px",
      left: 0,
      right: 0,
      padding: "4px",
      overflowX: "auto",
      whiteSpace: "nowrap", 
    }}
    className="sm-table-search-div"
  >
    <div style={{ display: "inline-block", marginLeft: "1px" }}>
      {columns.map((column) =>
        column.searchable !== false ? (
          <input
            key={column.id}
            type="text"
            value={searchValues[column.id] || ""}
            style={{ width: column.width, margin: "1px" }}
            onChange={(event) =>
              handleSearchChange(event, column.id)
            }
          />
        ) : null
      )}
    </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of {count}{" "}
          entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>

      <EvaluationFormSelect
        isOpen={isModalOpen}
        closeModal={closeModal}
        formTitle="Evaluation Form Select"
        options={evaluationFormDropDown}
        evaluationIdData={evaluationIdData}
      />

      {/* penalty popup */}
      <PenaltyPopup

        penaltyIsOpen={penaltyIsmodalOpen}
        penaltyFormTitle={"Penalty"}
        penaltyCloseModal={penaltyCloseModal}
        userData={evaluationIdData}
      />

      {/* PenaltyRejectPopup */}
      <PenaltyRejectPopup
        PenaltyRejectIsOpen={penaltyRejectModalOpen}
        PenaltyRejectFormTitle={"Penalty reject"}
        PenaltyRejectCloseModal={PenaltyRejectCloseModal}
        id={penaltyId}
      />
      <DownloadPopup
      DownloadIsOpen={downloadModalOpen}
      DownloadCloseModal={DownloadCloseModal}
    DownloadFormTitle={"Download Penalty"}
    id={id}
      />

      <ViewPopup
      ViewIsOpen={viewModalOpen}
      ViewCloseModal={viewCloseModal}
    ViewFormTitle={"View Attachments"}
    poData={poData}
      />

      
    </>
  );
};

export default SupplierManagementTable;
