import {
  React,
  useEffect,
  useForm,
  useDispatch,
  useSelector,
  useTranslation,
  useState,
  toast,
  Controller,
} from "../../Imports/import";
import { updatedDataFifth } from "../../../Store/Reducer/registrationReducers";
import { MultiSelect } from "react-multi-select-component";
import { handleKeyDown } from "../../../Utils/Utils";

const SupplierQuality = ({ setType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vendorData = useSelector((state) => state.registration.fifthObject);
  const onClickBack = () => {
    setType("Commercial");
  };



  const initialSelected = Array.isArray(
    vendorData?.company_certified_to_recognize_center
  )
    ? vendorData.company_certified_to_recognize_center.map((value) => ({
        label: value,
        value,
      }))
    : [];
  const [selected, setSelected] = useState(initialSelected);
  const [isNaSelected, setIsNaSelected] = useState(false);
  const [isOtherFieldSelected, setOtherFieldSelected] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const options = [
    { label: "ISO 9001", value: "ISO 9001", disabled: isNaSelected },
    { label: "ISO 14001", value: "ISO 14001", disabled: isNaSelected },
    { label: "TS 16949", value: "TS 16949", disabled: isNaSelected },
    {
      label: "HALAL ISO standard",
      value: "HALAL ISO standard",
      disabled: isNaSelected,
    },
    { label: "ISO 45001", value: "ISO 45001", disabled: isNaSelected },
    { label: "IEC 17025*", value: "IEC 17025*", disabled: isNaSelected },
    { label: "ASME U STAMP", value: "ASME U STAMP", disabled: isNaSelected },
    { label: "API", value: "API", disabled: isNaSelected },
    {
      label: "Other…. Please specify",
      value: "Other…. Please specify",
      disabled: isNaSelected,
    },
    { label: "N/A", value: "N/A", disabled: isOtherFieldSelected },
  ];

  useEffect(() => {
    if (vendorData.company_certified_to_recognize_center?.includes("N/A")) {
      setIsNaSelected(true);
      setOtherFieldSelected(false);
    } else if (
      Array.isArray(vendorData?.company_certified_to_recognize_center) &&
      vendorData.company_certified_to_recognize_center.length > 0
    ) {
      setIsNaSelected(false);
      setOtherFieldSelected(true);
    } else {
      setIsNaSelected(false);
      setOtherFieldSelected(false);
    }
  }, [vendorData.company_certified_to_recognize_center]);

  const onSubmit = (data) => {
  //changes after ball sir's approval
    // if (selected.length === 0) {
    //   toast.error(
    //     "Please select at least one option. in Quality Management System certified to a recognized standard"
    //   );
    //   return;
    // }

    if (selected.some((option) => option.value === "Other…. Please specify")) {
      // if (!data.certified_specify) {
      //   toast.error("Please specify the certification.");
      //   return;
      // }
    }
    // Include selected values in the data being dispatched
    data.company_certified_to_recognize_center = selected.map(
      (option) => option.value
    );
    dispatch(updatedDataFifth(data));
    setType("HSE");
  };

  const onChangerRecognizedStandard = (selectedValues) => {
    setSelected(selectedValues);

    const valuesArray = selectedValues.map((item) => item.value);

    if (valuesArray.includes("N/A")) {
      setIsNaSelected(true);
      setOtherFieldSelected(false);
    } else if (valuesArray.length == 0) {
      setIsNaSelected(false);
      setOtherFieldSelected(false);
    } else {
      setOtherFieldSelected(true);
      setIsNaSelected(false);
    }
  };

  useEffect(() => {
    Object.entries(vendorData).forEach(([name, value]) => {
      setValue(name, value);
    });
  }, [vendorData]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="pb-5">
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t(
                  "Is your company's Quality Management System certified to a recognized standard?"
                )}
                <span className="staricon">*</span>
              </label>
              <br />

              <div className="dropdown-multi">
                <Controller
                  name="company_certified_to_recognize_center"
                  control={control}
                  rules={{ required: false }}
                  render={({ field, fieldState }) => (
                    <>
                      <MultiSelect
                        className="mb-3"
                        options={options}
                        value={selected}
                        onChange={(selected) => {
                          onChangerRecognizedStandard(selected);
                          field.onChange(selected);
                        }}
                        labelledBy="Select"
                        hasSelectAll={false}
                      />
                      {fieldState.error && (
                        <span className="error-msg mt-3">
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />

                {/* <MultiSelect
                  options={options}
                  value={selected}
                  onChange={onChangerRecognizedStandard}
                  labelledBy={t("select")}
                  hasSelectAll={false}
                  name="company_certified_to_recognize_center"
                />

                {errors.company_certified_to_recognize_center && (
                  <span className="error-msg">
                    {t("Please select the correct value")}{" "}
                  </span>
                )} */}
              </div>
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t(
                  "For Laboratory Accreditation, please specify the equipment, calibration item, range and best uncertainty?"
                )}
                <span className="staricon">*</span>
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("laboratory_accreditation", {
                  required: false,
                })}
              >
                <option value="" hidden>
                  {t("select")}{" "}
                </option>
                <option value="Yes">{t("Yes")}</option>
                <option value="No">{t("No")}</option>
              </select>
              {errors.laboratory_accreditation && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            {selected.some(
              (option) => option.value === "Other…. Please specify"
            ) && (
              <div className="left-container-hidden-input">
                <br />

                <input
                  type="text"
                  className="input-border registration-left "
                  {...register("certified_specify", {
                    required: false,
                  })}
                />

                {errors.certified_specify && (
                  <span className="error-msg">
                    {t("Please enter other specific data")}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="buttons pt-5">
          <div className="button-submit-container-back">
            <input
              className="button-submit"
              type="submit"
              value={t("Back")}
              onClick={onClickBack}
            />
          </div>
          <div className="button-submit-container">
            <input className="button-submit" type="submit" value={t("Next")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default SupplierQuality;
