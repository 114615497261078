import React from "react";
import Modal from "react-modal";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./ViewIssuePopup.css";

const ViewIssuePopup = ({ isOpenPopup, closePopup, userData }) => {

  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={isOpenPopup}
        onRequestClose={closePopup}
        className="create-user-modal"
        overlayClassName="create-modal-overlay"
      >
        <div className="ts-title-close-icon-div">
          <p className="ts-title">View Issue</p>
          <img
            src={closeIcon}
            alt=""
            onClick={() => closePopup()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <p className="vi-name">From {userData?.rejected_by_group_name}</p>
        <p className="vi-name">description</p>
        <div className="vi-content">{userData?.rejection_description}</div>
      </Modal>
    </div>
  );
};

export default ViewIssuePopup;
