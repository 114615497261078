import Modal from "react-modal";
import { useForm } from "react-hook-form";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./PrDescriptionPopup.css";
import { useTranslation } from "../../../src/Components/Imports/import";


const PrDescriptionPopup = ({
  PrDescriptionIsOpen,
  PrDescriptionCloseModal,
  PrDescriptionFormTitle,
  shortDescription,
  longDescription,
}) => {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    // ... Handle form submission ...

    PrDescriptionCloseModal();
  };
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={PrDescriptionIsOpen}
        onRequestClose={PrDescriptionCloseModal}
        className="eva-user-model"
        overlayClassName="eva-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="evaa-cre-user-title d-flex">
          <h2 style={{ marginRight: "20px" }}>{PrDescriptionFormTitle}</h2>
          <img src={closeIcon} alt="" onClick={PrDescriptionCloseModal} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">
          <br />
          <label>{t("Short text")}</label>
          <br />
          <input className="short-text-inp"
            type="text"
            value={shortDescription} // Populate short description
          />
          <br />
          <label>{t("Long text")}</label>
          <br />
          <textarea className="long-text-txt"
            value={longDescription}// Populate long description
          />

          <div className="pt-3 create-btns-div">
           
            <button type="submit" className="um-submit-btn">
              {t("Submit")}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PrDescriptionPopup;
