import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import "./JobManagement.css";
import JobManagementTable from "../../Components/DataTable/JobManagementTable/JobManagmentTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllJobStatusCount,
  getAllJobTableStatusCount,
} from "../../Store/actions/jobManagementAction";
import PageLoader from "../../Models/PageLoader/PageLoader";

// card component
const JobCard = ({ title, content }) => {
  // based on keys giving the color for the card values
  const keyColorMapping = {
    "PR Pending": "#FD8A75",
    "Waiting Po": "#AFCA0B",
    Delivering: "#428BC1",
  };

  return (
    <div className="pr-card jbm-card">
      <h3 className="jbm-card-title pb-3">{title}</h3>
      <div className="jbm-card-content">
        {content?.map(({ key, value }) => (
          <div key={key} className="content-item">
            <span className="jbm-card-key">{key} </span> <br />
            <span
              className="jbm-card-value"
              style={{ color: keyColorMapping[key] }}
            >
              {value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const JobManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getAllJobManagementCountData, loading } = useSelector(
    (state) => state.getJobManagementInfo
  );

  const transformedData = getAllJobManagementCountData?.map((item) => {
    return {
      title: item?.PR_Company || "Not Available",
      content: [
        { key: "PR Pending", value: item?.PrPendingCount },
        { key: "Waiting PO", value: item?.WaitingPo },
        { key: "Delivering", value: item?.Delivering },
      ],
    };
  });

  useEffect(() => {
    dispatch(getAllJobStatusCount());
  }, []);

  return (
    <DefaultLayout>
      {loading && <PageLoader />}
      <section className="um-main">
        <div className="um-title-btn">
          <h1 className="db-title">{t("Job Management")}</h1>
        </div>
        <div className="pr-container pb-4">
          {transformedData?.map((card, index) => (
            <JobCard key={index} title={card?.title} content={card?.content} />
          ))}
        </div>
        <JobManagementTable />
      </section>
    </DefaultLayout>
  );
};

export default JobManagement;
