import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./TimeStamp.css";
import greenIcon from "../../Assets/Svg/green-icon.svg";
import redIcon from "../../Assets/Svg/red-icon.svg";
import clockIcon from "../../Assets/Svg/clock-icon.svg";
import personIcon from "../../Assets/Svg/person-icon.svg";
import Http from "../../HttpClients/HttpClients";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_VENDOR_HISTORY_BY_ID } from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import PageLoader from "../PageLoader/PageLoader";

const TimeStamp = ({ isOpen, closeModal, userData }) => {

  const userToken = localStorage.getItem("vms_admin_token");
  const [data, setData] = useState(null);

  
  useEffect(() => {
    if (userData?.vendor_id) {
      const queryParams = {
        vendor_id: userData?.vendor_id,
      };
      // Use the Http.get method to make the GET request
      Http.get(`${BaseUrl}${GET_VENDOR_HISTORY_BY_ID}`, queryParams, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            const currentData = result.data;
            setData(currentData);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) =>  toast.error(error?.message || "Something went wrong!"));
    }
  }, [isOpen === true]);


  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="create-user-modal time-stamp-modal"
        overlayClassName="create-modal-overlay"
      >
        <div className="ts-title-close-icon-div">
          <p className="ts-title pb-2">Timestamp</p>
          <img
            src={closeIcon}
            alt=""
            onClick={() => closeModal()}
            style={{ cursor: "pointer" }}
            id="closeIcon"
          />
        </div>

        <div className="ts-main-scroll pt-4">
          {data === null ? (
            <div style={{ marginLeft: "-20px" }}>
              <PageLoader />
            </div>
          ) : Array.isArray(data) && data?.length > 0 ? (
            data?.map((e, index) => (
              <div className="ts-main-div" key={index}>
                <div className="ts-img-container">
                  <img
                    src={e?.approve_reject_status == 2 ? redIcon : greenIcon}
                    alt=""
                  />
                  <div class="ts-vertical-line"></div>
                </div>
                <div className="">
                  <p className="ts-status">
                    {e?.approve_reject_status == 2 ? "Rejected" : "Approved"}
                  </p>
                  <div className="ts-time-div d-flex">
                    {" "}
                    <img src={clockIcon} alt="" />{" "}
                    <p className="ts-date">{e?.show_updated_at} </p>
                    <div className="ts-vl"></div>
                    <img
                      src={personIcon}
                      alt=""
                      className="ts-person-icon"
                    />{" "}
                    <p>
                      {e?.current_user_name}
                      {/* ({e?.current_group_name}) */}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h3
              className="d-flex justify-content-center align-content-center"
              style={{ color: "red" }}
            >
              {" "}
              Data Not Available
            </h3>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TimeStamp;
