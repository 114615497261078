import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./datatable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../Assets/Svg/arrow-icon.svg";
import deleteIcon from "../../Assets/Svg/Delete-icon.svg";
import expoIcon from "../../Assets/Svg/Expo-Icon.svg";
import timeLine from "../../Assets/Svg/TimeLine.svg";
import VendorHistoryIcon from "../../Assets/Svg/vendor-history.svg";
import TimeStamp from "../../Models/TimeStamp/TimeStamp";
import ViewIssuePopup from "../../Models/ViewIssuePopup/ViewIssuePopup";
import CustomPopup from "./../../Models/CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
import RejectVendorPopup from "../../Models/RejectVendorPopup/RejectVendorPopup";
import { useNavigate } from "react-router-dom";
import { getVendorListTableData } from "../../Store/Reducer/vendorTable";
import { useDispatch, useSelector } from "react-redux";
import { DateGenerator, transformVendorTableDataResponse } from "../../Utils/Utils";
import { currentVendorRowData } from "../../Store/actions/vendorMangementAction";
import useDecryptedData from "../../CustomHooks/useDecryptedData";
import { tableStyles } from "../../Utils/TableStyles";
import { useTranslation } from "react-i18next";
import VendorHistory from "../../Models/VendorHistory/VendorHistory";

const VendorManagementTable = ({ setPageLoading = false }) => {
  const dispatch = useDispatch();
  const { vendorList, loading } = useSelector((state) => state.vendorTableList);

  const { res_data, TotalCount } = vendorList || {};
  const data = transformVendorTableDataResponse(res_data);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [showPopup, setShowPopup] = useState({
    viewIssue: false,
    timeLine: false,
    showApprove: false,
    showReject: false,
    deleteVendor: false,
    showVendorHistory: false,
  });
  const [rowData, setRowData] = useState({});
  const [count,setCount]=useState(TotalCount || 0)
  const [tableParams, setTableParams] = useState({
    page: currentPage,
    pageSize: itemsPerPage,
    orderBy: "updated_at",
    orderDir: "DESC",
    searchBy: null,
    searchColumns: [],
    startDate: null,
    endDate: null,
  });

  useEffect(()=>{
    setCount(TotalCount)
  },[TotalCount])
  const page = currentPage;
  const pageSize = itemsPerPage;
  const orderBy = tableParams?.orderBy;
  const orderDir = tableParams?.orderDir;
  const searchBy = tableParams?.searchBy;
  const searchColumns = tableParams?.searchColumns;
  const startDate = tableParams?.startDate;
  const endDate = tableParams?.endDate;

  const secretKey = "mysecretkey12345";

  const columnWidths = {
    "Vendor Code": "10%",
    "Company Name": "10%",
    "Bidding Category": "10%",
    "Date": "10%",
    "Current Group": "10%",
  };

  const columns = [
    {
      id: "vendor_code",
      name: t("Vendor Code"),
      selector: (result) => result?.vendor_code,
      searchable: true,
      width: "11%",
    },
    {
      id: "company_name_eng",
      name: "Company Name",
      selector: (result) => result?.company_name_eng,
      searchable: true,
      width: "11%",
    },
    {
      id: "binding_category",
      name: "Bidding Category",
      selector: (result) =>
        result?.binding_category.includes("Other")
          ? result.binding_category_specify
          : result.binding_category,
      searchable: true,
      width: "11%",
    },
    {
      id: "created_at",
      name: "Date",
      selector: (result) => DateGenerator(result?.Date),
      searchable: true,
      width: "11%",
    },
    {
      id: "rejected_by_group_name" && "current_group_name",
      name: "Current Group",
      searchable: true,
      width: "16%",
      selector: (result) => (
        <p
          className={
            result?.vendor_status === "Rejected"
              ? "table-status vm-status-reject"
              : "table-status vm-status-approve"
          }
        >
          {result?.vendor_status === "Rejected"
            ? (result?.current_group_name === "Exit"
            ? "Vendor": result?.current_group_name) : result?.current_group_name}
        </p>
      ),
    },
    {
      id: "vendor_status",
      name: "Status",
      cell: (result) => (
        <p
          className={
            result?.vendor_status === "Rejected"
              ? "table-status vm-status-reject"
              : result.vendor_status === "Pending"
              ? "table-status vm-status-pending"
              : "table-status vm-status-approve"
          }
        >
          {result?.vendor_status}
        </p>
      ),
      searchable: true,
      width: "16%",
    },
    {
      id: "Action",
      name: "Action",
      cell: (result) => (
        <button
          className="table-btn"
          onClick={() => {
            handleChangeView(result);
          }}
        >
          View
        </button>
      ),
      searchable:false,
      width: "10%",
    },
    {
      id: "Approve",
      selector: "Approve",
      cell: (result) => {
        const userIdMatched =
          decryptedData?.user_id == result?.current_stage_user_id;
        const checkGroupMatch = decryptedData?.assigner_type.includes(
          result?.current_group_name
        );

        const isRoleAssigner =
          decryptedData?.assigner_type.includes("Assigner");

        const checkCurrentGroupRoleIsAssigner =
          result?.current_group_name?.includes("Assigner") ?? true;

        const isVendorRejected = result?.vendor_status == "Rejected";

        const checkCurrentRoleIsAdmin = decryptedData?.role === "Admin";

        return (
          <div className="d-flex">
            {checkGroupMatch &&
              result?.current_group_name != "Admin" &&
              result?.vendor_status != "Approved" &&
              userIdMatched && (
                <>
                  <button
                    className="table-status vm-approve mx-2"
                    onClick={() => {
                      handleChangeColumn(result, { showApprove: true });
                    }}
                  >
                    Approve
                  </button>
                  {!isRoleAssigner && (
                    <button
                      className="table-status vm-reject mx-2"
                      onClick={() => {
                        handleChangeColumn(result, { showReject: true });
                      }}
                    >
                      Reject
                    </button>
                  )}
                </>
              )}
            {isVendorRejected && (
              <button
                className="mx-2"
                onClick={() => {
                  handleChangeColumn(result, { viewIssue: true });
                }}
              >
                <img src={expoIcon} alt="" />
              </button>
            )}
            {checkCurrentRoleIsAdmin && (
              <button
                className="mx-2"
                onClick={() => {
                  handleChangeColumn(result, { deleteVendor: true });
                }}
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            )}
            {!checkCurrentGroupRoleIsAssigner && (
              <button
                className="mx-2"
                onClick={() => {
                  handleChangeColumn(result, { timeLine: true });
                }}
              >
                <img src={timeLine} alt="" />
              </button>
            )}
            {result?.keys_updated===1 && (
              <button
                className="mx-2"
                onClick={() => {
                  handleChangeColumn(result, { showVendorHistory: true });
                }}
              >
                <img src={VendorHistoryIcon} alt="" />
              </button>
            )}
          </div>
        );
      },
      searchable: false,
      width: "20%",
    },
  ];

  const handleChangeView = (result) => {
    localStorage.setItem("vendor_id", result.vendor_id);
    navigate("/vendor-profile");
  };

  const handleChangeColumn = (result, obj) => {
    
    dispatch(currentVendorRowData(result));
    setShowPopup(obj);
    setRowData(result);
  };

  const handlePageChange = (page) => {
    setTableParams({
      ...tableParams,
      page,
    });
    setCurrentPage(page);
  };

  // handleing the sort functionality
  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // handling the search functionality
  const handleSearchChange = (event, columnName) => {
    setCurrentPage(1);
    const { value } = event.target;
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [columnName]: value,
    }));
  };


  const handleTypeSelect = (selectedKey) => setType(selectedKey);

  const filterData = () => {
    let filteredData = [...data];
    for (const columnName in searchValues) {
      const searchValue = searchValues[columnName];
      if (searchValue) {
        filteredData = filteredData?.filter((item) => {
          return item[columnName]
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase());
        });

      }
    }

    if (selectedDateRange[0] && selectedDateRange[1]) {
      filteredData = filteredData.filter((item) => {
        const registerDate = new Date(item.Date);
        return (
          registerDate >= selectedDateRange[0] &&
          registerDate <= selectedDateRange[1]
        );
      });
    }

    return filteredData;
  };

  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
    updateTableParams(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
      updateTableParams([]);
    }
  };


  const filteredData = filterData();

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
    total: filteredData.length,
  };

  const updateTableParams = (newDateRange) => {
    if (newDateRange && newDateRange.length === 2) {
      const formattedStartDate = newDateRange[0]?.toISOString().split("T")[0];
      const formattedEndDate = newDateRange[1]?.toISOString().split("T")[0];
      setTableParams((prevParams) => ({
        ...prevParams,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));
    } else {
      setTableParams((prevParams) => ({
        ...prevParams,
        startDate: null,
        endDate: null,
      }));
    }
  };
  const headerNames = columns.map((column) => column?.name);
  const csvData = data.map((item) =>
    columns.map((column) => {
      if (column.id === "created_at") {
        return DateGenerator(item.Date).toString();
      }
      if (column.id === "vendor_status") {
        return item?.vendor_status == "Pending"
        ? "Waiting For Approval"
        : item?.vendor_status;
      }
      return item[column.id];
    })
  );
  const closeTimeLine = () => {
    setShowPopup(false);
  };

  const closeVendorHistory=()=>{
    setShowPopup(false)
  };


  // calling vendor data

  useEffect(() => {
    // setPageLoading(loading);
    dispatch(
      getVendorListTableData({
        page,
        pageSize,
        orderBy,
        orderDir,
        searchBy,
        searchColumns,
        startDate,
        endDate,
        searchValues,
      })
    );
    
  }, [tableParams, itemsPerPage, currentPage, searchValues]);

  const decryptedData = useDecryptedData("encryptedUserInfo", secretKey);

  if (!decryptedData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* {data.length > 0 ? ( */}
      <div>
        <div className="um-table-header-div">
          <div className="um-table-head">
            <div className="table-navbar">
              <Nav activeKey="" onSelect={handleTypeSelect} className="">
                <Nav.Item>
                  <Nav.Link
                    className={
                      type === "all"
                        ? "supplier-visit-table-active"
                        : "supplier-visit-table"
                    }
                    eventKey="all"
                  >
                    All
                    <span
                      className={
                        type === "all"
                          ? "supplier-visit-table-badge-active"
                          : "supplier-visit-table-badge"
                      }
                      eventKey="all"
                    >
                      {TotalCount}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <DateRangePicker
                appearance="default"
                format={"yyyy-MM-dd"}
                placement="bottomEnd"
                placeholder={"Start date ~ End date"}
                value={selectedDateRange}
                onChange={handleDateRangeChange}
                onClean={handleDateRangeClean}
              />
            </div>

            <div className="d-flex justify-content-between align-items-center um-entries-div">
              <div className="d-flex justify-content-between align-items-center um-entries">
                {" "}
                <p>Show </p>{" "}
                <select value={itemsPerPage} onChange={handleDropdownChange}>
                  <option>5</option>
                  <option>10</option>
                  <option>15</option>
                  <option>20</option>
                  <option>25</option>
                  <option>30</option>
                  <option>100</option>
                </select>{" "}
                <span>entries</span>
              </div>
              <CSVDownloadButton
                data={csvData}
                headers={headerNames}
                filename="VendorManagementTable_data.csv"
              />
            </div>
          </div>
          <div
            style={{ position: "relative", margin: "0px" }}
            className={data.length ? "vm-data-table" : "vm-empty-data-table"}
          >
            <DataTable
              columns={columns}
              data={data}
              customStyles={tableStyles}
              pagination={false}
              noDataComponent="No records to display"
            />
            <div
              style={{
                position: "absolute",
                top: "50px",
                left: 0,
                right: 0,
                padding: "4px",
              }}
              className="table-search-div"
            >
              {columns.map((column) =>
              column.searchable !== false ? (
                <input
                key={column.id}
                  type="text"
                  value={searchValues[column.id] || ""}
                  style={{ width: column.width , marginRight:"2px"}}
                  onChange={(event) =>
                    handleSearchChange(event, column.id)
                  }
                />
              ) : null
            )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center pb-4">
          <p className="um-show-entries">
            {" "}
            Showing {displayedRange.start} to {displayedRange.end} of{" "}
            {TotalCount} entries
          </p>
          <CustomPagination
            totalItems={TotalCount}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>

        {showPopup.timeLine && (
          <TimeStamp
            isOpen={showPopup.timeLine}
            closeModal={closeTimeLine}
            userData={rowData}
          />
        )}
        {showPopup.showVendorHistory && (
          <VendorHistory
          isOpen={showPopup.showVendorHistory}
            closeModal={closeVendorHistory}
            userData={rowData}
          />
        )}

        {showPopup.viewIssue && (
          <ViewIssuePopup
            isOpenPopup={showPopup.viewIssue}
            closePopup={closeTimeLine}
            userData={rowData}
          />
        )}

        {showPopup.showApprove && (
          <CustomPopup
            isOpenPopup={showPopup.showApprove}
            closePopup={closeTimeLine}
            title="Are you sure!"
            image={areIcon}
            text={"You want to Approve this Vendor ?"}
            btn1="Cancel"
            btn2="Confirm"
            successTitle="Done!"
            successText={"Approve successfully"}
            successIcon={successIcon}
            btn3="Ok"
            scenarioType="approveVendor"
            handleChangeColumn={handleChangeColumn}
            userData={rowData}
            setPageLoading={setPageLoading}
          />
        )}

        {showPopup.showReject && (
          <RejectVendorPopup
            isOpenPopup={showPopup.showReject}
            closePopup={closeTimeLine}
            userData={rowData}
            setPageLoading={setPageLoading}
          />
        )}

        {showPopup.deleteVendor && (
          <CustomPopup
            isOpenPopup={showPopup.deleteVendor}
            closePopup={closeTimeLine}
            title="Are you sure!"
            image={areIcon}
            text={"You want to Delete this Vendor ?"}
            btn1="Cancel"
            btn2="Confirm"
            successTitle="Done!"
            successText={"Delete successfully"}
            successIcon={successIcon}
            btn3="Ok"
            scenarioType="deleteVendor"
            handleChangeColumn={handleChangeColumn}
            userData={rowData}
          />
        )}
      </div>
      {/* ) : (
        <h1 className="d-flex justify-content-center align-items-center mt-5">
          No Vendors Assigned Under You
        </h1>
      )} */}
    </>
  );
};

export default VendorManagementTable;
