import React from "react";
import "./podetails.css";
import PdfIcon from "../../Assets/Images/doc-icon.png";
import Logo from "../../Assets/Images/companyLogo.svg";
import Name from "../../Assets/Images/companyName.svg";
import { formatNumber, formatNumberWithCommasandDecimals, numberToWords } from "../../Utils/Utils";
import ODSReference from "../../Components/Common/ODSReference";

const PoPrint = ({data}) => {
  if(!data){
    return null;
  }
  

 

  function DateGenerator(data) {
    if (!data || typeof data !== "string") {
      return null;
    }
  
    const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;
    if (dateFormatRegex.test(data)) {
      return data;
    }
  
    const date = new Date(data);
    if (isNaN(date.getTime())) {  
      return null;
    }
  
   
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}/${month}/${day}`;
  }
  
  
  return (
    <>
    
      <div className="mx-4">
        <div className=" ">
        </div>
        <div className="">
          <img className="logo-1" src={Logo} alt="" />
          <img  src={Name} alt="" className="px-3 logo-2" />
        </div>
        <div className="d-flex my-4">
          <div className="col-7">
            <div
              className=" p-3"
              style={{ marginRight: "20px" }}
            >
              <h3 className="po-details-top-cards-content p-1">
                VENDOR CODE: {data?.VendorCode || ""} <br />
                 {data?.VendorName || ""} <br />
                 <br />
                {data?.VendorAddress || ""}
              </h3>
              <h4 className="po-details-top-cards-content p-1">
                E-mail: {data?.VendorEmail || ""} <br />
                TEL: {data?.VendorTel || ""} <span className="px-3">FAX: {data?.VendorFax || ""}</span>{" "}
                <br />
                Attn: {data?.VendorContactPerson || ""}
              </h4>
            </div>
          </div>
          <div className="col-5">
            <div className=" p-3">
              <h3 className="po-details-top-cards-header">SHIP TO ADDRESS</h3>
              <h5 className="po-details-top-cards-content">
              {data?.AVTShipToName || ""} <br />
              {data?.AVTShipToAddress || ""}
              <br />
              {data?.AVTShipToTel || ""} 
                <span className="px-3">{data?.AVTShipToFax || ""}</span>
              </h5>
            </div>
          </div>
        </div>
<div className="purchase-order">
    <h3>ใบสั่งซื้อ</h3>
<h3>PURCHASE ORDER</h3>

</div>
        <div className="grid-container-print ">
          <div className="item-1-print">
            <h4 className="po-details-top-cards-header p-2">
              PERSON IN CHARGE
            </h4>
          </div>
          <div className="item-1-print">
            <h4 className="po-details-top-cards-content p-2">
            {data?.PCMPersonInCharge || ""} <br />
              TEL: {data?.PCMTel || ""} <span>FAX: {data?.PCMFax || ""}</span>
              <br />
              E-mail: {data?.PCMEmail || ""}
            </h4>
          </div>
          <div className="item-3-print">
            <h4 className="po-details-top-cards-content p-2">P/O {data?.PONumber || ""}</h4>
          </div>
          <div className="item-4-print">
            <h4 className="po-details-top-cards-content p-2">
              DATE: {DateGenerator(data?.PODate) || ""}
            </h4>
          </div>
          <div className="item-5">
            <h4 className="po-details-top-cards-content p-2">Incoterm: <ODSReference data={data?.PO_Incoterm || ""}/></h4>
          </div>
        </div>

        <div className="grid-container-print-2 ">
          <div className="item1-print">
            <h4 className="po-details-top-cards-header p-2">
              Tax Invoice detail
            </h4>
          </div>
          <div className="item2">
            <h4 className="po-details-top-cards-content p-2">
              AGC VINYTHAI PUBLIC COMPANY LIMITED <br />
              2, I-3 Road, Map Ta Phut Industrial Estate, <br />
              Map Ta Phut Subdistrict, Mueang Rayong District, Rayong 21150
              Thailand <br />
              TAX ID: 0107565000395 Head Office.
            </h4>
          </div>
        </div>
        <div className="grid-container-print-2">
          <div className="item1-print">
            <h4 className="po-details-top-cards-header p-2">Payment Term</h4>
          </div>
          <div className="item2">
            <h4 className="po-details-top-cards-content p-2">
              {data?.POPaymentTerm || ""}
            </h4>
          </div>
        </div>
        <div className="grid-container-print-2">
          <div className="item1-1-print">
            <h4 className="po-details-top-cards-header p-2">Requisitioner</h4>
          </div>
          <div className="item2-2-print">
            <h4 className="po-details-top-cards-content p-2">
              P/R NO. { ""}
            </h4>
          </div>
          <div className="item3-3">
            <h4 className="po-details-top-cards-content p-2">TEL. { ""}</h4>
          </div>
        </div>
        <div className="po-details-ref-print p-2">
          <p className="po-details-top-cards-content">
          <ODSReference data={data?.ODS_Reference || ""}/>
          </p>
        </div>
        
      </div>
      <br />
      <div className="mx-4">
      {data?.poPrData?.map((pr, index) => (
        <div className="reference-component" key={index}>
        <div className="d-flex pr-details description" style={{ background: "white" }}>
          <div className="col-1 pr-details-1-print">
          <p className="po-details-desc" style={{ textAlign: "center" }}>
              No
            </p>
            <p
              className="po-details-top-cards-content py-3"
              style={{ textAlign: "center" }}
            >
              {index+1}
            </p>
          </div>
          <div className="col-11 po-details-top-cards-content pr-details-2">
          <div className="reference-box" style={{ background: "white" }}>
        <div className=" pr-details-2">
            <p className="po-details-desc py-2">Description</p>
            <p><ODSReference data={pr?.PrShortText || ""}/></p>
          </div>
          <table style={{ width: "50%"}}>
          <thead>
              <tr>
                <th style={{border:"1px solid #0b0b0b" }} className="table-cell po-details-top-cards-content p-2">Delivery Date</th>
                <th className="table-cell po-details-top-cards-content p-2">Qty</th>
                <th className="table-cell po-details-top-cards-content p-2">Unit</th>
                <th className="table-cell po-details-top-cards-content p-2">Unit Price ({data?.POCurrency})</th>
                <th className="table-cell po-details-top-cards-content p-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              
                <tr>
                  <td className="table-cell po-details-top-cards-content p-2">{DateGenerator(pr?.PrDeliveryDate)}</td>
                  <td className="table-cell po-details-top-cards-content p-2">{formatNumber(pr?.PrQTY)}</td>
                  <td className="table-cell po-details-top-cards-content p-2">{pr?.PRUnit} </td>
                  <td className="table-cell po-details-top-cards-content p-2">{pr?.PRUnitPrice} {data?.POCurrency}</td>
                  <td className="table-cell po-details-top-cards-content p-2">{formatNumberWithCommasandDecimals(pr?.PRAmount)}</td>
        
                </tr>
            </tbody>
          </table>
        </div>
        <br />
            <p>
              
                
                <ODSReference data={pr?.PrLongText || ""}/>
              
            </p>
          </div>
        </div>
        </div>
        ))}
        <div className=" top-amount-box-print">
        <div className="d-flex justify-content-end py-3 amount-boxes">
          <div className="px-3 po-details-top-cards-content">
            <p>Sub Total</p>
            <p>VAT 7.00%</p>
          </div>
          <div className="px-3 po-details-top-cards-content">
            <p>{formatNumberWithCommasandDecimals(data?.POSubTotal) || ""} {data?.POCurrency}</p>
            <p>{formatNumberWithCommasandDecimals(data?.POVatAmount) || ""} {data?.POCurrency}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end po-details-top-cards-header px-3">
          <p>({numberToWords(data?.POTotalAmount) || ""} {data?.POCurrency}) </p>
          <div className="d-flex amount">
          <p className="px-4">Total Amount</p>
          <p className="numbered-amount">{formatNumberWithCommasandDecimals(data?.POTotalAmount) || ""} {data?.POCurrency}</p>
          </div>
        </div>
      </div>
      <div className="reference-component my-4 p-3">
        <div className="d-flex">
          <div
            className="col-4 po-details-top-cards-content"
            style={{
              borderRight: "1px solid rgba(16, 58, 93, 1)",
              paddingLeft: "20px",
            }}
          >
            <p>Authorized by</p>
            <p>{data?.AuthorizedBy || ""}</p>
            <p>{DateGenerator(data?.AuthorizedDate) || "\u00A0"}</p>
            <br />
            <br />
            
          </div>
          <div
            className="col-4  po-details-top-cards-content"
            style={{
              borderRight: "1px solid rgba(16, 58, 93, 1)",
              paddingLeft: "20px",
            }}
          >
            <p>Authorized by</p>
          </div>
          <div
            className="col-4 po-details-top-cards-content"
            style={{ paddingLeft: "20px" }}
          >
            <p>Authorized by</p>
          </div>
          <br />
        </div>
        <div>
         
          <p>
            <li className="po-details-top-cards-content px-3">
              This purchase order has been approved by the authorized person &
              is valid without signature for less than 1 MTHB amount excluding
              vat, & is valid with signature for more than or equal 1 MTHB
              amount excluding vat
            </li>
          </p>
        </div>
      </div>
      <div className="reference-component my-4 p-4">
        <h4 className="po-details-top-cards-header">ACKNOWLEDGEMENT BY</h4>
        <div className="d-flex justify-content-between po-details-top-cards-content">
          <p>NAME</p>
          <p style={{ paddingRight: "500px" }}>DATE</p>
        </div>
        <br />
        <li className="po-details-top-cards-content">
          Please return the Order Acknowledgement with your signature via e-mail
          or fax within 3 working days
        </li>
      </div>
      <div className="reference-component po-details-top-cards-content my-2 p-4">
        <li>Invoices without a purchase order reference will not be paid.</li>
        <li>
          the instructions, specifications, Payment Term & condition as
          appearing on the purchase order or
        </li>
        <li>
          any attachments are made a part, hereof, which seller agreed by
          acceptance this order.
        </li>
        <li>
          Due to limited space available, VNT-ACTH have no parking area for the
          contractors on this Contract.
        </li>
        <li>This order is complied with documents as per below link</li>
        <li> http://www.agcvinythai.com/en/supply-chain/pcm</li>
      </div>
      </div>
    </>
  );
};

export default PoPrint;
