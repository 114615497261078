import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const adminToken = localStorage.getItem("vms_admin_token");
 

  return adminToken ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/" replace={true} />
  );
};

export default ProtectedRoute;
