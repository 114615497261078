// checked with lazy loading
// import { lazy } from "react";
// const Login = lazy(() => import("./Pages/Login"));
// const Dashboard = lazy(() => import("./Pages/Dashboard"));
// const UserManagement = lazy(() => import("./Pages/UserManagement"));
// const VendorManagement = lazy(() => import("./Pages/VendorMangement"));
// const CreateVendor = lazy(() => import("./Pages/CreateVendor"));
// const VendorProfile = lazy(() => import("./Pages/VendorProfile"));

import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import UserManagement from "./Pages/UserManagement";
import VendorManagement from "./Pages/VendorMangement";
import CreateVendor from "./Pages/CreateVendor";
import VendorProfile from "./Pages/VendorProfile";
import PrRelease from "./Pages/PrRelease";
import PressRelease from "./Pages/PressRelease";
import HistoryStorage from "./Pages/HistoryStorage";
import HistoryStorageNew from "./Pages/HistoryStorage/HistoryStorageNew";
import SupplierManagement from "./Pages/SupplierManagement";
import QuotationDetailsPage from "./Pages/SupplierManagement/QuotationDetailsPage";
import EvaluatedPage from "./Pages/SupplierManagement/EvaluatedPage";
import EvaluationPage from "./Pages/SupplierManagement/EvaluationPage";
import AdminAccountSetting from "./Pages/AccountSetting/AdminAccountSetting";
import NotFound from "./NotFound";
import JobManagement from "./Pages/JobManagement";
import JobManagementNew from "./Pages/JobManagement/JobManagementNew";
import PurchasingGroup from "./Pages/PurchasingGroup";
import EditEvaluationByEmail from "./Pages/SupplierManagement/EditEvaluationByEmail";
import UpdateEvaluation from "./Pages/SupplierManagement/UpdateEvaluation";
import PoDetailsPage from "./Pages/PoDetails";

const routes = [
  {
    path: "/",
    component: Login,
    isProtected: false,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/user-management",
    component: UserManagement,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/vendor-management",
    component: VendorManagement,
    isProtected: true,
  },
  {
    path: "/create-vendor",
    component: CreateVendor,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/vendor-profile",
    component: VendorProfile,
    isProtected: true,
  },
  {
    path: "/pr-release",
    component: PrRelease,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/press-release",
    component: PressRelease,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/history-storage",
    component: HistoryStorage,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]  },
  {
    path: "/history-storage-view/:id/:code/:evaluationId?",
    component: HistoryStorageNew,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]  },
  {
    path: "/Supplier-Management",
    component: SupplierManagement,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]  },
  {
    path: "/Supplier-Management-Quotation/:id",
    component: QuotationDetailsPage,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]  },
  {
    path: "/Supplier-Management-Evaluated/:id",
    component: EvaluatedPage,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/Supplier-Management-Evaluation/:formId/:PoDbId/:PoPrDbId/:vendorId/:poNo/:vendorCode/:dateStatus",
    component: EvaluationPage,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "*",
    component: NotFound,
    isProtected: false,
  },
  {
    path: "/job-management",
    component: JobManagement,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/job-management-view/:id",
    component: JobManagementNew,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path: "/Admin-account-setting",
    component: AdminAccountSetting,
    isProtected: true,
  },
  {
    path: "/purchasing-group",
    component: PurchasingGroup,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path:"/Supplier-Management-Evaluation-Submit-By-Email-Url/:id",
    component: EditEvaluationByEmail,
    isProtected: false,
    // assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path:"/Supplier-Management-Update-Evaluation/:id",
    component: UpdateEvaluation,
    isProtected: true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  },
  {
    path:"/po-details/:id",
    component: PoDetailsPage,
    isProtected:true,
    assignerType:["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  }
];

export default routes;
