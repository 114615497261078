import React, { useState } from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import "./VendorMangement.css";
import plusIcon from "../../Assets/Images/plus-ico.png";
import VendorManagementTable from "../../Components/DataTable/VendorManagementTable";
import PageLoader from "../../Models/PageLoader/PageLoader";
import { useSelector, useNavigate } from "../../Components/Imports/import";

const VendorMangement = () => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const { loading } = useSelector((state) => state.deleteParticularVendor);
  const { LoginDetails } = useSelector((state) => state.loginDetails);
  const { loading: vendorLoading } = useSelector(
    (state) => state.vendorTableList
  );

  
  const onClickCreateVendor = () => {
    navigate("/create-vendor");
  };
  return (
    <DefaultLayout>
      {(pageLoading || loading || vendorLoading) && <PageLoader />}

      <section className="um-main">
        <div className="um-title-btn">
          <h1 className="db-title">Vendor Management</h1>

          <button
            className="table-btn create-btn"
            onClick={onClickCreateVendor}
          >
            {" "}
            <img style={{ marginRight: "10px" }} src={plusIcon} alt="+" />{" "}
            Create New Vendor
          </button>
        </div>
        <VendorManagementTable setPageLoading={setPageLoading} />
      </section>
    </DefaultLayout>
  );
};

export default VendorMangement;
