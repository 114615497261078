import {
  React,
  useEffect,
  useTranslation,
  useForm,
  useState,
  useDispatch,
  useSelector,
  toast,
} from "../Imports/import";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import useSetFormValues from "./../../CustomHooks/useSetFormValues";
import { adminUpdateVendor } from "../../Store/actions/updateVendorAction";
import { getSingleVendorDetails } from "../../Store/Reducer/singleVendorDetails";
import {
  formatNumberWithCommas,
  handleKeyDown,
  handleOnWheel,
} from "../../Utils/Utils";

const Commercial = ({ editMode, setEditMode, data, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { error } = useSelector((state) => state.updateVendorReducer);
  const [formattedMinJobsOrder, setFormattedMinJobsOrder] = useState("");
  const [formattedMaxJobsOrder, setFormattedMaxJobsOrder] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // checkingthe min jobs and max jobs fields before replace

    const formattedData = {
      ...data,
      min_jobs_order:
        typeof data.min_jobs_order === "string" &&
        data.min_jobs_order.includes(",")
          ? data.min_jobs_order.replace(/,/g, "")
          : data.min_jobs_order,
      max_jobs_order:
        typeof data.max_jobs_order === "string" &&
        data.max_jobs_order.includes(",")
          ? data.max_jobs_order.replace(/,/g, "")
          : data.max_jobs_order,
    };

    const min = parseInt(formattedData.min_jobs_order);
    const max = parseInt(formattedData.max_jobs_order);

    if (isNaN(min) || isNaN(max) || min >= max) {
      toast.error(
        "Invalid input. Please ensure minimum value is lower than maximum value."
      );

      return;
    }

    loading(true);
    const userName = data.user_name;
    const formdata = new FormData();

    formdata.append("user_name", userName);
    formdata.append("min_jobs_order", formattedData.min_jobs_order);
    formdata.append("max_jobs_order", formattedData.max_jobs_order);
    formdata.append(
      "company_limit_size_project",
      data.company_limit_size_project
    );
    formdata.append(
      "analyses_process_for_late_delivery",
      data.analyses_process_for_late_delivery
    );
    formdata.append("project_reference", data.project_reference);
    formdata.append("size_specify", data.size_specify);
    formdata.append(
      "specify_project_reference",
      data.specify_project_reference
    );

    try {
      const isSuccess = await dispatch(adminUpdateVendor(formdata));

      if (isSuccess?.data?.status === 200) {
        await dispatch(getSingleVendorDetails());
        setEditMode(false);
        loading(false);
        toast.success(isSuccess?.data?.message);
      } else {
        loading(false);
        toast.error(isSuccess?.data?.message || "Something went wrong");
      }
    } catch (error) {
      
      toast.error(error.message || "Something went wrong");
      loading(false);
    }
  };
  const setDataValues = useSetFormValues(setValue);
  useEffect(() => {
    // setValue("min_jobs_order", formatNumberWithCommas(data.min_jobs_order));
    // setValue("max_jobs_order", formatNumberWithCommas(data.max_jobs_order));
    setDataValues(data);
    setFormattedMinJobsOrder(
      formatNumberWithCommas(data?.min_jobs_order) || ""
    );
    setFormattedMaxJobsOrder(
      formatNumberWithCommas(data?.max_jobs_order) || ""
    );
  }, [data, setDataValues]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
      <div className="genaral-main-div">
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Maximum and Minimum Value of jobs/order that you can accept?")}
            {editMode && <span className="staricon">*</span>} :
          </label>

          <div className="vp-min-max-div">
            <label className="vp-min-label">{t("Minimum")}</label>
            <div className="d-block">
              <input
                disabled={!editMode}
                type="text"
                className="vp-input"
                {...register("min_jobs_order", {
                  required: false,
                  maxLength: 11,
                  // validate: (value) =>
                  //   isValidNumber(value) || "Please enter a valid number.",
                })}
                value={formattedMinJobsOrder}
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                  const formattedValue = formatNumberWithCommas(rawValue);
                  setFormattedMinJobsOrder(formattedValue);
                  setValue("min_jobs_order", rawValue);
                }}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />

              {errors.min_jobs_order && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the minimum value")}
                </span>
              )}
            </div>
            <label className="vp-min-label">{t("Maximum")}</label>
            <div className="d-block">
              <input
                disabled={!editMode}
                type="text"
                className="vp-input"
                {...register("max_jobs_order", {
                  required: false,
                  maxLength: 11,
                })}
                value={formattedMaxJobsOrder}
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                  const formattedValue = formatNumberWithCommas(rawValue);
                  setFormattedMaxJobsOrder(formattedValue);
                  setValue("max_jobs_order", rawValue);
                }}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />

              {errors.max_jobs_order && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the maximum value")}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="vp-label-input">
          <label className="vp-label">
            {t("Does your company limit on the size of works or projects?")}
            {editMode && <span className="staricon">*</span>} :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("company_limit_size_project", {
                required: false,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {" "}
                {t("select")}
              </option>
              <option value="No">{t("No")}</option>
              <option value="Yes">{t("Yes, in value....")}</option>
            </select>
            {errors.company_limit_size_project && (
              <span className="error-msg">
                {t("Please select the correct value")}{" "}
              </span>
            )}
            <div className="vp-input-div" style={{ display: "contents" }}>
              {watch("company_limit_size_project") === "Yes" && (
                <>
                  <input
                    type="number"
                    className="vp-input mt-3"
                    disabled={!editMode}
                    {...register("size_specify", {
                      required: false,
                      maxLength: 80,
                    })}
                    onWheel={handleOnWheel}
                  />
                  {errors.size_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="vp-label-input">
          <label className="vp-label">
            {t("Project reference / Work reference")}
            {editMode && <span className="staricon">*</span>} :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("project_reference", {
                required: false,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {" "}
                {t("select")}
              </option>
              <option value="no">{t("No")}</option>
              <option value="yes">{t("Yes , please specify")}</option>
            </select>
            {errors.project_reference && (
              <span className="error-msg">
                {t("Please select the correct value")}{" "}
              </span>
            )}
            <div className="vp-input-div" style={{ display: "contents" }}>
              {watch("project_reference") === "yes" && (
                <>
                  <input
                    type="text"
                    className="vp-input mt-3"
                    disabled={!editMode}
                    {...register("specify_project_reference", {
                      required: false,
                      maxLength: 80,
                    })}
                  />
                  {errors.specify_project_reference && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="vp-label-input mb-5">
          <label className="vp-label">
            {t(
              "Does your company have the procedure to analyses the late delivery of goods and/or service failure"
            )}
            {editMode && <span className="staricon">*</span>} :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("analyses_process_for_late_delivery", {
                required: false,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {" "}
                {t("select")}
              </option>
              <option value="Yes">{t("Yes")}</option>
              <option value="No">{t("No")}</option>
            </select>
            {errors.analyses_process_for_late_delivery && (
              <span className="error-msg">
                {t("Please select the correct value")}
              </span>
            )}
          </div>
        </div>
        <CancelAndUpdateBtn
          editMode={editMode}
          setEditMode={setEditMode}
          reset={reset}
        />
      </div>
    </form>
  );
};

export default Commercial;
