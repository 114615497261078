import { useEffect } from 'react';

const useTypeQueryParam = (type, queryParams) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        queryParams.set('type', type);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
      }, [type, queryParams]);
};

export default useTypeQueryParam;
