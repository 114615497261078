import React, { useEffect } from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";

import { PageLoader, useDispatch, useSelector, useTranslation } from "../../../src/Components/Imports/import";
import PoDetails from "./PoDetails";
import { useParams } from "react-router-dom";
import { getPoById } from "../../Store/actions/historyStorageActions";
import "./podetails.css"
const PoDetailsPage = () => {
    const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {poData,loading,error}=useSelector((state)=>state.HistoryStorageReducer)
  useEffect(() => {
    dispatch(getPoById(id));
  }, [dispatch,id]);

  return (
    <DefaultLayout>
        {loading && <PageLoader/>}
      <section className="um-main">
        <PoDetails data={poData}/>
        </section>
    </DefaultLayout>
  );
};

export default PoDetailsPage;
