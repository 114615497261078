import {
  React,
  useState,
  useForm,
  useDispatch,
  useSelector,
  toast,
  BaseUrl,
  useNavigate,
  Http,
  useTranslation,
} from "../../Imports/import";
import { ADD_VENDOR } from "../../../ApiEndPoints/ApiEndPoints";
import {
  updatedDataFirst,
  updatedDataFifth,
  updatedDataSecond,
  updatedDataFourth,
  updatedDataThird,
  updatedDataSixth,
} from "../../../Store/Reducer/registrationReducers";
import { handleKeyDown } from "../../../Utils/Utils";

const Documents = ({ setType, setPageLoading }) => {
  const dispatch = useDispatch();
  const vendorData = useSelector((state) => state.registration);
  const generalInfoData = useSelector(
    (state) => state.registration.firstObject
  );
  const [file, setFile] = useState({});
  const [fileNames, setFileNames] = useState({});
  const [fileSizeError, setFileSizeError] = useState({});
  const { t } = useTranslation();
  let navigate = useNavigate();
  const userToken = localStorage.getItem("vms_admin_token");
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();


  // extracting the commercial registration type
  const commercialRegType =
    generalInfoData && generalInfoData.commercial_reg_type;
  
  const extractedValue =
    commercialRegType && commercialRegType.toString().match(/\((.*?)\)/)?.[1];

  const isDisable = extractedValue && ["P"].includes(extractedValue);
  const isDisableCertificate = extractedValue && ["L"].includes(extractedValue);
  const isDisableAnnualRevenue =
    extractedValue && ["P", "O", "U"].includes(extractedValue);
  const isDisableDealer = extractedValue && ["L"].includes(extractedValue);

  const onSubmit = async (data) => {


    const paymentMethod = vendorData.thirdObject.payment_method;

    // making fields mandatory

    let requiredFiles = [];

    if (extractedValue === "L" || extractedValue === "T") {
      requiredFiles.push("company_affidavit_doc", "vat_registration_doc");
    }

    if (
      (extractedValue === "L" ||
        extractedValue === "T" ||
        extractedValue === "P") &&
      paymentMethod !== "AVT" &&
      paymentMethod !== "AYUDHAYA"
    ) {
      requiredFiles.push("bank_info_doc");
    }

    if (extractedValue === "P") {
      requiredFiles.push("copy_id_card");
    }

    if (extractedValue === "P" || extractedValue === "T") {
      requiredFiles.push("certification_doc");
    }

    const uploadedFiles = Object.keys(file);

    // mandatory files checking
    const allFilesUploaded = requiredFiles.every((file) =>
      uploadedFiles.includes(file)
    );


    if (allFilesUploaded) {
      setPageLoading(true);
      const {
        firstObject,
        secondObject,
        thirdObject,
        fourthObject,
        fifthObject,
        sixthObject,
      } = vendorData;

      var formData = new FormData();
      // post api calling form all seven registration pages
      const dataObjects = [
        firstObject,
        secondObject,
        thirdObject,
        fourthObject,
        fifthObject,
        sixthObject,
      ];

      const Enums = [
        "process_litigation",
        "registered_sme",
        "last_6months_bank_guarantee",
        "company_limit_size_project",
        "analyses_process_for_late_delivery",
        "laboratory_accreditation",
        "qhse_management",
        "injury_deaths",
        "registered_foreigner_labor",
        "lower18_labor",
      ];
      //
      dataObjects.forEach((data) => {
        Object.keys(data).forEach((key) => {
          if (
            !(
              Enums?.includes(key) &&
              (data[key] == undefined || data[key] == null || data[key] == "")
            )
          ) {
            formData.append(key, data[key] !== undefined ? data[key] : "");
          }
        });
      });

      Object.keys(file).forEach((key) => {
        formData.append(key, file[key]);
      });

      try {
        const response = await Http.post(`${BaseUrl}${ADD_VENDOR}`, formData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status === 200) {
          setPageLoading(false);
          navigate("/vendor-management");
          // data clearing from all pages after api successful
          dispatch(updatedDataFirst({}));
          dispatch(updatedDataSecond({}));
          dispatch(updatedDataThird({}));
          dispatch(updatedDataFourth({}));
          dispatch(updatedDataFifth({}));
          dispatch(updatedDataSixth({}));
          toast.success(response.message);
        } else if (response.status === 401) {
          toast.error(response.message);
          setPageLoading(false);
        } else {
          setPageLoading(false);
          toast.error("Something went wrong");
        }
      } catch (error) {
        setPageLoading(false);
      }
    } else {
      setPageLoading(false);
      toast.error("Please Upload mandatory files and try again");
    }
  };
  const onClickBack = () => {
    setType("HSE");
  };

  const handleChange = (e) => {
  
    const fileSizeNumber = e.target.files[0].size / 1024 / 1024;
    if (fileSizeNumber < 20) {
      setFile((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0],
      }));
      setFileNames((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0].name,
      }));
      setFileSizeError((prevState) => ({
        ...prevState,
        [e.target.name]: "",
      }));
    } else {
      setFileSizeError((prevState) => ({
        ...prevState,
        [e.target.name]: "Please enter the file below 20MB size.",
      }));
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="pb-5">
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                1.{" "}
                {["O", "U", "A"].includes(extractedValue)
                  ? "Business license or Excise Document"
                  : t("The company certificate must not be older than 6 months. If the seller has changed their address, a P.P. 09 form must be attached. All attached documents must be signed by the authorized person as stated in the company certificate.")}
                {["L", "T"].includes(extractedValue) && (
                  <span className="staricon">*</span>
                )}
              </label>
              <br />
              <div className="button-input-container">
                <label className="input-border-documents">
                  {["O", "U", "A"].includes(extractedValue)
                    ? fileNames.business_license
                    : fileNames.company_affidavit_doc}
                </label>

                <div className="button-attach-container-hse">
                  <label
                    className={
                      isDisable
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={isDisable ? "" : handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name={
                        ["O", "U", "A"].includes(extractedValue)
                          ? "business_license"
                          : "company_affidavit_doc"
                      }
                      accept="application/pdf"
                      disabled={isDisable}
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">
                {["O", "U", "A"].includes(extractedValue)
                  ? fileSizeError.business_license
                  : fileSizeError.company_affidavit_doc}
              </p>

              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                2.{" "}
                {["O", "U", "A"].includes(extractedValue)
                  ? "Tax Registration"
                  : t("The VAT registration certificate (P.P. 20), along with all attached documents, must be signed by the authorized person as specified in the company certificate.")}
                {["L", "T"].includes(extractedValue) && (
                  <span className="staricon">*</span>
                )}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {["O", "U", "A"].includes(extractedValue)
                    ? fileNames.tax_registration
                    : fileNames.vat_registration_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className={
                      isDisable
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={isDisable ? "" : handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name={
                        ["O", "U", "A"].includes(extractedValue)
                          ? "tax_registration"
                          : "vat_registration_doc"
                      }
                      accept="application/pdf"
                      disabled={isDisable}
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">
                {["O", "U", "A"].includes(extractedValue)
                  ? fileSizeError.tax_registration
                  : fileSizeError.vat_registration_doc}
              </p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                {t("3.The bank account book or bank details, along with all attached documents, must be signed by the authorized person as specified in the company certificate.")}
                {["L", "T", "P"].includes(extractedValue) && (
                  <span className="staricon">
                    {" "}
                    {vendorData.thirdObject.payment_method !== "AVT" &&
                    vendorData.thirdObject.payment_method !== "AYUDHAYA"
                      ? "*"
                      : ""}
                  </span>
                )}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {fileNames.bank_info_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className={
                      vendorData.thirdObject.payment_method === "AVT" ||
                      vendorData.thirdObject.payment_method === "AYUDHAYA"
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={
                      vendorData.thirdObject.payment_method === "AVT"
                        ? ""
                        : handleChange
                    }
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name="bank_info_doc"
                      accept="application/pdf"
                      disabled={
                        vendorData.thirdObject.payment_method === "AVT" ||
                        vendorData.thirdObject.payment_method === "AYUDHAYA"
                      }
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">{fileSizeError.bank_info_doc}</p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                {t("4.Three Year Annual Revenue")}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {fileNames.annual_revenue_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className={
                      isDisableAnnualRevenue
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={isDisableAnnualRevenue ? "" : handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name="annual_revenue_doc"
                      accept="application/pdf"
                      disabled={isDisableAnnualRevenue}
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">{fileSizeError.annual_revenue_doc}</p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                {t("5.Three Year Financial Statements")}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {fileNames.financial_statement_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className={
                      isDisableAnnualRevenue
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={isDisableAnnualRevenue ? "" : handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name="financial_statement_doc"
                      accept="application/pdf"
                      disabled={isDisableAnnualRevenue}
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">
                {fileSizeError.financial_statement_doc}
              </p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                6.{" "}
                {["O", "U", "A"].includes(extractedValue)
                  ? "Certificate of incorporation"
                  : "Memorandum of association (หนังสือบริคณห์สนธิ)"}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {["O", "U", "A"].includes(extractedValue)
                    ? fileNames.certificate_of_incoporation
                    : fileNames.memorandum_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className={
                      isDisable
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={isDisable ? "" : handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name={
                        ["O", "U", "A"].includes(extractedValue)
                          ? "certificate_of_incoporation"
                          : "memorandum_doc"
                      }
                      accept="application/pdf"
                      disabled={isDisable}
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">
                {["O", "U", "A"].includes(extractedValue)
                  ? fileSizeError.certificate_of_incoporation
                  : fileSizeError.memorandum_doc}
              </p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                7.{" "}
                {extractedValue === "P"
                  ? "Copy of ID Card / Copy of house Registration"
                  : t("Company profile / Product line")}
                {extractedValue === "P" && <span className="staricon">*</span>}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {extractedValue === "P"
                    ? fileNames.copy_id_card
                    : fileNames.company_profile_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className="uploadFile-document"
                    onChange={handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name={
                        extractedValue === "P"
                          ? "copy_id_card"
                          : "company_profile_doc"
                      }
                      accept="application/pdf"
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">
                {extractedValue === "P"
                  ? fileSizeError.copy_id_card
                  : fileSizeError.company_profile_doc}
              </p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                {t("8.Autherize dealer if any")}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {fileNames.autherize_dealer_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className={
                      isDisableDealer
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={isDisableDealer ? "" : handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name="autherize_dealer_doc"
                      accept="application/pdf"
                      disabled={isDisableDealer}
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">{fileSizeError.autherize_dealer_doc}</p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                {t("9.Certificate document (for example ISO 9001 or any)")}
                {["P", "T"].includes(extractedValue) && (
                  <span className="staricon">*</span>
                )}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {fileNames.certification_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className={
                      isDisableCertificate
                        ? "uploadFile-document-disabled"
                        : "uploadFile-document"
                    }
                    onChange={isDisableCertificate ? "" : handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name="certification_doc"
                      accept="application/pdf"
                      disabled={isDisableCertificate}
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">{fileSizeError.certification_doc}</p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-Username-container ">
              <label className="vendor-input-label">
                {t("10.Project reference")}
              </label>
              <br />
              <div className="button-input-container">
                <label htmlFor="files" className="input-border-documents">
                  {fileNames.project_reference_doc}
                </label>
                <div className="button-attach-container-hse">
                  <label
                    className="uploadFile-document"
                    onChange={handleChange}
                  >
                    {t("Upload File")}
                    <input
                      type="file"
                      hidden
                      name="project_reference_doc"
                      accept="application/pdf"
                    />
                  </label>
                </div>
              </div>
              <p className="error-msg">{fileSizeError.project_reference_doc}</p>
              <p className="hse-mini">
                {t("Maximum file size 20 MB. Supported file formats: .pdf")}
              </p>
            </div>
          </div>
        </div>
        <div className="buttons">
          <div className="button-submit-container-back">
            <input
              className="button-submit"
              type="button"
              value={t("Back")}
              onClick={onClickBack}
            />
          </div>
          <div className="button-submit-container">
            <input
              className="button-submit"
              type="submit"
              value={t("Submit")}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Documents;
