import TYPES, { GET_PENALTY_BY_ID_SUCCESS, PENALTY_SEND_SUCCESS } from "../constants";

const initialState = {
  error: null,
  loading: false,
  supplierTableStatusCount: [],
  supplierList: {},
  singleEvaluationData: {},
  evaluationTableStatusCount: [],
  allEvaluationList: {},
  evaluationFormDropDown:[],
  evaluationFormById:{},
  poDataOver1Million:{},
  sendPoAttachment:{},
  penaltySend:{},
  getPenaltyByIdData:{},
};

const getSupplierManagementInfo = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.EVALUATION_FORM_GET_BY_ID_REQUEST:
    case TYPES.EVALUATION_FORM_DROPDOWN_REQUEST:
    case TYPES.GET_SUPPLIER_TABLE_STATUS_COUNT_REQUEST:
    case TYPES.GET_ALL_SUPPLIER_LIST_REQUEST:
    case TYPES.SAVE_EVALUTATION_REQUEST:
    case TYPES.GET_EVALUTATION_BY_ID_REQUEST:
    case TYPES.GET_ALL_EVALUTATION_LIST_REQUEST:
    case TYPES.GET_EVALUTATION_TABLE_STATUS_COUNT_REQUEST:
    case TYPES.UPDATE_EVALUTATION_BY_ID_REQUEST:
    case TYPES.EVALUATION_SUBMIT_BY_EMAIL_URL_REQUEST:
    case TYPES.DELETE_EVALUATION_REQUEST:
    case TYPES.GET_PO_DATA_OVER_1MILLION_REQUEST:
    case TYPES.SEND_PO_ATTACHMENT_REQUEST:
    case TYPES.DELETE_JOB_ATTACHMENT_REQUEST:
    case TYPES.PENALTY_SEND_REQUEST:
    case TYPES.GET_PENALTY_BY_ID_REQUEST:
    case TYPES.ACCEPT_PENALTY_REJECT_BY_ID_REQUEST:
    case TYPES.REJECT_PENALTY_REJECT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.EVALUATION_FORM_GET_BY_ID_FAILURE:
    case TYPES.EVALUATION_FORM_DROPDOWN_FAILURE:
    case TYPES.GET_SUPPLIER_TABLE_STATUS_COUNT_FAILURE:
    case TYPES.GET_ALL_SUPPLIER_LIST_FAILURE:
    case TYPES.SAVE_EVALUTATION_FAILURE:
    case TYPES.GET_EVALUTATION_BY_ID_FAILURE:
    case TYPES.GET_ALL_EVALUTATION_LIST_FAILURE:
    case TYPES.GET_EVALUTATION_TABLE_STATUS_COUNT_FAILURE:
    case TYPES.UPDATE_EVALUTATION_BY_ID_FAILURE:
    case TYPES.EVALUATION_SUBMIT_BY_EMAIL_URL_FAILURE:
    case TYPES.DELETE_EVALUATION_FAILURE:
    case TYPES.GET_PO_DATA_OVER_1MILLION_FAILURE:
    case TYPES.SEND_PO_ATTACHMENT_FAILURE:
    case TYPES.DELETE_PO_ATTACHMENT_FAILURE:
    case TYPES.PENALTY_SEND_FAILURE:
    case TYPES.GET_PENALTY_BY_ID_FAILURE:
    case TYPES.ACCEPT_PENALTY_REJECT_BY_ID_FAILURE:
    case TYPES.REJECT_PENALTY_REJECT_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TYPES.EVALUATION_SUBMIT_BY_EMAIL_URL_SUCCESS:
    case TYPES.DELETE_EVALUATION_SUCCESS:
    case TYPES.UPDATE_EVALUTATION_BY_ID_SUCCESS:
    case TYPES.SAVE_EVALUTATION_SUCCESS:
    case TYPES.DELETE_PO_ATTACHMENT_SUCCESS:
    case TYPES.ACCEPT_PENALTY_REJECT_BY_ID_SUCCESS:
    case TYPES.REJECT_PENALTY_REJECT_BY_ID_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case TYPES.GET_SUPPLIER_TABLE_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        supplierTableStatusCount: action.payload,
      };
    case TYPES.GET_ALL_SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        supplierList: action.payload,
      };

    case TYPES.GET_EVALUTATION_BY_ID_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        singleEvaluationData: action.payload,
      };

    case TYPES.GET_ALL_EVALUTATION_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        allEvaluationList: action.payload,
      };

    case TYPES.GET_EVALUTATION_TABLE_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        evaluationTableStatusCount: action.payload,
      };
    case TYPES.EVALUATION_FORM_DROPDOWN_SUCCESS:
      return{
        ...state,
        error:null,
        loading:false,
        evaluationFormDropDown:action.payload,
      }
    case TYPES.EVALUATION_FORM_GET_BY_ID_SUCCESS:
      return{
        ...state,
        error:null,
        loading:false,
        evaluationFormById:action.payload,
      }
    case TYPES.GET_PO_DATA_OVER_1MILLION_SUCCESS:
      return{
        ...state,
        error:null,
        loading:false,
        poDataOver1Million:action.payload,
      }
    case TYPES.SEND_PO_ATTACHMENT_SUCCESS:
      return{
        ...state,
        error:null,
        loading:false,
        sendPoAttachment:action.payload,
      }
    case PENALTY_SEND_SUCCESS:
      return{
        ...state,
        error:null,
        loading:false,
        penaltySend:action.payload,
      }
    case GET_PENALTY_BY_ID_SUCCESS:
      return{
        ...state,
        error:null,
        loading:false,
        getPenaltyByIdData:action.payload,
      }
    default:
      return state;
  }
};

export default getSupplierManagementInfo;
