import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import axios from "axios";
import TYPES from "../constants";
import { cleanObject, convertToQueryString } from "../../Utils/Utils";
import {
  ACCEPT_PENALTY_REJECT_BY_ID,
  DELETE_EVALUATION,
  DELETE_PO_ATTACHMENT,
  EVALUATION_FORM_DROPDOWN,
  EVALUATION_FORM_GET_BY_ID,
  EVALUATION_SUBMIT_BY_EMAIL_URL,
  GET_ALL_EVALUTATION_LIST,
  GET_ALL_SUPPLIER_LIST,
  GET_EVALUTATION_BY_ID,
  GET_EVALUTATION_TABLE_STATUS_COUNT,
  GET_PENALTY_BY_ID,
  GET_PO_DATA_OVER_1MILLION,
  GET_SUPPLIER_TABLE_STATUS_COUNT,
  PENALTY_SEND,
  REJECT_PENALTY_REJECT_BY_ID,
  SAVE_EVALUTATION,
  SEND_PO_ATTACHMENT,
  UPDATE_EVALUTATION_BY_ID,
} from "../../ApiEndPoints/ApiEndPoints";

export const getAllSupplierTableStatusCount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_SUPPLIER_TABLE_STATUS_COUNT_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${GET_SUPPLIER_TABLE_STATUS_COUNT}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_SUPPLIER_TABLE_STATUS_COUNT_SUCCESS,
          payload: response?.data?.data,
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_SUPPLIER_TABLE_STATUS_COUNT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_SUPPLIER_TABLE_STATUS_COUNT_FAILURE,
        error: error.message,
      });
    }
  };
};

// Action creator for getting all supplier list
export const getAllSupplierMananagment = (
  page=1,
  pageSize=20,
  orderBy="PoPrDbId",
  orderDir="ASC",
  FilterStatus,
  startDate,
  endDate,
  searchValues,
) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_ALL_SUPPLIER_LIST_REQUEST,
      });

      const queryParams = buildQueryParams({
        page,
        pageSize,
        orderBy,
        orderDir,
        FilterStatus,
        startDate,
        endDate,
        searchByColumNameValue: searchValues,
      });

      const url = buildUrlWithQueryParams(queryParams, GET_ALL_SUPPLIER_LIST);

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_SUPPLIER_LIST_SUCCESS,
          payload: response.data?.data,
        });
      } else {
        dispatch({
          type: TYPES.GET_ALL_SUPPLIER_LIST_FAILURE,
          error: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_SUPPLIER_LIST_FAILURE,
        error: error.message,
      });
    }
  };
};

// New action creator for getting all evaluation list
export const getAllEvaluationMananagment = (
  page=1,
  pageSize=10,
  orderBy="evaluationId",
  orderDir="DESC",
  FilterStatus,
  startDate,
  endDate,
  searchValues,
) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_ALL_EVALUTATION_LIST_REQUEST,
      });

      const queryParams = buildQueryParams({
        page,
        pageSize,
        orderBy,
        orderDir,
        FilterStatus,
        startDate,
        endDate,
        searchByColumNameValue: searchValues,
      });

      const url = buildUrlWithQueryParams(
        queryParams,
        GET_ALL_EVALUTATION_LIST
      );

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_EVALUTATION_LIST_SUCCESS,
          payload: response.data?.data,
        });
      } else {
        dispatch({
          type: TYPES.GET_ALL_EVALUTATION_LIST_FAILURE,
          error: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_EVALUTATION_LIST_FAILURE,
        error: error.message,
      });
    }
  };
};

// Function to build query params
const buildQueryParams = ({
  page,
  pageSize,
  orderBy,
  orderDir,
  FilterStatus,
  startDate,
  endDate,
  searchByColumNameValue,
}) => {
  const queryParams = {
    page,
    pageSize,
    orderBy,
    orderDir,
  };
 
  if (FilterStatus && FilterStatus !== "all") {

    queryParams.filterStatus = FilterStatus;
  }

  if (startDate && endDate) {
    queryParams.startDate = startDate;
    queryParams.endDate = endDate;
  }

  if (searchByColumNameValue) {
    const cleanedSearchValues = cleanObject(searchByColumNameValue);
    if (Object.keys(cleanedSearchValues).length > 0) {
      queryParams.searchByColumNameValue = JSON.stringify(cleanedSearchValues);
    }
  }

  return queryParams;
};

// Function to build URL with query params
const buildUrlWithQueryParams = (queryParams, endpoint) => {
  const queryString = convertToQueryString(queryParams);
  return `${BaseUrl}${endpoint}${queryString}`;
};

export const getAllEvaluationTableStatusCount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_EVALUTATION_TABLE_STATUS_COUNT_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${GET_EVALUTATION_TABLE_STATUS_COUNT}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_EVALUTATION_TABLE_STATUS_COUNT_SUCCESS,
          payload: response?.data?.data?.data || [],
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_EVALUTATION_TABLE_STATUS_COUNT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_EVALUTATION_TABLE_STATUS_COUNT_FAILURE,
        error: error.message,
      });
    }
  };
};


//Function to  get the Evaluation Forms
export const getEvaluationFormDropDown= () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.EVALUATION_FORM_DROPDOWN_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${EVALUATION_FORM_DROPDOWN}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
     
      if (response.status === 200) {
        dispatch({
          type: TYPES.EVALUATION_FORM_DROPDOWN_SUCCESS,
          payload: response?.data?.data?.formList || [],
        });
        return response;
      } else {
        dispatch({
          type: TYPES.EVALUATION_FORM_DROPDOWN_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.EVALUATION_FORM_DROPDOWN_FAILURE,
        error: error.message,
      });
    }
  };
};

//Function to get Evaluation Form Data based on form selection:
export const getEvaluationFormData= (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.EVALUATION_FORM_GET_BY_ID_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${EVALUATION_FORM_GET_BY_ID}?evaluationFormId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
     
      if (response.status === 200) {
        dispatch({
          type: TYPES.EVALUATION_FORM_GET_BY_ID_SUCCESS,
          payload: response?.data?.data?.formData || [],
        });
        return response;
      } else {
        dispatch({
          type: TYPES.EVALUATION_FORM_GET_BY_ID_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.EVALUATION_FORM_GET_BY_ID_FAILURE,
        error: error.message,
      });
    }
  };
};

// New action creator for getting all evaluation list
export const getPODataOver1Million = (
  page=1,
  pageSize=10,
  orderBy="PoDbId",
  orderDir="DESC",
  FilterStatus="all",
  startDate,
  endDate,
  searchValues,
) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_PO_DATA_OVER_1MILLION_REQUEST,
      });

      const queryParams = buildQueryParams({
        page,
        pageSize,
        orderBy,
        orderDir,
        FilterStatus,
        startDate,
        endDate,
        searchByColumNameValue: searchValues,
      });

      const url = buildUrlWithQueryParams(
        queryParams,
        GET_PO_DATA_OVER_1MILLION
      );

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_PO_DATA_OVER_1MILLION_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: TYPES.GET_PO_DATA_OVER_1MILLION_FAILURE,
          error: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_PO_DATA_OVER_1MILLION_FAILURE,
        error: error.message,
      });
    }
  };
};


//send po
export const uploadPoDoc = (id,file)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.SEND_PO_ATTACHMENT_REQUEST,
    });
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
      formData.append('attachment', file);

      const response = await axios.post(
        `${BaseUrl}${SEND_PO_ATTACHMENT}/${id}`,
        formData,
        {
          headers
        }
      );
     
      if (response.status === 201) {
        dispatch({
          type: TYPES.SEND_PO_ATTACHMENT_SUCCESS,
          payload: response?.data,
        });
        toast.success(response.data.message);
      }
      else {
        dispatch({
          type: TYPES.SEND_PO_ATTACHMENT_FAILURE,
          error: "API request failed",
        });
        toast.error(response.message);
      }

    }
    catch(error){
      dispatch({
        type: TYPES.SEND_PO_ATTACHMENT_FAILURE,
        error: error.message,
      });
      toast.error(error?.response?.data?.message || "Error occured");
    }
  }
}
//delete po doc

export const deletePoDoc = (id) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.DELETE_PO_ATTACHMENT_REQUEST,
    });
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
     
      const response = await axios.delete(
        `${BaseUrl}${DELETE_PO_ATTACHMENT}/${id}`,
        {
          headers,
        }
      );
  if (response.status === 200) {
        dispatch({
          type: TYPES.DELETE_PO_ATTACHMENT_SUCCESS,
        });
        toast.success(response.data.message);
      } else {
        dispatch({
          type: TYPES.DELETE_PO_ATTACHMENT_FAILURE,
          error: "API request failed",
        });
        toast.error("Something went wrong, Please try again.");
      }
    } catch (error) {
      dispatch({
        type: TYPES.DELETE_PO_ATTACHMENT_FAILURE,
        error: error.message,
      });
      toast.error(error.message);
    }
  };
};


//Function to send penalty
export const sendPenalty = (
  vendorCode,
  vendorId,
  PoDbId,
  PoPrDbId,
  title,
  description,
  penaltyClause,
  penaltyAmount="1500.50",
  penaltyCurrency="USD",
) => {
    return async (dispatch)=>{
    
      dispatch({
        type: TYPES.PENALTY_SEND_REQUEST,
      });
      try {
        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
      
        const body = {
          "vendorCode": vendorCode,
          "vendorId": vendorId,
          "PoDbId": PoDbId,
          "PoPrDbId": PoPrDbId,
          "title": title,
          "description": description,
          "penaltyClause": penaltyClause,
          "penaltyAmount": penaltyAmount,
          "penaltyCurrency": penaltyCurrency,
        };
       
        const response = await axios.post(
          `${BaseUrl}${PENALTY_SEND}`,
          body,
          {headers}
        );
       
        if (response.status === 201) {
          dispatch({
            type: TYPES.PENALTY_SEND_SUCCESS,
            payload: response?.data,
          });
          toast.success(response.data.message);
        }
        else {
          dispatch({
            type: TYPES.PENALTY_SEND_FAILURE,
            error: "API request failed",
          });
          toast.error(response.message);
        }
  
      }
      catch(error){
   
        dispatch({
          type: TYPES.PENALTY_SEND_FAILURE,
          error: error.message,
        });
        toast.error(error.response.message);
      }
    }
  }


//Function to get Penalty By ID
export const getPenaltyById= (id) => {
  return async (dispatch) => {
    try {
      
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_PENALTY_BY_ID_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${GET_PENALTY_BY_ID}/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_PENALTY_BY_ID_SUCCESS,
          payload: response?.data?.data || [],
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_PENALTY_BY_ID_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_PENALTY_BY_ID_FAILURE,
        error: error.message,
      });
    }
  };
};


//Function to accept penalty Reject
export const acceptPenalty = (id) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.ACCEPT_PENALTY_REJECT_BY_ID_REQUEST,
    });
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
     
      const response = await axios.patch(
        `${BaseUrl}${ACCEPT_PENALTY_REJECT_BY_ID}/${id}`,
        {},
        {
          headers,
        }
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.ACCEPT_PENALTY_REJECT_BY_ID_SUCCESS,
        });
        toast.success(response.data.message);
      } else {
        dispatch({
          type: TYPES.ACCEPT_PENALTY_REJECT_BY_ID_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.ACCEPT_PENALTY_REJECT_BY_ID_FAILURE,
        error: error.message,
      });
      toast.error(error.response.data.message);
    }
  };
};


//Function to reject Penalty Reject
export const rejectPenalty = (id) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.REJECT_PENALTY_REJECT_BY_ID_REQUEST,
    });
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
     
      const response = await axios.patch(
        `${BaseUrl}${REJECT_PENALTY_REJECT_BY_ID}/${id}`,
        {},
        {
          headers,
        }
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.REJECT_PENALTY_REJECT_BY_ID_SUCCESS,
        });
        toast.success(response.data.message);
      } else {
        dispatch({
          type: TYPES.REJECT_PENALTY_REJECT_BY_ID_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.REJECT_PENALTY_REJECT_BY_ID_FAILURE,
        error: error.message,
      });
      toast.error(error.response.data.message);
    }
  };
};


//Save Evaluation 
export const saveEvaluation = (data) => {
    return async (dispatch)=>{
      
      dispatch({
        type: TYPES.SAVE_EVALUTATION_REQUEST,
      });
      try {
        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
      
        
       
        const response = await axios.post(
          `${BaseUrl}${SAVE_EVALUTATION}`,
          data,
          {headers}
        );
        
       
        if (response.status === 201) {
          dispatch({
            type: TYPES.SAVE_EVALUTATION_SUCCESS,
            payload: response?.data,
          });
          toast.success(response.data.message);
        }
        else {
          dispatch({
            type: TYPES.SAVE_EVALUTATION_FAILURE,
            error: "API request failed",
          });
          toast.error(response.message);
        }
  
      }
      catch(error){
        dispatch({
          type: TYPES.SAVE_EVALUTATION_FAILURE,
          error: error.message,
        });
        toast.error(error.response?.data.message);
      }
    }
  }


  export const getEvaluationDataById= (id) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: TYPES.GET_EVALUTATION_BY_ID_REQUEST,
        });
        const response = await axios.get(
          `${BaseUrl}${GET_EVALUTATION_BY_ID}/${id}`);
        if (response.status === 200) {
          dispatch({
            type: TYPES.GET_EVALUTATION_BY_ID_SUCCESS,
            payload: response?.data?.data?.data || {},
          });
          return response;
        } else {
          dispatch({
            type: TYPES.GET_EVALUTATION_BY_ID_FAILURE,
            error: "Something went wrong , please try again",
          });
        }
      } catch (error) {
        dispatch({
          type: TYPES.GET_EVALUTATION_BY_ID_FAILURE,
          error: error.message,
        });
      }
    };
  };
  


//Evaluation Submit By Email Url
export const submitEvaluation = (id,data) => {
  return async (dispatch)=>{
    
    dispatch({
      type: TYPES.EVALUATION_SUBMIT_BY_EMAIL_URL_REQUEST,
    });
    try {
      const token = localStorage.getItem("vms_admin_token"); 
      const response = await axios.patch(
        `${BaseUrl}${EVALUATION_SUBMIT_BY_EMAIL_URL}?evaluationId=${id}`,
        data,
      );
      
     
      if (response.status === 200) {
        dispatch({
          type: TYPES.EVALUATION_SUBMIT_BY_EMAIL_URL_SUCCESS,
          payload: response?.data,
        });
        toast.success(response.data.message);
      }
      else {
        dispatch({
          type: TYPES.EVALUATION_SUBMIT_BY_EMAIL_URL_FAILURE,
          error: "API request failed",
        });
        toast.error(response.message ||"");
      }

    }
    catch(error){
      dispatch({
        type: TYPES.EVALUATION_SUBMIT_BY_EMAIL_URL_FAILURE,
        error: error.message,
      });
      toast.error(error.response?.data?.message || "An error occurred during submission.");

    }
  }
}



//Evaluation Update
export const updateEvaluation = (id,data) => {
  return async (dispatch)=>{
    
    dispatch({
      type: TYPES.UPDATE_EVALUTATION_BY_ID_REQUEST,
    });
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.patch(
        `${BaseUrl}${UPDATE_EVALUTATION_BY_ID}?evaluationId=${id}`,
        data,
        {headers}
      );
      
     
      if (response.status === 200) {
        dispatch({
          type: TYPES.UPDATE_EVALUTATION_BY_ID_SUCCESS,
          payload: response?.data,
        });
        toast.success(response.data.message);
      }
      else {
        dispatch({
          type: TYPES.UPDATE_EVALUTATION_BY_ID_FAILURE,
          error: "API request failed",
        });
        toast.error(response.message);
      }

    }
    catch(error){
      dispatch({
        type: TYPES.UPDATE_EVALUTATION_BY_ID_FAILURE,
        error: error.message,
      });
      toast.error(error.response?.data.message);
    }
  }
}


export const deleteEvaluation = (id) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.DELETE_EVALUATION_REQUEST,
    });
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
     
      const response = await axios.delete(
        `${BaseUrl}${DELETE_EVALUATION}/?evaluationId=${id}`,
        {
          headers,
        }
      );
  if (response.status === 200) {
        dispatch({
          type: TYPES.DELETE_EVALUATION_SUCCESS,
        });
        toast.success(response.data.message);
        return true;
      } else {
        dispatch({
          type: TYPES.DELETE_EVALUATION_FAILURE,
          error: "API request failed",
        });
        toast.error("Something went wrong, Please try again.");
      }
    } catch (error) {
      dispatch({
        type: TYPES.DELETE_EVALUATION_FAILURE,
        error: error.message,
      });
      toast.error(error.response?.data.message);
    }
  };
};
