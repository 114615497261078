import React from "react";

const LazyLoadingFallback = () => {
  return (
    <h3 className="d-flex justify-content-center align-items-center">
      Loading...
    </h3>
  );
};

export default LazyLoadingFallback;
