import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import {
  useDispatch,
  useSelector,
  useTranslation,
} from "../../../src/Components/Imports/import";
import "./PrDetailsPopup.css";
import { getSingleJobDetails, updateJobDetails } from "../../Store/actions/jobManagementAction";
import { formatInputDate } from "../../Utils/Utils";

const PrDetailsPopup = ({ isOpen, closeModal, formTitle, jobId }) => {
  const dispatch = useDispatch();
  const { singleJobDetails } = useSelector(
    (state) => state.getJobManagementInfo
  );

  const { t } = useTranslation();
  const [data, setData] = useState({});
  useEffect(() => {
      if(jobId){
        dispatch(getSingleJobDetails(jobId));
      }
      
  }, [jobId]);

  useEffect(() => {
   
      setData(singleJobDetails?.JobDetails || {});
    
  }, [singleJobDetails]);

  // CardItem component to represent each card
  const CardItem = ({ item, index }) => (
    <div className="quo-pr-com-card-item">
      <span className="quo-pr-com-card-title">{t("Short Text")}</span>
      <input
        type="text"
        value={item?.PrSapDetail?.PR_description_Short || "" }
        readOnly
        className="quo-pr-com-card-content"
      />
      <span className="quo-pr-com-card-title">{t("PR Number")}</span>
      <input
        type="text"
        value={item?.PrSapDetail?.PR_ID}
        className="quo-pr-com-card-content"
        readOnly
      />

      <span className="quo-pr-com-card-title">{t("Long Text")}</span>
      <textarea
        value={item?.PrSapDetail?.PR_description_Long ||""}
        readOnly
        className="quo-pr-com-card-content"
      />
    </div>
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const [showError, setShowError] = useState(false);

  const onSubmit = (data) => {
    const prData={...data,JobId:jobId}
    dispatch(updateJobDetails(prData));
    closeModal();
  };
  useEffect(() => {
    if (data) {
      setValue("PrTitle", data.PrTitle);
      setValue("Note", data.Note);
      setValue("BiddingDate", formatInputDate(data?.BiddingDate));
      setValue("DeliveryDate", formatInputDate(data?.DeliveryDate));
    }
  }, [data, setValue]);

  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="pr-details-user-modal"
        overlayClassName="eva-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="evaa-cre-user-title d-flex">
          <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
          <img src={closeIcon} alt="" onClick={closeModal} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <div className="pr-details-pr-title" style={{ width: "100%" }}>
            <label htmlFor="PR Title">{t("PR Title")}</label>
            <br />
            <input
              type="text"
              id="PR Title"
              {...register("PrTitle")}
            />
          </div>
          <br />
          <div className="qt-main-scroll">
            <div className="quo-pr-comb-det">
              <div className="quo-pr-com-det-text">
                {t("PR Combined Detail")}
              </div>
              {Array.isArray(data?.JobPrDetails) &&
                data?.JobPrDetails?.map((item, index) => (
                 
                  <div className="quo-pr-com-card-qtn" key={index}>
                    {/* Render the CardItem component with props */}
                    <CardItem item={item} index={index} />
                  </div>
                ))}
            </div>
          </div>

          {/* note */}
          <div className="quotation-pr-title">
            <span className="quotation-pr-note-text">{t("Note")}</span>
            <div className="pr-note-textarea">
              <textarea
                rows="3"
                cols="130"
                {...register("Note")}
              
                
              />
            </div>
          </div>
          <br />
          <div className="pr-details-date-main">
            <div className="pr-details-bidd-deli-text">
              <label htmlFor="BiddingEnd">{t("Bidding End")}</label>
              <br />
              <input
                type="date"
                {...register("BiddingDate")}
                placeholder="Select a Date"
              />
            </div>
            <div className="pr-details-bidd-deli-text">
              <label>{t("Delivery Date")}</label>
              <input
                type="date"
                placeholder="Select a Date"
                {...register("DeliveryDate")}
              />
            </div>
          </div>

          <div className="pt-3 create-btns-div">
            <button type="submit" className="um-submit-btn">
              {t("Submit")}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PrDetailsPopup;
