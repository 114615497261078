import {
  React,
  useState,
  useForm,
  useDispatch,
  useSelector,
  PageLoader,
  toast,
} from "../../Components/Imports/import";
import Modal from "react-modal";
import CloseIcon from "../../Assets/Svg/close-icon.svg";
import "./PenaltyPopup.css";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
//need to update actual logic for api integration
import {
  createPressRelease,
  editParticularPressRelease,
} from "../../Store/actions/pressReleaseAction";
import Select, { components } from "react-select";
import Up from "../../Assets/Images/Up.png";
import Down from "../../Assets/Images/Down.png";
import { sendPenalty } from "../../Store/actions/supplierManagementActions";
import { useEffect } from "react";
import { calculateDaysBetweenDates, calculatePenalty, DateGenerator, formatNumber } from "../../Utils/Utils";

// PenaltyPopup Component
const PenaltyPopup = ({
  penaltyIsOpen,
  penaltyCloseModal,
  penaltyFormTitle,
  userData,
}) => {
  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };
  const { penaltySend,loading,error } = useSelector(
    (state) => state.getSupplierManagementInfo
  );
  const dispatch = useDispatch();
  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState();
  const [penaltyAmount,setPenaltyAmount]=useState();
  const [penaltyLimit,setPenaltyLimit]=useState(0)
  
  const [penaltyInfo,setPenaltyInfo]=useState({
    penaltyAmount: "",
    penaltyTitle:"",
    penaltyDescription: "",
  }) 
  
  useEffect(()=>{
    let currentDate =new Date();
    let days =userData?.GrDate ?Math.floor(calculateDaysBetweenDates(userData?.PrDeliveryDate,userData?.GrDate)) : Math.floor(calculateDaysBetweenDates(currentDate,userData?.PrDeliveryDate))
    const tenPercentOfPenalty=userData?.PoAmount*(0.1)
    setPenaltyLimit(tenPercentOfPenalty)
    const penalty=calculatePenalty(userData?.PoAmount, userData?.GrDate,userData?.PrDeliveryDate);
    const penaltyTitle=`Refer [${userData?.poNo}], Delivery date is [${DateGenerator(userData?.PrDeliveryDate)}]. However you deliver to us on [${DateGenerator(userData?.GrDate)}]`
    const penaltyDescription=`Under the delay penalty clause mentionable in General Terms and Condition, You agree to be penalized at the rate of 0.5% of the selling price per day and not exceeding than 10%. Due to you fall to delay delivery total ${userData?.GrDate ? days:`> ${days}`} days. We shall claim to you for penalty total amount  `;
    if(!penalty){
      penaltyCloseModal();
    }
    
    setPenaltyAmount(penalty>tenPercentOfPenalty?formatNumber(tenPercentOfPenalty):formatNumber(penalty));
    setPenaltyInfo({
      penaltyAmount: penalty>tenPercentOfPenalty?tenPercentOfPenalty:penalty,
      penaltyTitle:penaltyTitle,
      penaltyDescription: penaltyDescription,
    })
  },[userData])

  // select
  const [selectedOption, setSelectedOption] = useState(null);

  const DropdownIndicator = (props) => {
    const { selectProps } = props;
    let arrow = selectProps.menuIsOpen ? (
      <img src={Up} alt="Up Arrow" />
    ) : (
      <img src={Down} alt="Down Arrow" />
    );
    return (
      <components.DropdownIndicator {...props}>
        {arrow}
      </components.DropdownIndicator>
    );
  };

  const handlePenaltyAmountChange = (e) => {
    let value = e.target.value;
    const numericValue = value.replace(/,/g, "");
    setPenaltyAmount(numericValue>penaltyLimit?penaltyLimit:numericValue);
  };
  const handlePenaltyAmountBlur = () => {
    let value=String(penaltyAmount);
    const numericValue = value.replace(/,/g, "");
    setPenaltyAmount(formatNumber(numericValue));
  };
  //end

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    if(!userData.vendorCode){
      toast.error("vendor code is null")
      penaltyCloseModal();
    }
    if(!userData.vendorId){
      toast.error("vendor id null")
      penaltyCloseModal();
    }
    if(!userData.PoDbId){
      toast.error("PoDbId is null")
      penaltyCloseModal();
    }
    if(!userData.PoPrDbId){
      toast.error("PoPrDbId is null")
      penaltyCloseModal();
    }
    
    

    // setShowAreYouSurePopup(true);
    if(data ){
      dispatch(sendPenalty(userData?.vendorCode,
        userData?.vendorId,
        userData?.PoDbId,
        userData?.PoPrDbId,
        penaltyInfo?.penaltyTitle,
        penaltyInfo?.penaltyDescription,
        parseFloat(penaltyAmount.replace(/,/g, "")),
        parseFloat(penaltyAmount.replace(/,/g, "")),
        userData?.PoCurrency
      ))
      penaltyCloseModal();
    }
  };
  const closeAreYouSure = () => {
    setShowAreYouSurePopup(false);
  };

  const onClickCancel = async () => {
    penaltyCloseModal();
  };

  return (
    <>
    {( loading) && <PageLoader/>}
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={penaltyIsOpen}
          onRequestClose={penaltyCloseModal}
          className="create-user-modal create-penalty-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="create-user-title">
            <div className="d-flex">
              <h2 style={{ marginRight: "20px" }}>{penaltyFormTitle}</h2>
            </div>
            <img src={CloseIcon} alt="" onClick={() => penaltyCloseModal()} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">
            <div>
              <label>Title</label>
              <br />
              <input
                className="penalty-title"
                type="text"
                value={penaltyInfo?.penaltyTitle}
                readOnly
              />
              {errors.title && (
                <span className="error-msg">Please enter the Title</span>
              )}
            </div>
            <label>Description</label>
            <br />
            <textarea
              className="penalty-description"
              type="text"
              value={penaltyInfo?.penaltyDescription}
              readOnly
            />{" "}
            <br />
            {errors.description && (
              <span className="error-msg">Please enter the Description</span>
            )}
            <br />
            {/* selcte */}
            <label>Penalty clause</label>
            <br />
            <br />
            <div
              style={{
                width: "100%",
              }}
            >
              <div className="penalty-title "  style={{ position: 'relative', display: 'flex', justifyContent:"flex-start", width:"100%" }}>
  <input
    type="text"
    className="penalty-input"
    value={penaltyAmount}
    onChange={handlePenaltyAmountChange}
    onBlur={handlePenaltyAmountBlur}
  />
  <div style={{ 
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
   paddingTop:"1px",
    fontSize:"18px"
  }}>
    {userData?.PoCurrency || ""}
  </div>
</div>
              {/* <Select
                options={options}
                defaultValue={selectedOption}
                placeholder="[PO Amount] x 0.5 x [GR date] -[delivery date]"
                onChange={(option) => {
                  setSelectedOption(option);
                }}
                components={{ DropdownIndicator }}
                classNamePrefix="react-select"
                isSearchable={false}
              /> */}
              {errors.penaltyClause && (
                <span className="error-msg">Please enter the penalty Clause</span>
              )}
            </div>
            <br />
            <div className="pt-3 create-btns-div">
              <button
                type="button"
                className="um-create-btn"
                onClick={onClickCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="um-submit-btn"
                // disabled={!!fileErrorMsg || Object.keys(errors).length > 0}
              >
              Submit
              </button>
            </div>
          </form>
        </Modal>
      </div>

      <CustomPopup
        isOpenPopup={showAreYouSurePopup}
        closePopup={closeAreYouSure}
        image={areIcon}
        title="Are you sure!"
        text={
          userData
            ? "You want to Penalty this PO?"
            : "You want to Penalty this PO ?"
        }
        btn1="Cancel"
        btn2="Confirm"
        successTitle="Done!"
        successText={
          userData
            ? "Pennalty Details Updated Successfully"
            : "Pennalty successfully"
        }
        successIcon={successIcon}
        btn3="Ok"
        closeFormPopup={penaltyCloseModal}
        reset={reset}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        scenarioType={userData ? "editPressRelease" : "createPressRelease"}
        userData={userData}
      />
    </>
  );
};

export default PenaltyPopup;



