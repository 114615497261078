import TYPES from "../constants";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import {
  CREATE_USER,
  EDIT_USER,
  GET_ALL_USERS,
} from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";

// calling the get all users API for user manangement

export const getAllUser = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(getUserApiRequest());
      const response = await axios.get(`${BaseUrl}${GET_ALL_USERS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_USERS,
          payload: response.data.data,
        });
      } else if (response.status === 401) {
        dispatch({
          type: TYPES.GET_USERS_FAILURE,
          error: response.message,
        });
      } else {
        dispatch({
          type: TYPES.GET_USERS_FAILURE,
          error: "Something went wrong please try again.",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_USERS_FAILURE,
        error: error.message,
      });
    }
  };
};

// calling the Create New User API  in User management

export const createNewUser = (userDetails) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      const response = await axios.post(
        `${BaseUrl}${CREATE_USER}`,
        userDetails,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.CREATE_USER_SUCCESS,
          payload: response.data,
        });

        toast.success(response.data.message);
        return true;
      } else if (response.data.status === 401) {
        dispatch({
          type: TYPES.CREATE_USER_FAILURE,
          error: response.data.message,
        });
        toast.error(response.data.message);
        // Log the error state after updating it
      }
    } catch (error) {
      dispatch({
        type: TYPES.CREATE_USER_FAILURE,
        error: error.message,
      });
      toast.error("An error occurred while creating the user.");
      // Log the error state after updating it
    }
  };
};

// Action function for Paricular user data

export const createUser = (userData) => {
  return {
    type: TYPES.GET_CREATE_USER_DETAILS,
    payload: userData,
  };
};

// Initial action for calling get all user api

export const getUserApiRequest = () => {
  return {
    type: TYPES.GET_USERS_API_REQUEST,
  };
};

// Action function for current row data for tha edit purpose

export const currentRowData = (row) => {
  return {
    type: TYPES.CURRENT_ROW_DATA,
    payload: row,
  };
};

// calling the Edit API in user manangement

export const editUser = (user) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(editUserRequest());
      const response = await axios.post(`${BaseUrl}${EDIT_USER}`, user, {
        headers: { Authorization: `Bearer ${token} ` },
      });
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.EDIT_USER_SUCCESS,
          payload: response.data,
          loading: false,
          error: null,
        });
        toast.success(response.data.message);
        return true;
      } else if (response.data.status === 401) {
        dispatch({
          type: TYPES.EDIT_USER_FAILURE,
          error: "Something went wrong , please try again",
          loading: false,
        });
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch({
        type: TYPES.EDIT_USER_FAILURE,
        error: error.message,
        loading: false,
      });
      toast.error("An error occurred while editing the user.");
    }
  };
};

//Inital action for calling the edit user API
export const editUserRequest = () => {
  return {
    type: TYPES.EDIT_USER_API_REQUEST,
  };
};
// Action function for store the edit data for edit the user details in user manangement
export const editParticularUser = (editUser) => {
  return {
    type: TYPES.EDIT_PARTICULAR_USER,
    payload: editUser,
  };
};

// Action for Delete user id i usermanangement
export const deleteParticularUser = (id) => {
  return {
    type: TYPES.DELETE_USER_ID,
    payload: id,
  };
};
