import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import {
  GET_ALL_JOB_LIST,
  GET_ALL_COMPANY_JOB_STATUS_COUNT,
  UPDATE_PR_DETAILS_ATTACHMENT,
  UPDATE_JOB_DETAILS_ATTACHMENT,
  GET_ALL_JOB_ATTACHMENTS,
  DELETE_JOB_ATTACHMENT,
  GET_ALL_QUOTATIONS_LIST,
  GET_JOB_TABLE_STATUS_COUNT,
  GET_SINGLE_JOB_DETAILS,
  UPDATE_JOB_DETAILS,
} from "../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import TYPES from "../constants";
import { cleanObject, convertToQueryString } from "../../Utils/Utils";
import { toast } from "react-toastify";

// calling the get all JOB management api for job GroupCount

export const getAllJobStatusCount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(getAllJobStatusCountApiRequest());
      const response = await axios.get(
        `${BaseUrl}${GET_ALL_COMPANY_JOB_STATUS_COUNT}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_COMPANY_JOB_STATUS_COUNT_SUCCESS,
          payload: response?.data?.data?.AllComanyJobStatusInfo,
        });
      } else {
        dispatch({
          type: TYPES.GET_ALL_COMPANY_JOB_STATUS_COUNT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_COMPANY_JOB_STATUS_COUNT_FAILURE,
        error: error.message,
      });
    }
  };
};

// Initial action for calling get all PR RELEASE GroupCount

export const getAllJobStatusCountApiRequest = () => {
  return {
    type: TYPES.GET_ALL_COMPANY_JOB_STATUS_COUNT_REQUEST,
  };
};

// calling the get job mangement api for job management table

export const getAllJobManagement = ({
  page,
  pageSize,
  orderBy,
  orderDir,
  searchBy,
  searchFields,
  FilterStatus,
  startDate,
  endDate,
  searchValues,
}) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(getAllJobManagementApiRequest());

      const queryParams = buildQueryParams({
        page,
        pageSize,
        orderBy,
        orderDir,
        searchBy,
        searchFields,
        FilterStatus,
        startDate,
        endDate,
        searchByColumNameValue: searchValues,
      });

      const url = buildUrlWithQueryParams(queryParams);

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_JOB_MANAGEMENT_SUCCESS,
          payload: response.data?.data?.JobList,
        });
      } else {
        dispatch({
          type: TYPES.GET_ALL_JOB_MANAGEMENT_FAILURE,
          error: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_JOB_MANAGEMENT_FAILURE,
        error: error.message,
      });
    }
  };
};

const buildQueryParams = ({
  page,
  pageSize,
  orderBy,
  orderDir,
  searchBy,
  searchFields,
  FilterStatus,
  startDate,
  endDate,
  searchByColumNameValue,
}) => {
  const queryParams = {
    page,
    pageSize,
    orderBy,
    orderDir,
  };

  if (FilterStatus && FilterStatus !== "all") {
    queryParams.FilterStatus = FilterStatus;
  }

  if (startDate && endDate) {
    queryParams.startDate = startDate;
    queryParams.endDate = endDate;
  }

  // Include searchByColumNameValue in queryParams
  if (searchByColumNameValue) {
    const cleanedSearchValues = cleanObject(searchByColumNameValue);
    if (Object.keys(cleanedSearchValues).length > 0) {
      queryParams.searchByColumNameValue = JSON.stringify(cleanedSearchValues);
    }
  }

  return queryParams;
};

const buildUrlWithQueryParams = (queryParams) => {
  const queryString = convertToQueryString(queryParams);
  return `${BaseUrl}${GET_ALL_JOB_LIST}${queryString}`;
};

const getAllJobManagementApiRequest = () => ({
  type: TYPES.GET_ALL_JOB_MANAGEMENT_API_REQUEST,
});

export const editjobManagementAttachments = (jobManagement) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(editjobManagementAttachmentsRequest());
      const response = await axios.patch(
        `${BaseUrl}${UPDATE_JOB_DETAILS_ATTACHMENT}`,
        jobManagement,
        {
          headers: { Authorization: `Bearer ${token} ` },
        }
      );

      if (response.data.status === 200 || response.status == 200) {
        dispatch({
          type: TYPES.UPDATE_JOB_DETAILS_ATTACHMENT_SUCCESS,
          payload: response.data,
          loading: false,
          error: null,
        });
        toast.success(response?.data?.message);

        return true;
      } else if (response.data.status !== 200 || response.status !== 200) {
        dispatch({
          type: TYPES.UPDATE_JOB_DETAILS_ATTACHMENT_FAILURE,
          error: "Something went wrong , Please try again",
          loading: false,
        });

        toast.error(response?.data?.message);
        return false;
      }
    } catch (error) {
      dispatch({
        type: TYPES.UPDATE_JOB_DETAILS_ATTACHMENT_FAILURE,
        error: error.message,
        loading: false,
      });
    }
  };
};

//Inital action for calling the edit user API
export const editjobManagementAttachmentsRequest = () => {
  return {
    type: TYPES.UPDATE_JOB_DETAILS_ATTACHMENT_REQUEST,
  };
};

export const getAllJobManagemetAttachments = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_ALL_JOB_ATTACHMENTS_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${GET_ALL_JOB_ATTACHMENTS}?JobId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_JOB_ATTACHMENTS_SUCCESS,
          payload: response?.data?.data?.JobAttachments,
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_ALL_JOB_ATTACHMENTS_FAILURE,
          error: "Something went wrong , please try again",
        });
        return false;
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_JOB_ATTACHMENTS_FAILURE,
        error: error.message,
      });
    }
  };
};

export const deleteJobAttachment = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.DELETE_JOB_ATTACHMENT_REQUEST,
      });
      const response = await axios.delete(
        `${BaseUrl}${DELETE_JOB_ATTACHMENT}?JobAttachmentId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        dispatch({
          type: TYPES.DELETE_JOB_ATTACHMENT_SUCCESS,
          payload: response.data.data,
        });
        toast.success(response.data.message);
        return true;
      } else {
        dispatch({
          type: TYPES.DELETE_JOB_ATTACHMENT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.DELETE_JOB_ATTACHMENT_FAILURE,
        error: error.message,
      });
    }
  };
};

export const getAllQuotationByJobId = ({ page, pageSize, JobId }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({ type: TYPES.GET_ALL_QUOTATIONS_LIST_REQUEST });

      const url = `${BaseUrl}${GET_ALL_QUOTATIONS_LIST}?JobId=${JobId}&page=${page}&pageSize=${pageSize}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_QUOTATIONS_LIST_SUCCESS,
          payload: response.data?.data?.QuotationList,
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_ALL_QUOTATIONS_LIST_FAILURE,
          error: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_QUOTATIONS_LIST_FAILURE,
        error: error.message,
      });
    }
  };
};

export const getAllJobTableStatusCount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_JOB_TABLE_STATUS_COUNT_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${GET_JOB_TABLE_STATUS_COUNT}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_JOB_TABLE_STATUS_COUNT_SUCCESS,
          payload: response?.data?.data?.[0],
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_JOB_TABLE_STATUS_COUNT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_JOB_TABLE_STATUS_COUNT_FAILURE,
        error: error.message,
      });
    }
  };
};

//Action for get single job details
export const getSingleJobDetails = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_SINGLE_JOB_DETAILS_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${GET_SINGLE_JOB_DETAILS}?JobId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_SINGLE_JOB_DETAILS_SUCCESS,
          payload: response?.data?.data,
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_SINGLE_JOB_DETAILS_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_SINGLE_JOB_DETAILS_FAILURE,
        error: error.message,
      });
    }
  };
};


//Action for update job
export const updateJobDetails = (jobDetails) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.UPDATE_JOB_DETAILS_REQUEST,
      });
      const response = await axios.post(
        `${BaseUrl}${UPDATE_JOB_DETAILS}`,
        jobDetails,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.UPDATE_JOB_DETAILS_SUCCESS,
          payload: response.data,
        });
        toast.success(response.data.message);
        return true;
      } else if (response.status === 404 || response.data.status === 401) {
        dispatch({
          type: TYPES.UPDATE_JOB_DETAILS_FAILURE,
          error: "Something went wrong , please try again",
        });
        toast.error("Something went wrong , please try again");
      }
    } catch (error) {
      dispatch({
        type: TYPES.UPDATE_JOB_DETAILS_FAILURE,
        error: error.message,
      });
      toast.error("An error occurred while editing the PR.");
    }
  };
};

