import { useTranslation } from "../../Components/Imports/import";
import React, { useState } from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import "./PurchasingGroup.css";
import plusIcon from "../../Assets/Images/plus-ico.png";
import PurchasingGroupPopup from "../../Models/PurchasingGroupPopup/PurchasingGroupPopup";
import PurchasingGroupTable from "../../Components/DataTable/PurchasingGroupTable/PurchasingGroupTable";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../Models/PageLoader/PageLoader";

const PurchasingGroup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.getPurchaseGroupInfo);
  const [pageLoading, setPageLoading] = useState(false);
  const { LoginDetails } = useSelector((state) => state.loginDetails);

  const isAssignerIsBuyer = LoginDetails?.assigner_type == "Buyer Group";

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DefaultLayout>
      {(pageLoading || loading) && <PageLoader />}

      <section className="um-main">
        <div className="um-title-btn">
          <h2 className="um-title">{t("Purchasing Group")}</h2>
          {!isAssignerIsBuyer && (
            <button className="create-purchasing-btn" onClick={openModal}>
              <img style={{ marginRight: "10px" }} src={plusIcon} alt="+" />
              {t("Create Purchasing Group")}
            </button>
          )}
        </div>
        <br />
        <PurchasingGroupTable setPageLoading={setPageLoading} />
      </section>
      {isModalOpen && (
        <PurchasingGroupPopup
          isOpen={isModalOpen}
          formTitle={"Create Purchasing Group"}
          closeModal={closeModal}
          scenarioType={"createPurchasingGroup"}
        />
      )}
    </DefaultLayout>
  );
};

export default PurchasingGroup;
