import { useCallback } from 'react';

function useSetFormValues(setValue) {
  const setDataValues = useCallback(
    (data) => {
      // Ensure `data` is an object before proceeding
      if (data && typeof data === 'object') {
        Object.entries(data).forEach(([name, value]) => {
          setValue(name, value); // Ensure setValue accepts (name, value)
        });
      }
    },
    [setValue]
  );

  return setDataValues;
}

export default useSetFormValues;
