import React from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import QutotationTable from "../../Components/DataTable/JobManagementTable/QutotationTable";
import VectorArrow from "../../Assets/Images/backArrow.png";
import { useNavigate } from "react-router-dom";
import {
  useSelector,
  useTranslation,
} from "../../../src/Components/Imports/import";

const JobManagementNew = () => {
  const { t } = useTranslation();
  const handleGoBack = () => {
    navigate("/job-management");
  };
  const navigate = useNavigate();
  const { getAllQutotationDetails } = useSelector(
    (state) => state.getJobManagementInfo
  );
  const { Items, TotalCount } = getAllQutotationDetails || {};

  return (
    <DefaultLayout>
      <section className="um-main">
        <div className="um-title-btn">
          <div
            style={{
              color: "#103A5D",
              fontSize: 24,
              fontFamily: "Sarabun",
              cursor: "pointer",
              fontWeight: "700",
              textTransform: "capitalize",
              wordWrap: "break-word",
              marginLeft: 8,
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={handleGoBack}
          >
            <img src={VectorArrow} />
            {t("back")}
          </div>
        </div>
        <h1 className="hs-title">{t(`QTN. Received (${TotalCount})`)}</h1>
        <br></br>
        <QutotationTable />
      </section>
    </DefaultLayout>
  );
};

export default JobManagementNew;
