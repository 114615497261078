import { useEffect, useState } from "react";

const useDecryptedData = (localStorageKey, secretKey) => {
  const [decryptedData, setDecryptedData] = useState(null);

  useEffect(() => {
    const decryptData = (encryptedData) => {
      try {
        const decodedData = decodeURIComponent(atob(encryptedData));
        const decryptedData = JSON.parse(decodedData);
        return decryptedData;
      } catch (error) {
        return null;
      }
    };

    // Retrieve and decrypt the data from localStorage
    const encryptedDataFromLocalStorage = localStorage.getItem(localStorageKey);

    if (encryptedDataFromLocalStorage) {
      const decryptedDataString = decryptData(encryptedDataFromLocalStorage);
      if (decryptedDataString) {
        setDecryptedData(JSON.parse(decryptedDataString));
      }
    }
  }, [localStorageKey, secretKey]);

  return decryptedData;
};

export default useDecryptedData;
