import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import {
  DELETE_PR,
  DELETE_PR_ATTACHMENT,
  GET_ALL_PR_ATTACHMENTS,
  GET_ALL_PR_RELEASE,
  GET_ALL_PR_RELEASE_GROUP_COUNT,
  GET_PR_FILTER_DROPDOWN,
  GET_PR_STATUS_COUNT,
  GET_SINGLE_PR_DETAILS,
  RELEASE_PR,
  UPDATE_PR_DETAILS_ATTACHMENT,
} from "../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import TYPES from "../constants";
import { cleanObject, convertToQueryString, formatDate } from "../../Utils/Utils";

// calling the get all pr release api for pr release GroupCount

export const getAllPrReleaseGroupCount = (startDate,endDate) => {
  return async (dispatch) => {
    try {
      const params = {};
      if (startDate) params.startDate = formatDate(startDate);
      if (endDate) params.endDate = formatDate(endDate);
      const token = localStorage.getItem("vms_admin_token");
      dispatch(getAllPrReleaseGroupCountApiRequest());
      const response = await axios.get(
        `${BaseUrl}${GET_ALL_PR_RELEASE_GROUP_COUNT}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params,
        },
        
      );

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_PR_RELEASE_GROUP_COUNT_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: TYPES.GET_ALL_PR_RELEASE_GROUP_COUNT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_PR_RELEASE_GROUP_COUNT_FAILURE,
        error: error.message,
      });
    }
  };
};

// Initial action for calling get all PR RELEASE GroupCount

export const getAllPrReleaseGroupCountApiRequest = () => {
  return {
    type: TYPES.GET_ALL_PR_RELEASE_GROUP_COUNT_REQUEST,
  };
};

// calling the get all pr release api for pr release table

// export const getAllPrRelease = ({
//   page,
//   pageSize,
//   orderBy,
//   orderDir,
//   searchBy,
//   searchFields,
//   FilterStatus,
//   startDate,
//   endDate,
// }) => {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem("vms_admin_token");
//       dispatch(getAllPrReleaseApiRequest());
//       let queryParams = {
//         page: page,
//         pageSize: pageSize,
//         orderBy: orderBy,
//         orderDir: orderDir,
//         // searchBy,
//         // searchFields,
//         // FilterStatus,
//         // startDate,
//         // endDate,
//       };

//       const url = `${BaseUrl}${GET_ALL_PR_RELEASE}${convertToQueryString(
//         queryParams
//       )}`;

//       if (FilterStatus && FilterStatus !== "all") {
//         url += `&FilterStatus=${FilterStatus}`;
//       }

//       if (searchBy) {
//         url += `&searchBy=${searchBy}`;

//         if (
//           searchFields &&
//           Array.isArray(searchFields) &&
//           searchFields.length > 0
//         ) {
//           const encodedSearchFields = encodeURIComponent(
//             JSON.stringify(searchFields)
//           );
//           url += `&searchFields=${encodedSearchFields}`;
//         }
//       }



//       const response = await axios.get(url, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       if (response.status === 200) {
//         dispatch({
//           type: TYPES.GET_ALL_PR_RELEASE_SUCCESS,
//           payload: response.data?.data?.JobList,
//         });
//       } else {
//         dispatch({
//           type: TYPES.GET_ALL_PR_RELEASE_FAILURE,
//           error: "Something went wrong , please try again",
//         });
//       }
//     } catch (error) {
//       dispatch({
//         type: TYPES.GET_ALL_PR_RELEASE_FAILURE,
//         error: error.message,
//       });
//     }
//   };
// };

// Initial action for calling get all PR RELEASE

// export const getAllPrReleaseApiRequest = () => {
//   return {
//     type: TYPES.GET_ALL_PR_RELEASE_API_REQUEST,
//   };
// };

export const getAllPrRelease = (
  page,
  pageSize,
  orderBy,
  orderDir,
  FilterStatus,
  startDate,
  endDate,
  searchValues,
  appliedFilter,
) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(getAllPrReleaseApiRequest());

      const queryParams = buildQueryParams({
        page,
        pageSize,
        orderBy,
        orderDir,
        FilterStatus,
        startDate,
        endDate,
        searchByColumNameValue: searchValues,
        appliedFilter,
      });
      const url = buildUrlWithQueryParams(queryParams);
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_PR_RELEASE_SUCCESS,
          payload: response.data?.data?.JobList,
        });
      } else {
        dispatch({
          type: TYPES.GET_ALL_PR_RELEASE_FAILURE,
          error: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_PR_RELEASE_FAILURE,
        error: error.message,
      });
    }
  };
};

const buildQueryParams = ({
  page,
  pageSize,
  orderBy,
  orderDir,
  FilterStatus,
  startDate,
  endDate,
  searchByColumNameValue,
  appliedFilter
}) => {
  const queryParams = {
    page,
    pageSize,
    orderBy,
    orderDir,
  };

  if (FilterStatus && FilterStatus !== "all") {
    queryParams.FilterStatus = FilterStatus;
  }

  if (startDate && endDate) {
    queryParams.startDate = startDate;
    queryParams.endDate = endDate;
  }
  if(appliedFilter && appliedFilter?.length>0){
    const prGroup = appliedFilter.join(',');
    queryParams.PR_Group=prGroup;
  }

  // Include searchByColumNameValue in queryParams
  if (searchByColumNameValue) {
    const cleanedSearchValues = cleanObject(searchByColumNameValue);
    if (Object.keys(cleanedSearchValues).length > 0) {
      queryParams.searchByColumNameValue = JSON.stringify(cleanedSearchValues);
    }
  }

  return queryParams;
};

const buildUrlWithQueryParams = (queryParams) => {
  const queryString = convertToQueryString(queryParams);
  return `${BaseUrl}${GET_ALL_PR_RELEASE}${queryString}`;
};

const getAllPrReleaseApiRequest = () => ({
  type: TYPES.GET_ALL_PR_RELEASE_API_REQUEST,
});

export const editPrReleaseAttachments = (prRelease) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(editPrReleaseAttachmentsRequest());
      const response = await axios.patch(
        `${BaseUrl}${UPDATE_PR_DETAILS_ATTACHMENT}`,
        prRelease,
        {
          headers: { Authorization: `Bearer ${token} ` },
        }
      );
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.UPDATE_PR_DETAILS_ATTACHMENT_SUCCESS,
          payload: response.data,
          loading: false,
          error: null,
        });
        toast.success(response?.data?.message);
        return true;
      } else if (response.data.status !== 200) {
        dispatch({
          type: TYPES.UPDATE_PR_DETAILS_ATTACHMENT_FAILURE,
          error: "Something went wrong , Please try again",
          loading: false,
        });
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch({
        type: TYPES.UPDATE_PR_DETAILS_ATTACHMENT_FAILURE,
        error: error.message,
        loading: false,
      });
      toast.error("An error occurred while editing the press release.");
    }
  };
};

//Inital action for calling the edit user API
export const editPrReleaseAttachmentsRequest = () => {
  return {
    type: TYPES.UPDATE_PR_DETAILS_ATTACHMENT_REQUEST,
  };
};

export const getSinglePrDetails = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_SINGLE_PR_DETAILS_REQUEST,
      });
      const response = await axios.get(`${BaseUrl}${GET_SINGLE_PR_DETAILS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_SINGLE_PR_DETAILS_SUCCESS,
          payload: response.data.PrDetialsInfo,
        });
      } else {
        dispatch({
          type: TYPES.GET_SINGLE_PR_DETAILS_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_SINGLE_PR_DETAILS_FAILURE,
        error: error.message,
      });
    }
  };
};

export const deletePr = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.DELETE_PR_REQUEST,
      });
      const response = await axios.delete(
        `${BaseUrl}${DELETE_PR}?PrCreatedId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        dispatch({
          type: TYPES.DELETE_PR_SUCCESS,
          payload: response.data.data,
        });
        toast.success(response?.data?.message);
        return true;
      } else {
        dispatch({
          type: TYPES.DELETE_PR_FAILURE,
          error: "Something went wrong , please try again",
        });

        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch({
        type: TYPES.DELETE_PR_FAILURE,
        error: error.message,
      });

      toast.error("An error occurred while deleting the press release.");
    }
  };
};

export const getAllPrAttachmentsAction = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_ALL_PR_ATTACHMENTS_REQUEST,
      });
      const response = await axios.get(
        `${BaseUrl}${GET_ALL_PR_ATTACHMENTS}?PrCreatedId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_PR_ATTACHMENTS_SUCCESS,
          payload: response?.data?.data?.PrAttachments,
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_ALL_PR_ATTACHMENTS_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_PR_ATTACHMENTS_FAILURE,
        error: error.message,
      });
    }
  };
};

export const releasePr = (prRelease) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({ type: TYPES.RELEASE_PR_REQUEST });
      const response = await axios.post(`${BaseUrl}${RELEASE_PR}`, prRelease, {
        headers: { Authorization: `Bearer ${token} ` },
      });
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.RELEASE_PR_SUCCESS,
          payload: response.data,
          loading: false,
          error: null,
        });

        return true;
      } else if (response.data.status !== 200) {
        dispatch({
          type: TYPES.RELEASE_PR_FAILURE,
          error: "Something went wrong , Please try again",
          loading: false,
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.RELEASE_PR_FAILURE,
        error: error.message,
        loading: false,
      });
      toast.error("An error occurred while relasing the press release.");
    }
  };
};

export const deletePrAttachment = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.DELETE_PR_ATTACHMENT_REQUEST,
      });
      const response = await axios.delete(
        `${BaseUrl}${DELETE_PR_ATTACHMENT}?PrAttachmentId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        dispatch({
          type: TYPES.DELETE_PR_ATTACHMENT_SUCCESS,
          payload: response.data.data,
        });
        toast.success(response?.data?.message);
        return true;
      } else {
        dispatch({
          type: TYPES.DELETE_PR_ATTACHMENT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.DELETE_PR_ATTACHMENT_FAILURE,
        error: error.message,
      });
    }
  };
};

export const currentPrReleaseDataAction = (userData) => {
  return {
    type: TYPES.CURRENT_PR_RELEASE_DATA,
    payload: userData,
  };
};

export const getAllPrStatusCount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_PR_STATUS_COUNT_REQUEST,
      });
      const response = await axios.get(`${BaseUrl}${GET_PR_STATUS_COUNT}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_PR_STATUS_COUNT_SUCCESS,
          payload: response?.data?.data,
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_PR_STATUS_COUNT_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_PR_STATUS_COUNT_FAILURE,
        error: error.message,
      });
    }
  };
};


export const getAllPrFilterDropdown = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.GET_PR_FILTER_DROPDOWN_REQUEST,
      });
      const response = await axios.get(`${BaseUrl}${GET_PR_FILTER_DROPDOWN}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_PR_FILTER_DROPDOWN_SUCCESS,
          payload: response?.data?.data,
        });
        return response;
      } else {
        dispatch({
          type: TYPES.GET_PR_FILTER_DROPDOWN_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_PR_FILTER_DROPDOWN_FAILURE,
        error: error.message,
      });
    }
  };
};
