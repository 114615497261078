import React, { useEffect, useState } from "react";
import "./EvaluatedPage.css";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader, useDispatch, useSelector, useTranslation } from "../../../src/Components/Imports/import";
import VectorArrow from "../../Assets/Images/backArrow.png";
import GoodsEvaluation from "./GoodsEvaluation";
import ServiceEvaluation from "./ServicesEvaluation";
import { getEvaluationDataById } from "../../Store/actions/supplierManagementActions";

function UpdateEvaluation() {
  const {id}=useParams();
  const dispatch=useDispatch();
  const [formId,setformId]=useState(0);
  const [evaluationFormById,setEvaluationFormById]=useState({})
  const { singleEvaluationData,loading,error } = useSelector(
    (state) => state.getSupplierManagementInfo
  );

  useEffect(()=>{
    dispatch(getEvaluationDataById(id))
  },[])
  useEffect(()=>{
    setformId(singleEvaluationData?.evaluationForm?.evaluationFormId)
    setEvaluationFormById(singleEvaluationData)
  },[singleEvaluationData])
  return (
    <>
    <DefaultLayout>
    {loading && <PageLoader/>}
    
    {parseInt(formId)===1?<GoodsEvaluation evaluationFormById={evaluationFormById} isEmailUser={false} id={id}/>:parseInt(formId)===2?<ServiceEvaluation evaluationFormById={evaluationFormById} isEmailUser={false} id={id}/>:<h1>Refresh the page</h1>}
    </DefaultLayout>
    </>
  )
}
export default UpdateEvaluation;
