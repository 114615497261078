import {
    React,
    useState,
  } from "../../Components/Imports/import";
  import Modal from "react-modal";
  import CloseIcon from "../../Assets/Svg/close-icon.svg";
  import "./ViewPopup.css";
  import doc from "../../Assets/Images/doc-icon.png";
  import { useEffect } from "react";

  
  
  // PenaltyRejectPopup Component
  const ViewPopup = ({
      ViewIsOpen,
      ViewCloseModal,
    ViewFormTitle,
    poData
  }) => {
    
    
  const [vendorAttachments,setVendorAttachments]=useState([])
  const [adminAttachments,setAdminAttachments]=useState([])
  
  useEffect(() => {
    setVendorAttachments(poData?.vendorPoAttachments || []);
    setAdminAttachments(poData?.adminPoAttachments || []);
  }, [poData]);
    
    
    const onClickAccept = async () => {
      ViewCloseModal();
    };
    
    const downloadFiles = (attachments) => {
      if (!Array.isArray(attachments) || attachments.length === 0) {
        return;
      }
    
      attachments.forEach(file => {
        try {
          if (!file || !file.attachmentUrl) {
            throw new Error("Invalid file object or missing attachment URL.");
          }
          window.open(file.attachmentUrl, '_blank');
        } catch (error) {
          alert(`Could not open file: ${file?.attachmentName || "Unknown file"}. Error: ${error.message}`);
        }
      });
    };
    
  
    
    return (
      <>
      
        <div className="d-flex justify-content-center">
          <Modal
            isOpen={ViewIsOpen}
            onRequestClose={ViewCloseModal}
            className="create-user-modal create-penalty-rej-modal"
            overlayClassName="create-modal-overlay"
            shouldCloseOnOverlayClick={false}
          >
            <div className="create-user-title">
              <div className="d-flex">
                <h2 style={{ marginRight: "20px" }}>{ViewFormTitle}</h2>
              </div>
              <img src={CloseIcon} alt="" onClick={() => ViewCloseModal()} />
            </div>
            <form className="create-user-form">
              
              <br />
              <div className="prej-attachment-files">
                <label>Vendor Attachments: {vendorAttachments?.length || 0}</label>
                <button type="button" onClick={() => downloadFiles(vendorAttachments)}>
                  <label>Download All</label>
                  </button>

              </div>
              <div className="prej-attach-logo">
                {vendorAttachments?.length===0 && <h3>No Vendor PO Attachments available</h3>}
              {vendorAttachments.map((file, index) => (
              <a key={index} href={file?.attachmentUrl} target="_blank" rel="noreferrer">
                <img className="prej-doc-logo" src={doc} alt={file?.attachmentName} />
              </a>
            ))}
                 <br />
                 <br />
                 
                </div>
                <div className="prej-attachment-files">
                <label>Admin Attachments: {adminAttachments?.length || 0}</label>
                <button type="button" onClick={() => downloadFiles(adminAttachments)}>
                  <label>Download All</label>
                  </button>
              </div>
              <div className="prej-attach-logo">
              {adminAttachments?.length===0 && <h3>No Admin PO Attachments available</h3>}
              {adminAttachments.map((file, index) => (
              <a key={index} href={file?.attachmentUrl} target="_blank" rel="noreferrer">
                <img className="prej-doc-logo" src={doc} alt={file?.attachmentName} />
              </a>
            ))}
                 
                 
                </div>
              <br />
              <div className="pt-3 create-btns-div">
              
                <button
                  onClick={onClickAccept}
                  className="um-submit-btn"
                  // disabled={!!fileErrorMsg || Object.keys(errors).length > 0}
                >
                  Close
                </button>
              </div>
            </form>
          </Modal>
        </div>
  
       
      </>
    );
  };
  
  export default ViewPopup;
  