import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import {
  useDispatch,
  useSelector,
  useTranslation,
} from "../../../../src/Components/Imports/import";
import "./EvaluationTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../../Assets/Svg/arrow-icon.svg";
import editLogoo from "../../../Assets/Images/editLogoo.png";
import deleteLogo from "../../../Assets/Images/deleteLogoo.png";
import { useNavigate } from "react-router-dom";
import { tableStyles } from "../../../Utils/TableStyles";
import { DateGenerator, formatNumber, formatNumberWithCommasandDecimals, transformEvaluationManagementTableDataResponse } from "../../../Utils/Utils";
import {
  deleteEvaluation,
  getAllEvaluationMananagment,
  getAllEvaluationTableStatusCount,
} from "../../../Store/actions/supplierManagementActions";
import CustomPopup from "../../../Models/CustomPopup/CustomPopup";
import areIcon from "../../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../../Assets/Svg/success-icon.svg";

const EvaluationTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { evaluationTableStatusCount, allEvaluationList } = useSelector(
    (state) => state.getSupplierManagementInfo
  );
  const [data, setData] = useState({
    all: [],
    waitingEvaluation: [],
    evaluated: [],
    deleteEvaluation: [],
  });
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState(3);
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [orderDir, setOrderDir] = useState("DESC");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [count, setCount] = useState(
    evaluationTableStatusCount?.reduce(
      (total, stat) =>
        total + (typeof stat?.count === "number" ? stat?.count : 0),
      0
    ) || 0
  );
  const [showDeletePopup,setShowDeletePopup]=useState(false)
  const [rowData,setRowData]=useState(null)
  useEffect(() => {
    dispatch(
      getAllEvaluationMananagment(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type === 3 ? "all" : type === 0 ? "0" : type,
        selectedDateRange[0],selectedDateRange[1],searchValues
      )
    );

    dispatch(getAllEvaluationTableStatusCount());
  }, [itemsPerPage, currentPage, orderBy, orderDir, type,selectedDateRange[0],selectedDateRange[1], searchValues]);
  useEffect(() => {
    if (type === 3) {
      setData((prevState) => ({
        ...prevState,
        all: allEvaluationList?.data || [],
      }));
    }
   else if (type === 0) {
      setData((prevState) => ({
        ...prevState,
        waitingEvaluation: allEvaluationList?.data || [],
      }));
    }
    else if (type === 1) {
      setData((prevState) => ({
        ...prevState,
        evaluated: allEvaluationList?.data || [],
      }));
    }
    else if (type === 2) {
      setData((prevState) => ({
        ...prevState,
        deleteEvaluation: allEvaluationList?.data || [],
      }));
    }
   

    setCount(allEvaluationList?.TotalCount);
  }, [count, allEvaluationList]);
  useEffect(()=>{
    type===3?setFilteredData(data?.all|| []):type===0?setFilteredData(data?.waitingEvaluation|| []):type===1?setFilteredData(data?.evaluated|| []):type===2?setFilteredData(data?.deleteEvaluation|| []):setFilteredData([])
   },[data,type])

  const columnWidths = {
    VendorCode: "5%",
    PONo: "7%",
    Description: "10%",
    DeliveryDate: "7%",
    Qty: "5%",
    Unit: "6%",
    POAmount: "8%",
  };

  const columns = [
    {
      id: "vendorCode",
      name: t("Vendor Code"),
      selector: (result) => result.vendorCode,
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id: "poNo",
      name: t("PO No."),
      selector: (result) => result.poNo,
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id: "PrShortText",
      name: t("Description"),
      selector: (result) => result.prSapData.PrShortText,
      searchable: true,
      width: "10%",
    },
    {
      id: "PrDeliveryDate",
      name: t("Delivery Date(PO)"),
      selector: (result) =>
        result?.prSapData?.PrDeliveryDate
          ? DateGenerator(result?.prSapData?.PrDeliveryDate.split("T")[0])
          : "---",
      searchable: true,
      sortable: true,
      width: "10%",
    },

    {
      id: "PrQTY",
      name: t("Qty"),
      selector: (result) => formatNumber(result?.prSapData?.PrQTY),
      searchable: true,
      width: "6%",
    },
    {
      id: "PRUnit",
      name: t("Unit"),
      selector: (result) => result?.prSapData?.PRUnit + "",
      searchable: true,
      width: "6%",
    },
    {
      id: "POTotalAmount",
      width: "12%",
      name: t("PO Amount"),
      selector: (result) => formatNumberWithCommasandDecimals(result?.poSapData?.POTotalAmount),
      searchable: true,
      sortable: true,
    },

    {
      id: "Status",
      width: "12%",
      name: t("Status"),
      searchable: false,
      cell: (result) => (
        <div
          className={
            result.status === 1
              ? "table-status evaluated"
              : result.status === 2
              ? "table-status delete-evaluation"
              : result.status === 0
              ? "table-status waiting-evaluation"
              : ""
          }
        >
          {result.status === 1
            ? "Evaluated"
            : result.status === 2
            ? "Delete evaluation"
            : result.status === 0
            ? "Waiting evaluation"
            : ""}
        </div>
      ),
    },
    {
      id: "Action",
      name: t("Action"),
      cell: (result) => (
        <div className="d-flex">
          {result.status === 1 && (
            <>
              <button
                className="table-btn mx-3"
                onClick={() => {
                  handleEvaluatedView(result?.evaluationId);
                }}
              >
                {t("View")}
              </button>
              <button
                className="eva-edit-tbl-btn"
                onClick={() => {
                  handleEditView(result?.evaluationId);
                }}
              >
                <img src={editLogoo} alt="edit" />
              </button>
            </>
          )}
          {result.status === 0 && (
            <>
              <button
                className="table-btn mx-3"
                // onClick={() => {
                //   // handleView for waiting-evaluation status
                // }}
                onClick={() => {
                  handleEvaluatedView(result?.evaluationId);
                }}
              >
                {t("View")}
              </button>

              <button
                className="eva-dele-tbl-btn mx-3"
                onClick={() => {
                  handleDelete(result?.evaluationId);
                }}
              >
                <img src={deleteLogo} />
              </button>
            </>
          )}
          {result.status === 2 && (
            <>
              <button
                className="table-btn mx-3"
                onClick={() => {
                  handleEvaluatedView(result?.evaluationId);
                }}
              >
                {t("View")}
              </button>
            </>
          )}
        </div>
      ),
      searchable: false,
    },
  ];

  //handleEvaluation

  const handleEvaluatedView = (id) => {
    navigate(`/Supplier-Management-Evaluated/${id}`);
  };
  const handleEditView = (id) => {
    navigate(`/Supplier-Management-Update-Evaluation/${id}`);
  };
  const handleDelete = (id) => {
    // dispatch(deleteEvaluation(id));
    setRowData(id)
    setShowDeletePopup(true)
  };
  const closeDeletePopup=()=>{
    setShowDeletePopup(false)
    dispatch(
      getAllEvaluationMananagment(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type === 3 ? "all" : type === 0 ? "0" : type,
        selectedDateRange[0],selectedDateRange[1],searchValues
      )
    );
        
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      
      dispatch(getAllEvaluationMananagment(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type === 3 ? "all" : type === 0 ? "0" : type,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };
 

  const handleTypeSelect = (FilterStatus) => {
    setCurrentPage(1);
    setType(parseInt(FilterStatus));
  };

  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };
  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange?.length > 0) {
      setSelectedDateRange([]);
    }
  };

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
  };

  const headerNames = columns.map((column) => column.name);

  const csvData =
    filteredData?.map((item) =>
      columns.reduce((row, column) => {
        if(column.id==="Status"){
          row[column.name]=item.status === 1
          ? "Evaluated"
          : item.status === 2
          ? "Delete evaluation"
          : item.status === 0
          ? "Waiting evaluation"
          : ""
        }
        else{
          row[column.name] = column.selector ? column.selector(item) : item[column.id] || '';

        }
        return row;
      }, {})
    ) || []; 

  return (
    <>
      <div className="um-table-header-div">
        <h1 className="hs-title" style={{ margin: "2% 0% 0% 1.1% " }}>
          {"Evaluation"}
        </h1>
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey={type} onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 3
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="3"
                >
                  {t("All")}
                  <span
                    className={
                      type === 3
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="3"
                  >
                    {type===3?count:evaluationTableStatusCount?.reduce(
                      (total, stat) =>
                        total +
                        (typeof stat?.count === "number" ? stat?.count : 0),
                      0
                    ) || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 0
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="0"
                >
                  {t("Waiting Evaluation")}
                  <span
                    className={
                      type === 0
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="0"
                  >
                    {type===0?count:evaluationTableStatusCount?.find(
                      (stat) => stat?.status === 0
                    )?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 1
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="1"
                >
                  {t("Evaluated")}
                  <span
                    className={
                      type === 1
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="1"
                  >
                    {type===1?count:evaluationTableStatusCount?.find(
                      (stat) => stat?.status === 1
                    )?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 2
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="2"
                >
                  {t("Delete Evaluation")}
                  <span
                    className={
                      type === 2
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="2"
                  >
                    {type===2?count:evaluationTableStatusCount?.find(
                      (stat) => stat?.status === 2
                    )?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <DateRangePicker
              appearance="default"
              //   style={{width:"200px"}}
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>{t("Show")} </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>{t("entries")}</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="pr_table"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={filteredData.length ? "um-data-table" : "um-empty-data-table"}
        >
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="sm-table-search-div"
          >
            <div style={{ marginLeft: "1px" }}>
              {columns.map((column) =>
                column.searchable !== false ? (
                  <input
                    key={column.id}
                    type="text"
                    value={searchValues[column.id] || ""}
                    style={{ width: column?.width , margin:"1px", }}
                    onChange={(event) => handleSearchChange(event, column.id)}
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomPopup
            isOpenPopup={showDeletePopup}
            closePopup={closeDeletePopup}
            title="Are you sure!"
            image={areIcon}
            text={"You want to Delete this Evaluation ?"}
            btn1="Cancel"
            btn2="Confirm"
            successTitle="Done!"
            successText={"Evaluation deleted successfully"}
            successIcon={successIcon}
            btn3="Ok"
            scenarioType="deleteEvaluation"
            // handleChangeColumn={handleChangeColumn}
            rowData={rowData}
          />
    </>
  );
};

export default EvaluationTable;
