import React from "react";
import { formatNumber } from "../../../Utils/Utils";

const JobExpandedTable = ({ data }) => {
  return (
    data && (
      <div className="po-expaneded-table-div">
        <table className="po-expanded-table">
          <thead>
            <tr>
              <th>
                <p style={{ visibility: "hidden" }}>Location</p>
              </th>
              <th>{"Purchase Requisition"}</th>
              <th>{"Description"}</th>
              <th>{"PRG"}</th>
              <th>{"Item"}</th>
              <th>{"Quantity"}</th>
              <th>{"Unit"}</th>
              <th>
                <p style={{ visibility: "hidden" }}>Locationsssssssssssssss</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row, index) => (
                <tr key={index}>
                  <td></td>
                  <td>{row?.PrSapDetail?.PR_ID}</td>
                  <td>{row?.PrSapDetail?.PR_description_Short}</td>
                  <td>{row?.PrSapDetail?.PR_Group}</td>
                  <td>{index+1}</td>
                  <td>{formatNumber(row?.PrSapDetail?.PR_QTY)}</td>
                  <td>{row?.PrSapDetail?.PR_Unit}</td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  );
};

export default JobExpandedTable;
