import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_ALL_VENDORS } from "../../ApiEndPoints/ApiEndPoints";

export const getVendorListTableData = createAsyncThunk("vendorList", async (params, { rejectWithValue }) => {
  const userToken = localStorage.getItem("vms_admin_token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  try {
    let {
      page=1,
      pageSize=5,
      orderBy = "updated_at",
      orderDir = "DESC",
      startDate,
      endDate,
      searchValues,
    } = params
    
    const qParams = new URLSearchParams({
      page,
      pageSize,
      orderBy,
      orderDir,
    });

    if (startDate && endDate) {
      qParams.append("startDate", startDate);
      qParams.append("endDate", endDate);
    }
    // Remove keys with empty values from searchValues
    const filteredSearchValues = Object.fromEntries(
      Object.entries(searchValues || {}).filter(([_, value]) => !!value)
    );

    // Check if there are any keys with non-empty values
    if (Object.keys(filteredSearchValues).length > 0) {
      qParams.append(
      "searchByColumNameValue",
      JSON.stringify(filteredSearchValues)
    );
  }

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(`${BaseUrl}${GET_ALL_VENDORS}?${qParams.toString()}`, requestOptions);
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
});


export const myReducer = createSlice({
  name: "vendorList",
  initialState: {
    loading: false,
    selectedVendorId: "",
    vendorList: [],
  },
  reducers: {
    UpdateSelectedVendorId: (state, action) => {
      state.selectedVendorId = action.payload;
    },
    UpdateSelectedSingleVendorData: (state, action) => {
      state.selectedSingleVendorData = action.payload;
    },
    clearVendorTableList: (state) => {
      state.vendorList = [];
    },
  },
  extraReducers: {
      [getVendorListTableData.pending]: (state) => {
        state.loading = true;
      },
      [getVendorListTableData.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.vendorList = payload;
      },
      [getVendorListTableData.rejected]: (state, { payload }) => {
        state.loading = false;
      },
    },
});

export const {
  UpdateSelectedVendorId,
  UpdateSelectedSingleVendorData,
  clearVendorTableList,
} = myReducer.actions;

export default myReducer.reducer;
