import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Nav from "react-bootstrap/Nav";
import { DateRangePicker } from "rsuite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "rsuite/dist/rsuite-no-reset.min.css";
import JobExpandedTable from "./JobExpandedTable";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import fileIcon from "../../../Assets/Svg/attach-file-icon.svg";
import penIcon from "../../../Assets/Svg/edit-pen-icon.svg";
import AttachFilePopup from "../../../Models/AttachFilePopup/AttachFilePopup";
import PrDetailsPopup from "../../../Models/JobManagementPopup//PrDetailsPopup";
import { tableStyles } from "../../../Utils/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { compareTargetandCurrentDate, DateGenerator, transformJobManagementTableDataResponse } from "../../../Utils/Utils";
import {
  getAllJobManagement,
  getAllJobManagemetAttachments,
  getAllJobTableStatusCount,
} from "../../../Store/actions/jobManagementAction";
import NavLink from "../../Common/NavLink";
import { toast } from "react-toastify";
import "../../Common/NavLink.css";
const JobManagementTable = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    jobManagementTableData,
    getAllJobManagementAttachments,
    jobManagementTableCount,
  } = useSelector((state) => state.getJobManagementInfo);
  const { Items, TotalCount } = jobManagementTableData || {};
  const tableData = transformJobManagementTableDataResponse(Items) || [];
  const navigate = useNavigate();

  const navLinks = [
    { eventType: "All", label: "All", totalCount: jobManagementTableCount?.PrPendingCount+ jobManagementTableCount?.WaitingPo + jobManagementTableCount?.Delivering },
    {
      eventType: "QTN",
      label: "Waiting QTN",
      totalCount: jobManagementTableCount?.PrPendingCount,
    },
    {
      eventType: "PO",
      label: "Waiting PO",
      totalCount: jobManagementTableCount?.WaitingPo,
    },
    {
      eventType: "Delivering",
      label: "Delivering",
      totalCount: jobManagementTableCount?.Delivering,
    },
  ];

  const [showNavExceptDelivered, setShowNavExceptDelivered] = useState(false);
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("All");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [showDeliveryDateColumn, setShowDeliveryDateColumn] = useState(false);
  const [rowData, setRowData] = useState({});
  const [jobId,setJobId]=useState(null);
  const [attachFileModalOpen, setAttachFileIsModalOpen] = useState(false);
  

  const [tableParams, setTableParams] = useState({
    page: currentPage,
    pageSize: itemsPerPage,
    orderBy: "updatedAt",
    orderDir: "DESC",
    searchBy: null,
    searchFields: [],
    FilterStatus: "All",
    startDate: selectedDateRange[0],
    endDate: selectedDateRange[1],
  });

  const page = currentPage;
  const pageSize = itemsPerPage;
  const orderBy = tableParams?.orderBy;
  const orderDir = tableParams?.orderDir;
  const searchBy = tableParams?.searchBy;
  const searchFields = tableParams?.searchFields;
  const FilterStatus = tableParams?.FilterStatus;
  const startDate = tableParams?.startDate;
  const endDate = tableParams?.endDate;

  const ExpandedComponent = ({ data }) => {
    // Logging the data to console for debugging

    return (
      <pre>
        {/* Passing the data to JobExpandedTable */}
        <JobExpandedTable data={data?.JobPrDetails} />
      </pre>
    );
  };

  useEffect(()=>{
    const currentJobPage=parseInt(localStorage.getItem("jobManagementPageSelect")) || 1;
    const itemsJobPerPage=parseInt(localStorage.getItem("jobManagementItemsPerPageSelect")) || 5;
    const searchJobValues=JSON.parse(localStorage.getItem("jobManagementSearchValues")) || {};
    const jobType=localStorage.getItem("jobManagementTypeSelect") || "All";
   
    setSearchValues(searchJobValues)
    setCurrentPage(currentJobPage)
    setItemsPerPage(itemsJobPerPage)
    setType(jobType)
    if(jobType == "Delivered") setShowNavExceptDelivered(true);
  },[])


  const columnWidths = {
    PR_Company: "8%",
    PRNo: "8%",
    PrTitle: "8%",
    PRG: "8%",
    "Date Start": "9%",
    "Date End": "9%",
    BuyerName: "10%",
    DeliveryDate: "10%",
  };

  const columns = [
    {
      id: "PR_Company",
      name: "Location",
      selector: (result) => result?.PR_Company,
      searchable: true,
      sortable: true,
      width: "8%",
    },
    {
      id: "PR_ID",
      name: "PR No.",
      selector: (result) => (result?.JobPrDetails && result?.JobPrDetails?.length>0 ? (result?.JobPrDetails?.length>1 ?`Combined (${result?.JobPrDetails?.length})`:result?.JobPrDetails[0]?.PrSapDetail?.PR_ID): "")|| "",
      searchable: true,
      sortable: true,
      width: "8%",
    },
    {
      id: "PrTitle",
      name: "PR Title",
      selector: (result) => result?.PrTitle,
      searchable: true,
      sortable: true,
      width: "9%",
    },
    {
      id: "PR_Group",
      name: "PRG",
      selector: (result) => (result?.JobPrDetails && result?.JobPrDetails?.length>0 ? (result?.JobPrDetails?.length>1 ?`Combined (${result?.JobPrDetails?.length})`:result?.JobPrDetails[0]?.PrSapDetail?.PR_Group): "")|| "",
      searchable: true,
      sortable: true,
      width: "8%",
    },
    ...(showDeliveryDateColumn
      ? [
          {
            id: "DeliveryDate",
            name: "Delivery Start",
            selector: (result) => DateGenerator(result?.DeliveryDate),
            searchable: true,
            sortable: true,
            width: "11%",
          },
        ]
      : [
          {
            id: "BiddingDate",
            name: "Bidding End",
            selector: (result) => DateGenerator(result?.BiddingDate),
            searchable: true,
            sortable: true,
            width: "11%",
          },
          {
            id: "DeliveryDate",
            name: "Delivery Start",
            selector: (result) => DateGenerator(result?.DeliveryDate),
            searchable: true,
            sortable: true,
            width: "11%",
          },
        ]),
    {
      id: "BuyerName",
      name: "Buyer",
      selector: (result) => result?.BuyerName,
      searchable: true,
      sortable: true,
      width: "8%",
    },
    {
      id:"Status",
      name: "Status",
      searchable: false,
      sortable: true,
      width: "15%",
      cell: (result) => {
        const isFutureOrToday = compareTargetandCurrentDate(result?.BiddingDate);
        let statusClass = "";
        let statusText = "";
    
        if (result?.JobStatus === 1) {
          
          statusClass = isFutureOrToday ? "quotation" : "waiting-po";
          statusText =  isFutureOrToday ? `QTN. Recieved (${result?.QuotationCount})` : "Waiting PO";
        } else if (result?.JobStatus === 3) {
          statusClass = "delivering-job";
          statusText = "Delivering";
        }
        else if (result?.JobStatus === 4) {
          statusClass = "delivering-job";
          statusText = "Delivered";
        }
        return (
          <div className={`${statusClass} table-status`}>
            {statusText}
          </div>
        );
       
      },
      
    },
    {
      name: "Action",
      
      cell: (result) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ gap: "5px" }}
        >
          <button
            className="jbm-file-icon"
            onClick={async () => {
              await setRowData(result);
              const response = await dispatch(
                getAllJobManagemetAttachments(result?.JobId)
              );
              if (response.status === 200) {
                setAttachFileIsModalOpen(true);
              } else {
                toast.error(response.message);
              }
            }}
          >
            <img src={fileIcon} alt="attach file" />
          </button>
          <button className="jbm-pen-icon" onClick={()=>{
           setJobId(result?.JobId)
           openModal();
          }}>
            <img src={penIcon} alt="" />
          </button>
          <button
            className="action-view-table-btn"
            style={{ width: "50px" }}
            onClick={() => {
              navigate("/job-management-view/" + result.JobId);
            }}
          >
            {"View"}
          </button>
        </div>
      ),
      searchable: false,
    },
  ];
  const handlePageChange = (page) => {
    setTableParams({
      ...tableParams,
      page,
    });
    localStorage.setItem("jobManagementPageSelect",page)
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    localStorage.setItem("jobManagementItemsPerPageSelect",selectedItemsPerPage)
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (event, columnName) => {
    localStorage.setItem("jobManagementPageSelect",1)
    setCurrentPage(1)
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      localStorage.setItem("jobManagementSearchValues",JSON.stringify(updatedSearchValues))
      return updatedSearchValues;
    });
  };

  const handleTypeSelect = (FilterStatus) => {
    localStorage.setItem("jobManagementPageSelect",1)
    setCurrentPage(1)
    localStorage.setItem("jobManagementTypeSelect",FilterStatus)
    setTableParams({
      ...tableParams,
      FilterStatus,
    });
    setType(FilterStatus);
  };

  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
    updateTableParams(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
    }
  };

  const updateTableParams = (newDateRange) => {
    if (newDateRange && newDateRange.length === 2) {
      const formattedStartDate = newDateRange[0]?.toISOString().split("T")[0];
      const formattedEndDate = newDateRange[1]?.toISOString().split("T")[0];
      setTableParams((prevParams) => ({
        ...prevParams,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));
    } else {
      setTableParams((prevParams) => ({
        ...prevParams,
        startDate: null,
        endDate: null,
      }));
    }
  };

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage),
    total: TotalCount,
  };

  const headerNames = columns.map((column) => column.name);
  const trimmedHeaders = headerNames.slice(0, headerNames.length - 1);
  const csvData = tableData.map((item) =>
    columns.reduce((row, column) => {
      
      if (column.id === 'PR_ID') {
        if(item?.JobPrDetails?.length>1){
        const prNumbers = item?.JobPrDetails?.map((jobItem) => jobItem?.PrSapDetail?.PR_ID).filter(Boolean).join(', ') || '';
        row[column.name] = prNumbers ? `(${prNumbers})` : '';
      }
      else{
        row[column.name] = item?.JobPrDetails?.length==1? item?.JobPrDetails[0]?.PrSapDetail?.PR_ID: "";
      }
      }
      else if (column.id === 'PR_Group') {
        const prNumbers = item?.JobPrDetails?.map((jobItem) => jobItem?.PrSapDetail?.PR_Group).filter(Boolean).join(', ') || '';
        row[column.name] = prNumbers ? `(${prNumbers})` : '';
      }
      
      else if (column.id === "Status") {
        
        const isFutureOrToday = compareTargetandCurrentDate(item?.BiddingDate);
        let statusText = "";
        if (item?.JobStatus === 1) {
          statusText =  isFutureOrToday ? `QTN. Recieved (${item?.QuotationCount})` : "Waiting PO";
        } else if (item?.JobStatus === 3) {
          statusText = isFutureOrToday ? "" : "Delivering"; 
        }
        row[column.name]=statusText;
      }
      else {
        row[column.name] = column.selector ? column?.selector(item) : item[column.id] || '';
      }
      return row;
    }, {})
  ) || [];
  

  const handleDelivered = () => {
    setShowNavExceptDelivered(true);
    handleTypeSelect("Delivered")
  };

  const handleChangeDeliveredNav = () => {
    setType("All");
    setShowNavExceptDelivered(false);
  };

  // useEffect(() => {
  //   if (type === "Delivering" || type === "Delivered") {
  //     setShowDeliveryDateColumn(true);
  //   } else {
  //     setShowDeliveryDateColumn(false);
  //   }
  // }, [type]);

  //AttachFile Popup

  const AttachFileCloseModal = () => {
    setAttachFileIsModalOpen(false);
  };

  //prDetails popup
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
      const params = {
       page,
       pageSize,
       orderBy,
       orderDir,
       searchBy,
       searchFields,
       FilterStatus,
       startDate,
       endDate,
       searchValues
      };
      dispatch(getAllJobManagement(params));
      dispatch(getAllJobTableStatusCount());
    }, [tableParams, itemsPerPage, currentPage, searchValues, showNavExceptDelivered]);

  

  return (
    <>
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            {!showNavExceptDelivered ? (
              <Nav activeKey={type} onSelect={handleTypeSelect} className="">
                {navLinks.map((link) => (
                  <NavLink
                    key={link.eventType}
                    eventType={link.eventType}
                    label={link.label}
                    totalCount={type==link.eventType?TotalCount:link.totalCount}
                    activeKey={type}
                    handleTypeSelect={handleTypeSelect}
                  />
                ))}
              </Nav>
            ) : (
              <Nav
                activeKey=""
                onSelect={handleChangeDeliveredNav}
                className=""
              >
                <Nav.Item>
                  <Nav.Link
                    className={
                      type === "Delivered"
                        ? "supplier-visit-table-active"
                        : "supplier-visit-table"
                    }
                    eventKey="All"
                  >
                    Delivered
                    <span
                      className={
                        type === "Delivered"
                          ? "supplier-visit-table-badge-active"
                          : "supplier-visit-table-badge"
                      }
                      eventKey="All"
                    >
                      {TotalCount}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            )
            }
            <DateRangePicker
              appearance="default"
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>Show </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>entries</span>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: "10px" }}
            >
              {!showNavExceptDelivered && (
                <button className="jbm-delivered-btn" onClick={handleDelivered}>
                  Delivered
                </button>
              )}
              <CSVDownloadButton
                data={csvData}
                headers={trimmedHeaders}
                filename="job_management_table"
              />
            </div>
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={tableData.length ? "um-data-table" : "um-empty-data-table"}
        >
          <DataTable
            className="po-table"
            columns={columns}
            data={tableData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows={true}
            expandableRowDisabled={(row) => !row.JobPrDetails || row?.JobPrDetails?.length<2}
            expandableRowExpanded={(row) => row?.JobPrDetails && row?.JobPrDetails?.length>1}
            expandableRowsComponent={ExpandedComponent}
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
              paddingLeft: "40px",
            }}
            className="table-search-div"
          >
            {columns.map((column) =>
              column.searchable !== false ? (
                <input
                  key={column.id}
                  type="text"
                  value={searchValues[column.id] || ""}
                  style={{
                    width: column.width,
                    marginRight: "2px",
                  }}
                  onChange={(event) => handleSearchChange(event, column.id)}
                />
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of {TotalCount}{" "}
          entries
        </p>
        <CustomPagination
          totalItems={TotalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <AttachFilePopup
        AttachFileIsOpen={attachFileModalOpen}
        AttachFileFormTitle={"Attachments"}
        AttachFileCloseModal={AttachFileCloseModal}
        scenarioType="JobManagement"
        rowData={rowData}
      />
      <PrDetailsPopup
        isOpen={isModalOpen}
        closeModal={closeModal}
        formTitle="PR detail"
        jobId={jobId}
      />
    </>
  );
};

export default JobManagementTable;
