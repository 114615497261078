import React from "react";
import "./Login.css";
import loginImg from "../../Assets/Images/login-img.png";
import LoginForm from "../../Components/LoginForm/LoginForm";

const Login = () => {
  return (
    <div className="d-flex">
      <div className="login-form">
        <LoginForm />
      </div>
      <div className="login-logo-div">
        <img className="login-logo" src={loginImg} alt="" />
      </div>
    </div>
  );
};

export default Login;
