import React, { useState } from "react";
import "./CustomPagination.css";

const CustomPagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPageButtons = 5;
  const [startPage, setStartPage] = useState(1);

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      if (startPage === currentPage) {
        setStartPage(startPage - maxPageButtons);
      }
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      if (currentPage >= startPage + maxPageButtons - 1) {
        setStartPage(startPage + maxPageButtons);
      }
      handlePageChange(currentPage + 1);
    }
  };

  const generatePageButtons = () => {
    const buttons = [];
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="pagination-container">
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>
        Previous
      </button>

      {generatePageButtons()}

      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};

export default CustomPagination;
