import TYPES from "../constants";

const initialState = {
  error: null,
  loading: false,
  getPurchaseGroupTableData: [],
  createPurchaseGroup: {},
  currentPurchaseGroupData: {},
  currentPurchaseGroupRow: {},
  editPurchaseGroup: {},
  particularEditPurchaseGroup: {},
  deletePurchaseGroupId: null,
};

const getPurchaseGroupInfo = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CREATE_PURCHASE_GROUP_SUCCESS:
      return {
        ...state,
        createPurchaseGroup: action.payload,
        error: null,
      };
    case TYPES.CREATE_PURCHASE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.CREATE_PURCHASE_GROUP_DETAILS:
      return {
        ...state,
        currentPurchaseGroupData: action.payload,
        error: null,
      };

    case TYPES.GET_ALL_PURCHASE_GROUP_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_ALL_PURCHASE_GROUP_SUCCESS:
      return {
        ...state,
        getPurchaseGroupTableData: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_ALL_PURCHASE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.CURRENT_PURCHASE_GROUP_ROW_DATA:
      return {
        ...state,
        currentPurchaseGroupRow: action.payload,
        error: null,
      };
    case TYPES.EDIT_PURCHASE_GROUP_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.EDIT_PURCHASE_GROUP_SUCCESS:
      return {
        ...state,
        editPurchaseGroup: action.payload,
        error: null,
        loading: false,
      };
    case TYPES.EDIT_PURCHASE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.EDIT_PARTICULAR_PURCHASE_GROUP:
      return {
        ...state,
        particularEditPurchaseGroup: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default getPurchaseGroupInfo;
