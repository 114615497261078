import {
  React,
  useState,
  useForm,
  useEffect,
  useSetFormValues,
  useDispatch,
  useSelector,
  BaseUrl,
  toast,
  useTranslation,
  Controller,
  Http,
  CancelAndUpdateBtn,
  getAllCountryList,
} from "../Imports/import";
import {
  CHECK_TAX_NO,
  CITY_LIST,
  STATE_LIST,
} from "../../ApiEndPoints/ApiEndPoints";
import {
  adminUpdateVendor,
  latestVendorInfo,
} from "../../Store/actions/updateVendorAction";
import { getSingleVendorDetails } from "../../Store/Reducer/singleVendorDetails";
import { handleKeyDown, handleOnWheel } from "../../Utils/Utils";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";

const General = ({
  setEditMode,
  editMode,
  oldData,
  setType,
  setDocEdit,
  getCommercialRegType,
  loading,
  getGeneralInfoData,
}) => {
  const {
    t,
    // i18n
  } = useTranslation();
  const { CountryList } = useSelector((state) => state.countryList);
  const formatContactName = (name) => {
    // Match words (alphabetic characters) using regex and capitalize the first letter of each word
    return name.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();



  const selectedCountry = watch("country");
  const selectedState = watch("district");
  const selectedCommercialType = watch("commercial_reg_type");
  const oldCommercialType = oldData?.commercial_reg_type;
  const commercialTypeCheck = oldCommercialType !== selectedCommercialType;

  // Custom formatting function to add commas to the revenue value
  const formatRevenue = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, "");

    const [integerPart, decimalPart] = numericValue.split(".");

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    let formattedValue = formattedIntegerPart;

    if (decimalPart !== undefined) {
      formattedValue += "." + decimalPart?.slice(0, 2);
    }

    return formattedValue;
  };

  const handleChangeReg = (e) => {
    const { value } = e.target;

    setValue("registration_capital", formatRevenue(value));
  };

  // thai language validation
  const validateThaiInput = (value) => {
    // regex to accept Thai characters, numeric values, and special characters
    const thaiCharactersWithSpecial =
      /^[\u0E00-\u0E7F\d\s!@#$%^&*()_+{}\[\]:;"'<>,.?/|\\\-]*$/;

    if (!thaiCharactersWithSpecial.test(value)) {
      return "Please enter the input in Thai.";
    }

    return true;
  };

  // phone number validations
  const validatePhoneNumber = (value, isFieldMandatory) => {
    if (isFieldMandatory && !value) {
      return "Please enter the mobile phone number.";
    }

    if (value && !isValidPhoneNumber(value)) {
      return t("Mobile number is invalid.");
    }

    return true; // Return true to indicate validation success
  };
  

  // Check if any of the specified values are selected for commercial type
  const extractedValue =
    selectedCommercialType && selectedCommercialType.match(/\((.*?)\)/)?.[1];

  const isFalse =
    extractedValue &&
    ["O", "U", "P", "G", "N", "V", "C", "A"].includes(extractedValue);

  const isFalseThaiName = extractedValue && ["O", "U"].includes(extractedValue);

  const isFieldMandatory = extractedValue === "L";

  // function to find out country id by country name
  const getCountryIdByName = (selectedCountry) => {
    const country = CountryList?.reduce((acc, curr) => {
      if (curr.country === selectedCountry) {
        return curr;
      }
      return acc;
    }, null);

    return country ? country.id : null;
  };

  const countryId = getCountryIdByName(selectedCountry);

 
  // function to find out state id by state name
  const getStateIdByName = (selectedState) => {
    const state =
      stateList.length > 0 &&
      stateList.find((s) => s.state_name === selectedState);
    return state ? state.id : null;
  };

  const stateId = getStateIdByName(selectedState);

  useEffect(() => {
    if (countryId) {
      const fetchStateList = async () => {
        try {
          const queryParams = { country_id: countryId };
          const response = await Http.get(
            `${BaseUrl}${STATE_LIST}`,
            queryParams
          );
         
          // setValue("district", "");
          setStateList(response.data);
        } catch (error) {
          toast.error(error?.message || "Something went wrong!")
        }
      };
      fetchStateList();
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId && countryId) {
      const fetchCityList = async () => {
        try {
          const queryParams = { state_id: stateId, country_id: countryId };
          const response = await Http.get(
            `${BaseUrl}${CITY_LIST}`,
            queryParams
          );
          // setValue("city", "");
          setCityList(response.data);
        } catch (error) {
         toast.error(error?.message || "Something went wrong!")
        }
      };

      fetchCityList();
    }
  }, [stateId, countryId]);

  const registration_no = watch("registration_no");

  const oldRegistration = oldData?.registration_no;

  useEffect(() => {
    dispatch(getAllCountryList());
    dispatch(getSingleVendorDetails());
  }, []);

  const onSubmit = async (data) => {
    loading(true);

    var formdata = new FormData();
    formdata.append("vendor_code", data.vendor_code);
    formdata.append("register_country", data.register_country);
    formdata.append("vendor_type", data.vendor_type);
    formdata.append("user_name", data.user_name);
    formdata.append("commercial_reg_type", data.commercial_reg_type);
    formdata.append("registration_no", data.registration_no);
    formdata.append("registration_capital", data.registration_capital);
    formdata.append("established_date", data.established_date);
    formdata.append("company_name_th", data.company_name_th);
    formdata.append("company_name_eng", data.company_name_eng);
    formdata.append("title_name", data.title_name);
    formdata.append("sales_contact", data.sales_contact);
    formdata.append("telephone", data.telephone);
    formdata.append("mobile_phone", data.mobile_phone);
    formdata.append("website", data.website);
    formdata.append("contact_name", data.contact_name);
    formdata.append("contact_phone", data.contact_phone);
    formdata.append("contact_email", data.contact_email);
    formdata.append("contact_email2", data.contact_email2);
    formdata.append("email_for_vms", data.email_for_vms);
    formdata.append("email2_for_vms", data.email2_for_vms);
    formdata.append("street_house_no", data.street_house_no);
    formdata.append("city", data.city);
    formdata.append("district", data.district);
    formdata.append("country", data.country);
    formdata.append("postal_code", data.postal_code);
    formdata.append("title_specify", data.title_specify);
    formdata.append("fax", data.fax);

    const formDataObject = Object.fromEntries(formdata.entries());
    getCommercialRegType(data.commercial_reg_type);

    if (oldRegistration !== registration_no && registration_no) {
      // REGISTRATION number checking API
      try {
        const queryParams = { registration_no };
        const response = await Http.get(
          `${BaseUrl}${CHECK_TAX_NO}`,
          queryParams
        );
       

        if (!response.data.registration_status) {
          if (commercialTypeCheck && ["L", "T", "P"].includes(extractedValue)) {
            setType("Documents");
            dispatch(latestVendorInfo(formDataObject));
            loading(false);
            setDocEdit(true);
          } else {
            try {
              const isSuccess = await dispatch(adminUpdateVendor(formdata));
              
              if (isSuccess?.data?.status === 200) {
                await dispatch(getSingleVendorDetails());
                setEditMode(false);
                loading(false);
                toast.success(isSuccess?.data?.message);
              } else {
                loading(false);
                toast.error(isSuccess?.data?.message || "Something went wrong");
              }
            } catch (error) {
              toast.error(error.message || "Something went wrong");
              loading(false);
            }
          }
        } else {
          loading(false);
          toast.error(
            "This registration number already exists, please change and try again"
          );
        }
      } catch (error) {
        toast.error(error?.message || "Something went wrong!")
      }
    } else {
      if (commercialTypeCheck && ["L", "T", "P"].includes(extractedValue)) {
        setType("Documents");
        dispatch(latestVendorInfo(formDataObject));
        loading(false);
        setDocEdit(true);
      } else {
        const isSuccess = await dispatch(adminUpdateVendor(formdata));

        if (isSuccess?.data?.status === 200) {
          await dispatch(getSingleVendorDetails())
            .then(() => {
              setEditMode(false);
              loading(false);
              // toast.success("Updated Successfully");
              toast.success(isSuccess?.data?.message);
            })
            .catch((error) => {
              toast.error(error.message || "Something went wrong");
              loading(false);
            });
        } else {
          loading(false);
          toast.error(isSuccess?.data?.message || "Something went wrong");
        }
      }
    }
  };

  const setDataValues = useSetFormValues(setValue);

  useEffect(() => {
    setDataValues(oldData);
  
    setValue("established_date", oldData?.established_date);
  }, [oldData, setDataValues]);

  // vendor type validation based on country
  useEffect(() => {
    var domestic = watch("register_country");
    if (domestic === "Thailand") {
      setValue("vendor_type", "Domestic");
    } else {
      setValue("vendor_type", "Oversea");
    }
  }, [watch("register_country")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
      <div className="genaral-main-div">
        <div className="vp-label-input">
          <label className="vp-label">{t("Vendor Code")} :</label>
          <div className="vp-input-div">
            <input
              type="text"
              disabled={!editMode}
              className="vp-input"
              {...register("vendor_code", {})}
            />
            {errors.vendor_code && (
              <span className="error-msg">{t("Please enter the value")}</span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">{t("Registered Country")} : </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("register_country", {
                required: true,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {" "}
                {t("select country")}
              </option>
              {CountryList.map((country, index) => {
                return (
                  <option
                    className="dropdown-country"
                    key={index}
                    value={country.country}
                  >
                    {country.country}
                  </option>
                );
              })}
            </select>
            {errors.register_country && (
              <span className="error-msg">
                {t("Please select the register country.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">{t("Vendor Type")} : </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("vendor_type", {
                required: false,
              })}
            >
              <option value="Domestic">{t("Domestic")} </option>
              <option value="Oversea">{t("Oversea")}</option>
            </select>
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label"> {t("Commercial Register Type")}:</label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              className="vp-input"
              {...register("commercial_reg_type", {
                required: true,
              })}
            >
              <option value="" hidden>
                {t("select commerical register type")}
              </option>
              <option value="(L) Domestic Legal Entity">
                (L) Domestic Legal Entity(นิติบุคคล/บริษัทในประเทศ){" "}
              </option>

              <option value="(P) Domestic Individual person">
                (P) Domestic Individual person(บุคคลธรรมดาในประเทศ){" "}
              </option>

              <option value="(O) Oversea">
                (O) Oversea(บริษัทต่างประเทศ){" "}
              </option>

              <option value="(T) Transportation Domestic">
                (T) Transportation Domestic(บริษัทขนส่งภายในประเทศ){" "}
              </option>
              <option value="(U)Transportation Oversea">
                (U) Transportation Oversea(บริษัทขนส่งต่างประเทศ){" "}
              </option>
              <option value="(G) Government">
                (G) Government(หน่วยงานรัฐบาล){" "}
              </option>
              <option value="(N) Non-Government">
                (N) Non-Government(องค์กรไม่แสวงหากำไร เช่น สโมสร,สมาคม){" "}
              </option>
              <option value="(V) VAT report only">
                (V) VAT report only(ใช้เฉพาะฝ่ายบัญชีเท่านั้น){" "}
              </option>
              <option value="(C) Customer Claim">(C) Customer Claim </option>
              <option value="(A) Internal">
                (A) Internal(บริษัทในเครือ AGC)
              </option>
            </select>
            {errors.commercial_reg_type && (
              <span className="error-msg">
                {t("Please select the commercial register type.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Registration No. (TAX Number)")}
            {!isFalse && editMode && <span className="staricon">*</span>}:
          </label>
          <div className="vp-input-div">
            <input
              type="text"
              disabled={!editMode}
              className="vp-input"
              {...register("registration_no", {
                required: false,

              })}
              onWheel={handleOnWheel}
            />
            {errors.registration_no && (
              <span className="error-msg">
                {t("Please enter the registration no.(Tax number)")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Registered Capital (THB)")}
            {!isFalse && editMode && <span className="staricon">*</span>}:
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="text"
              className="vp-input"
              {...register("registration_capital", {
                required: false,
                maxLength: 20,
                onChange: handleChangeReg,
              })}
              onWheel={handleOnWheel}
            />
            {errors.registration_capital && (
              <span className="error-msg">
                {t("Please enter the registered capital.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Established Date")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="Date"
              className="vp-input"
              max={new Date().toISOString().split("T")[0]}
              value={watch("establishedDate")}
              {...register("established_date", {
                required: isFieldMandatory,
              })}
            />
            {errors.established_date && (
              <span className="error-msg">
                {t("Please enter the establishment date.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">{t("Username")}:</label>
          <div className="vp-input-div">
            <input
              type="email"
              disabled
              className="vp-input"
              {...register("user_name", {
                maxLength: 80,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.user_name && errors.user_name.type === "pattern" && (
              <span className="error-msg">{t("Invalid email address.")}</span>
            )}
            {errors.user_name && (
              <span className="error-msg">
                {t("Please enter the username.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">{t("Password")}:</label>
          <div className="vp-input-div">
            <input
              type="text"
              disabled
              value="**********"
              className="vp-input"
              {...register("password", {})}
            />
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Company Name (TH)")}
            {watch("vendor_type") !== "Oversea" &&
              !isFalseThaiName &&
              editMode && <span className="staricon">*</span>}
            :
          </label>
          <div className="vp-input-div">
            <input
              type="text"
              disabled={!editMode}
              // disabled={!editMode || watch("vendor_type") === "Oversea"}
              placeholder={
                watch("vendor_type") === "Oversea" ? "" : "Enter Thai text"
              }
              className="vp-input"
              {...register("company_name_th", {
                required: false,
                  // watch("vendor_type") !== "Oversea" && !isFalseThaiName,
                // validate: validateThaiInput,
                maxLength: 80,
              })}
            />
            {errors.company_name_th && (
              <span className="error-msg">
                {t("Please enter the company name in Thai.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Company Name (EN)")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              type="text"
              disabled={!editMode}
              className="vp-input"
              {...register("company_name_eng", {
                required: isFieldMandatory,
                maxLength: 160,
                minLength: 1,
              })}
            />
            {errors.company_name_eng && (
              <span className="error-msg">
                {t("Please enter the company name in English.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">{t("Search Name")}:</label>
          <div className="vp-input-div">
            <input
              type="text"
              disabled
              className="vp-input"
              value={watch("company_name_eng")}
            />
            {errors.company_name_eng && (
              <span className="error-msg">{t("Please enter the value")}</span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Title Name")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <select
              type="text"
              disabled={!editMode}
              className="vp-input"
              {...register("title_name", {
                required: isFieldMandatory,
              })}
            >
              <option value="" hidden>
                select
              </option>
              <option value="Public Company Limited">
                {t("Public Company Limited")}
              </option>
              <option value="Company Limited">{t("Company Limited")}</option>
              <option value="partnership">{t("Partnership")}</option>
              <option value="miss">{t("MR./MS/MISS/ Etc.")}</option>
              <option value="other">{t("Other please specify")} </option>
            </select>
            {errors.title_name && (
              <span className="error-msg">
                {t("Please select the company title prefix or suffix.")}
              </span>
            )}

            <div className="vp-input-div" style={{ display: "contents" }}>
              {watch("title_name") === "other" && (
                <>
                  <input
                    type="text"
                    disabled={!editMode}
                    className="vp-input mt-3"
                    {...register("title_specify", {
                      required: false,
                      maxLength: 80,
                    })}
                  />

                  {errors.title_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Sale or Marketing Contact email")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              type="email"
              placeholder={t("Enter contact email address")}
              disabled={!editMode}
              className="vp-input"
              {...register("sales_contact", {
                required: isFieldMandatory,
                maxLength: 80,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.sales_contact &&
              errors.sales_contact.type === "pattern" && (
                <span className="error-msg">{t("Invalid email address.")}</span>
              )}
            {errors.sales_contact && (
              <span className="error-msg">
                {t(
                  "Please enter the contact person's name, sales or marketing department."
                )}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Tel.")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="number"
              className="vp-input"
              {...register("telephone", {
                required: isFieldMandatory,
                maxLength: 20,
              })}
              onWheel={handleOnWheel}
            />
            {errors.telephone && (
              <span className="error-msg">
                {t("Please enter the telephone number.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Mobile phone")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          {editMode ? (
            <div className="vp-input-div">
              <Controller
                name="mobile_phone"
                control={control}
                rules={{
                  validate: (value) =>
                    validatePhoneNumber(value, isFieldMandatory),
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    // country={countryCode ? countryCode.toLowerCase() : "th"}
                    defaultCountry={countryCode ? countryCode : "TH"}
                    disabled={!editMode}
                    className="vp-input"
                  />
                )}
              />
              {errors.mobile_phone && (
                <span className="error-msg">{errors.mobile_phone.message}</span>
              )}
            </div>
          ) : (
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="text"
                className="vp-input"
                {...register("mobile_phone", {
                  required: isFieldMandatory,
                })}
              />
            </div>
          )}
        </div>

        <div className="vp-label-input">
          <label className="vp-label">{t("Fax Number")} :</label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="number"
              className="vp-input"
              {...register("fax", {
                pattern: {
                  value: /^[0-9]{9,20}$/, // Added a pattern to ensure only digits between 9 to 20 are allowed
                  message:
                    "Please enter a valid fax number with 9 to 20 digits",
                },
              })}
              onWheel={handleOnWheel}
            />
            {errors.fax && (
              <span className="error-msg">{errors.fax.message}</span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">{t("Web Site (If any)")} :</label>
          <div className="vp-input-div">
            <input
              type="url"
              disabled={!editMode}
              className="vp-input"
              {...register("website", {})}
            />
            {errors.website && (
              <span className="error-msg">{t("Please enter the value")}</span>
            )}
          </div>
        </div>
        <h3 className="vendor-profile-contact">
          {t("Contact person for (Account)")}{" "}
        </h3>
        <hr className="contact-line"></hr>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Name")}{" "}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="text"
              className="vp-input"
              {...register("contact_name", {
                required: isFieldMandatory,
                maxLength: 50,
                pattern:
                  /^[A-Za-z\sก-๙เแ่้๊๋า-ู็ิ์ํฺ\[\]{}()\-_.,:;'"!@#$%^&*+=<>?~]+$/,
                onChange: (e) => {
                  const enteredName = e.target.value;
                  // const formattedName = formatContactName(enteredName);
                  // e.target.value = formattedName;
                },
              })}
            />
            {errors.contact_name && (
              <span className="error-msg">
                {t("Please enter the correct contact person name for account")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Mobile phone")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>

          {editMode ? (
            <div className="vp-input-div">
              <Controller
                name="contact_phone"
                control={control}
                rules={{
                  validate: (value) =>
                    validatePhoneNumber(value, isFieldMandatory),
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    // country={countryCode ? countryCode.toLowerCase() : "th"}
                    defaultCountry={countryCode ? countryCode : "TH"}
                    disabled={!editMode}
                    className="vp-input"
                  />
                )}
              />
              {errors.contact_phone && (
                <span className="error-msg">
                  {errors.contact_phone.message}
                </span>
              )}
            </div>
          ) : (
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="text"
                className="vp-input"
                {...register("contact_phone", {
                  required: isFieldMandatory,
                })}
              />
            </div>
          )}
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("E-mail address")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="email"
              className="vp-input"
              {...register("contact_email", {
                required: isFieldMandatory
                  ? "Please enter the contact person email address for account."
                  : false,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Please enter a valid email address.",
                },
              })}
            />
            {errors.contact_email && (
              <span className="error-msg">{errors.contact_email.message}</span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("E-mail address 2")}{" "}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="email"
              className="vp-input"
              {...register("contact_email2", {
                required: isFieldMandatory
                  ? "Please enter the contact person email2 address for account."
                  : false,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Please enter a valid email address.",
                },
              })}
            />
            {errors.contact_email2 && (
              <span className="error-msg">{errors.contact_email2.message}</span>
            )}
          </div>
        </div>
        <h3 className="vendor-profile-contact">
          {t("Email  Contact person for vendor management system (VMS)")}{" "}
        </h3>
        <hr className="contact-line"></hr>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("E-mail address")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="email"
              className="vp-input"
              {...register("email_for_vms", {
                required: isFieldMandatory,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Please enter a valid email address.",
                },
              })}
            />
            {errors.email_for_vms && (
              <span className="error-msg"> {t("Please enter the value")}</span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("E-mail address 2")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="email"
              className="vp-input"
              {...register("email2_for_vms", {
                required: isFieldMandatory,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Please enter a valid email address.",
                },
              })}
            />
            {errors.email2_for_vms && (
              <span className="error-msg">{t("Please enter the value")}</span>
            )}
          </div>
        </div>
        <h3 className="vendor-profile-contact">{t("Registered Address")}</h3>
        <hr className="contact-line"></hr>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Street/House Number")}{" "}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="text"
              className="vp-input"
              {...register("street_house_no", {
                required: isFieldMandatory,
                maxLength: 200,
              })}
            />
            {errors.street_house_no && (
              <span className="error-msg">{t("Please enter the value")}</span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Country")}{" "}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <select
              type="text"
              disabled={!editMode}
              className="vp-input select"
              {...register("country", {
                required: isFieldMandatory,
              })}
            >
              <option className="dropdown-country" value="" hidden>
                {" "}
                {t("select country")}
              </option>
              {CountryList.map((country, index) => {
                return (
                  <option
                    className="dropdown-country"
                    key={country.country_id || index}
                    value={country.country}
                  >
                    {country.country}
                  </option>
                );
              })}
            </select>
            {errors.country && (
              <span className="error-msg">
                {t("Please select the country of registered of the company.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("Province/State/City")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              type="text"
              value={watch("district") ? watch("district") : oldData?.district}
              className="vp-input"
              {...register("district", {
                required: isFieldMandatory,
              })}
            >
              <option value="" hidden>
                select
              </option>
              {stateList.length > 0 &&
                stateList.map((state, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={state.id}
                      value={state.state_name}
                    >
                      {state.state_name}
                    </option>
                  );
                })}
            </select>
            {errors.district && (
              <span className="error-msg">
                {t(
                  "Please enter the district/state/province of registered of the company."
                )}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input">
          <label className="vp-label">
            {t("District")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <select
              disabled={!editMode}
              value={watch("city") ? watch("city") : oldData?.city}
              className="vp-input"
              {...register("city", {
                required: isFieldMandatory,
              })}
            >
              <option value="" hidden>
                select
              </option>
              {cityList.length > 0 &&
                cityList.map((city, index) => {
                  return (
                    <option className="dropdown-country" value={city.city_name}>
                      {city.city_name}
                    </option>
                  );
                })}
            </select>
            {errors.city && (
              <span className="error-msg">
                {t("Please enter the city of registered of the company.")}
              </span>
            )}
          </div>
        </div>
        <div className="vp-label-input mb-5">
          <label className="vp-label">
            {" "}
            {t("Postal Code")}
            {isFieldMandatory && editMode && (
              <span className="staricon">*</span>
            )}
            :
          </label>
          <div className="vp-input-div">
            <input
              disabled={!editMode}
              type="text"
              inputMode="text"
              className="vp-input"
              {...register("postal_code", {
                required: isFieldMandatory,
                maxLength: 12,
                minLength: 4,
              })}
              onWheel={handleOnWheel}
            />
            {errors.postal_code && (
              <span className="error-msg">
                {t(
                  "Please enter the postal code of registered of the company."
                )}
              </span>
            )}
          </div>
        </div>
        <CancelAndUpdateBtn
          editMode={editMode}
          setEditMode={setEditMode}
          reset={reset}
          data={oldData}
          setDocEdit={setDocEdit}
          commercialTypeCheck={commercialTypeCheck}
          extractedValue={extractedValue}
        />
      </div>
    </form>
  );
};

export default General;
