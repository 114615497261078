import axios from "axios";
import { toast } from "react-toastify";

const Http = {
  get: async (url, params = {}, config = {}) => {
    try {
      const response = await axios.get(url, { params, ...config });
      return response.data;
    } catch (error) {
      
      toast.error(error.message);
      throw new Error(error.response.data.error);
    }
  },

  post: async (url, data, config = {}) => {
    try {
      const response = await axios.post(url, data, config);
      return response.data;
    } catch (error) {
      
      toast.error(error.message);
      throw new Error(error.response.data.error);
    }
  },

  put: async (url, data, config = {}) => {
    try {
      const response = await axios.put(url, data, config);
      return response.data;
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.response.data.error);
    }
  },

  delete: async (url, config = {}) => {
    try {
      const response = await axios.delete(url, config);
      return response.data;
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.response.data.error);
    }
  },
};

export default Http;
