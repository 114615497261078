import React, { useEffect, useState } from "react";
import "./SideBar.css";
import logo from "../../Assets/Svg/agc-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { RiSuitcaseFill, RiLogoutCircleRLine } from "react-icons/ri";
import { AiFillProject, AiFillSetting } from "react-icons/ai";
import { BiNews } from "react-icons/bi";
import { FaCubes, FaUserCog } from "react-icons/fa";
import { MdWorkHistory } from "react-icons/md";
import supp from "../../Assets/Svg/supp-management.svg";
import suppWhite from "../../Assets/Svg/supp-management-white.svg";
import jobSuitcaseBlue from "../../Assets/Svg/suitcase-icon-blue.svg";
import jobSuitcaseWhite from "../../Assets/Svg/suitcase-icon-white.svg";
import store from "../../Store/store";
import { clearStoreInfo } from "../../Store/actions/updateVendorAction";
import { clearSingleVendorData } from "../../Store/Reducer/singleVendorDetails";
import { clearVendorTableList } from "../../Store/Reducer/vendorTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllLoginDetails } from "../../Store/Reducer/loginDetails";

const SideBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [administratorChild, setAdministratorChild] = useState(false);
  const currentPath = window.location.pathname;
  const { t } = useTranslation();
  const secretKey = "mysecretkey12345"; // Replace this with your actual secret key
  const { LoginDetails,loading } = useSelector((state) => state.loginDetails);
  const dispatch = useDispatch();
  const [hasAccess,setHasAccess]=useState(false);
 const assignerType=["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  useEffect(()=>{
      dispatch(getAllLoginDetails());
  },[])
  useEffect(()=>{
    const getAssignerType= LoginDetails?.assigner_type ?LoginDetails?.assigner_type.split(",") :[]
    const checkAccess=getAssignerType?.some((type)=> !assignerType.includes(type))
    setHasAccess(checkAccess);
  },[LoginDetails])

  
  const decryptData = (encryptedData, key) => {
    try {
      const decodedData = decodeURIComponent(atob(encryptedData));
      const decryptedData = JSON.parse(decodedData);
      return decryptedData;
    } catch (error) {
      return null;
    }
  };
  

  // Retrieve and decrypt the data from localStorage
  const encryptedDataFromLocalStorage =
    localStorage.getItem("encryptedUserInfo");
  const decryptedDataString = decryptData(
    encryptedDataFromLocalStorage,
    secretKey
  );

  // Parse the decrypted data into an object
  const decryptedData = decryptedDataString
    ? JSON.parse(decryptedDataString)
    : null;


  const handleClickNavigate = (path) => {
    navigate(path);
  };

  const handleAdminChild = () => {
    setAdministratorChild(true);
    navigate("/user-management");
  };

  const handleLogout = () => {
    localStorage.clear();
    store.dispatch(clearStoreInfo());
    store.dispatch(clearSingleVendorData());
    store.dispatch(clearVendorTableList());
    navigate("/");
  };

  return (
    <section className="sb-main">
      <div className="sb-logo-div">
        <img src={logo} alt="" />
      </div>
      <div className="sb-buttons-div">
        <div
          onClick={() =>{ 
            if(!hasAccess){
              handleLogout();
            } handleClickNavigate("/dashboard")}}
          className={`${
            pathname == "/dashboard" ? "sb-btn sb-btn-active" : "sb-btn"
          }`}
        >
          <MdDashboard />
          <p>Dashboard</p>
        </div>
        <div
          onClick={() =>{handleClickNavigate("/vendor-management")}}
          className={`${
            pathname == "/vendor-management" ||
            pathname == "/create-vendor" ||
            pathname == "/vendor-profile"
              ? "sb-btn sb-btn-active"
              : "sb-btn"
          }`}
        >
          <HiUsers />
          <p>{t("Vendor Management")}</p>
        </div>
        <div
          onClick={() => { 
            if(!hasAccess){
              handleLogout();
            }handleClickNavigate("/job-management")}}
          className={`${
            pathname == "/job-management" || pathname == "/job-management-view"
              ? "sb-btn sb-btn-active"
              : "sb-btn"
          }`}
        >
          {/* <RiSuitcaseFill /> */}

          <img
            src={
              currentPath?.includes("/job-")
                ? jobSuitcaseWhite
                : jobSuitcaseBlue
            }
            alt=""
          />
          <p> {t("Job Management")}</p>
        </div>
        <div
          onClick={() =>{ 
            if(!hasAccess){
              handleLogout();
            } handleClickNavigate("/pr-release")}}
          className={`${
            pathname == "/pr-release" ? "sb-btn sb-btn-active" : "sb-btn"
          }`}
        >
          <AiFillProject />
          <p>{t("PR Release")}</p>
        </div>
        <div
          onClick={() =>{ 
            if(!hasAccess){
              handleLogout();
            } handleClickNavigate("/Supplier-Management")}}
          className={`${
            pathname == "/Supplier-Management" ||
            pathname == "/Supplier-Management-Quotation" ||
            pathname == "/Supplier-Management-Evaluated"
              ? "sb-btn sb-btn-active"
              : "sb-btn"
          }`}
        >
          <img
            src={currentPath?.includes("/Supplier-") ? suppWhite : supp}
            alt=""
          />
          <p>{t("Supplier Management")}</p>
        </div>
        <div
          onClick={() =>{ 
            if(!hasAccess){
              handleLogout();
            } handleClickNavigate("/press-release")}}
          className={`${
            pathname == "/press-release" ? "sb-btn sb-btn-active" : "sb-btn"
          }`}
        >
          <BiNews />
          <p>{t("Press Release")} </p>
        </div>
        <div
          onClick={() =>{ 
            if(!hasAccess){
              handleLogout();
            } handleClickNavigate("/purchasing-group")}}
          className={`${
            pathname == "/purchasing-group" ? "sb-btn sb-btn-active" : "sb-btn"
          }`}
        >
          <FaCubes />
          <p>{t("Purchasing Group")}</p>
        </div>
        <div
          onClick={() =>{ 
            if(!hasAccess){
              handleLogout();
            } handleClickNavigate("/history-storage")}}
          className={`${
            pathname == "/history-storage" ||
            pathname == "/history-storage-view"
              ? "sb-btn sb-btn-active"
              : "sb-btn"
          }`}
        >
          <MdWorkHistory />
          <p>{t("History Storage")}</p>
        </div>
        {decryptedData?.role == "Admin" ? (
          <>
            {" "}
            <hr className="sb-hr mb-2"></hr>
            <div className="sb-btn pb-2" onClick=
{()=>{
    if(!hasAccess){
      handleLogout();
    }
  handleAdminChild()}}>
              <AiFillSetting />
              <p> {t("Administrator")}</p>
            </div>
            {(administratorChild || pathname == "/user-management") && (
              <>
                <div className="sb-btn sb-btn-active a-child">
                  <FaUserCog />
                  <p>{t("User Management")}</p>
                </div>
              </>
            )}
            <hr className="sb-hr"></hr>
          </>
        ) : null}
        <div className="sb-btn mb-5" onClick={handleLogout}>
          <RiLogoutCircleRLine />
          <p>{t("Log out")}</p>
        </div>
      </div>
    </section>
  );
};

export default SideBar;