import React, { useState, useRef, useEffect } from "react";
import { toast, useDispatch, useForm, useSelector } from "../../Components/Imports/import";
import Modal from "react-modal";
import CloseIcon from "../../Assets/Svg/close-icon.svg";
import QueMarkLogo from "../../Assets/Images/que-mark.png";
import FileUpload from "../../Assets/Images/fileupload.png";
import pdflogo from "../../Assets/Svg/pdf-icon.svg";
import jpglogo from "../../Assets/Svg/jpg-icon.svg";
import RemoveLogo from "../../Assets/Images/remove.png";
import { useDropzone } from "react-dropzone";
import "./AttachFilePopup.css";
import {
  editPrReleaseAttachments,
  deletePrReleaseAttachment,
  deletePrAttachment,
  getAllPrAttachmentsAction,
} from "../../Store/actions/prReleaseActions";
import {
  editjobManagementAttachments,
  deleteJobManagementAttachment,
  deleteJobAttachment,
  getAllJobManagemetAttachments,
} from "../../Store/actions/jobManagementAction";

const AttachFilePopup = ({
  AttachFileIsOpen,
  AttachFileCloseModal,
  AttachFileFormTitle,
  scenarioType,
  rowData,
}) => {
  const {getAllPrAttachments,loading,error} =
  useSelector((state) => state.getPrReleaseInfo);
  const {
    getAllJobManagementAttachments,
  } = useSelector((state) => state.getJobManagementInfo);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [filesData,setFilesData]=useState([]);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  useEffect(()=>{
    if(scenarioType==="PrRelease"){
      dispatch(getAllPrAttachmentsAction(rowData?.PrCreatedId))
    }
    else if(scenarioType==="JobManagement"){
      dispatch(getAllJobManagemetAttachments(rowData?.JobId))
    }
  },[rowData])
  useEffect(()=>{
    if(scenarioType==="JobManagement"){
      setFilesData(getAllJobManagementAttachments)
    }
    else if(scenarioType==="PrRelease"){
      setFilesData(getAllPrAttachments)
    }
    
  },[getAllPrAttachments,getAllJobManagementAttachments])

  const onDrop = (acceptedFiles) => {
    const maxFileSize=10*1024*1024;
    const allowedFileTypes = [
      "application/pdf", 
      "application/msword", 
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
      "application/vnd.ms-powerpoint", 
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
      "text/csv", 
      "image/jpeg", 
      "image/jpg", 
      "image/png", 
      "image/bmp", 
      "image/gif",
    ];
    const validFiles = acceptedFiles.filter(
      (file) => allowedFileTypes.includes(file.type) && file.size <= maxFileSize
    );
    const invalidFiles = acceptedFiles.filter(
      (file) => !allowedFileTypes.includes(file.type) || file.size > maxFileSize
    );
    if (invalidFiles.length > 0) {
      invalidFiles.forEach((file) => {
        if (!allowedFileTypes.includes(file.type)) {
          toast.error(`File type not supported: ${file.name}`, {
            position: "top-right",
            autoClose: 5000,
          });
        } else if (file.size > maxFileSize) {
          toast.error(
            `File size too large (${(file.size / 1024 / 1024).toFixed(2)} MB): ${file.name}`,
            {
              position: "top-right",
              autoClose: 5000,
            }
          );
        }
      });
    }
    if (validFiles.length < acceptedFiles.length) {
      
    } else {
      setFileError("");
      setSelectedFiles((prevSelectedFiles) => [
        ...prevSelectedFiles,
        ...validFiles
      ]);
    }
  };

  const removeFile = (fileIndex) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.filter((_, index) => index !== fileIndex)
    );
  };

  const { handleSubmit } = useForm({
    // Your form configuration
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    var formData = new FormData();

    if (!selectedFiles || selectedFiles.length === 0) {
      toast.error("Please add a file");
      return;
    }

    Object.keys(selectedFiles).forEach((key) => {
      formData.append("AttachmentFile", selectedFiles[key]);
    });

    if (scenarioType === "PrRelease") {
      formData.append("PrCreatedId", rowData?.PrCreatedId);
      const response = await dispatch(editPrReleaseAttachments(formData));
      if (response) {
        setSelectedFiles([]);
        AttachFileCloseModal();
      }
    } else if (scenarioType === "JobManagement") {
      formData.append("JobId", rowData?.JobId);
      const response = await dispatch(editjobManagementAttachments(formData));

      if (response) {
        setSelectedFiles([]);
        AttachFileCloseModal();
      }
    }
    // Handle form submission including selectedFiles
  };

  const onClickDiscard = () => {
    setSelectedFiles([]);
    AttachFileCloseModal();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf, image/jpeg, image/jpg",
    multiple: true,
  });

  const onSelectFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const deleteFile = async (fileId) => {
    if (scenarioType === "PrRelease") {
      const response = await dispatch(deletePrAttachment(fileId));
      if (response) {
        dispatch(getAllPrAttachmentsAction(rowData?.PrCreatedId));
      }
    } else if (scenarioType === "JobManagement") {
      const response = await dispatch(deleteJobAttachment(fileId));
      if (response) {
        dispatch(getAllJobManagemetAttachments(rowData?.JobId));
      }
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={AttachFileIsOpen}
        onRequestClose={AttachFileCloseModal}
        className="create-user-modal create-attach-file-modal"
        overlayClassName="create-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="create-user-title">
          <div className="d-flex">
            <h2 style={{ marginRight: "20px" }}>{AttachFileFormTitle}</h2>
          </div>
          <img
            src={CloseIcon}
            alt=""
            onClick={AttachFileCloseModal}
            className="attach-file-close-icon"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">
          <br />
          <div className="attach-file-main-div">
            <div className="attach-file-text-icon">
              <label>Attach file</label>
              <img src={QueMarkLogo} alt="?" className="que-mark-logo" />
            </div>
            <div className="attach-file-dropzone" {...getRootProps()}>
              <div className="attach-file-dropzone-icon">
                <img
                  src={FileUpload}
                  alt="Upload"
                  className="file-upload-icon"
                />
                <label>Select File to Upload</label>
                <p>or drag and drop it here</p>
              </div>
              <input
                {...getInputProps()}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            <button
              type="button"
              className="attach-sele-file-btn"
              onClick={onSelectFileClick}
            >
              Select File
            </button>
            {fileError && <p className="attach-file-error-m">{fileError}</p>}
          </div>

          <div className="attach-file-document-file">
            <label>Document File</label>
          </div>
          <br />

          {/* Display existing files */}

          {filesData &&
            filesData.map((file) => (
              <>
                <div
                  className="attach-file-border"
                  key={
                    scenarioType == "JobManagement"
                      ? file.JobAttachmentId
                      : file.PrCreatedId
                  }
                >
                  <div className="attach-file-upload-file">
                    <div className="d-flex gap-2" style={{ width: "100%" }}>
                      {file.AttachmentName.endsWith(".pdf") ? (
                        <img
                          src={pdflogo}
                          alt="PDF"
                          className="attach-file-all-logo"
                        />
                      ) : (
                        <img
                          src={jpglogo}
                          alt="JPG"
                          className="attach-file-all-logo"
                        />
                      )}
                      <p style={{ marginRight: "20%" }} className="file-name">
                        <a
                          href={file.AttachmentFileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {file.AttachmentName}{" "}
                        </a>
                      </p>
                    </div>
                    <img
                      src={RemoveLogo}
                      alt="Remove"
                      className="attach-file-remove-logo"
                      onClick={() =>
                        deleteFile(
                          scenarioType == "JobManagement"
                            ? file.JobAttachmentId
                            : file.PrAttachmentId
                        )
                      }
                    />
                  </div>
                </div>
              </>
            ))}

          {/* Display newly added files */}
          {/* <div className="attach-file-document-file">
            <label>New Attachments</label>
          </div> */}
          <br />
          {selectedFiles.map((file, index) => (
            <div className="attach-file-border" key={index}>
              <div className="attach-file-upload-file">
                <div className="d-flex gap-2" style={{ width: "100%" }}>
                  {file.type === "application/pdf" ? (
                    <img
                      src={pdflogo}
                      alt="PDF"
                      className="attach-file-all-logo"
                    />
                  ) : (
                    <img
                      src={jpglogo}
                      alt="JPG"
                      className="attach-file-all-logo"
                    />
                  )}
                  <p style={{ marginRight: "20%" }} className="file-name">
                    {file.name}
                  </p>
                </div>
                <img
                  src={RemoveLogo}
                  alt="Remove"
                  className="attach-file-remove-logo"
                  onClick={() => removeFile(index)}
                />
              </div>
            </div>
          ))}
          <br />
          <div className="create-btns-div">
            <button
              type="button"
              className="um-create-btn"
              onClick={onClickDiscard}
            >
              Discard
            </button>
            <button type="submit" className="um-submit-btn">
              Attach File
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AttachFilePopup;
