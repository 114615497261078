import React from "react";
import "./SupplierVisitTable.css";
import { formatNumber } from "../../../Utils/Utils";

const PoExpandedTable = ({ data,PurchasingGroup }) => {
  
  return (
    data && (
      <div className="po-expaneded-table-div">
        <table className="po-expanded-table">
          <thead>
            <tr>
              <th>Purchase Requisition</th>
              <th>Description</th>
              <th>PRG</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Unit</th>
            </tr>
          </thead>
          <tbody>
          {data &&
              data?.map((row, index) => (
                <tr key={index}>
                 
                  <td>{row?.PrNumber }</td>
                  <td style={{ maxWidth: "80px", wordWrap: "break-word", overflow: "hidden", whiteSpace: "normal" }}>{row?.PrShortText }</td>
                  <td>{PurchasingGroup }</td>
                  <td>{row?.PrItemNumber }</td>
                  
                  <td>{formatNumber(row?.PrQTY)}</td>
                  <td>{row?.PRUnit }</td>
                 
                  
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  );
};

export default PoExpandedTable;
