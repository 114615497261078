const ODSReference = ({ data }) => {
  const formatText = (text) => {
    // Convert newlines to <br /> and escape special HTML characters
    const escapedText = text
      .replace(/&Gt;/g, '>') 
      .replace(/&Lt;/g, '<') 
      .replace(/&gt;/g, '>') 
      .replace(/&lt;/g, '<') 
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/\\/g, '&#92;')
      .replace(/\r?\n/g, '<br />') 
      
      
      
    return { __html: escapedText }; // Prepare for rendering as HTML
  };

  return (
      <div dangerouslySetInnerHTML={formatText(data)} />
  );
};

export default ODSReference;
