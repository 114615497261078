import React, { useState } from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import "./UserManagement.css";
import UserManagementDataTable from "../../Components/DataTable/UserManagementDataTable";
import plusIcon from "../../Assets/Images/plus-ico.png";
import chart from "../../Assets/Svg/um-img.svg";
import { UserManegementTableBelowContent } from "../../Content/userManagement.content";
import CreateAndEditUser from "../../Models/CreateAndEditUser/CreateAndEditUser";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../Models/PageLoader/PageLoader";
// import { getAllUser } from "../../Store/actions/userManagementAction";

const UserManagement = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading } = useSelector((state) => state.getUserManagementInfo);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DefaultLayout>
      {loading && <PageLoader />}
      <section className="um-main">
        <div className="um-title-btn">
          <h2 className="um-title">User Management</h2>
          <button className="table-btn create-btn" onClick={openModal}>
            {" "}
            <img style={{ marginRight: "10px" }} src={plusIcon} alt="+" />{" "}
            Create New User
          </button>
        </div>
        <UserManagementDataTable />
        <p className="um-text">
          {UserManegementTableBelowContent.TABLEBELOWCONTENT}
        </p>
        <img className="um-chart" src={chart} alt="" />
      </section>
      {isModalOpen && (
        <CreateAndEditUser
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="CreateUser"
          scenarioType="createUser"
        />
      )}
    </DefaultLayout>
  );
};

export default UserManagement;
