import TYPES from "../constants";

const initialState = {
  error: null,
  loading: false,
  getUserInfo: {},
  createUser: {},
  currentUserData: {},
  currentRow: {},
  editUser: {},
  particularEditUser: {},
  deleteUserId: null,
};

const getUserManagementInfo = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_USERS_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_USERS:
      return {
        ...state,
        getUserInfo: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUser: action.payload,
      };
    case TYPES.CREATE_USER_FAILURE:
    
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case TYPES.GET_CREATE_USER_DETAILS:
      return {
        ...state,
        currentUserData: action.payload,
        error: null,
      };
    case TYPES.CURRENT_ROW_DATA:
      return {
        ...state,
        currentRow: action.payload,
        error: null,
      };
    case TYPES.EDIT_USER_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.EDIT_USER_SUCCESS:
      return {
        ...state,
        editUser: action.payload,
        error: null,
        loading: false,
      };
    case TYPES.EDIT_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.EDIT_PARTICULAR_USER:
      return {
        ...state,
        particularEditUser: action.payload,
        error: null,
      };
    case TYPES.DELETE_USER_ID:
      return {
        ...state,
        deleteUserId: action.payload,
      };

    default:
      return state;
  }
};

export default getUserManagementInfo;
