import { createSlice } from "@reduxjs/toolkit";

export const myRegistration = createSlice({
  name: "vinythai",
  initialState: {
    firstObject: {
      register_country: "",
      vendor_type: "",
      commercial_reg_type: "",
      registration_no: "",
      registration_capital: "",
      established_date: "",
      user_name: "",
      password: "",
      confirm_password: "",
      company_name_th: "",
      company_name_eng: "",
      title_name: "",
      sales_contact: "",
      telephone: "",
      mobile_phone: "",
      website: "",
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      contact_email2: "",
      email_for_vms: "",
      email2_for_vms: "",
      street_house_no: "",
      city: "",
      district: "",
      country: "",
      postal_code: "",
      title_specify: "",
      fax: "",
    },
    secondObject: {
      type_of_business: "",
      manufacturer_specify: "",
      business_specify: "",
      experience: "",
      working_day: "",
      working_time: "",
      binding_category: "",
      binding_category_specify: "",
      sub_category: "",
      sub_category_specify: "",
      name_of_top3_customers: "",
      process_litigation: "",
      litigation: "",
    },
    thirdObject: {
      annual_revenue: "",
      name_of_parents: "",
      bank_account_holder: "",
      bank_gaurantee_specify: "",
      account_number: "",
      currency: "",
      bank_name: "",
      bank_specify: "",
      payment_method: "",
      credit_term: "",
      registered_sme: "",
      last_6months_bank_guarantee: "",
      credit_term_specify: "",
      payment_specify: "",
    },
    fourthObject: {
      min_jobs_order: "",
      max_jobs_order: "",
      company_limit_size_project: "",
      analyses_process_for_late_delivery: "",
      project_reference: "",
      size_specify: "",
      specify_project_reference: "",
    },
    fifthObject: {
      company_certified_to_recognize_center: "",
      laboratory_accreditation: "",
      certified_specify: "",
    },
    sixthObject: {
      health_safty_management: "",
      qhse_management: "",
      injury_deaths: "",
      injury_deaths_specify: "",
      registered_foreigner_labor: "",
      lower18_labor: "",
      repair_equipment_pm: "",
      equipment_tools_doc: "",
      ndt_personal_doc: "",
      organization_chart_doc: "",
      qa_qc_policy_doc: "",
      technical_doc: "",
      ppe_specify: "",
    },
  },
  reducers: {
    updatedDataFirst: (state, action) => {
      state.firstObject = action.payload;
    },

    updatedDataSecond: (state, action) => {
      state.secondObject = action.payload;
    },

    updatedDataThird: (state, action) => {
      state.thirdObject = action.payload;
    },
    updatedDataFourth: (state, action) => {
      state.fourthObject = action.payload;
    },
    updatedDataFifth: (state, action) => {
      state.fifthObject = action.payload;
    },

    updatedDataSixth: (state, action) => {
      state.sixthObject = action.payload;
    },
  },
});

export const {
  updatedDataFirst,
  updatedDataSecond,
  updatedDataThird,
  updatedDataFourth,
  updatedDataFifth,
  updatedDataSixth,
} = myRegistration.actions;

export default myRegistration.reducer;
