import React, { useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import CSVDownloadButton from "../../../Components/CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./SupplierManagementTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../../Assets/Svg/arrow-icon.svg";
import SendPOExpandedTable from "./SendPOExpandedTable";
import { useNavigate } from "react-router-dom";
import { PageLoader, useDispatch, useSelector, useTranslation } from "../../Imports/import";
import SendPoPopup from "../../../Models/SendPoPopup/SendPoPopup";
import { tableStyles } from "../../../Utils/TableStyles";
import { getPODataOver1Million } from "../../../Store/actions/supplierManagementActions";
import { DateGenerator, formatNumberWithCommasandDecimals } from "../../../Utils/Utils";

const SendPOTable = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [orderDir, setOrderDir] = useState('DESC');
  const { poDataOver1Million,loading } = useSelector(
    (state) => state.getSupplierManagementInfo
  );
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("All");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [count,setCount]=useState(0)
  const [isReadyToOpenModal, setIsReadyToOpenModal] = useState(false);
  const [attachments,setAttachments]=useState([])
  const [PoDbId,setPoDbId]=useState(null)

  
  useEffect(()=>{
    dispatch(getPODataOver1Million(currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues));
  },[currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1]])


  useEffect(()=>{
    setData(poDataOver1Million?.result?.data)
    setCount(poDataOver1Million?.result?.TotalCount)
  },[poDataOver1Million,count])

  //Using this useEffect so that modal will open only after attachments are passed
  useEffect(()=>{
    if(isReadyToOpenModal){
      
      SendPoOpenModal();
      setIsReadyToOpenModal(false)
    }
  },[isReadyToOpenModal])

// Function to handle file change
  const handleFileChange = () => { 
    setAttachments([])
    dispatch(getPODataOver1Million(currentPage, itemsPerPage, orderBy, orderDir, type, selectedDateRange[0], selectedDateRange[1], searchValues));
  };
//function to handle send po popup and attachments passing
  const handleSendPo=(data)=>{
    setPoDbId(data?.PoDbId)
    setAttachments(data?.adminPoAttachments || [])
    setIsReadyToOpenModal(true);
    
  }


  const ExpandedComponent = ({ data }) => (
    <pre>
      <SendPOExpandedTable data={data?.poPrData} PurchasingGroup
={data?.PurchasingGroup} />
    </pre>
  );

  const columnWidths = {
    poPrData: "10%",
    PR_NO: "8%",
    PR_Title: "10%",
    POCurrency: "8%",
    PONumber: "10%",
    POTotalAmount:"10%",
    Deliverydate: "10%",
    PCMPersonInCharge: "10%",
    
  };

  const columns = [
    {
      id:"poPrData",
      name: "Location",
      selector: (result) => result?.poPrData?.[0]?.PRLocation || "",
      searchable: true,
      width:"10%"
    },
    {
      id:"PrNumber",
      name: "PR No.",
      selector: (result) => result?.poPrData?.length>0 ?(result?.poPrData?.length>1?`Combined (${result?.poPrData?.length || "0"} )`:result?.poPrData?.[0]?.PrNumber):"",
      searchable: true,
      width: "8%",
    },
    {
      id: "PrShortText",
      name: "PR Title",
      selector: (result) => result?.poPrData?.length>0 ?(result?.poPrData?.length>1?`Combined (${result?.poPrData?.length || "0"} )`:result?.poPrData?.[0]?.PrShortText):"",
      searchable: true,
      width:"14%",
    },
    {
      id: "POCurrency",
      name: t("Currency"),
      selector: (result) => result?.POCurrency || "",
      searchable: true,
      sortable: true,
      width: "7%",
    },
    {
      id: "PONumber",
      name: t("PO No."),
      selector: (result) => result?.PONumber || "",
      searchable: true,
      sortable: true,
      width: "12%",
    },
    {
      id: "POTotalAmount",
      name: t("PO Amount"),
      selector: (result) => formatNumberWithCommasandDecimals(result?.POTotalAmount) ||"",
      searchable: true,
      sortable: true,
      width: "10%",
    },
    {
      id: "PrDeliveryDate",
      name: "Delivery date(PO)",
      width:"10%",
      selector: (row) => {
        if(row?.poPrData){
          const dates = row?.poPrData.map(item => new Date(item.PrDeliveryDate));
          const maxDate = new Date(Math.max(...dates));
          return DateGenerator(maxDate) ; 
        }
        return ""
        
      },
      searchable: true,
    },
    {
      id: "PCMPersonInCharge",
      name: "Buyer",
      width:"10%",
      selector: (result) => result?.PCMPersonInCharge || "",
      searchable: true,
    },

    {
      id: "Action",
      name: t("Action"),
      cell: (result) => (
        <button className="action-view-table-btn" onClick={()=>{
          handleSendPo(result)
        }}>
          {t("Send PO")}
        </button>
      ),
      searchable: false,
    },
  ];
  // const handleChangeView = (result) => {
  //   navigate("/history-storage-view");
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

 
  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      
      dispatch(getPODataOver1Million(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };

  const handleTypeSelect = (selectedKey) => setType(selectedKey);



  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange?.length > 0) {
      setSelectedDateRange([]);
    }
  };
  //sendpo popup
  const [SendPoModalOpen, SetSendPoIsModalOpen] = useState(false);
  const SendPoOpenModal = () => {
    SetSendPoIsModalOpen(true);
  };
  const SendPoCloseModal = () => {
    SetSendPoIsModalOpen(false);
  };

  
  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
  };

  

  const headerNames = columns.map((column) => column.name);
  const csvData = data?.map((item) =>
    columns.reduce((row, column) => {
      row[column.name] = column.selector ? column.selector(item) : item[column.name] || '';
      return row;
    }, {})
  ) || []; 



  return (
    <>
    {( loading) && <PageLoader/>}
      <div className="um-table-header-div">
        <h1 className="hs-title" style={{ margin: "2% 0% 0% 2.1% " }}>
          {"Send PO (Over 10 million, excl. Vat)"}
        </h1>
        <div className="um-table-head">
          <div className="history-table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "All"
                      ? "history-supplier-visit-table-active"
                      : "history-supplier-visit-table"
                  }
                  eventKey="All"
                >
                  {t("All")}
                  <span
                    className={
                      type === "All"
                        ? "history-supplier-visit-table-badge-active"
                        : "history-supplier-visit-table-badge"
                    }
                    eventKey="All"
                  >
                    {count && count}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <DateRangePicker
              appearance="default"
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>{t("Show")} </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>{t("entries")}</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="po_table"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            data?.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            className="history-table"
            columns={columns}
            data={data}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows={true}
            expandableRowDisabled={(row) => !row?.poPrData || row?.poPrData?.length<2}
            expandableRowExpanded={(row) => row.poPrData && row?.poPrData?.length>1}
            expandableRowsComponent={ExpandedComponent}
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="history-table-search-div"
          >
            <div style={{ marginLeft: "20px" }}>
              {columns.map((column) =>
                column.searchable !== false ? (
                  <input
                    key={column.id}
                    type="text"
                    value={searchValues[column.id] || ""}
                    style={{ width: column.width ,margin:"1px 4px" }}
                    onChange={(event) => handleSearchChange(event, column.id)}
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <SendPoPopup
        SendPoIsOpen={SendPoModalOpen}
        SendPoFormTitle={"Send PO (Over 1 million, excl. Vat)"}
        PoDbId={PoDbId}
        attachments={attachments}
        SendPoCloseModal={SendPoCloseModal}
        onFileChange={handleFileChange}
      />
    </>
  );
};

export default SendPOTable;
