import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_ALL_GROUPS } from "../../ApiEndPoints/ApiEndPoints";

export const getAllGroupList = createAsyncThunk("GroupList", async () => {
  const userToken = localStorage.getItem("vms_admin_token");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(`${BaseUrl}${GET_ALL_GROUPS}`, requestOptions);
    const result = await response.json();
    return result.data;
  } catch (error) {
    
    throw error;
  }
});

export const myReducer = createSlice({
  name: "GroupList",
  initialState: {
    loading: false,
    GroupList: [],
  },
  reducers: {
    updateSelectGroupList: (state, action) => {
      state.selectedGroupId = action.payload;
    },
  },
  extraReducers: {
    [getAllGroupList.pending]: (state) => {
      state.loading = true;
    },
    [getAllGroupList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.GroupList = payload;
    },
  },
});

export const { updateSelectGroupList } = myReducer.actions;

export default myReducer.reducer;
