import { useTranslation } from "../../Components/Imports/import";
import React, { useState } from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import "./PressRelease.css";
import plusIcon from "../../Assets/Images/plus-ico.png";
import PressReleasePopup from "../../Models/PressReleasePopup/PressReleasePopup";
import PressReleaseTable from "../../Components/DataTable/PressReleaseTable";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../Models/PageLoader/PageLoader";

const PressRelease = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.getPressReleaseInfo);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DefaultLayout>
      {loading && <PageLoader />}

      <section className="um-main">
        <div className="um-title-btn">
          <h2 className="um-title">{t("Press Release")}</h2>
          <button className="table-btn create-btn" onClick={openModal}>
            <img style={{ marginRight: "10px" }} src={plusIcon} alt="+" />
            Create Press Release
          </button>
        </div>
        <PressReleaseTable />
      </section>

      <PressReleasePopup
        isOpen={isModalOpen}
        formTitle={"Create Press Release"}
        closeModal={closeModal}
        scenarioType={"createPressRelease"}
      />
    </DefaultLayout>
  );
};

export default PressRelease;
