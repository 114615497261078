import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import RegistrationReducers from "./Reducer/registrationReducers";
import countryList from "./Reducer/countryList";
import vendorTable from "./Reducer/vendorTable";
import singleVendorDetails from "./Reducer/singleVendorDetails";
import updateVendorReducer from "./Reducer/updatedVendor_Reducer";
import getUserManagementInfo from "./Reducer/userManagement_Reducer";
import getPressReleaseInfo from "./Reducer/pressReleaseReducer";
import allGroupList from "./Reducer/allGroupList";
import deleteParticularVendor from "./Reducer/vendorManagmentReducer";
import allGroupListForUser from "./Reducer/allGroupListForUser";
import loginDetails from "./Reducer/loginDetails";
import getPurchaseGroupInfo from "./Reducer/purchaseGroup_Reducer";
import getPrReleaseInfo from "./Reducer/prReleaseReducer";
import getJobManagementInfo from "./Reducer/jobManagementReducer";
import getSupplierManagementInfo from "./Reducer/supplierManagementReducer";
import DashboardReducer from "./Reducer/dashboardReducer";
import HistoryStorageReducer from "./Reducer/historyStorageReducer";

var reducers = combineReducers({
  registration: RegistrationReducers,
  countryList: countryList,
  vendorTableList: vendorTable,
  singleVendor: singleVendorDetails,
  updateVendorReducer: updateVendorReducer,
  getUserManagementInfo: getUserManagementInfo,
  getPressReleaseInfo: getPressReleaseInfo,
  allGroupList: allGroupList,
  deleteParticularVendor: deleteParticularVendor,
  allGroupListForUser: allGroupListForUser,
  loginDetails: loginDetails,
  getPurchaseGroupInfo: getPurchaseGroupInfo,
  getPrReleaseInfo: getPrReleaseInfo,
  getJobManagementInfo: getJobManagementInfo,
  getSupplierManagementInfo: getSupplierManagementInfo,
  DashboardReducer:DashboardReducer,
  HistoryStorageReducer:HistoryStorageReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});
