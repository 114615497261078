import { useSelector } from "react-redux";

const useIsEditFieldMandatory = () => {
  const { singleVendorDetails } = useSelector((state) => state.singleVendor);

  // from the userInfo object getting the commercial registration type
  
  const commercialRegType =
    singleVendorDetails && singleVendorDetails?.commercial_reg_type;

  //     from the commercialRegType extracting 1st index value
  const extractedValue =
    commercialRegType && commercialRegType?.toString().match(/\((.*?)\)/)?.[1];
  const isFieldMandatory = extractedValue === "L";

  return isFieldMandatory;
};

export default useIsEditFieldMandatory;
