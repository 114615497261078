import TYPES from "../constants";
import axios from "axios";
import { ADMIN_UPDATE_VENDOR } from "../../ApiEndPoints/ApiEndPoints";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";

export const adminUpdateVendor = (updatedVendor) => {

  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: ` Bearer ${token}`,
      };
      const response = await axios.patch(
        `${BaseUrl}${ADMIN_UPDATE_VENDOR}`,
        updatedVendor,
        { headers }
      );
   
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.UPDATE_VENDOR,
          payload: response.data,
        });
       
        return response;
      } else {
        dispatch({
          type: TYPES.UPDATE_VENDOR_FAILURE,
          payload: null,
          error: "Something went wrong , please try again",
        });

        return response;
      }
    } catch (error) {
      dispatch({
        type: TYPES.UPDATE_VENDOR,
        payload: null,
        error: error.message,
      });
      return error;
    }
  };
};

export const latestVendorInfo = (latestInfo) => {
  return {
    type: TYPES.LATEST_VENDOR_INFO,
    payload: latestInfo,
  };
};

export const clearLatestVendorInfo = () => {
  return {
    type: TYPES.CLEAR_LATEST_VENDOR_INFO,
    payload: null,
  };
};

export const clearStoreInfo = () => {
  return {
    type: TYPES.CLEAR_STORE_INFO,
  };
};
