import React from "react";
import "./HistorySupplierVisitTable.css";
import {useTranslation} from "../../../src/Components/Imports/import";
import { formatNumber } from "../../Utils/Utils";

const HistoryStorageExpandedTable = ({ data,PurchasingGroup }) => {

  const { t } = useTranslation();
  return (
    data && (
      <div className="history-expaneded-table-div">
        <table className="history-expanded-table">
          <thead>
            <tr>
              <th></th>
              <th>{t("Purchase Requisition")}</th>
              <th>{t("Description")}</th>
              <th>{t("PRG")}</th>
              <th>{t("Item")}</th>
              <th>{t("Quantity")}</th>
              <th>{t("Unit")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row, index) => (
                <tr key={index}>
                  <td></td>
                  <td>{row?.PrNumber}</td>
                  <td>{row?.PrShortText}</td>
                  <td>{PurchasingGroup}</td>
                  <td>{row?.PrItemNumber}</td>
                  <td>{formatNumber(row?.PrQTY)}</td>
                  <td>{row?.PRUnit}</td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  );
};

export default HistoryStorageExpandedTable;
