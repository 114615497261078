import React, { useEffect } from 'react';
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import HistoryStorageNewTable from "../../Components/DataTable/HistoryStorageNewTable";
import VectorArrow from "../../Assets/Images/backArrow.png"; 
import { useNavigate, useParams } from "react-router-dom";
// import "./HistoryStorage.css";
import { toast, useTranslation} from "../../../src/Components/Imports/import";


const HistoryStorageNew = () => {
  const { id, code,evaluationId } = useParams();
  const { t } = useTranslation();
  const handleGoBack = () => {
    navigate(-1);
  };
  const navigate = useNavigate();
  
  return (
    <DefaultLayout>
      <section className="um-main">
        <div className="um-title-btn">
          <div
          
            style={{
              color: '#103A5D',
              fontSize: 24,
              fontFamily: 'Sarabun',
              cursor: 'pointer',
              fontWeight: '700',
              textTransform: 'capitalize',
              wordWrap: 'break-word',
              marginLeft: 8,
              display: "flex",
              alignItems: "center",
              gap: '10px',
            }}
            onClick={handleGoBack}
          >
            <img src={VectorArrow} />
            {t("back")}
          </div>
        </div>
        <h1 className="hs-title">{t("History Storage")}</h1><br></br>
        <HistoryStorageNewTable id={id} code={code} evaluationId={evaluationId}/>
      </section>
    </DefaultLayout>
  );
};

export default HistoryStorageNew;
