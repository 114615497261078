import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_ALL_GROUPS_FOR_USER_CREATION } from "../../ApiEndPoints/ApiEndPoints";

export const getAllGroupListForUser = createAsyncThunk(
  "GroupListForUser",
  async () => {
    const userToken = localStorage.getItem("vms_admin_token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };
      const response = await fetch(
        `${BaseUrl}${GET_ALL_GROUPS_FOR_USER_CREATION}`,
        requestOptions
      );
      const result = await response.json();
      return result.data;
    } catch (error) {
      
      throw error;
    }
  }
);

export const myReducer = createSlice({
  name: "GroupList",
  initialState: {
    loading: false,
    GroupListForUser: [],
  },
  reducers: {
    updateSelectGroupListForUser: (state, action) => {
      state.selectedGroupId = action.payload;
    },
  },
  extraReducers: {
    [getAllGroupListForUser.pending]: (state) => {
      state.loading = true;
    },
    [getAllGroupListForUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.GroupListForUser = payload;
    },
  },
});

export const { updateSelectGroupListForUser } = myReducer.actions;

export default myReducer.reducer;
