const tableStyles = {
  headCells: {
    style: {
      backgroundColor: "#103A5D",
      color: "white",
      fontSize: "14px",
      fontFamily: "Sarabun",
      fontWeight: "400",
      width: "100%",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "12px",
      color: "#103a5d",
      height: "auto",
      fontWeight: "400",
      letterSpacing: "0.5px",
    },
  },
  cells: {
    style: {
      fontSize: "12px",
      fontWeight: "500",
    },
  },
};

module.exports = {
  tableStyles,
};
