import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_SINGLE_VENDOR } from "../../ApiEndPoints/ApiEndPoints";

export const getSingleVendorDetails = createAsyncThunk(
  "singleVendorDetails",
  () => {
    const userToken = localStorage.getItem("vms_admin_token");
    const vendor_id = localStorage.getItem("vendor_id");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`${BaseUrl}${GET_SINGLE_VENDOR}${vendor_id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        return result.data[0];
      })
  }
);

export const myReducer = createSlice({
  name: "singleVendorDetails",
  initialState: {
    loading: false,
    selectedVendorId: "",
    singleVendorDetails: {},
  },
  reducers: {
    UpdateSelectedVendorId: (state, action) => {
      state.selectedVendorId = action.payload;
    },
    UpdateSelectedSingleVendorData: (state, action) => {
      state.selectedSingleVendorData = action.payload;
    },

    clearSingleVendorData: (state, action) => {
      state.singleVendorDetails = {};
    },
  },
  extraReducers: {
    [getSingleVendorDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleVendorDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.singleVendorDetails = payload;
    
    },
  },
});

export const {
  UpdateSelectedVendorId,
  UpdateSelectedSingleVendorData,
  clearSingleVendorData,
} = myReducer.actions;

export default myReducer.reducer;
