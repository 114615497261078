import {
  React,
  useState,
  useForm,
  useDispatch,
  useSelector,
  PageLoader,
} from "../../Components/Imports/import";
import Modal from "react-modal";
import CloseIcon from "../../Assets/Svg/close-icon.svg";
import "./PenaltyRejectPopup.css";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
import doc from "../../Assets/Images/doc-icon.png";


//need to update actual logic for api integration
import { useEffect } from "react";
import { acceptPenalty, getPenaltyById, rejectPenalty } from "../../Store/actions/supplierManagementActions";


// PenaltyRejectPopup Component
const PenaltyRejectPopup = ({
    PenaltyRejectIsOpen,
    PenaltyRejectCloseModal,
  PenaltyRejectFormTitle,
  id
}) => {
  const dispatch = useDispatch();
  const [data,setData]=useState([])

const { getPenaltyByIdData,loading,error } = useSelector(
  (state) => state.getSupplierManagementInfo
);
useEffect(()=>{
    if(id){
      dispatch(getPenaltyById(id))
    }
   

  
},[PenaltyRejectIsOpen,id])

useEffect(()=>{
  setData(getPenaltyByIdData?.data)
},[getPenaltyByIdData])
  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState();

  
  const penaltyRejection = data?.penaltyRejection || {};
  const attachmentNames = penaltyRejection?.attachmentName?.split(',') || [];
  const attachmentUrls = penaltyRejection?.attachmentUrl?.split(',') || [];


const attachments = attachmentNames?.map((name, index) => ({
    attachmentName: name,
    attachmentUrl: attachmentUrls[index]
}));




  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    // Create a new instance of FormData
    const formData = new FormData();

    setShowAreYouSurePopup(true);
    // if (scenarioType === "createPressRelease") {
    //   dispatch(createPressRelease(formData));
    // } else if (scenarioType === "editPressRelease") {
    //   dispatch(editParticularPressRelease(formData));
    //   setIsEditMode(true);
    // }
  };
  const closeAreYouSure = () => {
    setShowAreYouSurePopup(false);
  };

  const onClickAccept = async () => {
    dispatch(acceptPenalty(id))
    PenaltyRejectCloseModal();
  };
  const onClickReject = async () => {
    dispatch(rejectPenalty(id))
    PenaltyRejectCloseModal();
  };

  return (
    <>
     {( loading) && <PageLoader/>}
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={PenaltyRejectIsOpen}
          onRequestClose={PenaltyRejectCloseModal}
          className="create-user-modal create-penalty-rej-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="create-user-title">
            <div className="d-flex">
              <h2 style={{ marginRight: "20px" }}>{PenaltyRejectFormTitle}</h2>
            </div>
            <img src={CloseIcon} alt="" onClick={() => PenaltyRejectCloseModal()} />
          </div>
          <form className="create-user-form">
            <br/>
            <label>Description</label>
            <br />
            <textarea
              className="penalty-rej-description"
              type="text"
              value={data?.penaltyRejection?.description}
              readOnly
            />{" "}
            <br />
            
            <br />
            <div className="prej-attachment-files">
              <label>{attachments?.length || 0} Attachment</label>
              <label>Download All</label>
            </div>
            <div className="prej-attach-logo">
              {attachments?.map((file,index)=>(
                <a key={index} href={file?.attachmentUrl} target="_blank" rel="noreferrer">
                  <img className="prej-doc-logo" src={doc} alt={file?.attachmentName} />
                </a>
                
              ))}
               
               
              </div>
            <br />
            <div className="pt-3 create-btns-div">
              <button
                type="button"
                className="um-create-btn"
                onClick={onClickReject}
              >
                Reject
              </button>

              <button
                onClick={onClickAccept}
                className="um-submit-btn"
                // disabled={!!fileErrorMsg || Object.keys(errors).length > 0}
              >
                Accept
              </button>
            </div>
          </form>
        </Modal>
      </div>

      <CustomPopup
        isOpenPopup={showAreYouSurePopup}
        closePopup={closeAreYouSure}
        image={areIcon}
        title="Are you sure!"
        text={
          id
            ? "You want to Update this PO ?"
            : "You want to Reject this Penalty ?"
        }
        btn1="Cancel"
        btn2="Confirm"
        successTitle="Done!"
        successText={
          id
            ? "Pennalty Details Updated Successfully"
            : "Pennalty Reject successfully"
        }
        successIcon={successIcon}
        btn3="Ok"
        closeFormPopup={PenaltyRejectCloseModal}
        reset={reset}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        scenarioType={id ? "editPressRelease" : "createPressRelease"}
       
      />
    </>
  );
};

export default PenaltyRejectPopup;
