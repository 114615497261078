import TYPES from "../constants";

const initialState = {
  error: null,
  loading: false,
  vendorStats:[],
  data:{},
  prStats:{},
  prData:{},
  poStats:[],
  poData:{},
  supplierData:{},
  statsLoading:false,
  poStatsLoading:false,
  prStatsLoading:false,
  prStatsError:null,
  poStatsError:false,
  statsError:null,
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ADMIN_DASHBOARD_STATUS_COUNT_LOADING:
        return{
            ...state,
            statsLoading:true,
            statsError:null,
        }
    case TYPES.ADMIN_DASHBOARD_PR_STATS_LOADING:
        return{
            ...state,
            prStatsLoading:true,
            prStatsError:null,
        }
    case TYPES.ADMIN_DASHBOARD_PO_STATS_LOADING:
        return{
            ...state,
            poStatsLoading:true,
            poStatsError:null,
        }
    case TYPES.ADMIN_VENDOR_REGISTER_LOADING:
    case TYPES.ADMIN_DASHBOARD_PR_DATA_LOADING:
    case TYPES.ADMIN_DASHBOARD_PO_DATA_LOADING:
    case TYPES.ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_LOADING:
        return{
            ...state,
            loading:true,
            error:null,
        }
    case TYPES.ADMIN_DASHBOARD_STATUS_COUNT_SUCCESS:
        return{
            ...state,
            statsLoading:false,
            vendorStats:action.payload,
            statsError:null,
        }
    case TYPES.ADMIN_DASHBOARD_PR_STATS_SUCCESS:
            return{
                ...state,
                prStatsLoading:false,
                prStats:action.payload,
                prStatsError:null,
            }
    case TYPES.ADMIN_VENDOR_REGISTER_SUCCESS:
        return{
            ...state,
            loading:false,
            error:null,
            data:action.payload,
        }
    case TYPES.ADMIN_DASHBOARD_PR_DATA_SUCCESS:
        return{
            ...state,
            loading:false,
            error:null,
            prData:action.payload,
        }
    case TYPES.ADMIN_DASHBOARD_PO_STATS_SUCCESS:
         return{
            ...state,
            poStatsLoading:false,
            poStats: action.payload,
            poStatsError:null,

        }
    case TYPES.ADMIN_DASHBOARD_PO_DATA_SUCCESS:
        return{
            ...state,
            loading: false,
            error: null,
            poData: action.payload,
            
        }
    case TYPES.ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_SUCCESS:
        return{
            ...state,
            loading:false,
            error:null,
            supplierData:action.payload,
        }
    case TYPES.ADMIN_DASHBOARD_STATUS_COUNT_FAILURE:
        return{
            ...state,
            statsLoading:false,
            statsError:action.error,
        }
    case TYPES.ADMIN_DASHBOARD_PR_STATS_FAILURE:
        return{
            ...state,
            prStatsLoading:false,
            prStatsError:action.error,
        }
        case TYPES.ADMIN_DASHBOARD_PO_STATS_FAILURE:
            return{
                ...state,
                poStatsLoading:false,
                poStatsError:action.error,
            }
    case TYPES.ADMIN_VENDOR_REGISTER_FAILURE:
    case TYPES.ADMIN_DASHBOARD_PR_DATA_FAILURE:
    case TYPES.ADMIN_DASHBOARD_PO_DATA_FAILURE:
    case TYPES.ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_FAILURE:
        return{
            ...state,
            loading:false,
            error:action.error,
        }
    default:
      return state;
  }
};

export default DashboardReducer;
