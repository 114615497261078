import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./SupplierVisitTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../../Assets/Svg/arrow-icon.svg";
import { useTranslation } from "react-i18next";
import { tableStyles } from "../../../Utils/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendorData, fetchVendorStats } from "../../../Store/actions/dashboardAction";
import { PageLoader } from "../../Imports/import";
import { DateGenerator } from "../../../Utils/Utils";

const SupplierVisitTable = () => {
  const dispatch =useDispatch();

  const {data:vendorData,error,loading,vendorStats,statsLoading,statsError}=useSelector((state)=>state.DashboardReducer)

 
 
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('updated_at');
  const [orderDir, setOrderDir] = useState('DESC');
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({
    all: [],
    newVendor: [],
    approved: [],
    rejected: [],
  });
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [filteredData, setFilteredData] = useState([]);
  const [count,setCount]=useState( vendorStats?.reduce((total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0), 0) ||0)


  useEffect(()=>{
   
      dispatch(fetchVendorStats())
  
    
    dispatch(fetchVendorData(currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues))
    
    
  },[currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues])

 useEffect(()=>{
  if (type === "all") {
    setData((prevState) => ({
      ...prevState,
      all: vendorData?.data || [],
    }));
  }
  else if (type === "Pending") {
    setData((prevState) => ({
      ...prevState,
      newVendor: vendorData?.data || [],
    }));
  }
  else if (type === "Approved") {
    setData((prevState) => ({
      ...prevState,
      approved: vendorData?.data || [],
    }));
  }
  else if (type === "Rejected") {
    setData((prevState) => ({
      ...prevState,
      rejected: vendorData?.data || [],
    }));
  }
  setCount(vendorData?.TotalCount);
 },[vendorData,count])
 useEffect(()=>{
  type==="all"?setFilteredData(data?.all|| []):type==="Pending"?setFilteredData(data?.newVendor|| []):type==="Approved"?setFilteredData(data?.approved|| []):type==="Rejected"?setFilteredData(data?.rejected|| []):setFilteredData([])
 },[data,type])



  const columnWidths = {
    VendorCode: "12%",
    CommercialRegisterType: "20%",
    TypeofBusiness: "15%",
    Purchasinggroup: "15%",
    RegisterDate: "15%",
  };
  const columns = [
    {
      id: "vendor_code",
      name: "Vendor Code",
      selector: (result) => result?.vendor_code || "",
      searchable: true,
    },
    {
      id: "commercial_reg_type",
      name: "Commercial Register Type",
      selector: (result) => result?.commercial_reg_type || "",
      searchable: true,
    },
    {
      id: "type_of_business",
      name: "Type of Business",
      selector: (result) => result?.type_of_business || "",
      searchable: true,
    },
    {
      id: "Purchasinggroup",
      name: "Purchasing group",
      selector: (result) => result?.Purchasinggroup || "",
      searchable: true,
    },
    {
      id: "created_at",
      name: "Register Date",
      selector: (result) => DateGenerator(result?.created_at)  || "",
      searchable: true,
    },
    {
      id: "vendor_status",
      name: "Status",
      searchable: false,
      cell: (result) => (
        <div
          className={
            result?.vendor_status === "Approved"
              ? "table-status approved"
              : result?.vendor_status === "Rejected"
              ? "table-status rejected"
              : result?.vendor_status === "Pending"
              ? "table-status newvendor"
              : ""
          }
        >
          {" "}
          {result?.vendor_status==="Pending"? "New Vendor" : result?.vendor_status || "N/A"}
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      dispatch(fetchVendorData(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };


  const handleTypeSelect = (selectedKey) => {
    setCurrentPage(1);
    setType(selectedKey);

  }

  //date changes
  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
    }
  };
  

  // const filteredData = filterData();

  // const filteredDataByType =
  //   type === "all"
  //     ? filteredData
  //     : filteredData.filter((item) => item.status === type);

  

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count), 
  };

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const headerNames = columns.map((column) => column.name);
  const csvData =
  filteredData?.map((item) =>
    columns.reduce((row, column) => {
      row[column.name] = column.selector ? column.selector(item) : item[column.id] || '';
      return row;
    }, {})
  ) || []; 
  

  return (
    <>
   {(statsLoading || loading) && <PageLoader/>}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "all"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="all"
                >
                  All
                  <span
                    className={
                      type === "all"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="all"
                  >
                   {type==="all"?count:vendorStats?.reduce((total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0), 0) ||0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Pending"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Pending"
                >
                  New vendor
                  <span
                    className={
                      type === "Pending"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Pending"
                  >
                    {type==="Pending"?count:vendorStats?.find(stat => stat?.vendor_status === "Pending")?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Approved"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Approved"
                >
                  Approved
                  <span
                    className={
                      type === "Approved"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Approved"
                  >
                    {type==="Approved"?count:vendorStats?.find(stat => stat?.vendor_status === "Approved")?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Rejected"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Rejected"
                >
                  Rejected
                  <span
                    className={
                      type === "Rejected"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Rejected"
                  >
                    {type==="Rejected"?count:vendorStats?.find(stat => stat?.vendor_status === "Rejected")?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <DateRangePicker
              appearance="default"
              //   style={{width:"200px"}}
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
              disabled={loading}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>Show </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>entries</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="VendorRegisterAndStatus.csv"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            filteredData.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
              
              display:"flex",
              gap:"1.5vw",
              
            }}
            className="table-search-div"
          >
            {columns.map((column) =>
              column.searchable !== false ? (
                <input
                key={column.id}
                  type="text"
                  value={searchValues[column.id] || ""}
                  style={{ width: columnWidths[column.name] }}
                  onChange={(event) =>
                    handleSearchChange(event, column.id)
                  }
                />
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default SupplierVisitTable;
