import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRouter";
import routes from "./routes";
import ErrorBoundary from "./ErrorBoundary";
import LazyLoadingFallback from "./LazyLoadingFallback";
import { useDispatch, useSelector } from "react-redux";
import { getAllLoginDetails } from "./Store/Reducer/loginDetails";
import NotFound from "./NotFound";
import PageLoader from "./Models/PageLoader/PageLoader";

function App() {
  const { LoginDetails,loading } = useSelector((state) => state.loginDetails);
  const dispatch = useDispatch();
  const [assignerType,setAssignerType]=useState([]);
  useEffect(()=>{
      dispatch(getAllLoginDetails());
  },[])
  useEffect(()=>{
    const getAssignerType= LoginDetails?.assigner_type ?LoginDetails?.assigner_type.split(",") :[]
    setAssignerType(getAssignerType)
  },[LoginDetails])

 
  return (
    <div className="App">
      {loading && <PageLoader/>}
      <Router>
        <ToastContainer />
        <ErrorBoundary>
          <Suspense fallback={<LazyLoadingFallback />}>
            <Routes>
              {routes.map((route, index) => {
           const routeAssignerType = route?.assignerType || [];

          const hasAccess = routeAssignerType.length === 0 || assignerType?.some((type) => !routeAssignerType.includes(type));
                return(

                <Route
                  key={index}
                  path={route.path}
                  element={
                    hasAccess ?  
                    (route.isProtected  ? (
                      <ProtectedRoute component={route.component} />
                    ) : (
                      <route.component />
                    )
                  )
                  : <NotFound/>
                  }
                />
              )})}
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;
