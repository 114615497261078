import React from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
// import HistoryStorageTable from "../../Components/DataTable/HistoryStorageTable";
import SupplierManagementTable from "../../Components/DataTable/SupplierManagementTable/SupplierManagementTable";
import EvaluationTable from "../../Components/DataTable/SupplierManagementTable/EvaluationTable";
import SendPOTable from "../../Components/DataTable/SupplierManagementTable/SendPOTable";
import "./SupplierManagement.css";
import { useTranslation } from "../../../src/Components/Imports/import";

const SupplierManagement = () => {
  const { t } = useTranslation();
  return (
    <DefaultLayout>
      <section className="um-main">
        <div className="um-title-btn"></div>
        <h1 className="hs-title">{t("Supplier Management")}</h1>
        <br></br>
        {/* <HistoryStorageNewTable/> */}
        <SupplierManagementTable />
        <br></br>
        <EvaluationTable />
        <br></br>
        <SendPOTable />
      </section>
    </DefaultLayout>
  );
};

export default SupplierManagement;
