import {
  React,
  useDispatch,
  useSelector,
  useEffect,
  useForm,
  toast,
} from "../../Components/Imports/import";

import Modal from "react-modal";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./RejectVendorPopup.css";
import { getAllGroupList } from "../../Store/Reducer/allGroupList";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import Http from "../../HttpClients/HttpClients";
import { getVendorListTableData } from "../../Store/Reducer/vendorTable";
import { VENDOR_REJECT } from "../../ApiEndPoints/ApiEndPoints";

const RejectVendorPopup = ({
  isOpenPopup,
  closePopup,
  userData,
  setPageLoading,
}) => {
  const dispatch = useDispatch();
  const { GroupList } = useSelector((state) => state.allGroupList);
  const userToken = localStorage.getItem("vms_admin_token");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //   getting the object by current_group_name
  function findGroupByName(groupName) {
    return GroupList.find((e) => e.group_name === groupName) || null;
  }

  const groupNameToFind = userData?.current_group_name;
  const foundGroup = findGroupByName(groupNameToFind);

  const onSubmit = (data) => {
    setPageLoading(true);
    const vendorID = userData?.vendor_id.toString();
    const requestBody = {
      vendor_id: vendorID,
      next_group_name: foundGroup?.after_reject_transfer,
      current_group_name: foundGroup?.group_name,
      rejection_description: data.rejection_description,
    };
    const url = `${BaseUrl}${VENDOR_REJECT}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    Http.post(url, requestBody, config)
      .then((result) => {
        setPageLoading(false);
        if (result.status === 200) {
          toast.success(result.message);
          closePopup();
          reset();
          dispatch(getVendorListTableData());
        } else if (result.status === 401) {
          toast.error(result.message);
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        setPageLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
  useEffect(() => {
    dispatch(getAllGroupList());
  }, [isOpenPopup]);

  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={isOpenPopup}
        onRequestClose={closePopup}
        className="create-user-modal"
        overlayClassName="create-modal-overlay"
      >
        <div className="ts-title-close-icon-div">
          <p className="ts-title">Reject</p>
          <img
            src={closeIcon}
            alt=""
            onClick={() => closePopup()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="vi-name">to {foundGroup?.after_reject_transfer}</p>
          <p className="vi-name">description</p>
          <textarea
            className="vi-content"
            {...register("rejection_description", {
              required: true,
            })}
          ></textarea>
          {errors.rejection_description && (
            <span className="error-msg">Please enter the feedback</span>
          )}
          <div className="d-flex justify-content-end">
            <button className="um-submit-btn mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default RejectVendorPopup;
