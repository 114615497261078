import React, { useEffect, useState } from "react";
import "./EvaluatedPage.css";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader, toast, useDispatch, useSelector, useTranslation } from "../../../src/Components/Imports/import";
import VectorArrow from "../../Assets/Images/backArrow.png";
import { getEvaluationFormData, saveEvaluation } from "../../Store/actions/supplierManagementActions";
const evaluationCriteria = [
  {
    category: "การให้บริการ",
    subCriteria: [
      {
        title: "1.1 ผู้ให้บริการด้านสอบเทียบได้มาตรฐานตรงตามระบบคุณภาพ",
        ratings: [
          { score: 4, description: "ได้รับการรับรองระบบคุณภาพตามที่กำหนด" },
          { score: 3, description: "ได้รับการรับรองระบบคุณภาพไม่ตรงตามที่กำหนด แต่สามารถยอมรับได้" },
          { score: 2, description: "ได้รับการรับรองระบบคุณภาพไม่ตรงตามที่กำหนด แต่สามารถยอมรับได้บางส่วน" },
          { score: 1, description: "ไม่ได้รับการรับรองใดๆเลย" }
        ]
      },
      {
        title: "1.2 คุณภาพการให้บริการ",
        ratings: [
          { score: 4, description: "ได้มาตรฐานเป็นที่ยอมรับ ไม่มีข้อผิดพลาด" },
          { score: 3, description: "การให้บริการมีข้อผิดพลาด 1 ครั้ง" },
          { score: 2, description: "การให้บริการมีข้อผิดพลาด 2-3 ครั้ง" },
          { score: 1, description: "การให้บริการมีข้อผิดพลาดมากกว่า 3 ครั้ง" }
        ]
      },
      {
        title: "1.3 ความชำนาญและการให้ความรู้ของผู้ให้บริการ",
        ratings: [
          { score: 4, description: "ดีเยี่ยม" },
          { score: 3, description: "ดี" },
          { score: 2, description: "พอใช้" },
          { score: 1, description: "ต้องปรับปรุง" }
        ]
      },
      {
        title: "1.4 ความรวดเร็วและถูกต้องในการสนับสนุนด้านเทคนิค",
        ratings: [
          { score: 4, description: "ดีเยี่ยม" },
          { score: 3, description: "ดี" },
          { score: 2, description: "พอใช้" },
          { score: 1, description: "ต้องปรับปรุง" }
        ]
      }
    ]
  },
  {
    category: "การส่งมอบ",
    subCriteria: [
      {
        title: "2.1 การให้บริการตรงตามกำหนดและเสร็จสิ้นตามเวลาที่ระบุ",
        ratings: [
          { score: 4, description: "เสร็จสิ้นตามเวลาที่กำหนด" },
          { score: 3, description: "งานเสร็จหลังเวลาที่กำหนด 1 วัน" },
          { score: 2, description: "งานเสร็จหลังเวลาที่กำหนดภายใน 3 วัน" },
          { score: 1, description: "งานเสร็จหลังเวลาที่กำหนดเกินกว่า 3 วัน" }
        ]
      },
      {
        title: "2.2 เอกสารประกอบการส่งมอบสินค้า",
        ratings: [
          { score: 4, description: "สมบูรณ์ครบถ้วนสามารถอ้างอิงได้" },
          { score: 3, description: "สมบูรณ์สามารถอ้างอิงได้ มีจุดบกพร่องเล็กน้อย" },
          { score: 2, description: "ไม่สมบูรณ์แต่สามารถใช้อ้างอิงได้แต่ต้องแก้ไขหลายจุด" },
          { score: 1, description: "ไม่สมบูรณ์ครบถ้วนไม่สามารถใช้อ้างอิงได้" }
        ]
      },
      {
        title: "2.3 ความรวดเร็วในการส่งรายงาน",
        ratings: [
          { score: 4, description: "ตามเวลาที่กำหนด" },
          { score: 3, description: "ไม่เกิน 2 วัน" },
          { score: 2, description: "ไม่เกิน 5 วัน" },
          { score: 1, description: "เกินกว่า 5 วัน" }
        ]
      }
    ]
  },
  {
    category: "การบริการ",
    subCriteria: [
      {
        title: "3.1 การแก้ไขงาน",
        ratings: [
          { score: 4, description: "ไม่มีการแก้ไขงาน" },
          { score: 3, description: "สามารถส่งงานที่แก้ไขกลับภายใน 3 วัน" },
          { score: 2, description: "สามารถส่งงานที่แก้ไขกลับภายใน 5 วัน" },
          { score: 1, description: "สามารถส่งงานที่แก้ไขกลับเกินกว่า 5 วัน" }
        ]
      },
      {
        title: "3.2 การให้บริการเมื่อมีการเปลี่ยนแปลง",
        ratings: [
          { score: 4, description: "สามารถปรับเปลี่ยนเป็นไปตามความต้องการได้" },
          { score: 3, description: "สามารถปรับเปลี่ยนได้ภายในเวลา 3 วัน" },
          { score: 2, description: "สามารถปรับเปลี่ยนได้ภายในเวลา 5 วัน" },
          { score: 1, description: "ไม่สามารถปรับเปลี่ยนได้เลย" }
        ]
      },
      {
        title: "3.3 การดำเนินการเมื่อมีข้อเรียกร้อง",
        ratings: [
          { score: 4, description: "ดำเนินการได้ทันทีตามความต้องการ" },
          { score: 3, description: "ดำเนินการได้แต่ต้องใช้เวลา" },
          { score: 2, description: "ดำเนินการได้บางส่วน" },
          { score: 1, description: "ไม่สามารถดำเนินการได้เลย" }
        ]
      },
      {
        title: "3.4 การปฏิบัติตามกฏระเบียบของวีนิไทย",
        ratings: [
          { score: 4, description: "ปฏิบัติตามกฏระเบียบของวีนิไทยได้อย่างดี" },
          { score: 3, description: "ปฏิบัติได้แต่มีการตักเตือนด้วยวาจา" },
          { score: 2, description: "ปฏิบัติได้แต่มีการตักเตือนด้วยเอกสาร" },
          { score: 1, description: "ไม่สามารถปฏิบัติตามกฏระเบียบได้" }
        ]
      }
    ]
  }
];
function EvaluationPage() {
  const {formId,PoDbId,PoPrDbId,vendorId,poNo,vendorCode,dateStatus}=useParams();
  const [ratings, setRatings] = useState([]);
  const [ratingsData, setRatingsData] = useState([]);
    const dispatch=useDispatch();
    const [evaluatorEmail,setEvaluatorEmail]=useState("")
    const { evaluationFormById,loading,error } = useSelector(
      (state) => state.getSupplierManagementInfo
    );
    const [data,setData]=useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
  
      const today = new Date().toISOString().split('T')[0];
      setCurrentDate(today);
  }, []);

    useEffect(()=>{
      dispatch(getEvaluationFormData(formId))
    },[formId])
  

  

  const vendorInfo = [
    {
      label: "PO Number",
      value: poNo,
    },
    {
      label: "Vendor Code",
      value: vendorCode,
    },
   
  ];

  
  useEffect(() => {
    if (evaluationFormById?.topics) {
      setData(evaluationFormById?.topics);
      const initialRatings = evaluationFormById?.topics?.flatMap((topic) => {
        const topicRatings = topic.subTopics.map((subTopic) => ({
          rating: 0, 
          topicId: topic.topicId, 
          subTopicId: subTopic.subTopicId,
        }));
  
        return topicRatings;
      });
  
      setRatingsData(initialRatings);
    }
  }, [evaluationFormById]);
  

  
  //function to handle submit
  function handleSend(){
   
    if(vendorId==='null'){
      toast.error("Vendor Id Invalid")
      return;
    }
    if(poNo==='null'){
      toast.error("Po Number Invalid")
      return;
    }
    if(PoPrDbId==='null'){
      toast.error("PoPrDbId Invalid")
      return;
    }
    if(PoDbId==='null'){
      toast.error("PoDbId Invalid")
      return;
    }
    if(formId==='null'){
      toast.error("Evaluation form Invalid")
      return;
    }

    if(evaluatorEmail){
      const date = new Date();
  const isoDateString = date.toISOString();
  
      const bodyData={
  vendorCode:vendorCode,
  poNo: poNo,
  evaluatorEmail: evaluatorEmail,
  evaluatedDate: isoDateString,
  topic1Score:0, 
  topic1Percantages: data[0]?.topicWeight, 
  topic2Score: 0,
  topic2Percantages: data[1]?.topicWeight,
  topic3Score: 0,
  topic3Percantages: data[2]?.topicWeight,
  overAllPercantages: data[0]?.topicWeight+data[1]?.topicWeight+data[2]?.topicWeight || 0,
  overAllScore: 0,
  scoreLevel: "",
  evaluationFormId: formId,
  PoPrDbId: PoPrDbId,
  PoDbId: PoDbId,
  vendorId: vendorId,
  ratingsData:ratingsData,
      }

  if(bodyData){
    dispatch(saveEvaluation(bodyData))
    navigate('/supplier-management')
  }

    }
    else{
      toast.error("no mail")
    }
  }
  //function to handle checkbox click
  const handleCheckboxClick = (topicId, subTopicId, rowIndex, cellIndex) => {
    const rating = parseInt(evaHeaders[cellIndex]);

    setSelectedCheckboxes((prev) => ({
      ...prev,
      [rowIndex]: cellIndex,
    }));

    setRatings((prev) => {
      // Remove any existing rating for this subTopicId
      const updatedRatings = prev?.filter(
        (item) => item.subTopicId !== subTopicId
      );

      // Add the new rating
      updatedRatings.push({
        rating,
        topicId,
        subTopicId,
      });

      return updatedRatings;
    });
  };


  const evaHeaders = formId==2? ["No.", "Evaluation", "(%)", "4", "3", "2", "1","Not Applicable"]: ["No.", "Evaluation", "(%)", "4", "3", "2", "1"];
  const evaHeadersWidths =formId==2?["6%", "37%", "10%", "10%", "8%", "10%", "10%","18%"] :["6%", "47%", "10%", "10%", "8%", "10%", "10%"];

  const evaData = data.flatMap((topic, index) => {
    let labels;
    if (index === 1) {
      labels =formId==2?["", "", "", "",""]: ["Complete", "", "", "Incomplete"];
    } else if (index === 2) {
      labels =formId==2?["", "", "", "",""]:  ["Very Good", "", "", "Bad"];
    } else {
      labels =formId==2?["", "", "", "",""]:  ["OnTime", "", "", "Delayed"];
    }

  
    //structure for the topicRow
    const topicRow = {
      isTopic: true, 
      topicId: topic.id,
      subTopicId: null, 
      cells: [
        `${index + 1}.`,
        topic.topicName,
        `${topic.topicWeight}%`,
        ...labels
      ]
    };
  
   
    const subTopicRows = topic.subTopics.map((subTopic) => ({
      isTopic: false,
      topicId: topic?.topicId,
      subTopicId: subTopic?.subTopicId,
      cells: formId==2?[
        " ",
        subTopic.subTopicName,
        "", "", "", "", "",""
      ]:[
        " ",
        subTopic.subTopicName,
        "", "", "", "", ""
      ]
    }));
  
    return [topicRow, ...subTopicRows];
  });
  
  
  evaData.push({
    isTopic: false,
    cells:formId==2?[
      "",              
      "Column Totals", 
      "100%",          
      "", "", "", "",""
      ] : [
    "",              
    "Column Totals", 
    "100%",          
    "", "", "", "",
    ]  
});
  
  evaData.push({
    isTopic:false,
    cells:formId==2?[
      "",              
      "Grand Total", 
      "",          
      "", "", "", 0,"",  
    ]:[
    "",              
    "Grand Total", 
    "",          
    "", "", "", 0,  
  ]});

  
  const TOTAL_ROW_INDEX = evaData.length;
  const GRAND_TOTAL_ROW_INDEX = evaData.length;

  
  // Functions
  const shouldHideBorder = (cellIndex, rowIndex) => {
    return (
      (rowIndex === evaData.length && cellIndex >= 0 && cellIndex <= 1) ||
      (rowIndex === evaData.length+2 && cellIndex >= 0 && cellIndex <= 5)
    );
  };

  const getTextAlignStyle = (cellIndex, rowIndex) => {
    return (rowIndex === TOTAL_ROW_INDEX ||
      rowIndex === GRAND_TOTAL_ROW_INDEX) &&
      cellIndex >= 0
      ? "end"
      : "";
  };

  const isSpecialCell = (cellIndex, rowIndex,isTopic) => {
    if (isTopic) {
      return true;
    }
    else if(
      cellIndex === 2 && [1, 2, 3].includes(rowIndex)
    )
    return false;
  };

  const getBackgroundColor = (cellContent, cellIndex, rowIndex) => {
    if (
      cellContent === "" &&
      (formId==1?[2, 4, 5].includes(cellIndex) && //create an array of evaData length
      [1,2, 3, 4, 5, 6,7, 8, 9, 10,11,12,13,14,15,16].includes(rowIndex):
      
      [2].includes(cellIndex) && //create an array of evaData length
      [1,2, 3, 4, 5, 6,7, 8, 9, 10,11,12,13,14,15,16].includes(rowIndex))
      
    ) {
      return "#ADB2B9";
    } else if (rowIndex === evaData.length-2 && cellIndex >= 0 && cellIndex <= 1) {
      return "#adb2b9"; // Background color for column totals row
    } else if (rowIndex === evaData.length-1 && cellIndex >= 0 && cellIndex <= 5) {
      return "#adb2b9"; // Background color for grand total row
    } else if (rowIndex === 1 && cellIndex >= 6 && cellIndex <= 6 && formId==1) {
      return "#adb2b9"; // Background color for grand total row
    }
    return "";
  };

  const getCellStyle = (cellContent, cellIndex, rowIndex) => {
    if (rowIndex === TOTAL_ROW_INDEX && cellContent === "100%") {
      return {
        color: "#103a5d",
        textAlign: getTextAlignStyle(cellIndex, rowIndex),
        width: evaHeadersWidths[cellIndex],
        backgroundColor: getBackgroundColor(cellContent, cellIndex, rowIndex),
        border: shouldHideBorder(cellIndex, rowIndex) ? "none" : "",
      };
    } else {
      return {
        width: evaHeadersWidths[cellIndex],
        backgroundColor: getBackgroundColor(cellContent, cellIndex, rowIndex),
        border: shouldHideBorder(cellIndex, rowIndex) ? "none" : "",
        textAlign: getTextAlignStyle(cellIndex, rowIndex),
      };
    }
  };


  const EvaluatedTableRow = ({ rowIndex, row, topicId, subTopicId ,isTopic }) => (
    <div
      className={`evaluated-table-row ${
        rowIndex === TOTAL_ROW_INDEX || rowIndex === GRAND_TOTAL_ROW_INDEX
          ? "eva-tbl-total-row"
          : ""
      }`}
    >
      {row?.map((cellContent, cellIndex) => (
        <EvaluatedTableCell
          key={cellIndex}
          cellContent={cellContent}
          cellIndex={cellIndex}
          rowIndex={rowIndex}
          topicId={topicId} 
          subTopicId={subTopicId} 
          isTopic={isTopic}
        />
      ))}
    </div>
  );
  const EvaluatedTableCell = 
    ({ cellContent, cellIndex, rowIndex, topicId, subTopicId, isTopic }) => {
      const specialCell = isSpecialCell(cellIndex, rowIndex,isTopic);
      const style = getCellStyle(cellContent, cellIndex, rowIndex);
      const isCheckbox = !isTopic && cellContent === "" && (formId==1? ((evaHeaders[cellIndex] === "4" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||(evaHeaders[cellIndex] === "1" && evaluationFormById?.topics?.[0]?.subTopics?.length && rowIndex >= (evaluationFormById.topics[0].subTopics.length + 1) && rowIndex <= evaData.length - 3)):((evaHeaders[cellIndex] === "4" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||(evaHeaders[cellIndex] === "3" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||(evaHeaders[cellIndex] === "2" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||(evaHeaders[cellIndex] === "1" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||(evaHeaders[cellIndex] === "Not Applicable" && rowIndex >= 1 && rowIndex <= evaData.length - 3)))
    let isChecked = selectedCheckboxes[rowIndex] === cellIndex;
    if(formId==1 && dateStatus==1 && rowIndex===data[0]?.subTopics?.length){
      isChecked=true
    }
      return (
        <div
          className={`evaluated-table-cell ${specialCell ? "special-cell" : ""}`}
          style={style}
        >
          {isCheckbox ? (
            <input
              className="eva-table-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheckboxClick(topicId, subTopicId, rowIndex, cellIndex)}
            />
          ) : (
            <p style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{cellContent}</p>
          )}
        </div>
      );
    }
  ;
  
  
  
  //small table
  const EvaSmallHeaders = ["grade level", "criterion"];
  const EvaSmallData = [
    ["more than 90%", "excellent"],
    ["more than 80%", "good according to standard"],
    ["more than 70%", "fair level"],
    ["more than 60%", "acceptable"],
    ["more than 50%", "should improve"],
  ];
  const arrowImg=<svg width="299" height="9" viewBox="0 0 299 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M298.436 4.85355C298.631 4.65829 298.631 4.34171 298.436 4.14645L295.254 0.964466C295.059 0.769204 294.742 0.769204 294.547 0.964466C294.351 1.15973 294.351 1.47631 294.547 1.67157L297.375 4.5L294.547 7.32843C294.351 7.52369 294.351 7.84027 294.547 8.03553C294.742 8.2308 295.059 8.2308 295.254 8.03553L298.436 4.85355ZM0 5L298.082 5V4L0 4L0 5Z" fill="#103A5D"/>
  </svg>
  const EvaSmallData2 = [
    ["85-100%","=", "A",arrowImg
      ,"Exellent performance (ดีมาก)"],
    ["70-<84%","=","B",arrowImg, "Good performance (ดี)"],
    ["50-<69%","=","C",arrowImg, "Average performance (พอใช้)"],
    ["<50%","=", "D",arrowImg,"Below performance (พอใช้)"],
  ];

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/Supplier-Management");
  };
  return (
    <DefaultLayout>
      {loading && <PageLoader/>}
      <section className="um-main">
        <div className="um-title-btn">
          <div className="back-button" onClick={handleGoBack}>
            <img src={VectorArrow} alt="Back Arrow" />
            {t("back")}
          </div>
        </div>
        <br></br>
        <h1 className="hs-title">{t("Evaluated")}</h1>
        <br></br>

        <div className="evaluated-info-container">
         
            
              <input type="email"    className="evaluated-info-box" placeholder="Evaluator : " 
               value={evaluatorEmail}
               onChange={(e)=>setEvaluatorEmail(e.target.value)}/>
             
          
         
              <input type="date"    className="evaluated-info-box" placeholder="Evaluator : " value={currentDate}  readOnly/>
             
        </div>

        <br></br>

        <div className="evaluated-section">
          <div className="evaluated-vendor-title">
            <div className="evaluated-vendor-title-text">
              {t("Vendor/ Supplier Information")}
            </div>
          </div>
          <div className="evaluated-vendor-info-container">
            {vendorInfo.map((item) => (
              <div key={item.label} className="evaluated-vendor-info">
                <div className="evaluated-vendor-label">{item.label}:</div>
                <div className="evaluated-vendor-value">{item.value}</div>
              </div>
            ))}
          </div>
        </div>
        <br></br>
        {/* Table code */}
        <div className={`evaluated-table ${true ? "disabled-table" : ""}`}>
          <div className="evaluated-table-row eva-tbl-header-row">
            {evaHeaders.map((header, index) => (
              <div
                key={index}
                className={`evaluated-table-header-cell ${
                  index === 0 ? "header-no-cell" : ""
                } eva-tbl-header-textt`}
                style={{
                  width: evaHeadersWidths[index],
                }}
              >
                {header}
              </div>
            ))}
          </div>
          {evaData.map((row, rowIndex) => (
  <EvaluatedTableRow
    key={rowIndex}
    rowIndex={rowIndex}
    row={row?.cells}
    topicId={row?.topicId}
    subTopicId={row?.subTopicId}
    isTopic={row?.isTopic} 
  />
))}
        </div>
        <br></br>
        <br></br>
        {/* small table */}

{formId==1?
        <div>
        <div className="EvaSmall-table-container">
          <table className="eva-table">
            <thead>
              <tr>
                {EvaSmallHeaders.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {EvaSmallData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {rowData.map((cellData, cellIndex) => (
                    <td key={cellIndex}>{cellData}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br></br>
        {/* Note code */}
        <div className="eva-note-container">
          <label className="eva-note-title">{t("Note")}</label>
          <p className="eva-note-content">
            Consideration of trading partners whose assessment results are below
            the criteria by partners who have an assessment score of less than
            50 points for the first time Will be notified by a letter from the
            procurement manager via VMS system to let the trader know and
            improve by continuing CA / PA and if the score is less than 50
            points in the next assessment cycle procurement will be presented to
            consider stopping the work and/or entering the process of reviewing
            supplier information (Black List).
          </p>
        </div>
        </div>
        :<>
        <div className="eva-supplier-top-container">
        <div className="Criteria">Criteria :</div>

        <div className="EvaSmall-table-container-supplier">
          <table className="eva-table-supplier">
            
            <tbody>
              {EvaSmallData2.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {rowData.map((cellData, cellIndex) => (
                    <td key={cellIndex}>{cellData}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <p className="remark"><span>Remark :</span>  Re-evaluate shall be made in 12 months</p>
        </div>
        </div>
        <br></br>
        <br />
       <div className="Criteria">
        Final Supplier Approval Decision
      </div>
      <div className="checkbox-selection">
        <br />
        <div style={{width:"40%", display:"flex", justifyContent:"space-between"}}>
          <label>
            <input 
              type="radio" 
              value={0}  
              className="input-radio"
              
              disabled={true}
            />
            Accepted (ยอมรับ)
          </label>
          <label>
            <input 
              type="radio" 
               
              value={1} 
              className="input-radio" 
              
              disabled={true}
            />
            Rejected (ยกเลิกการใช้บริการ)
          </label>
        </div>
        <label>
          <input 
            type="radio" 
        
            value={2} 
            className="input-radio"
           
            disabled={true}
          />
          {`Accepted in case the score <50%, please explain (กรณีคะแนนตำ่กว่า <50% ถ้าต้องการใช้บริการซื้อ-ขายต่อ โปรดอธิบายหัวข้อด้านล่างนี้)`}
        </label>
      </div>
    <br />
    <div className="inputTextbox">
      <label>
        {`Maintained Service (เหตุผลที่ต้องการใช้บริการต่อ)`}
      </label>
      <textarea 
        rows="4" 
        name="maintainServiceInfo"
       
        className="textArea-selected"
        disabled={true}
      />
    </div>
    <br />
    <div className="inputTextbox">
      <label>
        {`Cause (ข้อบกพร่องที่ควรปรับปรุง)`}
      </label>
      <textarea 
        rows="4" 
        name="cause"
       
        className="textArea-selected"
        disabled={true}
      />
    </div>
    <br />
    <div className="inputTextbox">
      <label>
        {`Improvement (แนวทางหรือวิธีการปรับปรุงแก้ไข)`}
      </label>
      <textarea 
        rows="4" 
        name="improvements"
       
        className="textArea-selected"
        disabled={true}
      />
    </div>

    <br />
    <br />
    <div className="evaText">
    {evaluationCriteria?.map((category, index) => (
        <div key={index}>
          <h2>{category.category}</h2>
          {category?.subCriteria.map((sub, subIndex) => (
            <div key={subIndex}>
              <h3>{sub.title}</h3>
              <ul>
                {sub?.ratings?.map((rating, ratingIndex) => (
                  <p key={ratingIndex}>
                    {rating.score} - {rating.description}
                  </p>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
    
        </>}

        <br></br>
        <div style={{display:"flex",justifyContent:"flex-end"}}> <button onClick={handleSend} className="table-btn">Send</button></div>
        <br></br>
       <br />
        <br></br>
      </section>
    </DefaultLayout>
  );
}

export default EvaluationPage;
