import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../CustomPagination/CustomPagination";
import "./HistorySupplierVisitTable.css";
import "rsuite/dist/rsuite-no-reset.min.css";
import { useNavigate } from "react-router-dom";
import { PageLoader, toast, useDispatch, useSelector, useTranslation } from "../Imports/import";
import { tableStyles } from "../../Utils/TableStyles";
import { fetchHistoryVendorData, getPenaltyAttachments, getPoById } from "../../Store/actions/historyStorageActions";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { downloadFilesInZip } from "../../Utils/Utils";

const HistoryStorageNewTable = ({id,code,evaluationId}) => {
  const dispatch=useDispatch();
  const {vendorData,loading,error}=useSelector((state)=>state.HistoryStorageReducer)
  const {poData,penaltyAttachments}=useSelector((state)=>state.HistoryStorageReducer)
  const [data, setData] = useState([]);
  const ExpandedComponent = () => <div>{data}</div>;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [attachments,setAttachments]=useState([])
  const [count,setCount]=useState((vendorData?.result?.TotalCount) || 0)
  useEffect(()=>{
    dispatch(fetchHistoryVendorData(id,currentPage))
    dispatch(getPenaltyAttachments(id));
    dispatch(getPoById(id))
  },[id,currentPage])
  useEffect(()=>{
    if(penaltyAttachments){
      setAttachments(penaltyAttachments);
    }
    else{
      setAttachments([])
    }
  },[penaltyAttachments])

  useEffect(()=>{
    if(vendorData?.result?.data.length>0){

      setData(vendorData?.result?.data|| [])
    setCount(vendorData?.result?.TotalCount);
    }
    else{
      setData([]);
    }

 
   },[vendorData,count])

  const columns = [
    {
      name: t("Vendor Code"),
      selector: (result) => result?.vendor?.vendor_code || "",
      width: "14%",
      sortable: true,
    },
    {
      name: t("Company Name"),
      selector: (result) => result?.vendor?.company_name_eng || "",
      width: "14%",
      sortable: true,
    },
    {
      name: t("Email Address"),
      selector: (result) =>  result?.vendor?.user_name || "",
      width: "15%",
    },
    {
      name: t("Phone number"),
      selector: (result) => result?.phoneNo || "",
      width: "14%",
    },
    {
      id: t("Action"),
      name: t("Action"),
      cell: (result) => (
        <div style={{ display: "flex", gap: "5px" }}>
          <button
            className="hs-view-qtn"
            onClick={() => {
              handleViewQtn(result?.JobQuotationId);
            }}
          >
            {t("View QTN")}
          </button>
          {result?.vendor?.vendor_code=== code && 
          <>
          <button
          className="table-status hs-download-po"
          onClick={() => {
            handleDownloadPO(id);
          }}
        >
          {t("Download PO")}
        </button>
        <button
          className={`table-status hs-download-penalty ${attachments.length<1 ? "disabled" : ""}`}
          onClick={handleDownloadPenalty}
          disabled={attachments?.length<1}
        >
          {t("Download Penalty")}
        </button>
        <button
  className={`table-status hs-view-evaluated ${evaluationId === "null" ? "disabled" : ""}`}
  onClick={() => {
    if (evaluationId !== "null") {
      handleEvaluatedView(evaluationId);
    }
  }}
  disabled={evaluationId === "null"}
>
  {t("View Evaluated")}
</button>
        </>}
          
        </div>
      ),
      width: "43%",
    },
  ];

  const handleViewQtn = (id) => {
    navigate(`/Supplier-Management-Quotation/${id}`);
  };
  const handleEvaluatedView = (id) => {
    navigate(`/Supplier-Management-Evaluated/${id}`);
  };

  const handleDownloadPO =async (id) => {
      if (poData?.PoDbId==id && poData?.vendorPoAttachments && poData.vendorPoAttachments.length > 0) {
        const downloadattachments =poData?.vendorPoAttachments?.map((file)=>{
          return file?.attachmentUrl;
        });   
        downloadFilesInZip(downloadattachments,"poDocuments")
      } else {
        toast.error(t("No attachments found."));
      }
  };
  const  handleDownloadPenalty= async ()=>{
    if (attachments.length > 0) {
      downloadFilesInZip(attachments,"penaltyDocuments")
     
    } else {
      toast.error(t("No attachments found."));
    }
    
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterData = () => {
    let filteredData = [...data];
    for (const columnName in searchValues) {
      const searchValue = searchValues[columnName];
      if (searchValue) {
        filteredData = filteredData.filter((item) =>
          item[columnName].toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    }

    if (selectedDateRange[0] && selectedDateRange[1]) {
      filteredData = filteredData.filter((item) => {
        const registerDate = new Date(item.Date);
        return (
          registerDate >= selectedDateRange[0] &&
          registerDate <= selectedDateRange[1]
        );
      });
    }

    return filteredData;
  };
  const filteredData = filterData();

  const filteredDataByType =
    type === "all"
      ? filteredData
      : filteredData.filter((item) => item.status === type);

      const displayedRange = {
        start: (currentPage - 1) * itemsPerPage + 1,
        end: Math.min(currentPage * itemsPerPage, count),
        
      };
    

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );
  return (
    <>
    {loading && <PageLoader/>}
      <div>
        <DataTable
          columns={columns}
          data={data}
          customStyles={tableStyles}
          MockRowsComponent={ExpandedComponent}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center pb-4">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default HistoryStorageNewTable;
