import TYPES from "../constants.js";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import { cleanObject, convertToQueryString, formatDate } from "../../Utils/Utils";
import { GET_PENALTY_ATTACHMENTS, GET_PO_DATA, HISTORY_STORAGE_PO_DATA, HISTORY_STORAGE_VENDOR_DATA, QUOTATION_BY_ID } from "../../ApiEndPoints/ApiEndPoints";


  // Function to build query params
  const buildQueryParams = ({
    page,
    pageSize,
    orderBy,
    orderDir,
    startDate,
    endDate,
    searchByColumNameValue,
  }) => {
    const queryParams = {
      page,
      pageSize,
      orderBy,
      orderDir,
    };
    if (startDate && endDate) {
      queryParams.startDate = formatDate(startDate);
      queryParams.endDate = formatDate(endDate);
    }
  
    if (searchByColumNameValue) {
      const cleanedSearchValues = cleanObject(searchByColumNameValue);
      if (Object.keys(cleanedSearchValues).length > 0) {
        queryParams.searchByColumNameValue = JSON.stringify(cleanedSearchValues);
      }
    }
  
    return queryParams;
  };
  
  // Function to build URL with query params
  const buildUrlWithQueryParams = (queryParams, endpoint,id) => {
    if(id){
      const queryString = convertToQueryString(queryParams);
    return `${BaseUrl}${endpoint}/${id}${queryString}`;
    }
    const queryString = convertToQueryString(queryParams);
    return `${BaseUrl}${endpoint}${queryString}`;
  };
  

  export const fetchHistoryPoData=(
    page=1,
    pageSize=5,
    orderBy="PoDbId",
    orderDir="ASC",
    startDate,
    endDate,
    searchValues,
    
)=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.HISTORY_STORAGE_PO_DATA_LOADING
      });
      try {
        const queryParams = buildQueryParams({
            page,
            pageSize,
            orderBy,
            orderDir,
            startDate,
            endDate,
            searchByColumNameValue: searchValues,
          });
          const url = buildUrlWithQueryParams(
            queryParams,
            HISTORY_STORAGE_PO_DATA

          );  
        

        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(url, { headers });
        
        if (response.status === 200){
          dispatch({
            type: TYPES.HISTORY_STORAGE_PO_DATA_SUCCESS,
            payload: response.data.data,
          })
        }
        
        else{
          dispatch({
            type: TYPES.HISTORY_STORAGE_PO_DATA_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.HISTORY_STORAGE_PO_DATA_FAILURE,
          error:error.message,
        })
      }
    }
  };


  export const fetchHistoryVendorData=(
    id,
    page=1,
    pageSize=5,
    orderBy="updatedAt",
    orderDir="DESC",

    searchValues,
    startDate,
    endDate,
    
)=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.HISTORY_STORAGE_VENDOR_DATA_LOADING
      });
      try {
        const queryParams = buildQueryParams({
            page,
            pageSize,
            orderBy,
            orderDir,
            startDate,
            endDate,
            searchByColumNameValue: searchValues,
            
          });
          const url = buildUrlWithQueryParams(
            queryParams,
            HISTORY_STORAGE_VENDOR_DATA,id
          );  
        

        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        
        const response = await axios.get(url, { headers });
        
        if (response.status === 200){
          dispatch({
            type: TYPES.HISTORY_STORAGE_VENDOR_DATA_SUCCESS,
            payload: response.data.data,
          })
        }
        
        else{
          dispatch({
            type: TYPES.HISTORY_STORAGE_VENDOR_DATA_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.HISTORY_STORAGE_VENDOR_DATA_FAILURE,
          error:error.message,
        })
      }
    }
  };



  export const fetchQuotation=(id)=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.QUOTATION_DATA_LOADING
      });
    //   const loadingAction = { type: TYPES.QUOTATION_DATA_LOADING };
    
    // dispatch(loadingAction);
      try {

        const token = localStorage.getItem("vms_admin_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(`${BaseUrl}${QUOTATION_BY_ID}/${id}`, { headers });
     
        if (response.status === 200){
          dispatch({
            type: TYPES.QUOTATION_DATA_SUCCESS,
            payload: response.data.data,
          })
        }
        
        else{
          dispatch({
            type: TYPES.QUOTATION_DATA_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.QUOTATION_DATA_FAILURE,
          error:error.message,
        })
      }
    }
  };
  

//function to get PO Data

export const getPoById=(id)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.PO_DATA_LOADING
    });
    try {

      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_PO_DATA}/${id}`, { headers });
   
      if (response.status === 200){
        dispatch({
          type: TYPES.PO_DATA_SUCCESS,
          payload: response?.data?.data,
        })
      }
      
      else{
        dispatch({
          type: TYPES.PO_DATA_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.PO_DATA_FAILURE,
        error:error.message,
      })
    }
  }
};

//function to get Penalty Attachments

export const getPenaltyAttachments=(id)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.PENALTY_ATTACHMENTS_LOADING
    });
    try {

      const token = localStorage.getItem("vms_admin_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_PENALTY_ATTACHMENTS}/${id}`, { headers });
   
      if (response.status === 200){
        dispatch({
          type: TYPES.PENALTY_ATTACHMENTS_SUCCESS,
          payload: response?.data?.data,
        })
      }
      
      
      else{
        dispatch({
          type: TYPES.PENALTY_ATTACHMENTS_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.PENALTY_ATTACHMENTS_FAILURE,
        error:error.message,
      })
    }
  }
};
