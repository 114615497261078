import {
  React,
  useDispatch,
  useState,
  useEffect,
  useTranslation,
  useSelector,
  Http,
  BaseUrl,
  toast,
} from "../../Imports/import";

import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./PurchasingGroupTable.css";
import PurchasingGroupPopup from "../../../Models/PurchasingGroupPopup/PurchasingGroupPopup";
import {
  currentPurchaseGroupRowData,
  getAllPurchaseGroup,
} from "../../../Store/actions/purshaseGroupAction";
import { transformPurchasingGroupTable } from "../../../Utils/Utils";
import {
  DELETE_PURCHASE_GROUP,
  GET_PURCHASE_GROUP_BY_ID,
  RELEASE_PURCHASE_GROUP,
} from "../../../ApiEndPoints/ApiEndPoints";
import { tableStyles } from "../../../Utils/TableStyles";
import CustomPopup from "../../../Models/CustomPopup/CustomPopup";
import areIcon from "../../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../../Assets/Svg/success-icon.svg";


const PurchasingGroupTable = ({ setPageLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  //view button open popup
  const [rowData, setRowData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userToken = localStorage.getItem("vms_admin_token");
  const [showDeletePopup,setShowDeletePopup]=useState(false)
  const { getPurchaseGroupTableData } = useSelector(
    (state) => state.getPurchaseGroupInfo
  );

  const { LoginDetails } = useSelector((state) => state.loginDetails);

  const isAssignerIsBuyer = LoginDetails?.assigner_type == "Buyer Group";

  const isAssigner = LoginDetails?.assigner_type?.includes("Assigner");

  const data = transformPurchasingGroupTable(getPurchaseGroupTableData);

  const columnWidths = {
    Purchasinggroup: "13%",
    Category: "22%",
    Meaning: "12%",
    Action: "5%",
  };

  const columns = [
    {
      id: "Purchasinggroup",
      name: "Purchasing Group",
      selector: (result) => result.Purchasinggroup,
      searchable: true,
      sortable: true,
      width: "15%",
    },
    {
      id: "Meaning",
      name: "Meaning",
      selector: (result) => result.Meaning,
      searchable: true,
      sortable: true,
      width: "12%",
    },
    {
      id: "Category",
      name: "Category",
      // selector: "Category",
      // selector: (data) => data.Category,
      searchable: true,
      sortable: true,
      width: "22%",
      cell: (result) => <div>{result.Category}</div>,
    },
    {
      id: "Status",
      name: "Status",
      // selector: (data) => data.Status,
      searchable: false,
      width: "16%",
      cell: (result) => (
        <div
          className={
            result.Status === "Released"
              ? "table-status Released"
              : result.Status === "Waiting Release"
              ? "table-status WaitingRelease"
              : ""
          }
        >
          {" "}
          {result.Status}
        </div>
      ),
    },
    {
      id: "Action",
      name: "Action",
      cell: (result) => (
        <div className="d-flex">
          <button
            className="table-btn"
            onClick={() => {
              handleChangeView(result);
            }}
          >
            {"View"}
          </button>{" "}
          {result.Status === "Waiting Release" && !isAssignerIsBuyer && (
            <button
              className="table-btn mx-2"
              style={{ background: "#428BC1" }}
              onClick={() => {
                handleRelease(result);
              }}
            >
              {"Release"}
            </button>
          )}
          {!isAssignerIsBuyer && !isAssigner && (
            <button
              className="table-btn mx-2"
              style={{ background: "#ED4C5C" }}
              onClick={() => {
                setRowData(result)
                handleDelete();
              }}
            >
              {"Delete"}
            </button>
          )}
        </div>
      ),
      searchable: false,
      width: "35%",
    },
  ];

  //view button popup

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //end view button

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  // handling the search functionality
  const handleSearchChange = (event, columnName) => {
    setCurrentPage(1);

    const { value } = event.target;
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [columnName]: value,
    }));
  };
  

  
  const handleTypeSelect = (selectedKey) => setType(selectedKey);

 

  const filterData = () => {
    let filteredData = [...data];
  

    for (const columnName in searchValues) {
      const searchValue = searchValues[columnName];

      if (searchValue) {
        filteredData = filteredData?.filter((item) =>
          item[columnName]?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
    }

    return filteredData;
  };

  const filteredData = filterData();

 

  const filteredDataByType =
    type === "all"
      ? filteredData
      : filteredData.filter((item) => item.Category === type);

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, filteredDataByType.length),
    total: filteredDataByType.length,
  };

  const paginatedData = filteredDataByType.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const headerNames = columns.map((column) => column.name).slice(0, -1);

  const csvData = paginatedData?.map((item) =>
    columns.map((column) => item[column.id]).slice(0, -1)
  );

  // const getCountByStatus = (status) => {
  //   return data.filter((item) => item.Status === status).length;
  // };

  const getCountByCategory = (Category) => {
    return data.filter((item) => item.Category === Category).length;
  };

  useEffect(() => {
    dispatch(getAllPurchaseGroup());
  }, []);

  // handling delete functionality and calling api methods
  
  const closeDeletePopup=()=>{
    setShowDeletePopup(false)
    dispatch(getAllPurchaseGroup());
  }

  const handleDelete = async () => {
    setShowDeletePopup(true);
  
  };

  const handleChangeView = (result) => {
    setRowData(result);
    const queryParams = {
      purchasinggroup_id: result.id,
    };
    // Use the Http.get method to make the GET request
    Http.get(`${BaseUrl}${GET_PURCHASE_GROUP_BY_ID}`, queryParams, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          setIsModalOpen(true);
          dispatch(currentPurchaseGroupRowData(result.data));
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => toast.error(error?.message || "Something Went Wrong!"));
  };

  // handling release functionality and calling api methods
  const handleRelease = async (result) => {
    setPageLoading(true);
    const data = { purchasinggroup_id: result?.id?.toString() };

    try {
      const response = await Http.post(
        `${BaseUrl}${RELEASE_PURCHASE_GROUP}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        setPageLoading(false);
        toast.success(response.message);
        dispatch(getAllPurchaseGroup());
      } else if (response.status === 401) {
        setPageLoading(false);
        toast.error(response.message);
      } else {
        setPageLoading(false);
        toast.error("Something went wrong");
      }
    } catch (err) {
      setPageLoading(false);
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "all"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="all"
                >
                  {t("All")}
                  <span
                    className={
                      type === "all"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="all"
                  >
                    {type==="all"?filteredDataByType.length:data.length}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Goods"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Goods"
                >
                  {t("Goods")}
                  <span
                    className={
                      type === "Goods"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Goods"
                  >
                    {type==="Goods"?filteredDataByType.length:getCountByCategory("Goods")}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Service"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Service"
                >
                  {t("Service")}
                  <span
                    className={
                      type === "Service"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Service"
                  >
                    {type==="Service"?filteredDataByType.length:getCountByCategory("Service")}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Project"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Project"
                >
                  {t("Project")}
                  <span
                    className={
                      type === "Project"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Project"
                  >
                    {type==="Project"?filteredDataByType.length:getCountByCategory("Project")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>{t("Show")} </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>{t("entries")}</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="purchasing_group_table_data.csv"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            paginatedData.length
              ? "um-data-table press-release-table"
              : "um-empty-data-table press-release-table"
          }
        >
          <DataTable
            columns={columns}
            data={paginatedData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
            // MockRowsComponent={ExpandedComponent}
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="table-search-div"
          >
            {columns.map((column) =>
              column.searchable !== false ? (
                <input
                  key={column.id}
                  type="text"
                  value={searchValues[column.id] || ""}
                  style={{ width: columnWidths[column.name] }}
                  onChange={(event) => handleSearchChange(event, column.id)}
                />
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {displayedRange.total} entries
        </p>
        <CustomPagination
          totalItems={filteredDataByType.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      {isModalOpen && (
        <PurchasingGroupPopup
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="Purchasing Group"
          scenarioType="editPurchasingGroup"
          userData={rowData}
        />
      )}
       <CustomPopup
            isOpenPopup={showDeletePopup}
            closePopup={closeDeletePopup}
            title="Are you sure!"
            image={areIcon}
            text={"You want to Delete this Press Release ?"}
            btn1="Cancel"
            btn2="Confirm"
            successTitle="Done!"
            successText={"Delete successfully"}
            successIcon={successIcon}
            btn3="Ok"
            scenarioType="deletePurchaseGroup"
            // handleChangeColumn={handleChangeColumn}
            rowData={rowData}
          />
    </>
  );
};

export default PurchasingGroupTable;
