import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Http from "../../HttpClients/HttpClients";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { LOGIN_DETAILS_UPDATE } from "../../ApiEndPoints/ApiEndPoints";
import PageLoader from "../../Models/PageLoader/PageLoader";
import { getAllLoginDetails } from "../../Store/Reducer/loginDetails";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import EditIcon from "../../Assets/Svg/edit-icon.svg";
import "./AdminAccountSetting.css";

const AdminAccountSetting = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const { t } = useTranslation();
  const userToken = localStorage.getItem("vms_admin_token");
  const dispatch = useDispatch();
  const { LoginDetails, loading } = useSelector((state) => state.loginDetails);

  

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Handle form submission logic here
  
    setPageLoading(true);
    const requestBody = { name: data.name, phone_number: data.phone_number };
    const url = `${BaseUrl}${LOGIN_DETAILS_UPDATE}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    Http.post(url, requestBody, config)
      .then((result) => {

        setPageLoading(false);
        if (result.status === 200) {
          toast.success(result.message);
          setIsEditMode(false);
          dispatch(getAllLoginDetails());
        } else if (result.status === 401) {
          toast.error(result.message);
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        setPageLoading(false);
        toast.error("Something went wrong. Please try again.");
        
      });
  };

  const handleEditMode = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    Object.entries(LoginDetails).forEach(([name, value]) => {
      setValue(name, value);
    });
  }, [LoginDetails]);

  useEffect(() => {
    dispatch(getAllLoginDetails());
  }, []);

  return (
    <DefaultLayout>
      {(pageLoading || loading) && <PageLoader />}
      <section className="um-main">
        <div className="admin-acc-title-btn">
          <h3 className="admin-acc-txt">{t("Account Setting")}</h3>
          {!isEditMode && (
            <button
              type="button"
              className="user-edit-btn"
              onClick={handleEditMode}
            >
              <img src={EditIcon} alt="" /> Edit
            </button>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="admin-acc-main" style={{ width: "100%" }}>
            <label htmlFor="fullName">{t("Full Name")}</label>
            <br />
            <input
              type="text"
              id="fullName"
              disabled={!isEditMode}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className="error-message">
                {t(" Please enter your full name.")}
              </span>
            )}
          </div>
          <br />
          <div className="admin-eml-ph">
            <div className="admin-acc-main">
              <label htmlFor="email">{t("Email Address")}</label>
              <br />
              <input
                type="text"
                id="email"
                disabled
                {...register("user_name", { required: false })}
              />
              {errors.user_name && (
                <span className="error-message">
                  {t("Please enter your email address.")}
                </span>
              )}
            </div>
            <div className="admin-acc-main">
              <label>Group</label>
              <input
                type="text"
                disabled
                {...register("assigner_type", { required: false })}
              />
            </div>
          </div>
          <br />
          <div className="admin-eml-ph">
            <div className="admin-acc-main">
              <label htmlFor="password">{t("Password")}</label>
              <br />
              <input
                type="password"
                disabled
                defaultValue="************"
                {...register("password", { required: false })}
              />
              {errors.password && (
                <span className="error-message">Please enter a password.</span>
              )}
            </div>
            <div className="admin-acc-main">
              <label htmlFor="phoneNumber">{t("Phone number")}</label>
              <Controller
                name="phone_number"
                control={control}
                rules={{ required: true, validate: isValidPhoneNumber }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    defaultCountry="TH"
                    placeholder="Enter phone number"
                    disabled={!isEditMode}
                  />
                )}
              />
              {errors.phone_number && (
                <span className="error-message">
                  {t("Please enter a valid phone number.")}
                </span>
              )}
            </div>
          </div>
          {isEditMode && (
            <div className="pt-3 create-btns-div">
              <button
                type="button"
                className="um-create-btn"
                onClick={() => {
                  setIsEditMode(false);
                }}
              >
                {t("Cancel")}
              </button>
              <button type="submit" className="um-submit-btn">
                {t("Submit")}
              </button>
            </div>
          )}
        </form>
      </section>
    </DefaultLayout>
  );
};

export default AdminAccountSetting;