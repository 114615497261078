import {
    React,
    useState,
    useDispatch,
    useSelector,
    PageLoader,
  } from "../../Components/Imports/import";
  import Modal from "react-modal";
  import CloseIcon from "../../Assets/Svg/close-icon.svg";
  import "./DownloadPopup.css";
  import doc from "../../Assets/Images/doc-icon.png";
  import { useEffect } from "react";
  import {getPenaltyById} from "../../Store/actions/supplierManagementActions";
  
  
  // PenaltyRejectPopup Component
  const DownloadPopup = ({
      DownloadIsOpen,
      DownloadCloseModal,
    DownloadFormTitle,
    id
  }) => {
    const dispatch = useDispatch();
    const [data,setData]=useState([])
  
  const { getPenaltyByIdData,loading,error } = useSelector(
    (state) => state.getSupplierManagementInfo
  );
  useEffect(()=>{
      if(id){
        dispatch(getPenaltyById(id))
      }
  },[DownloadIsOpen,id])
  
  useEffect(()=>{
    setData(getPenaltyByIdData?.data)
  },[getPenaltyByIdData])
   
    
    
    const attachmentUrls = data?.penaltyAcceptedDocUrl?.split(',') || [];
  const attachments = attachmentUrls?.map((name, index) => ({
      attachmentName: "document",
      attachmentUrl: attachmentUrls[index]
  }));
    
    const onClickAccept = async () => {
      DownloadCloseModal();
    };
    
    return (
      <>
       {( loading) && <PageLoader/>}
        <div className="d-flex justify-content-center">
          <Modal
            isOpen={DownloadIsOpen}
            onRequestClose={DownloadCloseModal}
            className="create-user-modal create-penalty-rej-modal"
            overlayClassName="create-modal-overlay"
            shouldCloseOnOverlayClick={false}
          >
            <div className="create-user-title">
              <div className="d-flex">
                <h2 style={{ marginRight: "20px" }}>{DownloadFormTitle}</h2>
              </div>
              <img src={CloseIcon} alt="" onClick={() => DownloadCloseModal()} />
            </div>
            <form className="create-user-form">
              
              <div className="prej-attachment-files">
                <label>{attachments?.length ||0} Attachment</label>
                <label>Download All</label>
              </div>
              <div className="prej-attach-logo">
                {attachments?.map((file,index)=>(
                  <a key={index} href={file?.attachmentUrl} target="_blank" rel="noreferrer">
                    <img className="prej-doc-logo" src={doc} alt={file?.attachmentName} />
                  </a>
                  
                ))}
                 
                 
                </div>
              <br />
              <div className="pt-3 create-btns-div">
              
                <button
                  onClick={onClickAccept}
                  className="um-submit-btn"
                  // disabled={!!fileErrorMsg || Object.keys(errors).length > 0}
                >
                  Close
                </button>
              </div>
            </form>
          </Modal>
        </div>
  
       
      </>
    );
  };
  
  export default DownloadPopup;
  