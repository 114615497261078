import React from "react";
import "./PageLoader.css";
import { Oval } from "react-loader-spinner";

const PageLoader = () => {
  return (
    <>
      <div className="loader-div">
        <div className="page-loader">
          <Oval
            height={50}
            width={50}
            color="#003595"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#003595"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </div>
    </>
  );
};

export default PageLoader;
