import {
  React,
  useEffect,
  useForm,
  useDispatch,
  useSelector,
  useTranslation,
} from "../../Imports/import";
import { updatedDataThird } from "../../../Store/Reducer/registrationReducers";
import {
  bankName,
  currencyData,
  handleKeyDown,
  handleOnWheel,
} from "../../../Utils/Utils";
import useIsFieldMandatory from "../../../CustomHooks/useIsFieldMandatory";

const FinancialData = ({ setType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFieldMandatory = useIsFieldMandatory();
  const onClickBack = () => {
    setType("categories");
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  //Custom formatting function to add commas to the revenue value
  const formatRevenue = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, "");

    const [integerPart, decimalPart] = numericValue.split(".");

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    let formattedValue = formattedIntegerPart;

    if (decimalPart !== undefined) {
      formattedValue += "." + decimalPart?.slice(0, 2);
    }

    return formattedValue;
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setValue("annual_revenue", formatRevenue(value));
  };

  const onSubmit = (data) => {
    dispatch(updatedDataThird(data));
  
    setType("Commercial");
  };

  const vendorData = useSelector((state) => state.registration.thirdObject);

  useEffect(() => {
    Object.entries(vendorData).forEach(([name, value]) => {
      setValue(name, value);
    });

  }, [vendorData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="pb-5">
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Annual Revenue (ข้อมูลล่าสุด)")}
                <span className="staricon">*</span>{" "}
              </label>
              <br />

              <input
                type="text"
                value={watch("annual_revenue")}
                className="input-border registration"
                {...register("annual_revenue", {
                  required: false,
                  maxLength: 20,
                  onChange: handleChange,
                })}
              />
              {errors.annual_revenue && (
                <span className="error-msg">
                  {t("Please enter the correct value")}{" "}
                </span>
              )}
            </div>
            <div className="right-container">
              <label
                className="vendor-input-label"
                // style={{ fontSize: "17px" }}
              >
                {t(
                  "Names of parent, Affiliate, and subsidiary companies (if any)"
                )}
                <span className="staricon">*</span>{" "}
              </label>
              <br />

              <input
                type="text"
                className="input-border registration"
                {...register("name_of_parents", {
                  required: false,
                  maxLength: 100,
                })}
              ></input>
              {errors.name_of_parents && (
                <span className="error-msg">
                  {t("Please enter the correct value")}{" "}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Account name")}
                {isFieldMandatory && <span className="staricon">*</span>}
              </label>
              <br />

              <input
                type="text"
                className="input-border registration"
                {...register("bank_account_holder", {
                  required: isFieldMandatory,
                  maxLength: 100,
                  pattern:
                    /^[A-Za-z\sก-๙เแ่้๊๋า-ู็ิ์ํฺ\[\]{}()\-_.,:;'"!@#$%^&*+=<>?~0-9]+$/,
                })}
              ></input>
              {errors.bank_account_holder && (
                <span className="error-msg">
                  {t("Please enter the Account name")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Bank Account")}
                {isFieldMandatory && <span className="staricon">*</span>}{" "}
              </label>
              <br />

              <input
                type="text"
                className="input-border registration"
                {...register("account_number", {
                  required: isFieldMandatory,
                })}
                onWheel={handleOnWheel}
              ></input>
              {errors.account_number && (
                <span className="error-msg">
                  {t("Please enter the bank account number.")}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Currency")}
                {isFieldMandatory && <span className="staricon">*</span>}{" "}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("currency", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select Currency")}
                </option>
                {currencyData.map((Currency, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={currencyData.value}
                    >
                      {Currency.label}
                    </option>
                  );
                })}
              </select>
              {errors.currency && (
                <span className="error-msg">
                  {t("Please select the currency.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Bank Name")}
                {isFieldMandatory && <span className="staricon">*</span>}{" "}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("bank_name", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select Bank name")}
                </option>
                {bankName.map((Bankname, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={bankName.value}
                    >
                      {Bankname.label}
                    </option>
                  );
                })}
              </select>
              {errors.bank_name && (
                <span className="error-msg">
                  {t("Please enter the bank account name.")}
                </span>
              )}
            </div>
          </div>
          <div className="two-specify">
            <div className="textbox-container-specify">
              {watch("bank_name") === "OTHER, please specify. (OTH) - 999" && (
                <div className="right-container-specify">
                  <br />
                  <input
                    type="text"
                    className="input-border registration-left "
                    {...register("bank_specify", {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  {errors.bank_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Payment Method")}
                {isFieldMandatory && <span className="staricon">*</span>}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("payment_method", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select payment method")}
                </option>
                <option value="Transfer">Transfer</option>
                <option value="Cheque (BANK OF AYUDHAYA , Rayong)">
                  Cheque (BANK OF AYUDHAYA , Rayong){" "}
                </option>
                <option value="Cheque (Citi Bank, BKK)">
                  Cheque (Citi Bank, BKK){" "}
                </option>
                <option value="Other please specify">
                  Other please specify{" "}
                </option>
              </select>
              {errors.payment_method && (
                <span className="error-msg">
                  {t("Please select the payment type.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Credit Term")}
                {isFieldMandatory && <span className="staricon">*</span>}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("credit_term", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select credit term")}
                </option>
                <option value="30Days">{t("30 Days")} </option>
                <option value="Other">{t("Other please specify")}</option>
              </select>
              {errors.credit_term && (
                <span className="error-msg">
                  {t("Please select the credit term.")}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="textbox-container">
              {watch("payment_method") === "Other please specify" && (
                <div className="left-container" style={{ marginRight: "10px" }}>
                  <br />

                  <input
                    type="text"
                    className="input-border registration-left "
                    {...register("payment_specify", {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  {errors.payment_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="textbox-container">
              {watch("credit_term") === "Other" && (
                <div className="right-container" style={{ marginLeft: "10px" }}>
                  <br />

                  <input
                    type="text"
                    className="input-border registration-left "
                    {...register("credit_term_specify", {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  {errors.credit_term_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="textbox-container">
            <div className="left-container">
              <label
                className="vendor-input-label"
                // style={{ fontSize: "15px" }}
              >
                {t(
                  "Have you ever registering entrepreneurs to operate business as SME?"
                )}
                {isFieldMandatory && <span className="staricon">*</span>}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("registered_sme", {
                  required: isFieldMandatory,
                })}
              >
                <option value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="Yes">{t("Yes")}</option>
                <option value="No">{t("No")}</option>
              </select>
              {errors.registered_sme && (
                <span className="error-msg">
                  {t(
                    "Please select whether the business has registered as an SME or not."
                  )}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">
                {t("Have you ever made Bank Guarantee within last 6 months")}?
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("last_6months_bank_guarantee", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="No">{t("No")}</option>
                <option value="Yes">{t("Yes")}</option>
              </select>
              {errors.last_6months_bank_guarantee && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
            </div>
          </div>
          {watch("last_6months_bank_guarantee") === "Yes" && (
            <div className="textbox-container-specify">
              <div className="right-container-specify">
                <br />

                <input
                  type="text"
                  className="input-border registration-left "
                  {...register("bank_gaurantee_specify", {
                    required: true,
                    maxLength: 80,
                  })}
                />

                {errors.bank_gaurantee_specify && (
                  <span className="error-msg">
                    {t("Please enter other specific data")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="buttons pt-5">
          <div className="button-submit-container-back">
            <input
              className="button-submit"
              type="submit"
              value={t("Back")}
              onClick={onClickBack}
            />
          </div>
          <div className="button-submit-container">
            <input className="button-submit" type="submit" value={t("Next")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default FinancialData;
