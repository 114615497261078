import React, { useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./EvaluationFormSelect.css";
import Select, { components } from "react-select";
import Up from "../../Assets/Images/Up.png";
import Down from "../../Assets/Images/Down.png";
import { useDispatch } from "react-redux";

const EvaluationFormSelect = ({ isOpen, closeModal, formTitle ,options,evaluationIdData }) => {

  const [optionsBoxOpen, setOptionsBoxOpen] = useState(false); // State to track the options box state
  const formattedOptions = options?.map(option => ({
    value: option?.evaluationFormId,
    label: option?.formName,
  }));

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  
  const [selectedOption, setSelectedOption] = useState(null);
  const [showError, setShowError] = useState(false);
  
  const DropdownIndicator = (props) => {
    const { selectProps } = props;
    let arrow = selectProps.menuIsOpen ? (
      <img src={Up} alt="Up Arrow" />
    ) : (
      <img src={Down} alt="Down Arrow" />
    );
    return (
      <components.DropdownIndicator {...props}>
        {arrow}
      </components.DropdownIndicator>
    );
  };




  const onSubmit = (data) => {
    if (!selectedOption) {
      setShowError(true); // Show the error message if no option is selected
      return;
    }
    const formData = {
      ...data,
      evaluationFormId: selectedOption.value,
    };
   

    setShowError(false);
    closeModal();
    handleEvaluatedView(formData?.evaluationFormId,evaluationIdData);
  };

  const navigate = useNavigate();
  const handleEvaluatedView = (id,data) => {
    navigate(`/Supplier-Management-Evaluation/${id}/${data?.PoDbId}/${data?.PoPrDbId}/${data?.vendorId}/${data?.poNo}/${data?.vendorCode}/${data?.dateStatus}`);
  };

  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="eva-user-model"
        overlayClassName="eva-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="evaa-cre-user-title d-flex">
          <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
          <img src={closeIcon} alt="" onClick={closeModal} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <span className="eva-fs-name">Form Select</span>
          <br />
          <br />

          <div
            style={{
              width: "100%",
              marginBottom: optionsBoxOpen ? "60px" : "0", // Adjust the margin based on options box state
            }}
          >
            <Select
              options={formattedOptions}
              defaultValue={selectedOption}
              placeholder="Select Evaluation Form"
              onChange={(option) => {
                setSelectedOption(option);
                setShowError(false); // Hide the error message when an option is selected
              }}
              components={{ DropdownIndicator }}
              onMenuOpen={() => setOptionsBoxOpen(true)} // Update options box state
              onMenuClose={() => setOptionsBoxOpen(false)} // Update options box state
            />
           {showError && (
              <span className="evaa-error-msg">
                Please select the Form
              </span>
            )}
          </div>
          <br />
          <div className="pt-3 create-btns-div">
            <button
              type="button"
              className="um-create-btn"
              onClick={closeModal}
              style={{
                marginTop: optionsBoxOpen ? "3%" : "0", // Adjust the margin based on options box state
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="um-submit-btn"
              style={{
                marginTop: optionsBoxOpen ? "3%" : "0", // Adjust the margin based on options box state
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EvaluationFormSelect;
