import React from 'react';
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import HistoryStorageTable from "../../Components/DataTable/HistoryStorageTable";
import "./HistoryStorage.css";
import {useTranslation} from "../../../src/Components/Imports/import";

const HistoryStorage = () => {
  const { t } = useTranslation();
  return (
  <DefaultLayout>
  <section className="um-main">
    <div className="um-title-btn">
      <h1 className="hs-title">{t("History Storage")}</h1>
    </div>
    <HistoryStorageTable/>
  </section>
</DefaultLayout>
  )
}

export default HistoryStorage;
