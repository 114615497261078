import React from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import "./Dashboard.css";
import SupplierVisitTable from "../../Components/DataTable/DashBoardTables/SupplierVisitTable";
import PrTable from "../../Components/DataTable/DashBoardTables/PrTable";
import PoTable from "../../Components/DataTable/DashBoardTables/PoTable";
import SupplierPerfomanceTable from "../../Components/DataTable/DashBoardTables/SupplierPerfomanceTable";

const Dashboard = () => {
  return (
    <DefaultLayout>
      <section className="um-main">
        <div className="um-title-btn">
          <h1 className="db-title">USER DASHBOARD</h1>
          <button className="table-btn create-btn"> Google Analytics</button>
        </div>
        <h2 className="um-title um-tile-not-transform py-3">Vendor Register and Status</h2>
        <SupplierVisitTable />
        <h2 className="um-title um-tile-not-transform py-4">How many PR </h2>
        <PrTable />
        <h2 className="um-title um-tile-not-transform py-4">How many PO </h2>
        <PoTable />
        <h2 className="um-title py-4">Supplier Performance</h2>
        <SupplierPerfomanceTable />
      </section>
    </DefaultLayout>
  );
};

export default Dashboard;
