import {
  React,
  useState,
  useEffect,
  useTranslation,
  useLocation,
  useDispatch,
  useSelector,
  PageLoader,
  useNavigate,
} from "../../Components/Imports/import";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import Nav from "react-bootstrap/Nav";
import "./VendorProfile.css";
import editLogo from "../../Assets/Images/editLogo.png";
import General from "../../Components/VendorProfile/VpGeneral";
import Financial from "../../Components/VendorProfile/VpFinancial";
import Commercial from "../../Components/VendorProfile/VpCommercial";
import Supplier from "../../Components/VendorProfile/VpSupplier";
import Hse from "../../Components/VendorProfile/VpHse";
import Categories from "../../Components/VendorProfile/VpCategories";
import VpDocuments from "../../Components/VendorProfile/VpDocuments";
import VpSummary from "../../Components/VendorProfile/VpSummary";
import { clearSingleVendorData, getSingleVendorDetails } from "../../Store/Reducer/singleVendorDetails";
import useTypeQueryParam from "../../CustomHooks/useTypeQueryParam";
import { getAllLoginDetails } from "../../Store/Reducer/loginDetails";
import store from "../../Store/store";
import { clearStoreInfo } from "../../Store/actions/updateVendorAction";
import { clearVendorTableList } from "../../Store/Reducer/vendorTable";

const VendorProfile = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [type, setType] = useState(queryParams.get("type") || "General");
  const [docEdit, setDocEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [commercialRegType, getCommercialRegType] = useState("");
  const [paymentMethod, getPaymentMethod] = useState("");
  const { singleVendorDetails, loading } = useSelector(
    (state) => state.singleVendor
  );

  const [generalInfoData, getGeneralInfoData] = useState({});

  useTypeQueryParam(type, queryParams);
  const { LoginDetails,loading :userLoginLoading} = useSelector((state) => state.loginDetails);
  const [hasAccess,setHasAccess]=useState(false);
 const assignerType=["ACT Division Group","ACT Verify Group","ACT Sap Group","ACT Department Group"]
  useEffect(()=>{
      dispatch(getAllLoginDetails());
  },[])
  useEffect(()=>{
    const getAssignerType= LoginDetails?.assigner_type ?LoginDetails?.assigner_type.split(",") :[]
    const checkAccess=getAssignerType?.some((type)=> !assignerType.includes(type))
    setHasAccess(checkAccess);
  },[LoginDetails])
  const handleTypeSelect = (selectedKey) => {
    if (!editMode) {
      setType(selectedKey);
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    store.dispatch(clearStoreInfo());
    store.dispatch(clearSingleVendorData());
    store.dispatch(clearVendorTableList());
    navigate("/");
  };

  const handleChangeEdit = () => {
    if(!hasAccess){
      handleLogout();
    }
    else{
      setEditMode(true);
    }
   
  };

  useEffect(() => {
    dispatch(getSingleVendorDetails());
  }, []);

 
  return (
    <DefaultLayout>
      {(loading || pageLoading) && <PageLoader />}

      <section className="um-main">
        <div className="um-title-btn vp-title-btn">
          <h2 className="um-title">{t("Vendor Profile")}</h2>
          {type !== "Summary" && (
            <button
              onClick={handleChangeEdit}
              className="vendor-profile-edit"
              disabled={editMode}
            >
              {" "}
              <img src={editLogo} alt="" />
              {t("Edit")}
            </button>
          )}
        </div>
        <Nav activeKey="" onSelect={handleTypeSelect} className="cv-navbar">
          <Nav.Item>
            <Nav.Link
              className={
                type === "General"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey={"General"}
            >
              <p
                className={
                  type === "General"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("General Info")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "categories"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey={"categories"}
            >
              <p
                className={
                  type === "categories"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Bidding Categories")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Financial"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Financial"
            >
              <p
                className={
                  type === "Financial"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {t("FinancialData/Health")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Commercial"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Commercial"
            >
              <p
                className={
                  type === "Commercial"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Commercial evaluation")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Supplier"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Supplier"
            >
              <p
                className={
                  type === "Supplier"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Supplier’s Quality")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "HSE"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="HSE"
            >
              <p
                className={
                  type === "HSE"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Suppliers’s HSE Management")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Documents"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Documents"
            >
              <p
                className={
                  type === "Documents"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Documents")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Summary"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Summary"
            >
              <p
                className={
                  type === "Summary"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Summary")}
              </p>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {type === "General" && (
          <General
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            oldData={singleVendorDetails}
            setDocEdit={setDocEdit}
            getCommercialRegType={getCommercialRegType}
            loading={setPageLoading}
            getGeneralInfoData={getGeneralInfoData}
          />
        )}
        {type === "categories" && (
          <Categories
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            data={singleVendorDetails}
            loading={setPageLoading}
          />
        )}
        {type === "Financial" && (
          <Financial
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            oldData={singleVendorDetails}
            getPaymentMethod={getPaymentMethod}
            setDocEdit={setDocEdit}
            loading={setPageLoading}
          />
        )}
        {type === "Commercial" && (
          <Commercial
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            data={singleVendorDetails}
            loading={setPageLoading}
          />
        )}
        {type === "Supplier" && (
          <Supplier
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            data={singleVendorDetails}
            loading={setPageLoading}
          />
        )}
        {type === "HSE" && (
          <Hse
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            data={singleVendorDetails}
            setPageLoading={setPageLoading}
          />
        )}
        {type === "Documents" && (
          <VpDocuments
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode || docEdit}
            oldData={singleVendorDetails}
            setPageLoading={setPageLoading}
            setDocEdit={setDocEdit}
            docEdit={docEdit}
            commercialRegistrationType={commercialRegType}
            paymentMethod={paymentMethod}
            generalInfoData={generalInfoData}
          />
        )}
        {type === "Summary" && (
          <VpSummary
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            data={singleVendorDetails}
          />
        )}
      </section>
    </DefaultLayout>
  );
};

export default VendorProfile;
