import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import "rsuite/dist/rsuite-no-reset.min.css";
import { useTranslation } from "../../Imports/import";
import QuotationDetailsPopup from "../../../Models/JobManagementPopup/QuotationDetailsPopup";
import { tableStyles } from "../../../Utils/TableStyles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuotationByJobId } from "../../../Store/actions/jobManagementAction";
import { transformQtTableDataResponse } from "../../../Utils/Utils";

const QutotationTable = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getAllQutotationDetails } = useSelector(
    (state) => state.getJobManagementInfo
  );
  const { Items, TotalCount } = getAllQutotationDetails || {};
  const tableData = transformQtTableDataResponse(Items) || [];

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [tableParams, setTableParams] = useState({
    page: currentPage,
    pageSize: itemsPerPage,
  });
  const page = currentPage;
  const pageSize = itemsPerPage;
  const columns = [
    {
      name: t("Vendor Code"),
      selector: (result) => result?.vendor?.vendor_code=="null" ?"" :result?.vendor?.vendor_code || "",
      width: "14%",
      sortable: true,
    },
    {
      name: t("Company Name"),
      selector: (result) => result?.vendor?.company_name_eng,
      width: "14%",
      sortable: true,
    },
    {
      name: t("Email Address"),
      selector: (result) => result?.vendor?.user_name,
      width: "15%",
    },
    {
      name: t("Phone number"),
      selector: (result) => result?.phoneNo,
      width: "27%",
    },
    {
      id: t("Action"),
      name: t("Action"),
      cell: (result) => (
        <div style={{ display: "flex", gap: "5px" }}>
          <button
            className="hs-view-qtn"
            onClick={() => {
              setRowData(result);
              setIsModalOpen(true);
            }}
          >
            {t("View QTN")}
          </button>
          {/* <button
            className="table-status hs-download-po"
            onClick={() => {
              // Add code for handling download PO button click here
              // handleDownloadPO(result);
            }}
          >
            {t("View PO")}
          </button> */}
        </div>
      ),
      width: "30%",
    },
  ];

  //QuotaionDetailspopup

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage),
    total: TotalCount,
  };
  useEffect(() => {
    dispatch(
      getAllQuotationByJobId({
        page,
        pageSize,
        JobId: id,
      })
    );
  }, [tableParams, itemsPerPage, currentPage]);
  
  return (
    <>
      <div>
        <DataTable
          columns={columns}
          data={tableData}
          customStyles={tableStyles}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center pb-4">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of {TotalCount}{" "}
          entries
        </p>
        <CustomPagination
          totalItems={TotalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <QuotationDetailsPopup
        isOpen={isModalOpen}
        closeModal={closeModal}
        formTitle="Quotation detail"
        data={rowData}
      />
    </>
  );
};

export default QutotationTable;
