import React from "react";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", padding: "3rem" }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for does not exist.</p>
      <img
        src=""
        alt="404 Illustration"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      <p>
        Return to the <a href="/">login page</a>.
      </p>
    </div>
  );
};

export default NotFound;
