import { getSingleJobDetails } from "../actions/jobManagementAction";
import TYPES from "../constants";

const initialState = {
  error: null,
  loading: false,
  getAllJobManagementCountData: [],
  jobManagementTableData: {},
  getAllJobManagementAttachments: [],
  getAllQutotationDetails: {},
  jobManagementTableCount: [],
  singleJobDetails: {},
};

const getJobManagementInfo = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_JOB_TABLE_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        jobManagementTableCount: action.payload,
        loading: false,
        error: null,
      };

    case TYPES.GET_ALL_JOB_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        getAllJobManagementAttachments: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_ALL_QUOTATIONS_LIST_SUCCESS:
      return {
        ...state,
        getAllQutotationDetails: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_JOB_TABLE_STATUS_COUNT_REQUEST:
    case TYPES.GET_ALL_QUOTATIONS_LIST_REQUEST:
    case TYPES.DELETE_JOB_ATTACHMENT_REQUEST:
    case TYPES.UPDATE_JOB_DETAILS_ATTACHMENT_REQUEST:
    case TYPES.GET_ALL_COMPANY_JOB_STATUS_COUNT_REQUEST:
    case TYPES.GET_SINGLE_JOB_DETAILS_REQUEST:
    case TYPES.UPDATE_JOB_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_ALL_COMPANY_JOB_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        getAllJobManagementCountData: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_SINGLE_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        singleJobDetails: action.payload,
        loading: false,
        error:null,
      }
    case TYPES.GET_JOB_TABLE_STATUS_COUNT_FAILURE:
    case TYPES.GET_ALL_QUOTATIONS_LIST_FAILURE:
    case TYPES.DELETE_JOB_ATTACHMENT_FAILURE:
    case TYPES.GET_ALL_JOB_ATTACHMENTS_FAILURE:
    case TYPES.UPDATE_JOB_DETAILS_ATTACHMENT_FAILURE:
    case TYPES.GET_ALL_COMPANY_JOB_STATUS_COUNT_FAILURE:
    case TYPES.GET_SINGLE_JOB_DETAILS_FAILURE:
    case TYPES.UPDATE_JOB_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.GET_ALL_JOB_ATTACHMENTS_REQUEST:
    case TYPES.GET_ALL_JOB_MANAGEMENT_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_ALL_JOB_MANAGEMENT_SUCCESS:
      return {
        ...state,
        jobManagementTableData: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_ALL_JOB_MANAGEMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.DELETE_JOB_ATTACHMENT_SUCCESS:
    case TYPES.UPDATE_JOB_DETAILS_ATTACHMENT_SUCCESS:
    case TYPES.UPDATE_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default getJobManagementInfo;
