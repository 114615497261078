import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "./datatable.css";
import CustomPagination from "./../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import CreateAndEditUser from "../../Models/CreateAndEditUser/CreateAndEditUser";
import { useDispatch, useSelector } from "react-redux";
import {
  currentRowData,
  deleteParticularUser,
  getAllUser,
} from "../../Store/actions/userManagementAction";
import { DateGenerator, transformUserManagementTable } from "../../Utils/Utils";
import { BaseUrl, toast } from "../Imports/import";
import { DELETE_USER } from "../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import { tableStyles } from "../../Utils/TableStyles";
import CustomPopup from "../../Models/CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";

const UserManagementDataTable = () => {
  const dispatch = useDispatch();
  const { getUserInfo } = useSelector((state) => state.getUserManagementInfo);

  useEffect(() => {
    dispatch(getAllUser());
  }, []);
  // const mockData = getUserInfo
  const data = transformUserManagementTable(getUserInfo);
  const [showDeletePopup,setShowDeletePopup]=useState(false)
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  // const [data, setData] = useState(mockData);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [rowData, setRowData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = [
    {
      id: "name",
      name: "Full Name",
      selector: (data) => data.name,
      sortable: true,
    },
    {
      id: "email",
      name: "Email Address",
      selector: (data) => data.email,
      sortable: true,
    },
    {
      id: "phone_number",
      name: "Phone number",
      selector: (data) => data.phone_number,
      sortable: true,
    },
    {
      id: "assigner_type",
      name: "Group",
      selector: (data) => data.assigner_type,
      sortable: true,
    },
    {
      id: "created_at",
      name: "Date",
      selector: (data) => DateGenerator(data.created_at),
      sortable: true,
    },
    {
      id: "Action",
      name: "Action",
      cell: (result) => (
        <button
          className="table-btn"
          onClick={() => {
            handleChangeView(result);
          }}
        >
          View
        </button>
      ),
      searchable: false,
    },
    {
      id: "Delete",
      name: "Delete",
      cell: (result) => (
        <div>
          <button
            className="table-btn"
            // onClick={handleDelete}
            onClick={() => {
              handleDelete(result);
              setRowData(result);
            }}
            style={{ background: "#ED4C5C" }}
          >
            Delete{" "}
          </button>
        </div>
      ),
      searchable: false,
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeView = (result) => {
    dispatch(deleteParticularUser(result?.user_id));
    dispatch(currentRowData(result));
    setRowData(result);
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    setShowDeletePopup(true);
  };
  const closeDeletePopup=()=>{
    setShowDeletePopup(false)
    dispatch(getAllUser());
  }


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  // handling the search functionality
  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setCurrentPage(1);
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [columnName]: value,
    }));
  };

  const filterData = () => {
    let filteredData = [...data];

    for (const columnName in searchValues) {
      const searchValue = searchValues[columnName];
      if (searchValue) {
        filteredData = filteredData.filter((item) =>
          item[columnName].toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
    }
    return filteredData;
  };

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, filterData().length),
    total: filterData().length,
  };

  const paginatedData = filterData().slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const headerNames = columns.map((column) => column?.name);



  const csvData = data.map((item) =>
    columns.map((column) => {
      if (column?.id === "phone_number") {
        return `=""${item[column?.id]}""`;
      }
      return item[column?.id];
    })
  );
  
  // const csvData = data.map((item) => columns.map((column) => item[column?.id]));

  return (
    <>
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="um-all">
            <p>
              All <span className="um-count">{data.length}</span>
            </p>
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>Show </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>entries</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="user_management_table_data.csv"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            paginatedData.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            columns={columns}
            data={paginatedData}
            customStyles={tableStyles}
            pagination={false}
            className="user-management-table"
            noDataComponent="No records to display"
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="table-search-div"
          >
            {columns.map((column) =>
              column.searchable !== false ? (
                <input
                  key={column.id}
                  type="text"
                  value={searchValues[column.id] || ""}
                  onChange={(event) => handleSearchChange(event, column.id)}
                />
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {displayedRange.total} entries
        </p>
        <CustomPagination
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      {isModalOpen && (
        <CreateAndEditUser
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="User"
          scenarioType="editUser"
          userData={rowData}
        />
      )}
      <CustomPopup
            isOpenPopup={showDeletePopup}
            closePopup={closeDeletePopup}
            title="Are you sure!"
            image={areIcon}
            text={"You want to Delete this Press Release ?"}
            btn1="Cancel"
            btn2="Confirm"
            successTitle="Done!"
            successText={"Delete successfully"}
            successIcon={successIcon}
            btn3="Ok"
            scenarioType="deleteUser"
            // handleChangeColumn={handleChangeColumn}
            rowData={rowData}
          />
    </>
  );
};

export default UserManagementDataTable;
