import React from "react";
import { Grid } from "@mui/material";
import TopBar from "../../Components/TopBar/TopBar";
import SideBar from "../../Components/SideBar/SideBar";
import Col from "react-bootstrap/Col";

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Col lg={2} className="d-none d-sm-block">
        <SideBar />
      </Col>
      <Col lg={12}>
        <TopBar />
        {children}
      </Col>
    </>
  );
};

export default DefaultLayout;
