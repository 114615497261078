import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader, useDispatch, useSelector, useTranslation } from "../../../src/Components/Imports/import";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import VectorArrow from "../../Assets/Images/backArrow.png";
import "./QuotationDetailsPage.css";
import PdfIcon from "../../Assets/Svg/pdf-icon.svg";
import JpgIcon from "../../Assets/Svg/jpg-icon.svg";
import DownloadIcon from "../../Assets/Svg/download.svg";
import { fetchQuotation } from "../../Store/actions/historyStorageActions";

const QuotationDetailsPage = () => {
  const { id} = useParams();
  const dispatch= useDispatch();
  const {quotationData:apiData,loading,error}=useSelector((state)=>state.HistoryStorageReducer)
  const [quotationData,setQuotationData]=useState({})
  // Initial data for the cards
  useEffect(()=>{
    if(id){
     
      dispatch(fetchQuotation(id))
    }
   
  },[id])
  useEffect(() => {
    if (apiData?.JobQuotationId==id) {
      setQuotationData(apiData);
      setData(apiData.JobManagementData.JobPrDetails || []);
    } else {
      setQuotationData({});
      setData([]);
    }
  }, [apiData,id]);

useEffect(() => {
  
  setData(quotationData?.JobManagementData?.JobPrDetails || []);
}, [quotationData]);


  const [data, setData] = useState([]);
 
  // CardItem component to represent each card
  const CardItem = ({ item = {} }) => (
    
    <div className="quo-pr-com-card-item">


      <span className="quo-pr-com-card-title">{"Short Text"}</span>
      <input type="text" className="quo-pr-com-card-content" value={item?.PrSapDetail?.PR_description_Short || ""} readOnly/>
      <span className="quo-pr-com-card-title">{"PR Number"}</span>
      <input type="text" className="quo-pr-com-card-content" value={item?.PrSapDetail?.PR_ID || ""} readOnly/>
      <span className="quo-pr-com-card-title">{"Long Text"}</span>
      <textarea className="quo-pr-com-card-content" value={item?.PrSapDetail?.PR_description_Long || ""} readOnly/>
    </div>
  );
  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <DefaultLayout>
       {( loading) && <PageLoader/>}
      <section className="um-main">
        <div className="um-title-btn">
          <div className="back-button" onClick={handleGoBack}>
            <img src={VectorArrow} alt="Back Arrow" />
            {t("back")}
          </div>
        </div>
        <h1 className="hs-title">{t("Quotation Detail")}</h1>
        <br></br>
        <div className="quo-details-date-main">
          <div>
            <label htmlFor="BiddingEnd">{t("Bidding End")}</label>
            <br />
            <input type="date" value={formatDateForInput(quotationData?.JobManagementData?.BiddingDate) ||""} placeholder="Select a Date" readOnly/>
          </div>
          <div>
            <label>{t("Vendor Code")}</label>
            <input
              className="quo-Details-vendor-code"
              type="text"
              value={quotationData?.vendor?.vendor_code || ""} readOnly
              placeholder=""
            />
          </div>
        </div>
        <br />
        <div className="pr-details-pr-title" style={{ width: "100%" }}>
          <label htmlFor="PRTitle">{t("PR Title")}</label>
          <br />
          <input type="text" id="PRTitle" value={quotationData?.JobManagementData?.PrTitle || ""} readOnly />
        </div>
        <br />
        <div className="qt-main-scroll">
          <div className="quo-pr-comb-det">
            <div className="quo-pr-com-det-text">{t("PR Combined Detail")}</div>
            {data?.map((item, index) => (
              <div className="quo-pr-com-card-qtn" key={index}>
                {/* Render the CardItem component with props */}
                <CardItem item={item} index={index} />
              </div>
            ))}
          </div>
        </div>

        {/* note */}
        <div className="quotation-pr-title">
          <span className="quotation-pr-note-text">{t("Note")}</span>
          <div className="pr-note-textarea">
            <textarea rows="4" cols="130" value={quotationData?.JobManagementData?.Note} readOnly/>
          </div>
        </div>

        {/* Quotation File  */}
        <div className="qtn-file-container">
          <div className="qtn-file-heading">
            <div className="qtn-file-heading-content">
              <h2 className="qtn-file-heading-text">{t("Quotation File")}</h2>
            </div>
          </div>
          <div className="qtn-file-item-container">
          {quotationData?.QtAttachments?.map((attachment, index) => (
              <div className="qtn-file-item" key={index}>
                <div className="qtn-file-item-content">
                  <img
                    src={attachment.AttachmentFileUrl.endsWith(".pdf") ? PdfIcon : JpgIcon}
                    className="qtn-file-item-icon"
                    alt={attachment.AttachmentFileUrl.endsWith(".pdf") ? "PDF" : "JPG"}
                  />
                  <div className="qtn-file-item-text">
                    {attachment.AttachmentName}
                  </div>
                </div>
                <img
                  src={DownloadIcon}
                  className="qtn-file-item-icon"
                  alt="Download"
                  onClick={() => handleDownloadClick(attachment.AttachmentFileUrl)}
                />
              </div>
            ))}
            </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
      </section>
    </DefaultLayout>
  );
};

export default QuotationDetailsPage;
