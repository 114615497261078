import React from "react";
import { Nav } from "react-bootstrap";
import "./NavLink.css";

const NavLink = ({
  eventType,
  label,
  totalCount,
  activeKey,
  handleTypeSelect,
}) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={
          activeKey === eventType
            ? "supplier-visit-table-active"
            : "supplier-visit-table"
        }
        eventKey={eventType}
        onSelect={handleTypeSelect}
      >
        {label}
        <span
          className={
            activeKey === eventType
              ? "supplier-visit-table-badge-active"
              : "supplier-visit-table-badge"
          }
          eventKey={eventType}
        >
          {totalCount}
        </span>
      </Nav.Link>
    </Nav.Item>
  );
};

export default NavLink;
