import {
  React,
  useState,
  useEffect,
  useTranslation,
  useLocation,
  useDispatch,
  useSelector,
  PageLoader,
} from "../../Components/Imports/import";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import "./CreateVendor.css";
import Nav from "react-bootstrap/Nav";
import GeneralInfo from "../../Components/CreateVendorComponents/GeneralInfo/GeneralInfo";
import BiddingCategories from "../../Components/CreateVendorComponents/BiddingCategories/BiddingCategories";
import FinancialData from "../../Components/CreateVendorComponents/FinancialData/FinancialData";
import CommercialEvalutaion from "../../Components/CreateVendorComponents/CommercialEvalutaion/CommercialEvalutaion";
import SupplierQuality from "../../Components/CreateVendorComponents/SupplierQuality/SupplierQuality";
import HseManagement from "../../Components/CreateVendorComponents/HseManagement/HseManagement";
import Documents from "../../Components/CreateVendorComponents/Documents/Documents";

const CreateVendor = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [type, setType] = useState(queryParams.get("type") || "General");
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    queryParams.set("type", type);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }, [type, queryParams]);

  const handleTypeSelect = (selectedKey) => setType(selectedKey);



  return (
    <DefaultLayout>
      {pageLoading && <PageLoader />}
      <section className="um-main">
        <div className="um-title-btn">
          <h2 className="um-title">{t("Create Vendor")}</h2>
        </div>
        <Nav activeKey="" onSelect={handleTypeSelect} className="cv-navbar">
          <Nav.Item>
            <Nav.Link
              className={
                type === "General"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              // eventKey="General"
            >
              <p
                className={
                  type === "General"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("General Info")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "categories"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              // eventKey={"categories"}
            >
              <p
                className={
                  type === "categories"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Bidding Categories")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Financial"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              // eventKey="Financial"
            >
              <p
                className={
                  type === "Financial"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {t("FinancialData/Health")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Commercial"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              // eventKey="Commercial"
            >
              <p
                className={
                  type === "Commercial"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Commercial evaluation")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Supplier"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              // eventKey="Supplier"
            >
              <p
                className={
                  type === "Supplier"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Supplier’s Quality")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "HSE"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              // eventKey="HSE"
            >
              <p
                className={
                  type === "HSE"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Suppliers’s HSE Management")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Documents"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              // eventKey="Documents"
            >
              <p
                className={
                  type === "Documents"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Documents")}
              </p>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        {type === "General" && <GeneralInfo setType={setType} />}
        {type === "categories" && <BiddingCategories setType={setType} />}
        {type === "Financial" && <FinancialData setType={setType} />}
        {type === "Commercial" && <CommercialEvalutaion setType={setType} />}
        {type === "Supplier" && <SupplierQuality setType={setType} />}
        {type === "HSE" && <HseManagement setType={setType} />}
        {type === "Documents" && (
          <Documents setPageLoading={setPageLoading} setType={setType} />
        )}
      </section>
    </DefaultLayout>
  );
};

export default CreateVendor;
