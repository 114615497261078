const ActionTypes = {
  UPDATE_VENDOR: "UPDATE_VENDOR",
  UPDATE_VENDOR_FAILURE: "UPDATE_VENDOR_FAILURE",
  LATEST_VENDOR_INFO: "LATEST_VENDOR_INFO",
  CLEAR_LATEST_VENDOR_INFO: "CLEAR_LATEST_VENDOR_INFO",
  CLEAR_STORE_INFO: "CLEAR_STORE_INFO",

  //  user management Actions

  GET_USERS_API_REQUEST: "GET_USERS_API_REQUEST",
  GET_USERS: "GET_USERS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  GET_CREATE_USER_DETAILS: "GET_CREATE_USER_DETAILS",
  CURRENT_ROW_DATA: "CURRENT_ROW_DATA",
  EDIT_USER_API_REQUEST: "EDIT_USER_API_REQUEST",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",
  EDIT_PARTICULAR_USER: " EDIT_PARTICULAR_USER",
  DELETE_USER_ID: " DELETE_USER_ID",

  //   press release actions
  CREATE_PRESS_RELEASE_DETAILS: "CREATE_PRESS_RELEASE_DETAILS",
  CREATE_PRESS_RELEASE_SUCCESS: "CREATE_PRESS_RELEASE_SUCCESS",
  CREATE_PRESS_RELEASE_FAILURE: "CREATE_PRESS_RELEASE_FAILURE",
  GET_ALL_PRESS_RELEASE_API_REQUEST: "GET_ALL_PRESS_RELEASE_API_REQUEST",
  GET_ALL_PRESS_RELEASE_SUCCESS: "GET_ALL_PRESS_RELEASE_SUCCESS",
  GET_ALL_PRESS_RELEASE_FAILURE: "GET_ALL_PRESS_RELEASE_FAILURE",
  CURRENT_PRESS_RELEASE_ROW_DATA: "CURRENT_PRESS_RELEASE_ROW_DATA",
  EDIT_PRESS_RELEASE_API_REQUEST: "EDIT_PRESS_RELEASE_API_REQUEST",
  EDIT_PRESS_RELEASE_SUCCESS: "EDIT_PRESS_RELEASE_SUCCESS",
  EDIT_PRESS_RELEASE_FAILURE: "EDIT_PRESS_RELEASE_FAILURE",
  EDIT_PARTICULAR_PRESS_RELEASE: "EDIT_PARTICULAR_PRESS_RELEASE",

  // vendor management actions
  DELETE_VENDOR: "DELETE_VENDOR",
  DELETE_VENDOR_SUCCESS: "DELETE_VENDOR_SUCCESS",
  DELETE_VENDOR_FAILURE: "DELETE_VENDOR_FAILURE",
  CURRENT_VENDOR_ROW_DATA: "CURRENT_VENDOR_ROW_DATA",

  // purchase group actions
  CREATE_PURCHASE_GROUP_DETAILS: "CREATE_PURCHASE_GROUP_DETAILS",
  CREATE_PURCHASE_GROUP_SUCCESS: "CREATE_PURCHASE_GROUP_SUCCESS",
  CREATE_PURCHASE_GROUP_FAILURE: "CREATE_PURCHASE_GROUP_FAILURE",
  GET_ALL_PURCHASE_GROUP_API_REQUEST: "GET_ALL_PURCHASE_GROUP_API_REQUEST",
  GET_ALL_PURCHASE_GROUP_SUCCESS: "GET_ALL_PURCHASE_GROUP_SUCCESS",
  GET_ALL_PURCHASE_GROUP_FAILURE: "GET_ALL_PURCHASE_GROUP_FAILURE",
  CURRENT_PURCHASE_GROUP_ROW_DATA: "CURRENT_PURCHASE_GROUP_ROW_DATA",
  EDIT_PURCHASE_GROUP_API_REQUEST: "EDIT_PURCHASE_GROUP_API_REQUEST",
  EDIT_PURCHASE_GROUP_SUCCESS: "EDIT_PURCHASE_GROUP_SUCCESS",
  EDIT_PURCHASE_GROUP_FAILURE: "EDIT_PURCHASE_GROUP_FAILURE",
  EDIT_PARTICULAR_PURCHASE_GROUP: "EDIT_PARTICULAR_PURCHASE_GROUP",

  // pr relesae group actions
  GET_ALL_PR_RELEASE_GROUP_COUNT_REQUEST:
    "GET_ALL_PR_RELEASE_GROUP_COUNT_REQUEST",
  GET_ALL_PR_RELEASE_GROUP_COUNT_SUCCESS:
    "GET_ALL_PR_RELEASE_GROUP_COUNT_SUCCESS",
  GET_ALL_PR_RELEASE_GROUP_COUNT_FAILURE:
    "GET_ALL_PR_RELEASE_GROUP_COUNT_FAILURE",

  GET_ALL_PR_RELEASE_API_REQUEST: "GET_ALL_PR_RELEASE_API_REQUEST",
  GET_ALL_PR_RELEASE_SUCCESS: "GET_ALL_PR_RELEASE_SUCCESS",
  GET_ALL_PR_RELEASE_FAILURE: "GET_ALL_PR_RELEASE_FAILURE",

  GET_ALL_COMPANY_JOB_STATUS_COUNT_REQUEST:
    "GET_ALL_COMPANY_JOB_STATUS_COUNT_REQUEST",
  GET_ALL_COMPANY_JOB_STATUS_COUNT_SUCCESS:
    "GET_ALL_COMPANY_JOB_STATUS_COUNT_SUCCESS",
  GET_ALL_COMPANY_JOB_STATUS_COUNT_FAILURE:
    "GET_ALL_COMPANY_JOB_STATUS_COUNT_FAILURE",

  GET_ALL_JOB_MANAGEMENT_API_REQUEST: "GET_ALL_JOB_MANAGEMENT_API_REQUEST",
  GET_ALL_JOB_MANAGEMENT_SUCCESS: "GET_ALL_JOB_MANAGEMENT_SUCCESS",
  GET_ALL_JOB_MANAGEMENT_FAILURE: "GET_ALL_JOB_MANAGEMENT_FAILURE",

  UPDATE_PR_DETAILS_ATTACHMENT_REQUEST: "UPDATE_PR_DETAILS_ATTACHMENT_REQUEST",
  UPDATE_PR_DETAILS_ATTACHMENT_SUCCESS: "UPDATE_PR_DETAILS_ATTACHMENT_SUCCESS",
  UPDATE_PR_DETAILS_ATTACHMENT_FAILURE: "UPDATE_PR_DETAILS_ATTACHMENT_FAILURE",

  GET_SINGLE_PR_DETAILS_REQUEST: "GET_SINGLE_PR_DETAILS_REQUEST",
  GET_SINGLE_PR_DETAILS_SUCCESS: "GET_SINGLE_PR_DETAILS_SUCCESS",
  GET_SINGLE_PR_DETAILS_FAILURE: "GET_SINGLE_PR_DETAILS_FAILURE",

  DELETE_PR_REQUEST: "DELETE_PR_REQUEST",
  DELETE_PR_SUCCESS: "DELETE_PR_SUCCESS",
  DELETE_PR_FAILURE: "DELETE_PR_FAILURE",

  GET_ALL_PR_ATTACHMENTS_REQUEST: "GET_ALL_PR_ATTACHMENTS_REQUEST",
  GET_ALL_PR_ATTACHMENTS_SUCCESS: "GET_ALL_PR_ATTACHMENTS_SUCCESS",
  GET_ALL_PR_ATTACHMENTS_FAILURE: "GET_ALL_PR_ATTACHMENTS_FAILURE",

  RELEASE_PR_REQUEST: "RELEASE_PR_REQUEST",
  RELEASE_PR_SUCCESS: "RELEASE_PR_SUCCESS",
  RELEASE_PR_FAILURE: "RELEASE_PR_FAILURE",

  DELETE_PR_ATTACHMENT_REQUEST: "DELETE_PR_ATTACHMENT_REQUEST",
  DELETE_PR_ATTACHMENT_SUCCESS: "DELETE_PR_ATTACHMENT_SUCCESS",
  DELETE_PR_ATTACHMENT_FAILURE: "DELETE_PR_ATTACHMENT_FAILURE",

  GET_ALL_QUOTATIONS_LIST_REQUEST: "GET_ALL_QUOTATIONS_LIST_REQUEST",
  GET_ALL_QUOTATIONS_LIST_SUCCESS: "GET_ALL_QUOTATIONS_LIST_SUCCESS",
  GET_ALL_QUOTATIONS_LIST_FAILURE: "GET_ALL_QUOTATIONS_LIST_FAILURE",

  GET_SINGLE_JOB_DETAILS_REQUEST: "GET_SINGLE_JOB_DETAILS_REQUEST",
  GET_SINGLE_JOB_DETAILS_SUCCESS: "GET_SINGLE_JOB_DETAILS_SUCCESS",
  GET_SINGLE_JOB_DETAILS_FAILURE: "GET_SINGLE_JOB_DETAILS_FAILURE",

  UPDATE_JOB_DETAILS_REQUEST: "UPDATE_JOB_DETAILS_REQUEST",
  UPDATE_JOB_DETAILS_SUCCESS: "UPDATE_JOB_DETAILS_SUCCESS",
  UPDATE_JOB_DETAILS_FAILURE: "UPDATE_JOB_DETAILS_FAILURE",

  UPDATE_JOB_DETAILS_ATTACHMENT_REQUEST:
    "UPDATE_JOB_DETAILS_ATTACHMENT_REQUEST",

  UPDATE_JOB_DETAILS_ATTACHMENT_SUCCESS:
    "UPDATE_JOB_DETAILS_ATTACHMENT_SUCCESS",

  UPDATE_JOB_DETAILS_ATTACHMENT_FAILURE:
    "UPDATE_JOB_DETAILS_ATTACHMENT_FAILURE",

  GET_ALL_JOB_ATTACHMENTS_REQUEST: "GET_ALL_JOB_ATTACHMENTS_REQUEST",
  GET_ALL_JOB_ATTACHMENTS_SUCCESS: "GET_ALL_JOB_ATTACHMENTS_SUCCESS",
  GET_ALL_JOB_ATTACHMENTS_FAILURE: "GET_ALL_JOB_ATTACHMENTS_FAILURE",

  DELETE_JOB_ATTACHMENT_REQUEST: "DELETE_JOB_ATTACHMENT_REQUEST",
  DELETE_JOB_ATTACHMENT_SUCCESS: "DELETE_JOB_ATTACHMENT_SUCCESS",
  DELETE_JOB_ATTACHMENT_FAILURE: "DELETE_JOB_ATTACHMENT_FAILURE",

  GET_JOB_TABLE_STATUS_COUNT_REQUEST: "GET_JOB_TABLE_STATUS_COUNT_REQUEST",
  GET_JOB_TABLE_STATUS_COUNT_SUCCESS: "GET_JOB_TABLE_STATUS_COUNT_SUCCESS",
  GET_JOB_TABLE_STATUS_COUNT_FAILURE: "GET_JOB_TABLE_STATUS_COUNT_FAILURE",

  CURRENT_PR_RELEASE_DATA: "CURRENT_PR_RELEASE_DATA",

  GET_PR_STATUS_COUNT_REQUEST: "GET_PR_STATUS_COUNT_REQUEST",
  GET_PR_STATUS_COUNT_SUCCESS: "GET_PR_STATUS_COUNT_SUCCESS",
  GET_PR_STATUS_COUNT_FAILURE: "GET_PR_STATUS_COUNT_FAILURE",

  //PR FILTER DROPDOWN
  GET_PR_FILTER_DROPDOWN_REQUEST: "GET_PR_FILTER_DROPDOWN_REQUEST",
  GET_PR_FILTER_DROPDOWN_SUCCESS: "GET_PR_FILTER_DROPDOWN_SUCCESS",
  GET_PR_FILTER_DROPDOWN_FAILURE: "GET_PR_FILTER_DROPDOWN_FAILURE",

  // supplier table constants

  GET_SUPPLIER_TABLE_STATUS_COUNT_REQUEST:
    "GET_SUPPLIER_TABLE_STATUS_COUNT_REQUEST",
  GET_SUPPLIER_TABLE_STATUS_COUNT_SUCCESS:
    "GET_SUPPLIER_TABLE_STATUS_COUNT_SUCCESS",
  GET_SUPPLIER_TABLE_STATUS_COUNT_FAILURE:
    "GET_SUPPLIER_TABLE_STATUS_COUNT_FAILURE",

  GET_ALL_SUPPLIER_LIST_REQUEST: "GET_ALL_SUPPLIER_LIST_REQUEST",
  GET_ALL_SUPPLIER_LIST_SUCCESS: "GET_ALL_SUPPLIER_LIST_SUCCESS",
  GET_ALL_SUPPLIER_LIST_FAILURE: "GET_ALL_SUPPLIER_LIST_FAILURE",

  // evaluation constants

  SAVE_EVALUTATION_REQUEST: "SAVE_EVALUTATION_REQUEST",
  SAVE_EVALUTATION_SUCCESS: "SAVE_EVALUTATION_SUCCESS",
  SAVE_EVALUTATION_FAILURE: "SAVE_EVALUTATION_FAILURE",

  GET_EVALUTATION_BY_ID_REQUEST: "GET_EVALUTATION_BY_ID_REQUEST",
  GET_EVALUTATION_BY_ID_SUCCESS: "GET_EVALUTATION_BY_ID_SUCCESS",
  GET_EVALUTATION_BY_ID_FAILURE: "GET_EVALUTATION_BY_ID_FAILURE",

  GET_ALL_EVALUTATION_LIST_REQUEST: "GET_ALL_EVALUTATION_LIST_REQUEST",
  GET_ALL_EVALUTATION_LIST_SUCCESS: "GET_ALL_EVALUTATION_LIST_SUCCESS",
  GET_ALL_EVALUTATION_LIST_FAILURE: "GET_ALL_EVALUTATION_LIST_FAILURE",

  GET_EVALUTATION_TABLE_STATUS_COUNT_REQUEST:
    "GET_EVALUTATION_TABLE_STATUS_COUNT_REQUEST",
  GET_EVALUTATION_TABLE_STATUS_COUNT_SUCCESS:
    "GET_EVALUTATION_TABLE_STATUS_COUNT_SUCCESS",
  GET_EVALUTATION_TABLE_STATUS_COUNT_FAILURE:
    "GET_EVALUTATION_TABLE_STATUS_COUNT_FAILURE",

  UPDATE_EVALUTATION_BY_ID_REQUEST: "UPDATE_EVALUTATION_BY_ID_REQUEST",
  UPDATE_EVALUTATION_BY_ID_SUCCESS: "UPDATE_EVALUTATION_BY_ID_SUCCESS",
  UPDATE_EVALUTATION_BY_ID_FAILURE: "UPDATE_EVALUTATION_BY_ID_FAILURE",

  EVALUATION_SUBMIT_BY_EMAIL_URL_REQUEST:
    "EVALUATION_SUBMIT_BY_EMAIL_URL_REQUEST",
  EVALUATION_SUBMIT_BY_EMAIL_URL_SUCCESS:
    "EVALUATION_SUBMIT_BY_EMAIL_URL_SUCCESS",
  EVALUATION_SUBMIT_BY_EMAIL_URL_FAILURE:
    "EVALUATION_SUBMIT_BY_EMAIL_URL_FAILURE",

  DELETE_EVALUATION_REQUEST: "DELETE_EVALUATION_REQUEST",
  DELETE_EVALUATION_SUCCESS: "DELETE_EVALUATION_SUCCESS",
  DELETE_EVALUATION_FAILURE: "DELETE_EVALUATION_FAILURE",

  EVALUATION_FORM_DROPDOWN_REQUEST: "EVALUATION_FORM_DROPDOWN_REQUEST",
  EVALUATION_FORM_DROPDOWN_SUCCESS: "EVALUATION_FORM_DROPDOWN_SUCCESS",
  EVALUATION_FORM_DROPDOWN_FAILURE: "EVALUATION_FORM_DROPDOWN_FAILURE",

  EVALUATION_FORM_GET_BY_ID_REQUEST: "EVALUATION_FORM_GET_BY_ID_REQUEST",
  EVALUATION_FORM_GET_BY_ID_SUCCESS: "EVALUATION_FORM_GET_BY_ID_SUCCESS",
  EVALUATION_FORM_GET_BY_ID_FAILURE: "EVALUATION_FORM_GET_BY_ID_FAILURE",
  
  //Send Penalty
  PENALTY_SEND_REQUEST:"PENALTY_SEND_REQUEST",
  PENALTY_SEND_SUCCESS:"PENALTY_SEND_SUCCESS",
  PENALTY_SEND_FAILURE:"PENALTY_SEND_FAILURE",

  //Get Penalty By Id
  GET_PENALTY_BY_ID_REQUEST:"GET_PENALTY_BY_ID_REQUEST",
  GET_PENALTY_BY_ID_SUCCESS:"GET_PENALTY_BY_ID_SUCCESS",
  GET_PENALTY_BY_ID_FAILURE:"GET_PENALTY_BY_ID_FAILURE",

  //Accept Penalty Reject By Id
  ACCEPT_PENALTY_REJECT_BY_ID_REQUEST:"ACCEPT_PENALTY_REJECT_BY_ID_REQUEST",
  ACCEPT_PENALTY_REJECT_BY_ID_SUCCESS:"ACCEPT_PENALTY_REJECT_BY_ID_SUCCESS",
  ACCEPT_PENALTY_REJECT_BY_ID_FAILURE:"ACCEPT_PENALTY_REJECT_BY_ID_FAILURE",

  //Reject Penalty Reject By Id
  REJECT_PENALTY_REJECT_BY_ID_REQUEST:"REJECT_PENALTY_REJECT_BY_ID_REQUEST",
  REJECT_PENALTY_REJECT_BY_ID_SUCCESS:"REJECT_PENALTY_REJECT_BY_ID_SUCCESS",
  REJECT_PENALTY_REJECT_BY_ID_FAILURE:"REJECT_PENALTY_REJECT_BY_ID_FAILURE",

  //Po data over 1 million excl. VAT
  GET_PO_DATA_OVER_1MILLION_REQUEST:"GET_PO_DATA_OVER_1MILLION_REQUEST",
  GET_PO_DATA_OVER_1MILLION_SUCCESS:"GET_PO_DATA_OVER_1MILLION_SUCCESS",
  GET_PO_DATA_OVER_1MILLION_FAILURE:"GET_PO_DATA_OVER_1MILLION_FAILURE",

  //Send PO Attachment
  SEND_PO_ATTACHMENT_REQUEST:"SEND_PO_ATTACHMENT_REQUEST",
  SEND_PO_ATTACHMENT_SUCCESS:"SEND_PO_ATTACHMENT_SUCCESS",
  SEND_PO_ATTACHMENT_FAILURE:"SEND_PO_ATTACHMENT_FAILURE",

  //Delete Po Attachment
  DELETE_PO_ATTACHMENT_REQUEST:"DELETE_PO_ATTACHMENT_REQUEST",
  DELETE_PO_ATTACHMENT_SUCCESS:"DELETE_PO_ATTACHMENT_SUCCESS",
  DELETE_PO_ATTACHMENT_FAILURE:"DELETE_PO_ATTACHMENT_FAILURE",


  //Admin Dashboard Constants
  ADMIN_DASHBOARD_STATUS_COUNT_LOADING:"ADMIN_DASHBOARD_STATUS_COUNT_LOADING",
  ADMIN_DASHBOARD_STATUS_COUNT_SUCCESS:"ADMIN_DASHBOARD_STATUS_COUNT_SUCCESS",
  ADMIN_DASHBOARD_STATUS_COUNT_FAILURE:"ADMIN_DASHBOARD_STATUS_COUNT_FAILURE",

  //Admin Dashboard Vendor Register and Status
  ADMIN_VENDOR_REGISTER_LOADING:"ADMIN_VENDOR_REGISTER_LOADING",
  ADMIN_VENDOR_REGISTER_SUCCESS:"ADMIN_VENDOR_REGISTER_SUCCESS",
  ADMIN_VENDOR_REGISTER_FAILURE:"ADMIN_VENDOR_REGISTER_FAILURE",

  //ADMIN DASHBOARD PR STATS
  ADMIN_DASHBOARD_PR_STATS_LOADING:"ADMIN_DASHBOARD_PR_STATS_LOADING",
  ADMIN_DASHBOARD_PR_STATS_SUCCESS:"ADMIN_DASHBOARD_PR_STATS_SUCCESS",
  ADMIN_DASHBOARD_PR_STATS_FAILURE:"ADMIN_DASHBOARD_PR_STATS_FAILURE",


  //ADMIN DASHBOARD PR DATA 
  ADMIN_DASHBOARD_PR_DATA_LOADING:"ADMIN_DASHBOARD_PR_DATA_LOADING",
  ADMIN_DASHBOARD_PR_DATA_SUCCESS:"ADMIN_DASHBOARD_PR_DATA_SUCCESS",
  ADMIN_DASHBOARD_PR_DATA_FAILURE:"ADMIN_DASHBOARD_PR_DATA_FAILURE",

  //ADMIN DASHBOARD PO STATS
  ADMIN_DASHBOARD_PO_STATS_LOADING:"ADMIN_DASHBOARD_PO_STATS_LOADING",
  ADMIN_DASHBOARD_PO_STATS_SUCCESS:"ADMIN_DASHBOARD_PO_STATS_SUCCESS",
  ADMIN_DASHBOARD_PO_STATS_FAILURE:"ADMIN_DASHBOARD_PO_STATS_FAILURE",

  //ADMIN DASHBOARD PO DATA
  ADMIN_DASHBOARD_PO_DATA_LOADING:"ADMIN_DASHBOARD_PO_DATA_LOADING",
  ADMIN_DASHBOARD_PO_DATA_SUCCESS:"ADMIN_DASHBOARD_PO_DATA_SUCCESS",
  ADMIN_DASHBOARD_PO_DATA_FAILURE:"ADMIN_DASHBOARD_PO_DATA_FAILURE",

  //ADMIN DASHBOARD SUPPLIER PERFORMANCE
  ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_LOADING:"ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_LOADING",
  ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_SUCCESS:"ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_SUCCESS",
  ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_FAILURE:"ADMIN_DASHBOARD_SUPPLIER_PERFORMANCE_FAILURE",


  //History Storage  PO DATA
  HISTORY_STORAGE_PO_DATA_LOADING:"HISTORY_STORAGE_PO_DATA_LOADING",
  HISTORY_STORAGE_PO_DATA_FAILURE:"HISTORY_STORAGE_PO_DATA_FAILURE",
  HISTORY_STORAGE_PO_DATA_SUCCESS:"HISTORY_STORAGE_PR_DATA_SUCCESS",


  //History Storage Vendor Data With Po Id
  HISTORY_STORAGE_VENDOR_DATA_LOADING:"HISTORY_STORAGE_VENDOR_DATA_LOADING",
  HISTORY_STORAGE_VENDOR_DATA_SUCCESS:"HISTORY_STORAGE_VENDOR_DATA_SUCCESS",
  HISTORY_STORAGE_VENDOR_DATA_FAILURE:"HISTORY_STORAGE_VENDOR_DATA_FAILURE",


  //Quotations:
  QUOTATION_DATA_LOADING: "QUOTATION_DATA_LOADING",
  QUOTATION_DATA_SUCCESS: "QUOTATION_DATA_SUCCESS",
  QUOTATION_DATA_FAILURE: "QUOTATION_DATA_FAILURE",

  //PO Data
  PO_DATA_LOADING: "PO_DATA_LOADING",
  PO_DATA_SUCCESS: "PO_DATA_SUCCESS",
  PO_DATA_FAILURE: "PO_DATA_FAILURE",

  //Penalty Attachments
  PENALTY_ATTACHMENTS_LOADING: "PENALTY_ATTACHMENTS_LOADING",
  PENALTY_ATTACHMENTS_SUCCESS: "PENALTY_ATTACHMENTS_SUCCESS",
  PENALTY_ATTACHMENTS_FAILURE: "PENALTY_ATTACHMENTS_FAILURE",

  //vendor logs
  VENDOR_CHANGES_LOG_LOADING:"VENDOR_CHANGES_LOG_LOADING",
  VENDOR_CHANGES_LOG_SUCCESS:"VENDOR_CHANGES_LOG_SUCCESS",
  VENDOR_CHANGES_LOG_FAILURE:"VENDOR_CHANGES_LOG_FAILURE",
};

module.exports = ActionTypes;
