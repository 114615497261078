import React, { useEffect, useState } from "react";
import "./EvaluatedPage.css";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader, useDispatch, useSelector, useTranslation } from "../../../src/Components/Imports/import";
import VectorArrow from "../../Assets/Images/backArrow.png";
import { getEvaluationFormData, submitEvaluation, updateEvaluation } from "../../Store/actions/supplierManagementActions";
import { useForm } from "react-hook-form";
import { formatNumber } from "../../Utils/Utils";

const evaluationCriteria = [
  {
    category: "การให้บริการ",
    subCriteria: [
      {
        title: "1.1 ผู้ให้บริการด้านสอบเทียบได้มาตรฐานตรงตามระบบคุณภาพ",
        ratings: [
          { score: 4, description: "ได้รับการรับรองระบบคุณภาพตามที่กำหนด" },
          { score: 3, description: "ได้รับการรับรองระบบคุณภาพไม่ตรงตามที่กำหนด แต่สามารถยอมรับได้" },
          { score: 2, description: "ได้รับการรับรองระบบคุณภาพไม่ตรงตามที่กำหนด แต่สามารถยอมรับได้บางส่วน" },
          { score: 1, description: "ไม่ได้รับการรับรองใดๆเลย" }
        ]
      },
      {
        title: "1.2 คุณภาพการให้บริการ",
        ratings: [
          { score: 4, description: "ได้มาตรฐานเป็นที่ยอมรับ ไม่มีข้อผิดพลาด" },
          { score: 3, description: "การให้บริการมีข้อผิดพลาด 1 ครั้ง" },
          { score: 2, description: "การให้บริการมีข้อผิดพลาด 2-3 ครั้ง" },
          { score: 1, description: "การให้บริการมีข้อผิดพลาดมากกว่า 3 ครั้ง" }
        ]
      },
      {
        title: "1.3 ความชำนาญและการให้ความรู้ของผู้ให้บริการ",
        ratings: [
          { score: 4, description: "ดีเยี่ยม" },
          { score: 3, description: "ดี" },
          { score: 2, description: "พอใช้" },
          { score: 1, description: "ต้องปรับปรุง" }
        ]
      },
      {
        title: "1.4 ความรวดเร็วและถูกต้องในการสนับสนุนด้านเทคนิค",
        ratings: [
          { score: 4, description: "ดีเยี่ยม" },
          { score: 3, description: "ดี" },
          { score: 2, description: "พอใช้" },
          { score: 1, description: "ต้องปรับปรุง" }
        ]
      }
    ]
  },
  {
    category: "การส่งมอบ",
    subCriteria: [
      {
        title: "2.1 การให้บริการตรงตามกำหนดและเสร็จสิ้นตามเวลาที่ระบุ",
        ratings: [
          { score: 4, description: "เสร็จสิ้นตามเวลาที่กำหนด" },
          { score: 3, description: "งานเสร็จหลังเวลาที่กำหนด 1 วัน" },
          { score: 2, description: "งานเสร็จหลังเวลาที่กำหนดภายใน 3 วัน" },
          { score: 1, description: "งานเสร็จหลังเวลาที่กำหนดเกินกว่า 3 วัน" }
        ]
      },
      {
        title: "2.2 เอกสารประกอบการส่งมอบสินค้า",
        ratings: [
          { score: 4, description: "สมบูรณ์ครบถ้วนสามารถอ้างอิงได้" },
          { score: 3, description: "สมบูรณ์สามารถอ้างอิงได้ มีจุดบกพร่องเล็กน้อย" },
          { score: 2, description: "ไม่สมบูรณ์แต่สามารถใช้อ้างอิงได้แต่ต้องแก้ไขหลายจุด" },
          { score: 1, description: "ไม่สมบูรณ์ครบถ้วนไม่สามารถใช้อ้างอิงได้" }
        ]
      },
      {
        title: "2.3 ความรวดเร็วในการส่งรายงาน",
        ratings: [
          { score: 4, description: "ตามเวลาที่กำหนด" },
          { score: 3, description: "ไม่เกิน 2 วัน" },
          { score: 2, description: "ไม่เกิน 5 วัน" },
          { score: 1, description: "เกินกว่า 5 วัน" }
        ]
      }
    ]
  },
  {
    category: "การบริการ",
    subCriteria: [
      {
        title: "3.1 การแก้ไขงาน",
        ratings: [
          { score: 4, description: "ไม่มีการแก้ไขงาน" },
          { score: 3, description: "สามารถส่งงานที่แก้ไขกลับภายใน 3 วัน" },
          { score: 2, description: "สามารถส่งงานที่แก้ไขกลับภายใน 5 วัน" },
          { score: 1, description: "สามารถส่งงานที่แก้ไขกลับเกินกว่า 5 วัน" }
        ]
      },
      {
        title: "3.2 การให้บริการเมื่อมีการเปลี่ยนแปลง",
        ratings: [
          { score: 4, description: "สามารถปรับเปลี่ยนเป็นไปตามความต้องการได้" },
          { score: 3, description: "สามารถปรับเปลี่ยนได้ภายในเวลา 3 วัน" },
          { score: 2, description: "สามารถปรับเปลี่ยนได้ภายในเวลา 5 วัน" },
          { score: 1, description: "ไม่สามารถปรับเปลี่ยนได้เลย" }
        ]
      },
      {
        title: "3.3 การดำเนินการเมื่อมีข้อเรียกร้อง",
        ratings: [
          { score: 4, description: "ดำเนินการได้ทันทีตามความต้องการ" },
          { score: 3, description: "ดำเนินการได้แต่ต้องใช้เวลา" },
          { score: 2, description: "ดำเนินการได้บางส่วน" },
          { score: 1, description: "ไม่สามารถดำเนินการได้เลย" }
        ]
      },
      {
        title: "3.4 การปฏิบัติตามกฏระเบียบของวีนิไทย",
        ratings: [
          { score: 4, description: "ปฏิบัติตามกฏระเบียบของวีนิไทยได้อย่างดี" },
          { score: 3, description: "ปฏิบัติได้แต่มีการตักเตือนด้วยวาจา" },
          { score: 2, description: "ปฏิบัติได้แต่มีการตักเตือนด้วยเอกสาร" },
          { score: 1, description: "ไม่สามารถปฏิบัติตามกฏระเบียบได้" }
        ]
      }
    ]
  }
];


function ServiceEvaluation({evaluationFormById,isReadOnly=false,isEmailUser=false,id}) {
  const { register, handleSubmit, getValues } = useForm();
  const [ratings, setRatings] = useState([]);
  const [ratingsData, setRatingsData] = useState([]);
  const [data,setData]=useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
 
  const dispatch=useDispatch();
  const {loading,error } = useSelector(
    (state) => state.getSupplierManagementInfo
  );
  const [scores,setScores]=useState({});
  const [topic1Score,setTopic1Score]=useState(evaluationFormById?.topic1Score || 0)
  const [topic2Score,setTopic2Score]=useState(evaluationFormById?.topic2Score || 0)
  const [topic3Score,setTopic3Score]=useState(evaluationFormById?.topic3Score || 0)
  
  const [evaluatedData, setEvaluatedData] = useState({
    improvements: evaluationFormById?.improvements || "",
    maintainServiceInfo: evaluationFormById?.maintainServiceInfo || "",
    supplierApprovalDecision: evaluationFormById?.supplierApprovalDecision || 0,
    cause: evaluationFormById?.cause || ""
  });
  
  useEffect(() => {
    setTopic1Score(evaluationFormById?.topic1Score || 0);
    setTopic2Score(evaluationFormById?.topic2Score || 0);
    setTopic3Score(evaluationFormById?.topic3Score || 0);

    if (evaluationFormById?.evaluationRatings) {
      const initialRatingsData = evaluationFormById.evaluationRatings.map(({ evaluationRatingId, rating }) => ({
        evaluationRatingId,
        rating,
      }));
      setRatingsData(initialRatingsData);
    } else {
      setRatingsData([]);
    }

    setEvaluatedData({
      improvements: evaluationFormById?.improvements || "",
      maintainServiceInfo: evaluationFormById?.maintainServiceInfo || "",
      supplierApprovalDecision: evaluationFormById?.supplierApprovalDecision || 0,
      cause: evaluationFormById?.cause || ""
    })
  },[evaluationFormById])

  const handleRadioChange = (value) => {
    setEvaluatedData(prevData => ({
      ...prevData,
      supplierApprovalDecision: parseInt(value)
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEvaluatedData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

//function for grouping the data by topics
    const groupedTopics = evaluationFormById?.evaluationRatings?.reduce((acc, curr) => {
        const { topic, subTopic, evaluationRatingId, rating } = curr;
        const { topicId, topicName, topicWeight } = topic;
        const existingTopic = acc.find(t => t.topicId === topicId);
    
        const subTopicData = {
            subTopicId: subTopic.subTopicId,
            subTopicName: subTopic.subTopicName,
            evaluationRatingId,
            rating,
        };
    
        if (existingTopic) {
            existingTopic.subTopics.push(subTopicData);
        } else {
            acc.push({
                topicId,
                topicName,
                topicWeight,
                subTopics: [subTopicData],
            });
        }
    
        return acc;
    }, []);
    

  

  const vendorInfo = [
    {
      label: "PO Number",
      value: evaluationFormById?.poNo,
    },
    {
      label: "Vendor Code",
      value: evaluationFormById?.vendorCode,
    },
  ];

  useEffect(() => {
    if (groupedTopics) {
      setData(groupedTopics);
    }
  }, [evaluationFormById]);
  
 
  const evaHeaders = ["No.", "Evaluation", "(%)", "4", "3", "2", "1","Not Applicable"];
  const evaHeadersWidths = ["6%", "32%", "10%", "10%", "8%", "10%", "10%","15%"];

  const evaData = groupedTopics?.flatMap((topic, index) => {
    let labels;
    if (index === 1) {
      labels = ["", "", "", "",""];
    } else if (index === 2) {
      labels = ["", "", "", "",""];
    } else {
      labels = ["", "", "", "",""];
    }

  
    //structure for the topicRow
    const topicRow = {
      isTopic: true, 
      topicId: topic.topicId,
      subTopicId: null, 
      cells: [
        `${index + 1}.`,
        topic.topicName,
        `${topic.topicWeight}%`,
        ...labels
      ]
    };
  
   
    const subTopicRows = topic.subTopics.map((subTopic) => ({
      isTopic: false,
      topicId: topic?.topicId,
      subTopicId: subTopic?.subTopicId,
      rating:subTopic?.rating,
      evaluationRatingId:subTopic?.evaluationRatingId,
      cells: [
        " ",
        subTopic.subTopicName,
        "", "", "", "", "",""
      ]
    }));
  
    return [topicRow, ...subTopicRows];
  }) ||[];
  
  evaData.push({
    isTopic: false,
    cells: [
      "",              
      "Column Totals", 
      "100%",          
      "", "", "", "","", 
    ]
  });
  
  evaData.push({
    isTopic: false,
    cells: [
      "",              
      "Grand Total", 
      "",          
      "", "", "","", Math.ceil((topic1Score+topic2Score+topic3Score)*100) 
    ]
  });
    //function to get sum of keys
    function getSumOfValuesForKey(key){
     
      const valuesObject = scores[key];
      if (valuesObject) {
          return Object.values(valuesObject).reduce((sum, value) => sum + value, 0);
      }
      return 0;
  };
  function calculateScore (index){
   
      const sumForKey = getSumOfValuesForKey(index);
      
      const numOfSubTopics = Object.keys(scores[index] || {}).length;
      const topicWeight =  groupedTopics.find(topic => topic?.topicId == index)?.topicWeight || null;
     
      const score1 = numOfSubTopics ? ((sumForKey )/ (numOfSubTopics * 4)) * (topicWeight/100) : 0;
      
      return score1?score1:0;
  }
 

   //function for marking selected checkboxes 
   function initializeSelectedCheckboxes (data) {
    return data.reduce((acc, row, index) => {
      if (!row.isTopic && row.rating !== undefined) {
        acc[index] = row.rating;
      }
      return acc;
    }, {});
  };
  useEffect(()=>{
    const checkboxData=initializeSelectedCheckboxes(evaData)
    
    setSelectedCheckboxes(checkboxData)
    const formattedObject = groupedTopics?.reduce((acc, topic) => {
      const subTopicRatings = topic?.subTopics.reduce((subAcc, subTopic) => {
        subAcc[subTopic.subTopicId] = subTopic?.rating;
        return subAcc;
      }, {});
      acc[topic.topicId] = subTopicRatings;
      return acc;
    }, {});
    setScores(formattedObject)
  },[evaluationFormById,id])
  useEffect(() => {
    const keys = Object.keys(scores);
    const score2=calculateScore(keys[1])
    const score1=calculateScore(keys[0]);
    const score3=calculateScore(keys[2]);
    setTopic1Score(score1)
    setTopic2Score(score2);
    setTopic3Score(score3);
  },[scores])
  

  //function for the rating 
  function getRating(percentage) {
    switch (true) {
      case (percentage > 90):
        return 'excellent';
      case (percentage > 80):
        return 'good according to standard';
      case (percentage > 70):
        return 'fair level';
      case (percentage > 60):
        return 'acceptable';
      case (percentage > 50):
        return 'should improve';
      default:
        return 'below standard'; 
    }
  }
  //function to send the data
  function handleSend(e){
    e.preventDefault();
    const scoreLevel=getRating((topic1Score+topic2Score+topic3Score)*100)
          const bodyData={
      vendorCode:evaluationFormById?.vendorCode,
      poNo: evaluationFormById?.poNo,
      evaluatorEmail: evaluationFormById?.evaluatorEmail,
      evaluatedDate: evaluationFormById?.evaluatedDate,
      topic1Score: topic1Score, 
      topic1Percantages: topic1Score*100, 
      topic2Score: topic2Score,
      topic2Percantages: topic2Score*100,
      topic3Score: topic3Score,
      topic3Percantages: topic3Score*100,
      overAllPercantages: (topic1Score+topic2Score+topic3Score)*100,
      overAllScore: topic1Score+topic2Score+topic3Score,
      scoreLevel: scoreLevel,
      evaluationFormId: evaluationFormById?.evaluationFormId,
      PoPrDbId: evaluationFormById?.PoPrDbId,
      PoDbId: evaluationFormById?.PoDbId,
      vendorId: evaluationFormById?.vendorId,
      ratingsData:ratingsData,
      improvements: evaluatedData?.improvements || "",
    maintainServiceInfo: evaluatedData?.maintainServiceInfo || "",
    supplierApprovalDecision: evaluatedData?.supplierApprovalDecision || 0,
    cause: evaluatedData?.cause || ""
          }
         
          isEmailUser?dispatch(submitEvaluation(id,bodyData)):dispatch(updateEvaluation(id,bodyData))  
  
    
      }
  //function to handle checkbox click
  const handleCheckboxClick = (topicId, subTopicId, rowIndex, cellIndex, evaluationRatingId) => {
    const rating = evaHeaders[cellIndex] === "Not Applicable" ? 0 : parseInt(evaHeaders[cellIndex], 10);
    
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [rowIndex]: evaHeaders[cellIndex] === "Not Applicable" ? 0 : parseInt(evaHeaders[cellIndex], 10),
    }));
  
    
    setRatingsData((prevRatings) => {
      const index = prevRatings.findIndex(
        (item) => item.evaluationRatingId === evaluationRatingId
      );
      const updatedRatings = [...prevRatings];
  
      if (index !== -1) {
        updatedRatings[index] = {
          ...updatedRatings[index],
          rating,
        };
      } else {
        updatedRatings.push({
          evaluationRatingId,
          rating,
        });
      }
      return updatedRatings;
    });
    setScores((prevScores) => {
      const updatedScores = { ...prevScores };
      if (updatedScores[topicId] && updatedScores[topicId][subTopicId] !== undefined) {
        updatedScores[topicId][subTopicId] = rating;
      }
      return updatedScores;
    });
  };
 
  

  const TOTAL_ROW_INDEX = evaData.length;
  const GRAND_TOTAL_ROW_INDEX = evaData.length;

  
  // Functions
  const shouldHideBorder = (cellIndex, rowIndex) => {
    return (
      (rowIndex === evaData.length && cellIndex >= 0 && cellIndex <= 1) ||
      (rowIndex === evaData.length+2 && cellIndex >= 0 && cellIndex <= 5)
    );
  };

  const getTextAlignStyle = (cellIndex, rowIndex) => {
    return (rowIndex === TOTAL_ROW_INDEX ||
      rowIndex === GRAND_TOTAL_ROW_INDEX) &&
      cellIndex >= 0
      ? "end"
      : "";
  };

  const isSpecialCell = (cellIndex, rowIndex, isTopic) => {
    if (isTopic) {
      return true;
    } else if (
      cellIndex === 2 && [1, 2, 3].includes(rowIndex)
    ) 
    return false;
  };

  const getBackgroundColor = (cellContent, cellIndex, rowIndex) => {
    if (
      cellContent === "" &&
      [2].includes(cellIndex) && //create an array of evaData length
      [1,2, 3, 4, 5, 6,7, 8, 9, 10,11,12,13,14,15,16].includes(rowIndex)
    ) {
      return "#ADB2B9";
    } else if (rowIndex === evaData.length-2 && cellIndex >= 0 && cellIndex <= 1) {
      return "#adb2b9"; // Background color for column totals row
    } else if (rowIndex === evaData.length-1 && cellIndex >= 0 && cellIndex <= 5) {
      return "#adb2b9"; // Background color for grand total row
    } else if (rowIndex === 1 && cellIndex >= 6 && cellIndex <= 6) {
      return ""; // Background color for grand total row
    }
    return "";
  };

  const getCellStyle = (cellContent, cellIndex, rowIndex) => {
    if (rowIndex === TOTAL_ROW_INDEX && cellContent === "100%") {
      return {
        color: "#103a5d",
        textAlign: getTextAlignStyle(cellIndex, rowIndex),
        width: evaHeadersWidths[cellIndex],
        backgroundColor: getBackgroundColor(cellContent, cellIndex, rowIndex),
        border: shouldHideBorder(cellIndex, rowIndex) ? "none" : "",
      };
    } else {
      return {
        width: evaHeadersWidths[cellIndex],
        backgroundColor: getBackgroundColor(cellContent, cellIndex, rowIndex),
        border: shouldHideBorder(cellIndex, rowIndex) ? "none" : "",
        textAlign: getTextAlignStyle(cellIndex, rowIndex),
      };
    }
  };


  const EvaluatedTableRow = React.memo(({ rowIndex, row, topicId, subTopicId,rating,isTopic,evaluationRatingId  }) => (
    <div
      className={`evaluated-table-row ${
        rowIndex === TOTAL_ROW_INDEX || rowIndex === GRAND_TOTAL_ROW_INDEX
          ? "eva-tbl-total-row"
          : ""
      }`}
    >
      {row?.map((cellContent, cellIndex) => (
        <EvaluatedTableCell
          key={cellIndex}
          cellContent={cellContent}
          cellIndex={cellIndex}
          rowIndex={rowIndex}
          topicId={topicId} 
          subTopicId={subTopicId} 
          rating={rating}
          isTopic={isTopic}
          evaluationRatingId={evaluationRatingId}
        />
      ))}
    </div>
  ));
  const EvaluatedTableCell = React.memo(
    ({ cellContent, cellIndex, rowIndex, topicId, subTopicId, isTopic,rating ,evaluationRatingId }) => {
      const specialCell = isSpecialCell(cellIndex, rowIndex,isTopic);
      const style = getCellStyle(cellContent, cellIndex, rowIndex);
  
      const isCheckbox = !isTopic && cellContent === "" &&
      
        ((evaHeaders[cellIndex] === "4" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||(evaHeaders[cellIndex] === "3" && rowIndex >= 1 && rowIndex <= evaData.length - 3)
         ||
         (evaHeaders[cellIndex] === "2" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||
         (evaHeaders[cellIndex] === "Not Applicable" && rowIndex >= 1 && rowIndex <= evaData.length - 3) ||
         (evaHeaders[cellIndex] === "1" &&
          rowIndex >= 1 &&
          rowIndex <= evaData.length - 3));
          
  
          const isChecked = selectedCheckboxes[rowIndex] === parseInt(evaHeaders[cellIndex]) || (selectedCheckboxes[rowIndex] === 0 && evaHeaders[cellIndex] === "Not Applicable");

  
      return (
        <div
          className={`evaluated-table-cell ${specialCell ? "special-cell" : ""}`}
          style={style}
        >
          {isCheckbox ? (
            <input
              className="eva-table-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheckboxClick(topicId, subTopicId, rowIndex, cellIndex,evaluationRatingId )}
              disabled={isReadOnly}
            />
          ) : (
            <p style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{cellContent}</p>
            
          )}
        </div>
      );
    }
  );
  
  
  //small table
  const arrowImg=<svg width="299" height="9" viewBox="0 0 299 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M298.436 4.85355C298.631 4.65829 298.631 4.34171 298.436 4.14645L295.254 0.964466C295.059 0.769204 294.742 0.769204 294.547 0.964466C294.351 1.15973 294.351 1.47631 294.547 1.67157L297.375 4.5L294.547 7.32843C294.351 7.52369 294.351 7.84027 294.547 8.03553C294.742 8.2308 295.059 8.2308 295.254 8.03553L298.436 4.85355ZM0 5L298.082 5V4L0 4L0 5Z" fill="#103A5D"/>
  </svg>
  const EvaSmallData = [
    ["85-100%","=", "A",arrowImg
      ,"Exellent performance (ดีมาก)"],
    ["70-<84%","=","B",arrowImg, "Good performance (ดี)"],
    ["50-<69%","=","C",arrowImg, "Average performance (พอใช้)"],
    ["<50%","=", "D",arrowImg,"Below performance (พอใช้)"],
  ];

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/Supplier-Management");
  };
  return (
   
      <section className={`${isEmailUser?"um-not-main":"um-main"}`}>
        {loading && <PageLoader/>}
        
        <div className="um-title-btn">
        {!isEmailUser &&<div className="back-button" onClick={handleGoBack}>
            <img src={VectorArrow} alt="Back Arrow" />
            {t("back")}
          </div>}
        </div>
        <br></br>
        <h1 className="hs-title">{t("Evaluated")}</h1>
        <br></br>

        <div className="evaluated-info-container">
          <input className="evaluated-info-box" type="text" value={evaluationFormById?.evaluatorEmail} readOnly/>
          
          <input className="evaluated-info-box" type="date" value={evaluationFormById?.evaluatedDate ? evaluationFormById.evaluatedDate.split('T')[0] : ''}  readOnly/>
        </div>

        <br></br>

        <div className="evaluated-section">
          <div className="evaluated-vendor-title">
            <div className="evaluated-vendor-title-text">
              {t("Vendor/ Supplier Information")}
            </div>
          </div>
          <div className="evaluated-vendor-info-container">
            {vendorInfo.map((item) => (
              <div key={item.label} className="evaluated-vendor-info">
                <div className="evaluated-vendor-label">{item.label}:</div>
                <div className="evaluated-vendor-value">{item.value}</div>
              </div>
            ))}
          </div>
        </div>
        <br></br>
        {/* Table code */}
        <div className={` ${isReadOnly ? "eva-read-only disabled-table" : ""} evaluated-table`}>
          <div className="evaluated-table-row eva-tbl-header-row">
            {evaHeaders.map((header, index) => (
              <div
                key={index}
                className={`evaluated-table-header-cell ${
                  index === 0 ? "header-no-cell" : ""
                } eva-tbl-header-textt`}
                style={{
                  width: evaHeadersWidths[index],
                }}
              >
                {header}
              </div>
            ))}
          </div>
          {evaData.map((row, rowIndex) => (
            <>
  <EvaluatedTableRow
    key={rowIndex}
    rowIndex={rowIndex}
    row={row?.cells}
    topicId={row?.topicId}
    subTopicId={row?.subTopicId}
    isTopic={row?.isTopic} 
    rating={row?.rating}
    evaluationRatingId={row?.evaluationRatingId}
  />
  </>
))}
       
        </div>
        <br></br>
        <br></br>
        {/* small table */}
        <div className="eva-supplier-top-container">
        <div className="Criteria">Criteria :</div>

        <div className="EvaSmall-table-container-supplier">
          <table className="eva-table-supplier">
            
            <tbody>
              {EvaSmallData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {rowData.map((cellData, cellIndex) => (
                    <td key={cellIndex}>{cellData}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <p className="remark"><span>Remark :</span>  Re-evaluate shall be made in 12 months</p>
        </div>
        </div>
        <br></br>
        <br />
       <div className="Criteria">
        Final Supplier Approval Decision
      </div>
      <div className="checkbox-selection">
        <br />
        <div style={{width:"40%", display:"flex", justifyContent:"space-between"}}>
          <label>
            <input 
              type="radio" 
              {...register('selectedOption')} 
              value={0}  
              className="input-radio"
              checked={evaluatedData.supplierApprovalDecision === 0}
              onChange={(e) => handleRadioChange(e.target.value)}
              disabled={isReadOnly}
            />
            Accepted (ยอมรับ)
          </label>
          <label>
            <input 
              type="radio" 
              {...register('selectedOption')} 
              value={1} 
              className="input-radio" 
              checked={evaluatedData.supplierApprovalDecision === 1}
              onChange={(e) => handleRadioChange(e.target.value)}
              disabled={isReadOnly}
            />
            Rejected (ยกเลิกการใช้บริการ)
          </label>
        </div>
        <label>
          <input 
            type="radio" 
            {...register('selectedOption')} 
            value={2} 
            className="input-radio"
            checked={evaluatedData.supplierApprovalDecision === 2}
            onChange={(e) => handleRadioChange(e.target.value)}
            disabled={isReadOnly}
          />
          {`Accepted in case the score <50%, please explain (กรณีคะแนนตำ่กว่า <50% ถ้าต้องการใช้บริการซื้อ-ขายต่อ โปรดอธิบายหัวข้อด้านล่างนี้)`}
        </label>
      </div>
    <br />
    <div className="inputTextbox">
      <label>
        {`Maintained Service (เหตุผลที่ต้องการใช้บริการต่อ)`}
      </label>
      <textarea 
        rows="4" 
        name="maintainServiceInfo"
        value={evaluatedData?.maintainServiceInfo}
        onChange={handleInputChange}
        className="textArea-selected"
        disabled={isReadOnly}
      />
    </div>
    <br />
    <div className="inputTextbox">
      <label>
        {`Cause (ข้อบกพร่องที่ควรปรับปรุง)`}
      </label>
      <textarea 
        rows="4" 
        name="cause"
        value={evaluatedData?.cause}
        onChange={handleInputChange}
        className="textArea-selected"
        disabled={isReadOnly}
      />
    </div>
    <br />
    <div className="inputTextbox">
      <label>
        {`Improvement (แนวทางหรือวิธีการปรับปรุงแก้ไข)`}
      </label>
      <textarea 
        rows="4" 
        name="improvements"
        value={evaluatedData?.improvements}
        onChange={handleInputChange}
        className="textArea-selected"
        disabled={isReadOnly}
      />
    </div>

    <br />
    <br />
    <div className="evaText">
    {evaluationCriteria?.map((category, index) => (
        <div key={index}>
          <h2>{category.category}</h2>
          {category?.subCriteria.map((sub, subIndex) => (
            <div key={subIndex}>
              <h3>{sub.title}</h3>
              <ul>
                {sub?.ratings?.map((rating, ratingIndex) => (
                  <p key={ratingIndex}>
                    {rating.score} - {rating.description}
                  </p>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
    
        
        <br></br>
        {!isReadOnly && <div style={{display:"flex",justifyContent:"flex-end"}}> <button  className="table-btn" onClick={handleSend}>Send</button></div>}
        <br></br>
      
        <br></br>
      
      </section>
   
  );
}

export default ServiceEvaluation;
