var BaseUrl = "https://apis-dev.avt-vms.com/api/";

if (window.location.hostname != "admin.avt-vms.com") {
  BaseUrl = "https://apis-dev.avt-vms.com/api/";
} else {
  BaseUrl = "https://apis.avt-vms.com/api/";
}

module.exports = {
  BaseUrl,
};
