import TYPES from "../constants";

const initialState = {
  updatedVendor: {},
  error: null,
  latestVendorInfo: {},
  vendorLogs :{},
  loading:false,
};

const AdminUpdateVendor = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.UPDATE_VENDOR:
      return {
        ...state,
        updatedVendor: action.payload,
        error: null,
      };
    case TYPES.VENDOR_CHANGES_LOG_LOADING:
      return {
        ...state,
        vendorLogs: action.payload,
        error:null,
      }
    case TYPES.VENDOR_CHANGES_LOG_SUCCESS:
      return {
        ...state,
        vendorLogs: action.payload,
        error:null,
        loading:false
      }
    case TYPES.UPDATE_VENDOR_FAILURE:
    case TYPES.VENDOR_CHANGES_LOG_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TYPES.LATEST_VENDOR_INFO:
      return {
        ...state,
        latestVendorInfo: action.payload,
      };
    case TYPES.CLEAR_LATEST_VENDOR_INFO:
      return {
        ...state,
        latestVendorInfo: action.payload,
      };
    case TYPES.CLEAR_STORE_INFO:
      return initialState;

    default:
      return state;
  }
};
export default AdminUpdateVendor;
