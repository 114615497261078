import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./VendorHistory.css";

import { toast } from "react-toastify";
import PageLoader from "../PageLoader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { getAllVendorLogs } from "../../Store/actions/vendorMangementAction";
const keyMapping = {
  registration_capital:"Registration Capital",
  company_name_eng:"Company Name (eng)",
  company_name_th:"Company Name (thai)",
  contact_name:"Contact Name",
  title_name:"Title Name",
  contact_email:"Contact Email",
  contact_email2:"Contact Email2",
  street_house_no:"Street House No",
  country:"Country",
  city:"City",
  district:"District",
  bank_account_holder:"Bank Account Holder",
  account_number:"Account Number",
  currency:"Currency",
  bank_name:"Bank Name",
  payment_method:"Payment Method",
  credit_term:"Credit Term",
  bank_info_doc:"Bank Info Doc",
  vat_registration_doc:"Vat Registration Doc",
  registration_no:"Registration No",
  mobile_phone:"Mobile Phone",
}

const VendorHistory = ({ isOpen, closeModal, userData }) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { vendorLogs, loading } = useSelector((state) => state.updateVendorReducer);
 
  useEffect(()=>{
    dispatch(getAllVendorLogs({id:userData?.vendor_id}))
  },[userData])


  useEffect(()=>{
    if(vendorLogs?.data?.length > 0){
    const newData=transformData(vendorLogs?.data)
    
    setData(newData || [])
    }

  },[vendorLogs])
  
  function transformData(data) {
    const updatedData = data?.map((item) => {
      const changeLogs = JSON.parse(item.changeLogs);
      const fieldsUpdated = changeLogs.flatMap((log) => {
        return JSON.parse(log.vendorUpdatedData).map((field) => ({
          fieldName: keyMapping[field.fieldName] || field.fieldName,
          oldValue: field.oldValue,
          updatedValue: field.updatedValue,
        }));
      });
  
      return {
        changesMadeOn: item.changesMadeOn,
        fieldsUpdated,
      };
    });
  
    return updatedData;
  }
  



  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="create-user-modal time-stamp-modal"
        overlayClassName="create-modal-overlay"
      >
        <div className="ts-title-close-icon-div">
          <p className="ts-title pb-2">You have made Important changes that Require Re-Approval as Follows:</p>
          <img
            src={closeIcon}
            alt=""
            onClick={() => closeModal()}
            style={{ cursor: "pointer" }}
            id="closeIcon"
          />
        </div>

        <div className=" pt-4">
          
           {loading && <div style={{ marginLeft: "-20px" }}>
              <PageLoader />
            </div>}
         { Array.isArray(data) && data?.length > 0 && 
            data?.map((e, index) => {
            
             
              return(
              <div>   
              <div className="changelog-box">
                  <h3 className="changelog-date-heading">{e?.changesMadeOn}</h3>
                  <div className="changelog-innerboxes">
                    <div className="changelog changelog-title" style={{textWrap: "nowrap"}}>
                      {e?.fieldsUpdated?.map((field)=>(
                       <div>{field?.fieldName}</div> 
                      )   
                      )}
                    
                    </div>
                    <div className="changelog changelog-value" style={{textWrap: "nowrap"}}>
                    {e?.fieldsUpdated?.map((field)=>(
                     <div>{field?.oldValue ||"---"}</div>
                      )   
                      )}
                    </div>
                    <div className="changelog changelog-arrows">
                      {e?.fieldsUpdated?.map((field)=>(
                        <div>{ `->`}</div>
                      
                      )   
                      )}
                      
                    </div>
                    <div className="changelog changelog-value" style={{textWrap: "nowrap"}}>
                    {e?.fieldsUpdated?.map((field)=>(
                         <div>{field?.updatedValue}</div>
                      )   
                      )}
                    </div>
                  </div>
              </div>
              </div>
              )
})
          }
        </div>
      </Modal>
    </div>
  );
};

export default VendorHistory;
