import React from "react";
import { CSVLink } from "react-csv";
import csvIcon from "../../Assets/Svg/csv-icon.svg";

const CSVDownloadButton = ({ data, headers, filename }) => {

  return (
    <CSVLink data={data} headers={headers} filename={filename}>
      <img src={csvIcon} alt="download" />
    </CSVLink>
  );
};

export default CSVDownloadButton;
