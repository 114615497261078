import React, { useEffect, useState } from "react";
import "./EvaluatedPage.css";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageLoader,
  useDispatch,
  useSelector,
  useTranslation,
} from "../../../src/Components/Imports/import";
import VectorArrow from "../../Assets/Images/backArrow.png";
import GoodsEvaluation from "./GoodsEvaluation";
import ServiceEvaluation from "./ServicesEvaluation";
import { getEvaluationDataById } from "../../Store/actions/supplierManagementActions";

function EvaluatedPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [formId, setformId] = useState(0);
  const [evaluationFormById, setEvaluationFormById] = useState({});
  const { singleEvaluationData, loading, error } = useSelector(
    (state) => state.getSupplierManagementInfo
  );

  useEffect(() => {
    dispatch(getEvaluationDataById(id));
  }, []);
  useEffect(() => {
    setformId(singleEvaluationData?.evaluationForm?.evaluationFormId);
    setEvaluationFormById(singleEvaluationData);
  }, [singleEvaluationData]);
  return (
    <>
      {loading && <PageLoader />}
      <DefaultLayout>
        {parseInt(formId) === 1 ? (
          <GoodsEvaluation
            evaluationFormById={evaluationFormById}
            isReadOnly={true}
          />
        ) : parseInt(formId) === 2 ? (
          <ServiceEvaluation
            evaluationFormById={evaluationFormById}
            isReadOnly={true}
          />
        ) : (
          <h1>Refresh the page</h1>
        )}
      </DefaultLayout>
    </>
  );
}
export default EvaluatedPage;
