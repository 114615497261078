import TYPES from "../constants";

const initialState = {
  error: null,
  loading: false,
  getPressReleaseTableData: [],
  createPressRelease: {},
  currentPressReleaseData: {},
  currentPressReleaseRow: {},
  editPressRelease: {},
  particularEditPressRelease: {},
  deletePressReleaseId: null,
};

const getPressReleaseInfo = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CREATE_PRESS_RELEASE_SUCCESS:
      return {
        ...state,
        createPressRelease: action.payload,
        error: null,
      };
    case TYPES.CREATE_PRESS_RELEASE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.CREATE_PRESS_RELEASE_DETAILS:
      return {
        ...state,
        currentPressReleaseData: action.payload,
        error: null,
      };

    case TYPES.GET_ALL_PRESS_RELEASE_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_ALL_PRESS_RELEASE_SUCCESS:
      return {
        ...state,
        getPressReleaseTableData: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_ALL_PRESS_RELEASE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.CURRENT_PRESS_RELEASE_ROW_DATA:
      return {
        ...state,
        currentPressReleaseRow: action.payload,
        error: null,
      };
    case TYPES.EDIT_PRESS_RELEASE_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.EDIT_PRESS_RELEASE_SUCCESS:
      return {
        ...state,
        editPressRelease: action.payload,
        error: null,
        loading: false,
      };
    case TYPES.EDIT_PRESS_RELEASE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.EDIT_PARTICULAR_PRESS_RELEASE:
      return {
        ...state,
        particularEditPressRelease: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default getPressReleaseInfo;
