import TYPES from "../constants";

const initialState = {
  error: null,
  loading: false,
  data:{},
  vendorData:{},
  quotationData:{},
  poData:{},
  penaltyAttachments:[],
};

const HistoryStorageReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.HISTORY_STORAGE_PO_DATA_LOADING:
    case TYPES.HISTORY_STORAGE_VENDOR_DATA_LOADING:
    case TYPES.QUOTATION_DATA_LOADING:
    case TYPES.PO_DATA_LOADING:
    case TYPES.PENALTY_ATTACHMENTS_LOADING:
        return{
            ...state,
            loading:true,
            error:null,
        }
        
    
    case TYPES.HISTORY_STORAGE_PO_DATA_SUCCESS:
        return{
            ...state,
            loading: false,
            error: null,
            data: action.payload,
            
        }
    case TYPES.QUOTATION_DATA_SUCCESS:
        return{
            ...state,
            loading: false,
            error:null,
            quotationData: action.payload,
        }
    case TYPES.HISTORY_STORAGE_VENDOR_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                error: null,
                vendorData: action.payload,
                
            }
    case TYPES.PO_DATA_SUCCESS:
        return{
            ...state,
            loading: false,
            error:null,
            poData: action.payload,
        }
    case TYPES.PENALTY_ATTACHMENTS_SUCCESS:
        return{
            ...state,
            loading:false,
            error:null,
            penaltyAttachments:action.payload,
        }
    case TYPES.HISTORY_STORAGE_PO_DATA_FAILURE:
    case TYPES.HISTORY_STORAGE_VENDOR_DATA_FAILURE:
    case TYPES.QUOTATION_DATA_FAILURE:
    case TYPES.PO_DATA_FAILURE:
    case TYPES.PENALTY_ATTACHMENTS_FAILURE:
        return{
            ...state,
            loading:false,
            error:action.error,
        }
    default:
      return state;
  }
};

export default HistoryStorageReducer;
