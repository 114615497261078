import React, { useState, useRef, useEffect } from "react";
import "./TopBar.css";
import { BsFillBellFill } from "react-icons/bs";
import usFlag from "../../Assets/Svg/us-flag.svg";
import thaiFlag from "../../Assets/Svg/thai-flag.svg";
import dropDownIcon from "../../Assets/Svg/drop-down-icon.svg";
import dropDown from "../../Assets/Images/dropdown.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllLoginDetails } from "../../Store/Reducer/loginDetails";

const TopBar = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const topBarRef = useRef(null);
  const { LoginDetails } = useSelector((state) => state.loginDetails);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [flagDropdownOpen, setFlagDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const currentPathname = window.location.pathname;

  // Access the "name" property from decrypted data
  const userName = LoginDetails?.name;

  // making first letter upper case
  let capitalizedUserName =
    userName?.charAt(0)?.toUpperCase() + userName?.slice(1);

  // Get the first letter of the name
  const firstLetter = userName ? userName?.charAt(0) : "";
  

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    setFlagDropdownOpen(false);
  };

  const handleChangeLanguage = () => {
    setFlagDropdownOpen(!flagDropdownOpen);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setFlagDropdownOpen(false);
  };

  const handleMenuItemClick = (menuItem) => {

    setIsDropdownOpen(false);
    navigate(menuItem);
  };

  useEffect(() => {
    // Function to close dropdowns when clicking outside
    const handleClickOutside = (event) => {
      if (topBarRef.current && !topBarRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setFlagDropdownOpen(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllLoginDetails());
  }, []);

  return (
    <section className="top-bar-main" ref={topBarRef}>
      <div className="top-bar">
        <div className="top-bell">
          <BsFillBellFill />
        </div>
        <div>
          <div
            onClick={handleDropdownToggle}
            className="d-flex"
            style={{ cursor: "pointer" }}
          >
            <p className="tb-name-p">{firstLetter}</p>
            <div className="d-flex justify-content-between">
              <div className="px-2">
                <p className="tb-name">{capitalizedUserName}</p>
                <p className="tb-admin">{LoginDetails?.role}</p>
              </div>
              <div className="px-2 pt-3">
                <img
                  style={{ width: "12px", height: "7px" }}
                  src={dropDown}
                  alt=""
                />
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <div className="menu-dropdown">
              <ul>
                <li
                  // onClick={() => {
                  //   if (!currentPathname.includes("dashboard")) {
                  //     // Handle the click action for the enabled item
                  //     handleMenuItemClick("/dashboard");
                  //   }
                  // }}
                  style={{
                    cursor: "not-allowed",
                  }}
                >
                  Dashboard
                </li>
                <li
                  onClick={() => {
                    if (!currentPathname.includes("admin-account-setting")) {
                      handleMenuItemClick("/admin-account-setting");
                    }
                  }}
                  style={{
                    cursor: currentPathname.includes("admin-account-setting")
                      ? "not-allowed"
                      : "pointer",
                  }}
                >
                  Account Settings
                </li>
                <hr></hr>
                <li onClick={() => handleMenuItemClick("/")}>Logout</li>
              </ul>
            </div>
          )}
        </div>
        <div>
          <div className="" style={{ marginLeft: "30px" }}>
            {selectedLanguage == "thai" ? (
              <img src={thaiFlag} alt="" />
            ) : (
              <img src={usFlag} alt="" />
            )}
            <img
              onClick={handleChangeLanguage}
              style={{
                marginLeft: "10px",
                marginRight: "50px",
                cursor: "pointer",
              }}
              src={dropDownIcon}
              alt=""
            />
          </div>
          {flagDropdownOpen && (
            <div className="flag-dropdown">
              <div className="flag-img-div" onClick={() => handleClick("en")}>
                <img src={usFlag} alt="" />
                <p>English (United States)</p>
              </div>

              <div className="flag-img-div" onClick={() => handleClick("thai")}>
                <img src={thaiFlag} alt="" />
                <p>Thai</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopBar;
