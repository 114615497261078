import TYPES from "../constants";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import {
  CREATE_PRESS_RELEASE,
  EDIT_PRESS_RELEASE,
  GET_ALL_PRESS,
} from "../../ApiEndPoints/ApiEndPoints";

// calling the get all users API for user manangement

export const getAllPressRelease = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(getPressReleaseApiRequest());
      const response = await axios.get(`${BaseUrl}${GET_ALL_PRESS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_PRESS_RELEASE_SUCCESS,
          payload: response.data.data,
        });
        
      } else {
        dispatch({
          type: TYPES.GET_ALL_PRESS_RELEASE_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_PRESS_RELEASE_FAILURE,
        error: error.message,
      });
    }
  };
};

// calling the Create PRESS RELEASE api

export const createNewPressRelease = (userDetails) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      const response = await axios.post(
        `${BaseUrl}${CREATE_PRESS_RELEASE}`,
        userDetails,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.CREATE_PRESS_RELEASE_SUCCESS,
          payload: response.data,
        });
        toast.success(response.data.message);
        return true;
      } else if (response.status === 404 || response.data.status === 401) {
        dispatch({
          type: TYPES.CREATE_PRESS_RELEASE_FAILURE,
          error: "Something went wrong , please try again",
        });
        toast.error("Something went wrong , please try again");
      }
    } catch (error) {
      dispatch({
        type: TYPES.CREATE_PRESS_RELEASE_FAILURE,
        error: error.message,
      });
      toast.error("An error occurred while editing the user.");
    }
  };
};

// Action function for Paricular PRESS_RELEASE DATA

export const createPressRelease = (userData) => {

  return {
    type: TYPES.CREATE_PRESS_RELEASE_DETAILS,
    payload: userData,
  };
};

// Initial action for calling get all user api

export const getPressReleaseApiRequest = () => {
  return {
    type: TYPES.GET_ALL_PRESS_RELEASE_API_REQUEST,
  };
};

// Action function for current row data for tha edit purpose

export const currentPressReleaseRowData = (row) => {
  return {
    type: TYPES.CURRENT_PRESS_RELEASE_ROW_DATA,
    payload: row,
  };
};

// calling the Edit API in for press release

export const editPressRelease = (pressRelease) => {

  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(editPressReleaseRequest());
      const response = await axios.patch(
        `${BaseUrl}${EDIT_PRESS_RELEASE}`,
        pressRelease,
        {
          headers: { Authorization: `Bearer ${token} ` },
        }
      );
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.EDIT_PRESS_RELEASE_SUCCESS,
          payload: response.data,
          loading: false,
          error: null,
        });
        toast.success("Press Data Updated successfully!");
        return true;
      } else if (response.data.status === 401) {
        dispatch({
          type: TYPES.EDIT_PRESS_RELEASE_FAILURE,
          error: "Something went wrong , Please try again",
          loading: false,
        });
        toast.error("Something went wrong , Please try again.");
      }
    } catch (error) {
      dispatch({
        type: TYPES.EDIT_PRESS_RELEASE_FAILURE,
        error: error.message,
        loading: false,
      });
      toast.error("An error occurred while editing the press release.");
    }
  };
};

//Inital action for calling the edit user API
export const editPressReleaseRequest = () => {
  return {
    type: TYPES.EDIT_PRESS_RELEASE_API_REQUEST,
  };
};
// Action function for store the edit data for edit the user details in press release
export const editParticularPressRelease = (editPressRelease) => {
  return {
    type: TYPES.EDIT_PARTICULAR_PRESS_RELEASE,
    payload: editPressRelease,
  };
};
