import TYPES from "../constants";

const initialState = {
  error: null,
  loading: false,
  getAllPrReleaseGroupCountData: [],
  prReleaseTableData: {},
  getAllPrAttachments: [],
  currentPrReleaseData: {},
  prStatusCounts: [],
  filterList:{},
};

const getPrReleaseInfo = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PR_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        prStatusCounts: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.CURRENT_PR_RELEASE_DATA:
      return {
        currentPrReleaseData: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.DELETE_PR_ATTACHMENT_SUCCESS:
    case TYPES.UPDATE_PR_DETAILS_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TYPES.GET_PR_FILTER_DROPDOWN_REQUEST:
    case TYPES.GET_PR_STATUS_COUNT_REQUEST:
    case TYPES.GET_ALL_PR_ATTACHMENTS_REQUEST:
    case TYPES.DELETE_PR_ATTACHMENT_REQUEST:
    case TYPES.UPDATE_PR_DETAILS_ATTACHMENT_REQUEST:
    case TYPES.GET_ALL_PR_RELEASE_GROUP_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_ALL_PR_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        getAllPrAttachments: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_ALL_PR_RELEASE_GROUP_COUNT_SUCCESS:
      return {
        ...state,
        getAllPrReleaseGroupCountData: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.GET_ALL_PR_RELEASE_GROUP_COUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.GET_ALL_PR_RELEASE_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_ALL_PR_RELEASE_SUCCESS:
      return {
        ...state,
        prReleaseTableData: action.payload,
        loading: false,
        error: null,
      };
      case TYPES.GET_PR_FILTER_DROPDOWN_SUCCESS:
        return{
          ...state,
          filterList: action.payload,
          loading: false,
          error:null,
        } 
    case TYPES.GET_PR_FILTER_DROPDOWN_FAILURE:
    case TYPES.GET_PR_STATUS_COUNT_FAILURE:
    case TYPES.GET_ALL_PR_ATTACHMENTS_FAILURE:
    case TYPES.DELETE_PR_ATTACHMENT_FAILURE:
    case TYPES.UPDATE_PR_DETAILS_ATTACHMENT_FAILURE:
    case TYPES.GET_ALL_PR_RELEASE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default getPrReleaseInfo;
