import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./SupplierVisitTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../../Assets/Svg/arrow-icon.svg";
import { tableStyles } from "../../../Utils/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { fetchPRData, fetchPrStats } from "../../../Store/actions/dashboardAction";
import { PageLoader } from "../../Imports/import";
import { DateGenerator, formatNumber } from "../../../Utils/Utils";

const PrTable = () => {
  const dispatch =useDispatch();
  const [orderBy,setOrderBy]=useState('updatedAt');
  const [orderDir, setOrderDir] = useState('DESC');
  const {prData,loading,error,prStats,prStatsLoading,prStatsError}=useSelector((state)=>state.DashboardReducer)
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({
    all: [],
    newPr: [],
    approved: [],
    rejected: [],
  });
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [filteredData, setFilteredData] = useState([]);
  const [count,setCount]=useState(prStats?.data?.reduce(
    (total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0),
    0
  ) || 0)
  
  useEffect(()=>{
  
    dispatch(fetchPrStats())
 
  if(type===0){
    dispatch(fetchPRData(currentPage,itemsPerPage,orderBy,orderDir,"all",selectedDateRange[0],selectedDateRange[1],searchValues))
  }
  dispatch(fetchPRData(currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues))
   
  },[currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues])
  useEffect(()=>{
    if (type === 0) {
      setData((prevState) => ({
        ...prevState,
        all: prData?.data || [],
      }));
    }
    else if(type===1){
      setData((prevState) => ({
        ...prevState,
        newPr: prData?.data || [],
      }));
    }
    else if(type===2){
      setData((prevState) => ({
        ...prevState,
        approved: prData?.data || [],
      }));
    }
    else if(type===3){
      setData((prevState) => ({
        ...prevState,
        rejected: prData?.data || [],
      }));
    }
    setCount(prData?.TotalCount);
   },[prData,count])

   useEffect(()=>{
    type===0?setFilteredData(data?.all|| []):type===1?setFilteredData(data?.newPr|| []):type===2?setFilteredData(data?.approved|| []):type===3?setFilteredData(data?.rejected|| []):setFilteredData([])
   },[data,type])

  
  const columnWidths = {
    Date: "10%",
    PurchasingGroup: "20%",
    Company: "10%",
    purchaseRequisition: "10%",
    Description: "10%",
    Qty: "10%",
    Unit: "10%",
  };

  const columns = [
    {
      id: "PR_Date",
      name: "Date",
      selector: (result) => DateGenerator(result?.PR_Date) || "",
      searchable: true,
    },
    {
      id: "PR_Group",
      name: "Purchasing Group",
      selector: (result) => result?.PR_Group|| "",
      searchable: true,
    },
    {
      id: "PR_Company",
      name: "Company",
      selector: (result) => result?.PR_Company || "",
      searchable: true,
    },
    {
      id: "PR_ID",
      name: "Purchase Requisition",
      selector: (result) => result?.PR_ID || "",
      searchable: true,
    },
    {
      id: "PR_description_Short",
      name: "Description",
      selector: (result) => result?.PR_description_Short || "",
      searchable: true,
    },
    {
      id: "PR_QTY",
      name: "Qty",
      selector: (result) => formatNumber(result?.PR_QTY) || "",
      searchable: true,
    },
    {
      id: "PR_Unit",
      name: "Unit",
      selector: (result) => result?.PR_Unit || "",
      searchable: true,
    },
    {
      id: "PR_VMS_Status",
      name: "Status",
      searchable: false,
      cell: (result) => (
        <div
          className={
            result?.PR_VMS_Status === 2
              ? "table-status approved"
              : result?.PR_VMS_Status === 3
              ? "table-status rejected"
              : result?.PR_VMS_Status === 1
              ? "table-status newvendor"
              : ""
          }
        >
          {" "}
          {
            result?.PR_VMS_Status === 2
              ? "Approved"
              : result?.PR_VMS_Status === 3
              ? "Rejected"
              : result?.PR_VMS_Status === 1
              ? "New PR"
              : ""
          }
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  // handling the search functionality
  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => {
      const updatedSearchValues = { ...prevSearchValues, [columnName]: value };
      
      dispatch(fetchPRData(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type===0?"all":type,
        selectedDateRange[0],
        selectedDateRange[1],
        updatedSearchValues
      ));
      return updatedSearchValues;
    });
  };
  

 const handleTypeSelect = (selectedKey) => {
    setCurrentPage(1);
    setType(parseInt(selectedKey));

  }
  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
    }
  };

  // const filteredData = filterData();

  // const filteredDataByType =
  //   type === "all"
  //     ? filteredData
  //     : filteredData.filter((item) => item.status === type);

  

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
    
  };

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const headerNames = columns.map((column) => column.name);
  const csvData =
  filteredData?.map((item) =>
    columns.reduce((row, column) => {
      if(column.id==="PR_VMS_Status"){
        row[column.name]=item?.PR_VMS_Status==1?"New PR":item?.PR_VMS_Status==2?"Approved":item?.PR_VMS_Status==3?"Rejected":""
      }
      else{
        row[column.name] = column.selector ? column.selector(item) : item[column.id] || '';
      }
      return row;
    }, {})
  ) || []; 


  
  return (
    <>
    {(prStatsLoading || loading) && <PageLoader/>}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 0
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="0"
                >
                  All
                  <span
                    className={
                      type === 0
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="0"
                  >
                    {type===0?count:prStats?.data?.reduce(
  (total, stat) => total + (typeof stat?.count === "number" ? stat?.count : 0),
  0
) || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 1
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="1"
                >
                  New PR
                  <span
                    className={
                      type === 1
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="1"
                  >
                   {type===1?count:prStats?.data?.find(stat => stat?.PR_VMS_Status === 1)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 2
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="2"
                >
                  Approved
                  <span
                    className={
                      type === 2
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="2"
                  >
                     {type===2?count:prStats?.data?.find(stat => stat?.PR_VMS_Status === 2)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 3
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="3"
                >
                  Rejected
                  <span
                    className={
                      type === 3
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="3"
                  >
                     {type===3?count:prStats?.data?.find(stat => stat?.PR_VMS_Status === 3)?.count || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <DateRangePicker
              appearance="default"
              //   style={{width:"200px"}}
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder={"Start date ~ End date"}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
              disabled={loading}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>Show </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>entries</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="pr_table"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            filteredData?.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
            
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="table-search-div"
          >
            {columns.map((column) =>
              column.searchable !== false ? (
                <input
                  key={column.id}
                  type="text"
                  value={searchValues[column.id] || ""}
                  style={{ width: columnWidths[column.name] }}
                  onChange={(event) =>
                    handleSearchChange(event, column.id)
                  }
                />
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
      <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default PrTable;
