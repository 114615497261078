import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import { useDispatch, useSelector, useTranslation } from "../../../src/Components/Imports/import";
import chatlogo from "../../Assets/Images/chatlogo.png";
import PdfIcon from "../../Assets/Svg/pdf-icon.svg";
import JpgIcon from "../../Assets/Svg/jpg-icon.svg";
import DownloadIcon from "../../Assets/Svg/download.svg";
import "./PrDetailsPopup.css";
import { getSingleJobDetails } from "../../Store/actions/jobManagementAction";
import { formatInputDate } from "../../Utils/Utils";

const QuotationFiles = ({ QtAttachments }) => {
  const handleDownloadClick = (fileName, fileUrl) => {
    // Use either Version 1 or Version 2 here

    // Version 2: Trigger Download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName); // Set the file name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="qtn-file-container">
      <div className="qtn-file-heading">
        <div className="qtn-file-heading-content">
          <h2 className="qtn-file-heading-text">Quotation File</h2>
        </div>
      </div>
      <div className="qtn-file-item-container">
        {QtAttachments &&
          QtAttachments.map((attachment) => (
            <div className="qtn-file-item" key={attachment.QtAttachmentId}>
              <div className="qtn-file-item-content">
                <img
                  src={
                    attachment.AttachmentName.endsWith(".pdf")
                      ? PdfIcon
                      : JpgIcon
                  }
                  className="qtn-file-item-icon"
                  alt={
                    attachment.AttachmentName.endsWith(".pdf") ? "PDF" : "Image"
                  }
                />
                <div className="qtn-file-item-text">
                  {attachment.AttachmentName}
                </div>
              </div>
              <img
                src={DownloadIcon}
                className="qtn-file-item-icon"
                alt="Download"
                onClick={() =>
                  handleDownloadClick(
                    attachment.AttachmentName,
                    attachment.AttachmentFileUrl
                  )
                }
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const PrDetailsPopup = ({ isOpen, closeModal, formTitle, data }) => {
  const { t } = useTranslation();
  const [quotationData,setQuotationData]=useState({})
  const { singleJobDetails } = useSelector(
    (state) => state.getJobManagementInfo
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleJobDetails(data?.JobId));
}, [data]);
useEffect(() => {
  if(singleJobDetails?.JobDetails?.JobId==data?.JobId){
    setQuotationData(singleJobDetails.JobDetails)
  } 
  else{
    setQuotationData({})
  }

}, [singleJobDetails]);
const {
    // register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  //   const [showError, setShowError] = useState(false);

  const onSubmit = (data) => {
    // setShowError(true); // Show the error message if no option is selected
    return;

    // setShowError(false);
    // closeModal();
  };
  
  const CardItem = ({ item, index }) => (
    <div className="quo-pr-com-card-item">
      <span className="quo-pr-com-card-title">{t("Short Text")}</span>
      <input
        type="text"
        value={item?.PrSapDetail?.PR_description_Short}
        readOnly
        className="quo-pr-com-card-content"
      />
      <span className="quo-pr-com-card-title">{t("PR Number")}</span>
      <input
        type="text"
        value={item?.PrSapDetail?.PR_ID}
        className="quo-pr-com-card-content"
        readOnly
      />
      <span className="quo-pr-com-card-title">{t("Long Text")}</span>
      <textarea
        value={item?.PrSapDetail?.PR_description_Long}
        readOnly
        className="quo-pr-com-card-content"
      />
    </div>
  );


  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="quo-details-user-modal"
        overlayClassName="eva-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="evaa-cre-user-title d-flex">
          <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
          <img src={closeIcon} alt="" onClick={closeModal} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <div className="quo-details-date-main">
            <div>
              <label htmlFor="BiddingEnd">{t("Bidding End")}</label>
              <br />
              <input type="date" value={formatInputDate(quotationData?.BiddingDate)} placeholder="Select a Date" disabled />
            </div>
            <div>
              <label>{t("Vendor Code")}</label>
              <input
                className="quo-Details-vendor-code"
                type="text"
                value={data?.vendor?.vendor_code || "Not available"}
                disabled
              />
            </div>
            {/* <button className="quo-chat-logo">
              <img src={chatlogo} /> {t("Chat")}
            </button> */}
          </div>
          <br />
          <div className="qt-main-scroll">
            <div className="quo-pr-comb-det">
              <div className="quo-pr-com-det-text">
                {t("PR Combined Detail")}
              </div>
              {Array.isArray(quotationData?.JobPrDetails) &&
                quotationData?.JobPrDetails?.map((item, index) => (
                  <div className="quo-pr-com-card-qtn" key={index}>
                    {/* Render the CardItem component with props */}
                    <CardItem item={item} index={index} />
                  </div>
                ))}
            </div>
          </div>
          
          {/* note */}
          <div className="quotation-pr-title">
            <span className="quotation-pr-note-text">{t("Note")}</span>
            <div className="pr-note-textarea">
              <textarea
                rows="1"
                cols="130"
                placeholder="Shutdown"
                disabled
                value={quotationData?.Note}
                style={{ padding: "10px" }}
              />
            </div>
          </div>

          {/* Quotation File  */}
          {/* <div className="qtn-file-container">
            <div className="qtn-file-heading">
              <div className="qtn-file-heading-content">
                <h2 className="qtn-file-heading-text">{t("Quotation File")}</h2>
              </div>
            </div>
            <div className="qtn-file-item-container">
              <div className="qtn-file-item">
                <div className="qtn-file-item-content">
                  <img src={PdfIcon} className="qtn-file-item-icon" alt="PDF" />
                  <div className="qtn-file-item-text">
                    vendor#1_PO_file_project1.pdf
                  </div>
                </div>
                <img
                  src={DownloadIcon}
                  className="qtn-file-item-icon"
                  alt="Download"
                  onClick={() =>
                    handleDownloadClick("vendor#1_PO_file_project1.pdf")
                  }
                />
              </div>
              <div className="qtn-file-item">
                <div className="qtn-file-item-content">
                  <img src={JpgIcon} className="qtn-file-item-icon" alt="JPG" />
                  <div className="qtn-file-item-text">
                    vendor#1_PO_file_project1.jpg
                  </div>
                </div>
                <img
                  src={DownloadIcon}
                  className="qtn-file-item-icon"
                  alt="Download"
                  onClick={() =>
                    handleDownloadClick("vendor#1_PO_file_project1.jpg")
                  }
                />
              </div>
            </div>
          </div> */}

          <QuotationFiles QtAttachments={data?.QtAttachments} />

          <div className="pt-3 create-btns-div">
            <button
              type="submit"
              className="um-submit-btn"
              onClick={closeModal}
            >
              {t("Back")}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PrDetailsPopup;
