import TYPES from "../constants";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import { DELETE_VENDOR_BY_ADMIN, VENDOR_CHANGES_LOG } from "../../ApiEndPoints/ApiEndPoints";

//Inital action for calling the DELETE VENDOR API
export const deleteVendorRequest = () => {
  return {
    type: TYPES.DELETE_VENDOR,
  };
};

// Action function for current row data for the delete purpose

export const currentVendorRowData = (row) => {

  return {
    type: TYPES.CURRENT_VENDOR_ROW_DATA,
    payload: row,
  };
};

export const deleteVendor = (vendorData) => {
 
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(deleteVendorRequest());
      const response = await axios.get(
        `${BaseUrl}${DELETE_VENDOR_BY_ADMIN}${vendorData?.vendor_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

   
      if (response.data.status === 200) {
        toast.success(response.data.message);
        dispatch({
          type: TYPES.DELETE_VENDOR_SUCCESS,
          payload: response.data,
        });

        return true;
      } else {
        toast.error(response.data.message);
        dispatch({
          type: TYPES.DELETE_VENDOR_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.DELETE_VENDOR_FAILURE,
        error: error.message,
      });
      toast.success(error.message);
    }
  };
};

export const getAllVendorLogs = ({id}) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch({
        type: TYPES.VENDOR_CHANGES_LOG_LOADING,
      })
      const response = await axios.get(`${BaseUrl}${VENDOR_CHANGES_LOG}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_CHANGES_LOG_SUCCESS,
          payload: response.data.data,
        });
        
      } else {
        dispatch({
          type: TYPES.VENDOR_CHANGES_LOG_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_CHANGES_LOG_FAILURE,
        error: error.message,
      });
    }
  };
};