import React, { useEffect, useState } from "react";

import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import PrReleaseTable from "../../Components/DataTable/PrReleaseTable/PrReleaseTable";
import "./PrRelease.css";
import {
  PageLoader,
  useDispatch,
  useSelector,
  useTranslation,
} from "../../../src/Components/Imports/import";
import { getAllPrReleaseGroupCount } from "../../Store/actions/prReleaseActions";

// PrRelease component displays PR release information in a structured formats.
const PrRelease = () => {
  const { t } = useTranslation();
  const [selectedDateRange, setSelectedDateRange] = useState([]);
 
  const dispatch = useDispatch();
  const { getAllPrReleaseGroupCountData, loading } = useSelector(
    (state) => state.getPrReleaseInfo
  );

  useEffect(() => {
    dispatch(getAllPrReleaseGroupCount(selectedDateRange[0],selectedDateRange[1]));
  }, [selectedDateRange[0],selectedDateRange[1]]);

  return (
    <DefaultLayout>
      {loading && <PageLoader />}
      <section className="um-main">
        <div className="um-title-btn">
          <h1 className="um-title">{"PR Release"}</h1>
        </div>
        <div className="pr-container">
          {getAllPrReleaseGroupCountData?.map((card, i) => (
            <div key={i} className="pr-card">
              <h5 className="pr-title">{card?.PR_Company}</h5>
              <div className="pr-content">
                {card?.PurchasingGorupInfo?.map((row, j) => (
                  <p key={j}>
                    {row?.purchasinggroup_name}
                    {"  "}
                    <span style={{ color: "var(--pr-count)" }}>
                      {row?.count}
                    </span>
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <br />
        <PrReleaseTable selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
      </section>
    </DefaultLayout>
  );
};

export default PrRelease;
