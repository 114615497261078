import {
  React,
  useState,
  useForm,
  toast,
  useSelector,
  useDispatch,
} from "../Imports/import";
import pngIcon from "../../Assets/Images/png-icon.png";
import VpFiles from "./VpFiles/VpFiles";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import {
  adminUpdateVendor,
  clearLatestVendorInfo,
} from "../../Store/actions/updateVendorAction";
import { getSingleVendorDetails } from "../../Store/Reducer/singleVendorDetails";
import { handleKeyDown } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";

const VpDocuments = ({
  editMode,
  setEditMode,
  oldData,
  setPageLoading,
  setDocEdit,
  docEdit,
  commercialRegistrationType,
  paymentMethod,
  generalInfoData,
}) => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const vendorId = oldData.vendor_id;
  const [uploadedFiles, setUploadedFiles] = useState({});
  const { t } = useTranslation();

  const { latestVendorInfo, error } = useSelector(
    (state) => state.updateVendorReducer
  );
 

  const getPaymentMethod = paymentMethod
    ? paymentMethod
    : oldData?.payment_method;

  const commercialRegType = oldData.commercial_reg_type;
  const company_certified_to_recognize_center=oldData?.company_certified_to_recognize_center.split(',') || [];
  const isIsoVerified= !company_certified_to_recognize_center?.includes("N/A")
  const extractedValue = commercialRegistrationType
    ? commercialRegistrationType.toString().match(/\((.*?)\)/)?.[1]
    : commercialRegType?.toString().match(/\((.*?)\)/)?.[1];

  function isEmptyOrWhitespace(str) {
      return !str || str.trim() === "";
  }
  
  const checkCommercialDocumentUpload =()=>{
    if(isIsoVerified){
      return false;
    }
      else{
        return true;
      }
  }
  const checkIsIsoDocumentUploadDisabled=checkCommercialDocumentUpload();
  const valdiateBookBank =
    !["AVT", "AYUDHAYA"].includes(getPaymentMethod) &&
    ["L", "T", "P"].includes(extractedValue);


  function isNullOrEmpty(value) {
    return value === "" || value === null;
  }

  const userName = oldData.user_name;

  const onSubmit = async (data) => {
    setPageLoading(true);

    let requiredFiles = [];

    if (
      (extractedValue === "L" || extractedValue === "T") &&
      isNullOrEmpty(oldData.company_affidavit_doc)
    ) {
      requiredFiles.push("company_affidavit_doc");
    }
    if (
      (extractedValue === "L" || extractedValue === "T") &&
      isNullOrEmpty(oldData.vat_registration_doc)
    ) {
      requiredFiles.push("vat_registration_doc");
    }

    if (extractedValue === "P" && isNullOrEmpty(oldData.copy_id_card)) {
      requiredFiles.push("copy_id_card");
    }

    if (
      (extractedValue === "P" || extractedValue === "T") &&
      isNullOrEmpty(oldData.certification_doc)
    ) {
      requiredFiles.push("certification_doc");
    }

  

    const files = Object.keys(uploadedFiles);

    const allFilesUploaded = requiredFiles.every((file) =>
      files.includes(file)
    );

    if (allFilesUploaded) {
      // do api call here
      setPageLoading(true);

      function isFile(value) {
        return value instanceof File;
      }

      const formdata = new FormData();
      if (latestVendorInfo && latestVendorInfo != null) {
        const keyToRemove = "user_name";

        // Check if the key exists in the object before removing it
        if (latestVendorInfo.hasOwnProperty(keyToRemove)) {
          // Remove the key and its value
          delete latestVendorInfo[keyToRemove];
        }

        Object.entries(latestVendorInfo).forEach(([key, value]) => {
          formdata.append(key, value);
        });
      }
      formdata.append("user_name", userName);

      formdata.append(
        "company_affidavit_doc",
        uploadedFiles.company_affidavit_doc
          ? uploadedFiles.company_affidavit_doc
          : ""
      );
      formdata.append(
        "vat_registration_doc",
        uploadedFiles.vat_registration_doc
          ? uploadedFiles.vat_registration_doc
          : ""
      );
      formdata.append(
        "bank_info_doc",
        uploadedFiles.bank_info_doc ? uploadedFiles.bank_info_doc : ""
      );
      formdata.append(
        "annual_revenue_doc",
        uploadedFiles.annual_revenue_doc ? uploadedFiles.annual_revenue_doc : ""
      );
      formdata.append(
        "financial_statement_doc",
        uploadedFiles.financial_statement_doc
          ? uploadedFiles.financial_statement_doc
          : ""
      );
      formdata.append(
        "memorandum_doc",
        uploadedFiles.memorandum_doc ? uploadedFiles.memorandum_doc : ""
      );
      formdata.append(
        "company_profile_doc",
        uploadedFiles.company_profile_doc
          ? uploadedFiles.company_profile_doc
          : ""
      );
      formdata.append(
        "autherize_dealer_doc",
        uploadedFiles.autherize_dealer_doc
          ? uploadedFiles.autherize_dealer_doc
          : ""
      );
      formdata.append(
        "certification_doc",
        uploadedFiles.certification_doc ? uploadedFiles.certification_doc : ""
      );
      if (isFile(uploadedFiles.business_license)) {
        formdata.append(
          "business_license",
          uploadedFiles.business_license ? uploadedFiles.business_license : ""
        );
      }
      if (isFile(uploadedFiles.tax_registration)) {
        formdata.append(
          "tax_registration",
          uploadedFiles.tax_registration ? uploadedFiles.tax_registration : ""
        );
      }
      if (isFile(uploadedFiles.certificate_of_incoporation)) {
        formdata.append(
          "certificate_of_incoporation",
          uploadedFiles.certificate_of_incoporation
            ? uploadedFiles.certificate_of_incoporation
            : ""
        );
      }
      if (isFile(uploadedFiles.copy_id_card)) {
        formdata.append(
          "copy_id_card",
          uploadedFiles.copy_id_card ? uploadedFiles.copy_id_card : ""
        );
      }

      formdata.append(
        "project_reference_doc",
        uploadedFiles.project_reference_doc
          ? uploadedFiles.project_reference_doc
          : ""
      );
      try {
        const isSuccess = await dispatch(adminUpdateVendor(formdata));

        if (isSuccess?.data?.status === 200) {
          await dispatch(getSingleVendorDetails());
          setEditMode(false);
          setPageLoading(false);
          toast.success(isSuccess?.data?.message);
        } else {
          setPageLoading(false);
          toast.error(isSuccess?.data?.message || "Something went wrong");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
        setPageLoading(false);
      }
    } else {
      setPageLoading(false);
      toast.error("Please Upload mandatory files and try again");
    }
  };

  const handleFileUpload = (fileType, fileData) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fileType]: fileData,
    }));
  };

  const getBusinessLicenseLabel = () => {
    if (["O", "U", "A"].includes(extractedValue)) {
      return "1. Business license or Excise Document";
    }
    return t("1.The company certificate must not be older than 6 months. If the seller has changed their address, a P.P. 09 form must be attached. All attached documents must be signed by the authorized person as stated in the company certificate.");
  };

  const getTaxRegistrationLabel = () => {
    if (["O", "U", "A"].includes(extractedValue)) {
      return "2. Tax Registration";
    }
    return t("2.The VAT registration certificate (P.P. 20), along with all attached documents, must be signed by the authorized person as specified in the company certificate.");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
      <div className="genaral-main-div">
        <VpFiles
          icon={pngIcon}
          label={getBusinessLicenseLabel()}
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={
            ["O", "U", "A"].includes(extractedValue)
              ? oldData.business_license_doc
              : oldData.company_affidavit_doc
          }
          fileUrl={
            ["O", "U", "A"].includes(extractedValue)
              ? oldData.business_license_url
              : oldData.company_affidavit_url
          }
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          mandotary={["L", "T"].includes(extractedValue)}
          isDisable={extractedValue === "P"}
          onFileUpload={(fileData) =>
            handleFileUpload(
              ["O", "U", "A"].includes(extractedValue)
                ? "business_license"
                : "company_affidavit_doc",
              fileData
            )
          }
        />
        <VpFiles
          icon={pngIcon}
          label={getTaxRegistrationLabel()}
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={
            ["O", "U", "A"].includes(extractedValue)
              ? oldData.tax_registration_doc
              : oldData.vat_registration_doc
          }
          fileUrl={
            ["O", "U", "A"].includes(extractedValue)
              ? oldData.tax_registration_url
              : oldData.vat_registration_url
          }
          vendorId={vendorId}
          mandotary={["L", "T"].includes(extractedValue)}
          setPageLoading={setPageLoading}
          onFileUpload={(fileData) =>
            handleFileUpload(
              ["O", "U", "A"].includes(extractedValue)
                ? "tax_registration"
                : "vat_registration_doc",
              fileData
            )
          }
          isDisable={extractedValue === "P"}
        />
        <VpFiles
          icon={pngIcon}
          label={t("3.The bank account book or bank details, along with all attached documents, must be signed by the authorized person as specified in the company certificate.")}
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={oldData.bank_info_doc}
          fileUrl={oldData.bank_info_url}
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          onFileUpload={(fileData) =>
            handleFileUpload("bank_info_doc", fileData)
          }
          mandotary={valdiateBookBank}
          isDisable={["AVT", "AYUDHAYA"].includes(getPaymentMethod)}
        />
        <VpFiles
          icon={pngIcon}
          label={t("4.Three Year Annual Revenue")}
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={oldData.annual_revenue_doc}
          fileUrl={oldData.annual_revenue_url}
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          onFileUpload={(fileData) =>
            handleFileUpload("annual_revenue_doc", fileData)
          }
          isDisable={["P", "O", "U"].includes(extractedValue)}
        />
        <VpFiles
          icon={pngIcon}
          label={t("5.Three Year Financial Statements")}
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={oldData.financial_statement_doc}
          fileUrl={oldData.financial_statement_url}
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          onFileUpload={(fileData) =>
            handleFileUpload("financial_statement_doc", fileData)
          }
          isDisable={["P", "O", "U"].includes(extractedValue)}
        />
        <VpFiles
          icon={pngIcon}
          label={
            ["O", "U", "A"].includes(extractedValue)
              ? "6. Certificate of incorporation"
              : "6. Memorandum of association (หนังสือบริคณห์สนธิ)"
          }
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={
            ["O", "U", "A"].includes(extractedValue)
              ? oldData.certificate_of_incoporation_doc
              : oldData.memorandum_doc
          }
          fileUrl={
            ["O", "U", "A"].includes(extractedValue)
              ? oldData.certificate_of_incoporation_url
              : oldData.memorandum_url
          }
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          onFileUpload={(fileData) =>
            handleFileUpload(
              ["O", "U", "A"].includes(extractedValue)
                ? "certificate_of_incoporation"
                : "memorandum_doc",
              fileData
            )
          }
          isDisable={extractedValue === "P"}
        />
        <VpFiles
          icon={pngIcon}
          label={
            ["P"].includes(extractedValue)
              ? "7. Copy of ID Card / Copy of house Registration"
              : t("7.Company profile / Product line")
          }
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={
            ["P"].includes(extractedValue)
              ? oldData.copy_id_card_doc
              : oldData.company_profile_doc
          }
          fileUrl={
            ["P"].includes(extractedValue)
              ? oldData.copy_id_card_url
              : oldData.company_profile_url
          }
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          mandotary={["P"].includes(extractedValue)}
          onFileUpload={(fileData) =>
            handleFileUpload(
              ["P"].includes(extractedValue)
                ? "copy_id_card"
                : "company_profile_doc",
              fileData
            )
          }
        />
        <VpFiles
          icon={pngIcon}
          label="8. Authorize dealer if any"
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={oldData.autherize_dealer_doc}
          fileUrl={oldData.autherize_dealer_url}
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          onFileUpload={(fileData) =>
            handleFileUpload("autherize_dealer_doc", fileData)
          }
          isDisable={extractedValue === "L"}
        />
        <VpFiles
          icon={pngIcon}
          label={t("9.Certificate document (for example ISO 9001 or any)")}
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={oldData.certification_doc}
          fileUrl={oldData.certification_url}
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          isDisable={checkIsIsoDocumentUploadDisabled}
          mandotary={["P", "T"].includes(extractedValue)}
          onFileUpload={(fileData) =>
            handleFileUpload("certification_doc", fileData)
          }
        />
        <VpFiles
          icon={pngIcon}
          label={t("10.Project reference")}
          editMode={editMode}
          setEditMode={setEditMode}
          fileCount={1}
          fileName={oldData.project_reference_doc}
          fileUrl={oldData.project_reference_url}
          vendorId={vendorId}
          setPageLoading={setPageLoading}
          onFileUpload={(fileData) =>
            handleFileUpload("project_reference_doc", fileData)
          }
        />
        <CancelAndUpdateBtn
          editMode={editMode}
          setEditMode={setEditMode}
          reset={reset}
          setDocEdit={setDocEdit}
          docEdit={docEdit}
        />
      </div>
    </form>
  );
};

export default VpDocuments;
