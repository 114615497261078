const JSZip = require("jszip");
const { saveAs } =require('file-saver');
const { toast } = require("react-toastify");

const currencyData = [
  { label: "THB", value: "THB" },
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "EUR", value: "EUR" },
  { label: "JPY", value: "JPY" },
  { label: "HKD", value: "HKD" },
  { label: "MYR", value: "MYR" },
  { label: "SGD", value: "SGD" },
  { label: "BND", value: "BND" },
  { label: "CNY", value: "CNY" },
  { label: "IDR", value: "IDR" },
  { label: "MMK", value: "MMK" },
  { label: "INR", value: "INR" },
  { label: "KRW", value: "KRW" },
  { label: "LAK", value: "LAK" },
  { label: "PHP", value: "PHP" },
  { label: "TWD", value: "TWD" },
  { label: "NZD", value: "NZD" },
  { label: "CHF", value: "CHF" },
  { label: "DKK", value: "DKK" },
  { label: "NOK", value: "NOK" },
  { label: "SEK", value: "SEK" },
  { label: "CAD", value: "CAD" },
  { label: "RUB", value: "RUB" },
  { label: "VND", value: "VND" },
  { label: "ZAR", value: "ZAR" },
  { label: "AED", value: "AED" },
  { label: "BHD", value: "BHD" },
  { label: "OMR", value: "OMR" },
  { label: "QAR", value: "QAR" },
  { label: "SAR", value: "SAR" },
];

const bankName = [
  {
    label: "BANGKOK BANK PUBLIC COMPANY LTD. (Thailand) -0020000",
    value: "BANGKOK BANK PUBLIC COMPANY LTD. (Thailand) -0020000",
  },
  {
    label: "KASIKORNBANK PUBLIC COMPANY LIMITED (Thailand) -0040000",
    value: "KASIKORNBANK PUBLIC COMPANY LIMITED (Thailand) -0040000",
  },
  {
    label: "THE ROYAL BANK OF SCOTLAND N.V. (United Kingdom) -0050001",
    value: "THE ROYAL BANK OF SCOTLAND N.V. (United Kingdom) -0050001",
  },
  {
    label: "KRUNG THAI BANK PUBLIC COMPANY LTD. (Thailand) -0060000",
    value: "KRUNG THAI BANK PUBLIC COMPANY LTD. (Thailand) -0060000",
  },
  {
    label: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION (United States of America) -0080001",
    value: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION (United States of America) -0080001",
  },
  {
    label: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED (Thailand) -0110000",
    value: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED (Thailand) -0110000",
  },
  {
    label: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD. (Thailand) -0140000",
    value: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD. (Thailand) -0140000",
  },
  {
    label: "CITIBANK, N.A. (United States of America) -0170001",
    value: "CITIBANK, N.A. (United States of America) -0170001",
  },
  {
    label: "SUMITOMO MITSUI BANKING CORPORATION (Japan) -0180001",
    value: "SUMITOMO MITSUI BANKING CORPORATION (Japan) -0180001",
  },
  {
    label: "STANDARD CHARTERED BANK (THAI) PUBLIC CO., LTD. (Thailand) -0200000",
    value: "STANDARD CHARTERED BANK (THAI) PUBLIC CO., LTD. (Thailand) -0200000",
  },
  {
    label: "CIMB BANKTHAI PUBLIC COMPANY LIMITED (Thailand) -0220000",
    value: "CIMB BANKTHAI PUBLIC COMPANY LIMITED (Thailand) -0220000",
  },
  {
    label: "UNITED OVERSEAS BANK (THAI) PUBLIC CO., LTD. (Thailand) -0240000",
    value: "UNITED OVERSEAS BANK (THAI) PUBLIC CO., LTD. (Thailand) -0240000",
  },
  {
    label: "BANK OF AYUDHAYA PUBLIC COMPANY LTD. (Thailand) -0250000",
    value: "BANK OF AYUDHAYA PUBLIC COMPANY LTD. (Thailand) -0250000",
  },
  {
    label: "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED (Taiwan) -0260001",
    value: "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED (Taiwan) -0260001",
  },
  {
    label: "BANK OF AMERICA NATIONAL ASSOCIATION (United States of America) -0270001",
    value: "BANK OF AMERICA NATIONAL ASSOCIATION (United States of America) -0270001",
  },
  {
    label: "GOVERNMENT SAVING BANK (Thailand) -030---",
    value: "GOVERNMENT SAVING BANK (Thailand) -030---",
  },
  {
    label: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (Hong Kong and Shanghai) -0310001",
    value: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (Hong Kong and Shanghai) -0310001",
  },
  {
    label: "DEUTSCHE BANK AG. (Germany) -0320001",
    value: "DEUTSCHE BANK AG. (Germany) -0320001",
  },
  {
    label: "GOVERNMENT HOUSING BANK (Thailand) -033---",
    value: "GOVERNMENT HOUSING BANK (Thailand) -033---",
  },
  {
    label: "BANK FOR AGRICULTURAL AND AGRICULTURAL CO-OPERATIVES (Thailand) -0340001",
    value: "BANK FOR AGRICULTURAL AND AGRICULTURAL CO-OPERATIVES (Thailand) -0340001",
  },
  {
    label: "MIZUHO BANK, LTD. BANGKOK BRANCH (Japan) -0390001",
    value: "MIZUHO BANK, LTD. BANGKOK BRANCH (Japan) -0390001",
  },
  {
    label: "BNP PARIBAS, BANGKOK BRANCH (France) -0450010",
    value: "BNP PARIBAS, BANGKOK BRANCH (France) -0450010",
  },
  {
    label: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED (China) -052---",
    value: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED (China) -052---",
  },
  {
    label: "ISLAMIC BANK OF THAILAND (Thailand) -0660001",
    value: "ISLAMIC BANK OF THAILAND (Thailand) -0660001",
  },
  {
    label: "TISCO BANK PUBLIC COMPANY LIMITED (Thailand) -067---",
    value: "TISCO BANK PUBLIC COMPANY LIMITED (Thailand) -067---",
  },
  {
    label: "KIATNAKIN BANK PUBLIC COMPANY LIMITED (Thailand) -069---",
    value: "KIATNAKIN BANK PUBLIC COMPANY LIMITED (Thailand) -069---",
  },
  {
    label: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PCL (China) -070---",
    value: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PCL (China) -070---",
  },
  {
    label: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (Thailand) -0710001",
    value: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (Thailand) -0710001",
  },
  {
    label: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED (Thailand) -0730001",
    value: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED (Thailand) -0730001",
  },
  {
    label: "ANZ BANK (THAI) PUBLIC CO. LTD. (Australia and New Zealand) -0790001",
    value: "ANZ BANK (THAI) PUBLIC CO. LTD. (Australia and New Zealand) -0790001",
  },
  {
    label: "SUMITOMO MITSUI TRUST BANK (TH) PCL (Japan) -0800001",
    value: "SUMITOMO MITSUI TRUST BANK (TH) PCL (Japan) -0800001",
  },
  {
    label: "OTHER, please specify. (OTH) – 999---",
    value: "OTHER, please specify. (OTH) – 999---",
  },
];

const transformVendorTableDataResponse = (vendorTableData) => {
  if (vendorTableData && vendorTableData.length > 0) {
    return vendorTableData.map((e, index) => {
      return {
        vendor_id: e.id,
        vendor_code: e?.vendor_code ? e?.vendor_code : "---",
        type_of_business: e.type_of_business ? e.type_of_business : "---",
        company_name_eng: e.company_name_eng ? e.company_name_eng : "---",
        user_id: e.user_id,
        Date: e.created_at ? e.created_at.split("T")[0] : "---",
        assigner_id: e.assigner_id,
        vendor_status: e.vendor_status,
        registration_type: e.registration_type,
        is_delete: e.is_delete,
        status: e.status,
        updated_at: e.updated_at.split("T")[0],
        current_group_name: e?.current_group_name,
        rejected_by_group_name: e?.rejected_by_group_name,
        rejection_description: e?.rejection_description,
        binding_category: e.binding_category,
        binding_category_specify: e.binding_category_specify,
        current_stage_user_id: e?.current_stage_user_id,
        keys_updated:e?.keys_updated,
      };
    });
  } else {
    return [];
  }
};

const transformUserManagementTable = (userManagementTable) => {
  if (userManagementTable && userManagementTable.length > 0) {
    return userManagementTable.map((e, index) => {
      return {
        user_id: e.user_id,
        assigner_type: e.assigner_type,
        name: e.name,
        password: e.password,
        phone_number: e.phone_number,
        user_name: e.user_name,
        created_at: e.created_at.split("T")[0],
        updated_at: e.updated_at,
        email: e.email,
      };
    });
  } else {
    return [];
  }
};

const transformPressReleaseTable = (pressReleaseTable) => {
  if (pressReleaseTable && pressReleaseTable.length > 0) {
    return pressReleaseTable.map((e, index) => {
      return {
        id: e.id,
        Title: e.title,
        press_image: e.press_image,
        press_url: e.press_url,
        Description: e.description,
        Status: e.press_status,
        Date: DateGenerator(e.created_at),
      };
    });
  } else {
    return [];
  }
};

const transformPrReleaseTableDataResponse = (prReleaseTable) => {
  if (prReleaseTable && prReleaseTable.length > 0) {
    return prReleaseTable.map((e, index) => {
    
      return {
        PrCreatedId: e?.PrCreatedId,
        PR_ID: e?.PR_ID || "---",
        PR_Company: e?.PR_Company || "---",
        PR_Description: e?.PR_Description || "---",
        PR_Date: e?.PR_Date || "---",
        PR_QTY: e?.PR_QTY || "---",
        PR_Unit: e?.PR_Unit || "---",
        PR_Group: e?.PR_Group || "---",
        PR_Status: e?.PR_Status,
        PR_description_Long: e?.PR_description_Long || "",
        PR_description_Short: e?.PR_description_Short || "",
        Delete_Flag: e?.Delete_Flag,
        status: e?.status,
        createdAt: e?.createdAt,
        updatedAt: e?.updatedAt,
        PR_VMS_Status:e?.PR_VMS_Status || "",
      };
    });
  } else {
    return [];
  }
};

const transformSupplierManagementTableDataResponse = (
  supplierManagementTable
) => {
  if (supplierManagementTable && supplierManagementTable.length > 0) {
    return supplierManagementTable.map((e, index) => {
      return {
        PoPrDbId: e?.PoPrDbId,
        PrLongText: e?.PrLongText,
        PRUnitPrice: e?.PRUnitPrice,
        PrShortText: e?.PrShortText,
        PrQTY: e?.PrQTY,
        PrDeliveryDate: e?.PrDeliveryDate
          ? e.PrDeliveryDate.split("T")[0]
          : "---",
        VmsStatus: e?.VmsStatus,
        SelectedForEvaluation: e?.SelectedForEvaluation,
        poData: e?.poData,
      };
    });
  } else {
    return [];
  }
};

const transformEvaluationManagementTableDataResponse = (eavluationTable) => {
  if (eavluationTable && eavluationTable.length > 0) {
    return eavluationTable.map((e, index) => {
      return {
        evaluationId: e?.evaluationId,
        prSapData: e?.prSapData,
        poSapData: e?.poSapData,
        poNo: e?.poNo,
        vendorCode: e?.vendorCode,
        status: e?.status,
      };
    });
  } else {
    return [];
  }
};

const transformJobManagementTableDataResponse = (jobManagementTable) => {
  if (jobManagementTable && jobManagementTable.length > 0) {
    return jobManagementTable.map((e, index) => {
      return {
        PrCreatedId: e?.PrCreatedId,
        PR_ID: e?.PR_ID || "---",
        PR_Company: e?.PR_Company || "---",
        PR_Description: e?.PR_Description || "---",
        PR_Date: e?.PR_Date || "---",
        PR_QTY: e?.PR_QTY || "---",
        PR_Unit: e?.PR_Unit || "---",
        PR_Group: e?.PR_Group || "---",
        PR_Status: e?.PR_Status,
        Delete_Flag: e?.Delete_Flag,
        status: e?.status,
        createdAt: e.createdAt ? e.createdAt.split("T")[0] : "---",
        updatedAt: e?.updatedAt,
        PrTitle: e?.PrTitle || "---",
        DeliveryDate: e?.DeliveryDate ? e.DeliveryDate.split("T")[0] : "---",
        BuyerName: e?.BuyerName || "---",
        JobPrDetails: e?.JobPrDetails,
        BiddingDate: e?.BiddingDate ? e.BiddingDate.split("T")[0] : "---",
        JobId: e?.JobId,
        JobStatus: e?.JobStatus,
        QuotationCount: e?.QuotationCount,
      };
    });
  } else {
    return [];
  }
};

const transformQtTableDataResponse = (qtnTable) => {
  if (qtnTable && qtnTable.length > 0) {
    return qtnTable.map((e, index) => {
      return {
        status: e?.status,
        createdAt: e.createdAt ? e.createdAt.split("T")[0] : "---",
        updatedAt: e?.updatedAt,
        JobId: e?.JobId,
        QtAttachments: e?.QtAttachments,
        vendor: e?.vendor,
        JobQuotationId: e?.JobQuotationId,
        AttachmentCount: e?.AttachmentCount,
        Delete_Flag: e?.Delete_Flag,
        VendorId: e?.VendorId,
        QuotationStatus: e?.QuotationStatus,
        phoneNo:e?.phoneNo,
      };
    });
  } else {
    return [];
  }
};

const transformPurchasingGroupTable = (purchasingGroupTable) => {
  if (purchasingGroupTable && purchasingGroupTable?.length > 0) {
    return purchasingGroupTable?.map((e, index) => {
      return {
        id: e?.purchasing_group_id,
        Purchasinggroup: e?.purchasinggroup_name,
        Meaning: e?.meaning,
        Category: e?.category,
        Status:
          e?.current_status == "review"
            ? "Waiting Release"
            : e?.current_status == "released"
            ? "Released"
            : e?.current_status,
        Date: e?.created_at.split("T")[0],
      };
    });
  } else {
    return [];
  }
};

function convertToQueryString(queryParams) {
  const queryString = Object.entries(queryParams)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `?${queryString}`;
}

function convertStringToArray(str) {
  if (str.length > 0) {
    if (str.includes(",")) {
      return str.split(",");
    } else {
      return [str];
    }
  }
}

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

const subCategoryOptions = [
  { label: "ELECTRICAL", value: "ELECTRICAL" },
  { label: "INSTRUMENT", value: "INSTRUMENT" },
  { label: "MECHANICAL", value: "MECHANICAL" },
  {
    label: "PIPING & FITTINGS",
    value: "PIPING & FITTINGS",
  },
  {
    label: "INSTRUMENT SERVICE",
    value: "INSTRUMENT SERVICE",
  },
  {
    label: "ELECTRICAL SERVICE",
    value: "ELECTRICAL SERVICE",
  },
  { label: "CIVIL", value: "CIVIL" },
  {
    label: "HEALTH-ENVI SERVICES",
    value: "HEALTH-ENVI SERVICES",
  },
  {
    label: "INSPECTION SERVICE",
    value: "INSPECTION SERVICE",
  },
  {
    label: "MECHANICAL SERVICE",
    value: "MECHANICAL SERVICE",
  },
  {
    label: "PIPING SERVICES",
    value: "PIPING SERVICES",
  },
  {
    label: "LAB&CHEMICAL GOODS",
    value: "LAB&CHEMICAL GOODS",
  },
  { label: "OFFICE GOODS", value: "OFFICE GOODS" },
  {
    label: "SAFETY&PROTECTION GOODS",
    value: "SAFETY&PROTECTION GOODS",
  },
  { label: "IT GOODS", value: "IT GOODS" },
  { label: "CHEMICALS", value: "CHEMICALS" },
  {
    label: "INDUSTRIAL GAS",
    value: "INDUSTRIAL GAS",
  },
  { label: "PACKAGING", value: "PACKAGING" },
  { label: "Other", value: "Other" },
];

const handleOnWheel = (event) => {
  event.currentTarget.blur();
};

const formatNumberWithCommas = (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return '';
  }
  
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ppeOptions = [
  { label: "Safety shoes", value: "Safety shoes" },
  { label: "Safety helmet", value: "Safety helmet" },
  { label: "Safety Glassed/Goggle", value: "Safety Glassed/Goggle" },
  { label: "Chemical Gloves", value: "Chemical Gloves" },
  { label: "Long sleeves shirt", value: "Long sleeves shirt" },
  { label: "Half Mask & Respirator", value: "Half Mask & Respirator" },
  { label: "Other. please specify", value: "Other. please specify" },
];

const formatNumberWithCommasandDecimals = (value) => {
  const formatValue = (num) => {
    const [integerPart, decimalPart] = num.toFixed(2).split(".");
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (decimalPart ? `.${decimalPart}` : "");
  };

  if (typeof value === "number") {
    return formatValue(value);
  } else if (typeof value === "string") {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return formatValue(parsedValue);
    }
  }

  // If the input is neither a number nor a parseable string, return as is
  return value;
};


function formatNumber(numberString) {
  const integerPart = parseInt(numberString, 10);
  return integerPart.toLocaleString();
}

const cleanObject = (obj) => {
  const cleanedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null && value !== undefined && value !== "") {
      cleanedObj[key] = value;
    }
  }
  return cleanedObj;
};

//function to generate date in correct format 
function DateGenerator(data){
  if(!data){
    return null;
  }
 const date =new Date(data)
 return date.toLocaleDateString('en-GB');
}

function formatDate (date) {
  if (!date) return null;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

function formatInputDate(dateString) {
  if (!dateString) return ''; 
  const date = new Date(dateString);
  if (isNaN(date)) return ''; 
  return date.toISOString().split('T')[0];
}

//This function would compare the given date with current date
// Condition if target date is greater than current date then it would return true else return false
function compareTargetandCurrentDate(dateString) {
  if (typeof dateString !== 'string') {
    return null;
  }
  const targetDate = new Date(dateString);
  if (isNaN(targetDate.getTime())) {
    return null;
  }
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);
  return targetDate >= currentDate; 
}

//function for calculating penalty
function calculatePenalty(poAmount, grDate, deliveryDate) {
  if (!poAmount) {
    toast.error("Missing purchase order amount (poAmount)");
    return;
  }
  
  if (!grDate) {
    toast.error("Missing goods receipt date (grDate)");
    return;
  }
  
  if (!deliveryDate) {
    toast.error("Missing delivery date (deliveryDate)");
    return;
  }
  const grDateObj = new Date(grDate);
  const deliveryDateObj = new Date(deliveryDate);
  
  const diffInTime = grDateObj - deliveryDateObj;
  const diffInDays = diffInTime / (1000 * 60 * 60 * 24); 
  const penalty = poAmount * 0.005 * diffInDays;
  
  return penalty;
}

function numberToWords(number) {
  const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];

  // Split number into integer and decimal parts
  let [integerPart, decimalPart] = number?.toString().split('.') || [];
  integerPart = parseInt(integerPart || "0");
  decimalPart = decimalPart ? decimalPart.substring(0, 2) : ""; // Limit to two decimal places

  if (integerPart === 0 && decimalPart === "") return "Zero";

  let word = '';
  let thousandCounter = 0;

  // Convert integer part to words
  while (integerPart > 0) {
      let num = integerPart % 1000;
      if (num !== 0) {
          let str = '';
          if (num % 100 < 20 && num % 100 > 10) {
              str = teens[num % 10] || ones[num % 10];
              num = Math.floor(num / 10);
          } else {
              str = ones[num % 10];
              num = Math.floor(num / 10);
              str = tens[num % 10] + (str ? " " + str : "");
              num = Math.floor(num / 10);
          }
          if (num > 0) {
              str = ones[num] + " Hundred" + (str ? " " + str : "");
          }
          word = str + " " + thousands[thousandCounter] + (word ? " " + word : "");
      }
      integerPart = Math.floor(integerPart / 1000);
      thousandCounter++;
  }

  
  if (decimalPart) {
      let decimalValue = parseInt(decimalPart);
      if (decimalValue > 0) {
          word += " and " + convertDecimalToWords(decimalValue);
      }
  }

  return word.trim();
}

function convertDecimalToWords(decimal) {
  const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

  let word = '';
  let tensValue = Math.floor(decimal / 10);
  let onesValue = decimal % 10;

  // Handle decimal values from 01 to 99
  if (tensValue === 0 && onesValue > 0) {
      word += ones[onesValue];
  } else if (tensValue === 1 && onesValue > 0) {
      word += teens[onesValue];
  } else {
      if (tensValue > 0) {
          word += tens[tensValue] + " ";
      }
      if (onesValue > 0) {
          word += ones[onesValue];
      }
  }
  
  return word.trim() + " Hundredths"; // Use "Hundredths" for decimal parts
}

const formatStringForCSV=(inputString) =>{
  let formattedString = inputString.replace(/\\"/g, '"');
  formattedString = formattedString.replace(/,/g, ';'); 
  return formattedString;
}
const downloadFilesInZip=async (attachments,zipFolderName)=>{
  const zip = new JSZip();
  const allFiles=attachments;
  try {
   const filePromises = allFiles?.map(url => 
     fetch(url).then(response => response.blob())
   );
   const files = await Promise.all(filePromises);

   // Add files to the zip
   files.forEach((file, index) => {
     zip.file(`file${index + 1}.pdf`, file);
   });

   // Generate the zip file
   const zipContent = await zip.generateAsync({ type: 'blob' });

   // Trigger the download
   saveAs(zipContent, `${zipFolderName}.zip`);
 } catch (error) {
  toast.error(error?.message || "Something went wrong!")
 }
}

const compareDates = (GRDate, PrDeliveryDate) => {
    const isValidDate = (date) => !isNaN(new Date(date).getTime());

  if (!isValidDate(GRDate) || !isValidDate(PrDeliveryDate)) {
    return 0; 
  }
  const grDate = new Date(GRDate);
  const prDeliveryDate = new Date(PrDeliveryDate);
  if (prDeliveryDate > grDate) {
    return 1; 
  } else {
    return 0;
  }
};
const calculateDaysBetweenDates=(date1, date2) =>{
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const timeDifference = Math.abs(d2 - d1);
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return daysDifference;
}

module.exports = {
  cleanObject,
  formatNumberWithCommasandDecimals,
  convertToQueryString,
  handleKeyDown,
  currencyData,
  bankName,
  transformVendorTableDataResponse,
  convertStringToArray,
  transformUserManagementTable,
  transformPressReleaseTable,
  isValidUrl,
  handleOnWheel,
  subCategoryOptions,
  formatNumberWithCommas,
  ppeOptions,
  transformPurchasingGroupTable,
  transformPrReleaseTableDataResponse,
  transformJobManagementTableDataResponse,
  transformQtTableDataResponse,
  transformSupplierManagementTableDataResponse,
  transformEvaluationManagementTableDataResponse,
  DateGenerator,
  formatDate,
  formatInputDate,
  compareTargetandCurrentDate,
  calculatePenalty,
  numberToWords,
  formatNumber,
  downloadFilesInZip,
  formatStringForCSV,
  compareDates,
  calculateDaysBetweenDates,
};
