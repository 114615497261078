import TYPES from "../constants";

const initialState = {
  error: null,
  deleteUserId: null,
  loading: false,
  deleteUserInfo: {},
  currentVendorRowData: {},
};

const deleteParticularVendor = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DELETE_VENDOR:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        deleteUserInfo: action.payload,
        loading: false,
        error: null,
      };
    case TYPES.DELETE_VENDOR_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.CURRENT_VENDOR_ROW_DATA:
      return {
        ...state,
        currentVendorRowData: action.payload,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default deleteParticularVendor;
