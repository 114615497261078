import {
  toast,
  React,
  useState,
  useEffect,
  useDispatch,
  useSelector,
  BaseUrl,
  useTranslation,
} from "../Imports/import";
import DataTable from "react-data-table-component";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import "./PressReleaseTable.css";
import PressReleasePopup from "../../Models/PressReleasePopup/PressReleasePopup";
import {
  getAllPressRelease,
  currentPressReleaseRowData,
} from "../../Store/actions/pressReleaseAction";
import { DateGenerator, transformPressReleaseTable } from "../../Utils/Utils";
import { Http } from "../Imports/import";
import {
  DELETE_PRESS_RELEASE,
  GET_PRESS_BY_ID,
  RELEASE_PRESS,
} from "../../ApiEndPoints/ApiEndPoints";
import { tableStyles } from "../../Utils/TableStyles";
import CustomPopup from "../../Models/CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";

const PressReleaseTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userToken = localStorage.getItem("vms_admin_token");
  const { getPressReleaseTableData } = useSelector(
    (state) => state.getPressReleaseInfo
  );

  useEffect(() => {
    dispatch(getAllPressRelease());
  }, []);
  const [showDeletePopup,setShowDeletePopup]=useState(false)
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  //view button open popup
  const [rowData, setRowData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columnWidths = {
    Title: "13%",
    Description: "25%",
    Date: "12%",
    Action: "5%",
  };

  const data = transformPressReleaseTable(getPressReleaseTableData);

  const columns = [
    {
      id: "Title",
      name: t("Title"),
      selector: (result) => result.Title,
      searchable: true,
      sortable: true,
      width: "15%",
    },
    {
      id: "Date",
      name: t("Date"),
      selector: (result) => result?.Date,
      searchable: true,
      sortable: true,
      width: "12%",
    },
    {
      id: "Description",
      name: t("Description"),
      selector: (result) => result.Description,
      searchable: true,
      sortable: true,
      width: "30%",
    },

    {
      id: "Status",
      name: t("Status"),
      searchable: true,
      width: "13%",
      cell: (result) => (
        <div
          className={
            result.Status === "Released"
              ? "table-status Released"
              : result.Status === "Waiting Release"
              ? "table-status WaitingRelease"
              : ""
          }
        >
          {" "}
          {result.Status}
        </div>
      ),
    },
    {
      id: "Action",
      name: t("Action"),
      cell: (result) => (
        <div className="d-flex">
          <button
            className="table-btn"
            onClick={() => {
              handleChangeView(result);
            }}
          >
            {t("View")}
          </button>{" "}
          {result.Status === "Waiting Release" && (
            <button
              className="table-btn mx-2"
              style={{ background: "#428BC1" }}
              onClick={() => {
                handleRelease(result);
              }}
            >
              {t("Release")}
            </button>
          )}
          <button
            className="table-btn mx-2"
            style={{ background: "#ED4C5C" }}
            onClick={() => {
              setRowData(result)
              handleDelete();
            }}
          >
            {t("Delete")}
          </button>
        </div>
      ),
      searchable: false,
      width: "30%",
    },
  ];

  // handling release functionality and calling api methods
  const handleRelease = async (result) => {
    const data = { press_id: result.id, press_status: "Released" };
    try {
      const response = await Http.post(`${BaseUrl}${RELEASE_PRESS}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.status === 200) {
        
        toast.success(response.message);
        dispatch(getAllPressRelease());
      } else {
        
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // handling delete functionality and calling api methods
  const handleDelete = async () => {
    setShowDeletePopup(true)
  };

  const closeDeletePopup=()=>{
    setShowDeletePopup(false)
    dispatch(getAllPressRelease());
  }

  //view button popup

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeView = (result) => {
    setRowData(result);
    setIsModalOpen(true);
    const queryParams = {
      press_id: result.id,
    };
    // Use the Http.get method to make the GET request
    Http.get(`${BaseUrl}${GET_PRESS_BY_ID}`, queryParams, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          const rowData = result?.data[0];
          const modifiedApiData = {
            ...rowData, // Copy all properties from the original object
            Title: rowData.title,
            Description: rowData.description,
          };
          delete modifiedApiData.title;
          delete modifiedApiData.description;
          dispatch(currentPressReleaseRowData(modifiedApiData));
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => toast.error(error?.message || "Something went wrong"));
    // dispatch(currentPressReleaseRowData(result));
  };

  //end view button

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  // handling the search functionality
  const handleSearchChange = (event, columnName) => {
    setCurrentPage(1);
    const { value } = event.target;

    if (columnName === "Date") {
      setSearchValues((prevSearchValues) => ({
        ...prevSearchValues,
        [columnName]: value,
      }));
    } else {
      setSearchValues((prevSearchValues) => ({
        ...prevSearchValues,
        [columnName]: value,
      }));
    }
  
  };

  
  const handleTypeSelect = (selectedKey) => setType(selectedKey);

  const filterData = () => {
    let filteredData = [...data];
  
    for (const columnName in searchValues) {
      const searchValue = searchValues[columnName];
  
      if (searchValue) {
        if (columnName === "Date") {
          filteredData = filteredData.filter((item) =>
            DateGenerator(item[columnName]).includes(searchValue)
          );
        } else {
          filteredData = filteredData.filter((item) =>
            item[columnName]?.toLowerCase().includes(searchValue?.toLowerCase())
          );
        }
      }
    }
  
    return filteredData;
  };
  
  const filteredData = filterData();


  const filteredDataByType =
    type === "all"
      ? filteredData
      : filteredData.filter((item) => item.Status === type);


  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, filteredDataByType.length),
    total: filteredDataByType.length,
  };

  const paginatedData = filteredDataByType.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const headerNames = columns.map((column) => column.name).slice(0, -1);

  const csvData = paginatedData?.map((item) =>
    columns.map((column) => item[column.id]).slice(0, -1)
  );
  

  const getCountByStatus = (status) => {
    return data.filter((item) => item.Status === status).length;
  };

  return (
    <>
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "all"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="all"
                >
                  {t("All")}
                  <span
                    className={
                      type === "all"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="all"
                  >
                    {type === "all"?filteredDataByType.length:data.length}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Waiting Release"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Waiting Release"
                >
                  {t("Waiting Release")}
                  <span
                    className={
                      type === "Waiting Release"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Waiting Release"
                  >
                    {type ==="Waiting Release"?filteredDataByType.length:getCountByStatus("Waiting Release")}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "Released"
                      ? "supplier-visit-table-active"
                      : "supplier-visit-table"
                  }
                  eventKey="Released"
                >
                  {t("Released")}
                  <span
                    className={
                      type === "Released"
                        ? "supplier-visit-table-badge-active"
                        : "supplier-visit-table-badge"
                    }
                    eventKey="Released"
                  >
                    {type === "Released"?filteredDataByType.length:getCountByStatus("Released")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="d-flex justify-content-between align-items-center um-entries-div">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>Show </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>entries</span>
            </div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="press_release_table_data.csv"
            />
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            paginatedData.length
              ? "um-data-table press-release-table"
              : "um-empty-data-table press-release-table"
          }
        >
          <DataTable
            columns={columns}
            data={paginatedData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="table-search-div"
          >
            {columns.map((column) =>
              column.searchable !== false ? (
                <input
                  key={column.id}
                  type="text"
                  value={searchValues[column.id] || ""}
                  style={{ width: columnWidths[column.name] }}
                  onChange={(event) => handleSearchChange(event, column.id)}
                />
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {displayedRange.total} entries
        </p>
        <CustomPagination
          totalItems={filteredDataByType.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>

      <PressReleasePopup
        isOpen={isModalOpen}
        closeModal={closeModal}
        formTitle="Press Release"
        scenarioType="editPressRelease"
        userData={rowData}
      />
      <CustomPopup
            isOpenPopup={showDeletePopup}
            closePopup={closeDeletePopup}
            title="Are you sure!"
            image={areIcon}
            text={"You want to Delete this Press Release ?"}
            btn1="Cancel"
            btn2="Confirm"
            successTitle="Done!"
            successText={"Delete successfully"}
            successIcon={successIcon}
            btn3="Ok"
            scenarioType="deletePress"
            // handleChangeColumn={handleChangeColumn}
            rowData={rowData}
          />
    </>
  );
};

export default PressReleaseTable;
