import React from "react";

const ItemsPerPageDropdown = ({ value, onChange }) => {
  return (
    <>
      <p style={{ marginLeft: "10px" }}>{"Show"} </p>{" "}
      <select value={value} onChange={onChange}>
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>100</option>
      </select>{" "}
      <span>{"entries"}</span>
    </>
  );
};

export default ItemsPerPageDropdown;
