import React, { useState, useEffect } from "react";
import logo from "../../Assets/Svg/agc-logo.svg";
import "./LoginForm.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { ADMIN_LOGIN } from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import Http from "../../HttpClients/HttpClients";
import showPass from "../../Assets/Images/show-password.png";
import hidePassword from "../../Assets/Images/hide-password.png";
import { useDispatch, useTranslation } from "../Imports/import";
import { getAllLoginDetails } from "../../Store/Reducer/loginDetails";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
const dispatch=useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const secretKey = "mysecretkey12345"; // Replace this with your actual secret key

  const encryptData = (data, key) => {
    try {
      const serializedData = JSON.stringify(data);
      const encryptedData = btoa(encodeURIComponent(serializedData));
      return encryptedData;
    } catch (error) {
      return null;
    }
  };

  const onSubmit = (data) => {
    const { user_name, password } = data;

    if (rememberMe) {
      document.cookie = `admin-email=${btoa(user_name)};path=/`;
      document.cookie = `admin-password=${btoa(password)};path=/`;
      document.cookie = `admin-rememberMe=true;path=/`;
    } else {
      document.cookie =
        "admin-email=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
      document.cookie =
        "admin-password=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
      document.cookie =
        "admin-rememberMe=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
    }

    Http.post(`${BaseUrl}${ADMIN_LOGIN}`, data)
      .then((res) => {

        if (res.status === 200) {
          const userInfo = JSON.stringify(res.data.user_info);

          const encryptedUserInfo = encryptData(userInfo, secretKey);
          localStorage.setItem("encryptedUserInfo", encryptedUserInfo);
          localStorage.setItem("vms_admin_token", res.data.token);
          dispatch(getAllLoginDetails());
          toast.success(res.message);
          navigate("/vendor-management");
          window.scrollTo(0, 0);
        } else if (res.status === 401) {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong Please try again");
        }
      })
      .catch((error) => {
        toast.error(error?.message || 'Something went wrong!')
      });
  };

  useEffect(() => {
    const cookies = document.cookie.split("; ");
    let storedEmail = "";
    let storedPassword = "";
    let storedRememberMe = false;

    cookies.forEach((cookie) => {
      const [key, value] = cookie.split("=");
      if (key === "admin-email") {
        storedEmail = atob(value); // Decode the Base64 encoded value
      } else if (key === "admin-password") {
        storedPassword = atob(value); // Decode the Base64 encoded value
      } else if (key === "admin-rememberMe") {
        storedRememberMe = value === "true";
      }
    });

    if (storedEmail && storedPassword) {
      setValue("user_name", storedEmail);
      setValue("password", storedPassword);
      setRememberMe(true);
    }
  }, [setValue]);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <img src={logo} alt="" style={{ width: "160px" }} />
      </div>
      <div className="login-form-div">
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="login-title">AGC VinYthai</p>
          <h1 className="login-welcome">Welcome to user Panel</h1>
          <div className="login-label-input pt-3">
            <label className="login-label">Username</label>
            <br />
            <input
              className="login-input"
              type="email"
              {...register("user_name", {
                required: "Please enter the Username*",
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.user_name && errors.user_name.type === "pattern" && (
              <span className="error-msg">{t("Invalid email address.")}</span>
            )}
            {errors.user_name && (
              <span className="error-msg">{errors.user_name.message}</span>
            )}
          </div>
          <div className="login-label-input pt-2">
            <label className="login-label">Password</label> <br />
            <div className="login-input d-flex justify-content-between">
              <input
                style={{ width: "100%" }}
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: "Please enter the Password*",
                })}
              />
              <button
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <img src={showPassword ? hidePassword : showPass} alt="" />
              </button>
            </div>
            {errors.password && (
              <span className="error-msg">{errors.password.message}</span>
            )}
          </div>
          <div className="pt-3 d-flex align-items-center ">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              style={{ marginRight: "5px", height: "20px" }}
            />
            <label className="login-label"> Remember me</label>
          </div>
          <button type="submit" className="login-btn">
            Login
          </button>
        </form>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "45px", marginBottom: "auto" }}
      >
        <p className="lg-terms">
          {" "}
          <a href="https://agcvinythai.com/pdpa/" target="_blank">
            Term of Service{" "}
          </a>{" "}
          |{" "}
          <a href="https://agcvinythai.com/pdpa/" target="_blank">
            {" "}
            Privacy Policy{" "}
          </a>{" "}
          |{" "}
          <a href="https://agcvinythai.com/pdpa/" target="_blank">
            {" "}
            Accessibility{" "}
          </a>{" "}
        </p>
      </div>
    </>
  );
};

export default LoginForm;
