import {
  React,
  useState,
  useForm,
  useDispatch,
} from "../../Components/Imports/import";
import Modal from "react-modal";
import CloseIcon from "../../Assets/Svg/close-icon.svg";
import "./PrReleasePopup.css";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
import { useTranslation } from "../../../src/Components/Imports/import";
import { currentPrReleaseDataAction } from "../../Store/actions/prReleaseActions";

// PrReleasePopup Component
const PrReleasePopup = ({
  PrReleaseIsOpen,
  PrReleaseCloseModal,
  PrReleaseFormTitle,
  rowData,
  selectedRows,
}) => {
  const dispatch = useDispatch();
  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if(selectedRows.length>1){
      await dispatch(currentPrReleaseDataAction(data));
    }
    else{
      data.PrCreatedId = [rowData.PrCreatedId];
      data.PR_Company = rowData.PR_Company;
     
      await dispatch(currentPrReleaseDataAction(data));
    }
    setShowAreYouSurePopup(true);
  };

  const closeAreYouSure = () => {
    setShowAreYouSurePopup(false);
  };

  const onClickCancel = async () => {
    reset();
    PrReleaseCloseModal();
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={PrReleaseIsOpen}
          onRequestClose={PrReleaseCloseModal}
          className="pr-release-cre-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="create-user-title">
            <div className="d-flex">
              <h2 style={{ marginRight: "20px" }}>{PrReleaseFormTitle}</h2>
            </div>
            <img
              src={CloseIcon}
              alt=""
              onClick={() => {
                reset();
                PrReleaseCloseModal();
              }}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="pr-rele-labels">
            <br />
            <div className="date-main-container">
              <div>
                <label>{t("Bidding End")}</label>
                <br />
                <input
                  className="pr-bid-end-inp"
                  type="date"
                  {...register("BiddingDate", {
                    required: true,
                  })}
                />

                {errors.BiddingDate && (
                  <span className="error-msg">
                    {t("Please select the bidding date")}
                  </span>
                )}
              </div>
              <div>
                <label>{t("Delivery Date")}</label>
                <br />
                <input
                  className="pr-bid-end-inp"
                  type="date"
                  {...register("DeliveryDate", {
                    required: true,
                  })}
                />
                {errors.DeliveryDate && (
                  <span className="error-msg">
                    {t("Please select the delivery date")}
                  </span>
                )}
              </div>
              <div>
                <div className="pr-rel-code-select">
                  <label htmlFor="code">{t("Code")}</label>
                  <div className="pr-rel-select-container">
                    <select
                      className="pr-code-select"
                      id="code"
                      defaultValue=""
                      {...register("Code", {
                        required: true,
                      })}
                    >
                      <option value="" disabled hidden>
                        {t("Select an option")}
                      </option>
                      <option value="ShutDown">{t("ShutDown")}</option>
                      <option value="TurnAround">{t("TurnAround")}</option>
                      <option value="MRO">{t("MRO")}</option>
                      <option value="Service">{t("Service")}</option>
                      <option value="Contract">{t("Contract")}</option>
                      <option value="CAPEX">{t("CAPEX")}</option>
                      <option value="Project">{t("Project")}</option>
                      <option value="N/A">{t("N/A")}</option>
                      <option value="General">{t("General")}</option>
                      <option value="Logistic">{t("Logistic")}</option>
                      <option value="Process treatment">{t("Process treatment")}</option>
                      <option value="Scrap/Surplus">{t("Scrap/Surplus")}</option>
                      <option value="Wastes Disposal">{t("Wastes Disposal")}</option>
                      <option value="Packing">{t("Packing")}</option>
                    </select>
                    {errors.Code && (
                      <span className="error-msg">
                        {t("Please select the code")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-rel-inp-tit-text">
              <label>{t("PR Title")}</label>
              <input
                className="penalty-title"
                type="text"
                {...register("PrTitle", {
                  required: true,
                })}
              />
              {errors.PrTitle && (
                <span className="error-msg">{t("Please enter the Title")}</span>
              )}
              <br />
              <label>{t("Note")}</label>
              <br />
              <input
                type="text"
                {...register("Note", {
                  required: true,
                })}
              />{" "}
              {errors.Note && (
                <span className="error-msg">{t("Please enter the Note")}</span>
              )}
            </div>
            <div className="pt-3 create-btns-div">
              <button
                type="button"
                className="um-create-btn"
                onClick={onClickCancel}
              >
                {t("Cancel")}
              </button>

              <button type="submit" className="um-submit-btn">
                {t("Submit")}
              </button>
            </div>
          </form>
        </Modal>
      </div>

      <CustomPopup
        isOpenPopup={showAreYouSurePopup}
        closePopup={closeAreYouSure}
        image={areIcon}
        title="Are you sure!"
        text={"You want to Release this PR ?"}
        btn1="Cancel"
        btn2="Confirm"
        successTitle="Done!"
        successText={"PR released Successfully"}
        successIcon={successIcon}
        btn3="Ok"
        closeFormPopup={PrReleaseCloseModal}
        reset={reset}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        scenarioType={selectedRows?.length>1?"releaseMultiplePr":"releasePr" || "releasePr"}
        userData={rowData}
        selectedRows={selectedRows}
      />
    </>
  );
};

export default PrReleasePopup;
