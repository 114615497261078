import TYPES from "../constants";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import {
  CREATE_PURCHASE_GROUP,
  EDIT_PURCHASE_GROUP,
  GET_ALL_PURCHASE_GROUP,
} from "../../ApiEndPoints/ApiEndPoints";

// calling the get all users API for PURCHASE_GROUP

export const getAllPurchaseGroup = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(getPurchaseGroupApiRequest());
      const response = await axios.get(`${BaseUrl}${GET_ALL_PURCHASE_GROUP}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.GET_ALL_PURCHASE_GROUP_SUCCESS,
          payload: response.data.data,
        });
        
      } else {
        dispatch({
          type: TYPES.GET_ALL_PURCHASE_GROUP_FAILURE,
          error: "Something went wrong , please try again",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_ALL_PURCHASE_GROUP_FAILURE,
        error: error.message,
      });
    }
  };
};

// calling the Create PURCHASE_GROUP api

export const createNewPurchaseGroup = (userDetails) => {
  
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      const response = await axios.post(
        `${BaseUrl}${CREATE_PURCHASE_GROUP}`,
        userDetails,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.CREATE_PURCHASE_GROUP_SUCCESS,
          payload: response.data,
        });
        toast.success(response.data.message);
        return true;
      } else if (response.status === 404 || response.data.status === 401) {
        dispatch({
          type: TYPES.CREATE_PURCHASE_GROUP_FAILURE,
          error: "Something went wrong, Please try again",
        });
        toast.error("Something went wrong, Please try again.");
      }
    } catch (error) {
      dispatch({
        type: TYPES.CREATE_PURCHASE_GROUP_FAILURE,
        error: error.message,
      });
      toast.error("An error occurred while editing the user.");
    }
  };
};

// Action function for Paricular PURCHASE_GROUP DATA

export const createPurchaseGroup = (userData) => {
  
  return {
    type: TYPES.CREATE_PURCHASE_GROUP_DETAILS,
    payload: userData,
  };
};

// Initial action for calling get all user api

export const getPurchaseGroupApiRequest = () => {
  return {
    type: TYPES.GET_ALL_PURCHASE_GROUP_API_REQUEST,
  };
};

// Action function for current row data for tha edit purpose

export const currentPurchaseGroupRowData = (row) => {
  return {
    type: TYPES.CURRENT_PURCHASE_GROUP_ROW_DATA,
    payload: row,
  };
};

// calling the Edit API in for PURCHASE_GROUP

export const editPurchaseGroup = (PurchaseGroup) => {

  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_admin_token");
      dispatch(editPurchaseGroupRequest());
      const response = await axios.patch(
        `${BaseUrl}${EDIT_PURCHASE_GROUP}`,
        PurchaseGroup,
        {
          headers: { Authorization: `Bearer ${token} ` },
        }
      );
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.EDIT_PURCHASE_GROUP_SUCCESS,
          payload: response.data,
          loading: false,
          error: null,
        });
        toast.success("Purchase Group Data Updated successfully!");
        return true;
      } else if (response.data.status === 401) {
        dispatch({
          type: TYPES.EDIT_PURCHASE_GROUP_FAILURE,
          error: "Something went wrong , Please try again",
          loading: false,
        });
        toast.error(response.data.message);
      } else {
        toast.error("Something went wrong , Please try again.");
      }
    } catch (error) {
      dispatch({
        type: TYPES.EDIT_PURCHASE_GROUP_FAILURE,
        error: error.message,
        loading: false,
      });
      toast.error("An error occurred while editing the purchase group.");
    }
  };
};

//Inital action for calling the edit user API
export const editPurchaseGroupRequest = () => {
  return {
    type: TYPES.EDIT_PURCHASE_GROUP_API_REQUEST,
  };
};
// Action function for store the edit data for edit the user details in PURCHASE_GROUP
export const editParticularPurchaseGroup = (editPurchaseGroup) => {
  return {
    type: TYPES.EDIT_PARTICULAR_PURCHASE_GROUP,
    payload: editPurchaseGroup,
  };
};
