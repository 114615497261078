import React, { useState, useRef, useEffect } from "react";
import { toast, useDispatch, useForm, useSelector } from "../../Components/Imports/import";
import Modal from "react-modal";
import CloseIcon from "../../Assets/Svg/close-icon.svg";
import QueMarkLogo from "../../Assets/Images/que-mark.png";
import FileUpload from "../../Assets/Images/fileupload.png";
import pdflogo from "../../Assets/Svg/pdf-icon.svg";
import RemoveLogo from "../../Assets/Images/remove.png";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "../../../src/Components/Imports/import";

import "./SendPoPopup.css";
import { deletePoDoc, uploadPoDoc } from "../../Store/actions/supplierManagementActions";

const SendPoPopup = ({ SendPoIsOpen, SendPoCloseModal, SendPoFormTitle,PoDbId,attachments , onFileChange}) => {
  const dispatch =useDispatch();
  const {sendPoAttachment,loading,error}=useSelector((state)=>state.DashboardReducer)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newAttachments,setNewAttachmets]=useState([]);
  const [fileError, setFileError] = useState("");
  const { t } = useTranslation();

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (attachments.length>0) {
      setSelectedFiles(attachments.map(attachment => ({
        id:attachment?.attachmentId || "",
        path: attachment?.attachmentName || "",
        url: attachment?.attachmentUrl || "",
        
      })));
    }
    else{
      setSelectedFiles([])
    }
  }, [attachments,SendPoIsOpen]);




  const onDrop = acceptedFiles => {
    const pdfFiles = acceptedFiles.filter(file => file.type === "application/pdf");
    if (pdfFiles.length < acceptedFiles.length) {
      setFileError("Only PDF files are allowed.");
    } else {
      setFileError("");
      setNewAttachmets(prevSelectedFiles => [...prevSelectedFiles, ...pdfFiles]);
    }
  };
  
  
  const removeFile = (file) => async () => {
    const result = window.confirm("Are you sure you want to delete this file?");
    if (result) {
      try {
        await dispatch(deletePoDoc(file.id));
        setSelectedFiles(prevFiles => prevFiles.filter(f => f.id !== file.id));
        onFileChange();
      } catch (error) {
        toast.error(error?.message || "Something went wrong!")
      }
    }
  };
  const removeNewAttachments = (file)=> async()=>{
    setNewAttachmets(prevFiles => prevFiles.filter(f => f.name !== file.name));
  }

  const { handleSubmit } = useForm({
    // Your form configuration

  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (newAttachments?.length < 1) {
      toast.error("Please add attachment");
      return;
    }
    try {
      const uploadPromises = newAttachments.map(file => 
        dispatch(uploadPoDoc(PoDbId, file))
      );
      await Promise.all(uploadPromises);
      setSelectedFiles([]);
      setNewAttachmets([]);
      onFileChange();
      SendPoCloseModal();
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
    }
  };
  

  const onClickDiscard = () => {
    setSelectedFiles([]);
    setNewAttachmets([]);
    SendPoCloseModal();
  };
  const onCloseModal = () => {
    setSelectedFiles([]);
    SendPoCloseModal();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: true,
  });

  const onSelectFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <Modal
        isOpen={SendPoIsOpen}
        onRequestClose={SendPoCloseModal}
        className="create-user-modal create-send-po-modal"
        overlayClassName="create-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="create-user-title">
          <div className="d-flex">
            <h2 style={{ marginRight: "20px" }}>{SendPoFormTitle}</h2>
          </div>
          <img
            src={CloseIcon}
            alt=""
            onClick={onCloseModal}
            className="send-po-close-icon"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">
          <br />
          <div className="snd-po-main-div">
            <div className="snd-po-text-icon">
              <label>{t("Attach PO Document")}</label>
              <img src={QueMarkLogo} alt="?" className="que-mark-logo" />
            </div>
            <div className="snd-po-dropzone" {...getRootProps()}>
              <div className="snd-po-dropzone-icon">
                <img
                  src={FileUpload}
                  alt="Upload"
                  className="file-upload-icon"
                />
                <label>{t("Select File to Upload")}</label>
                <p>{t("or drag and drop it here")}</p>
              </div>
              <input {...getInputProps()} ref={fileInputRef} style={{ display: 'none' }} />
            </div>
            <button
              type="button"
              className="snd-po-sele-file-btn"
              onClick={onSelectFileClick}
            >
              {t("Select File")}
            </button>
            {fileError && <p className="send-po-error-m">{fileError}</p>}
          </div>
          <div className="snd-po-document-file">
            <label>{t("Document File")}</label>
          </div>
          <br />
          {selectedFiles.map((file, index) => (
            <div className="snd-po-file-border" key={index}>
              
              <div className="snd-po-upload-file">
              
                <img src={pdflogo} alt="PDF" className="sendpo-pdf-logo" />
                <a style={{marginRight:"50%"}} href={file?.url} target="_blank" rel="noreferrer">
                <p >{file?.path}</p>
                </a>
                <img
                  src={RemoveLogo}
                  alt="Remove"
                  className="sendpo-remove-logo"
                  onClick={removeFile(file)}
                />
              </div>
            
            </div>
          ))}
          <br />
          {newAttachments.map((file, index) => (
            <div className="snd-po-file-border" key={index}>
              
              <div className="snd-po-upload-file">
              
                <img src={pdflogo} alt="PDF" className="sendpo-pdf-logo" />
                <a style={{marginRight:"50%"}} href={file?.url} target="_blank" rel="noreferrer">
                <p >{file?.path}</p>
                </a>
                <img
                  src={RemoveLogo}
                  alt="Remove"
                  className="sendpo-remove-logo"
                  onClick={removeNewAttachments(file)}
                />
              </div>
            
            </div>
          ))}
          <br />
          <div className="create-btns-div">
            <button
              type="button"
              className="um-create-btn"
              onClick={onClickDiscard}
            >
              {t("Discard")}
            </button>
            <button type="submit" className="um-submit-btn">
              {t("Send PO")}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default SendPoPopup;



