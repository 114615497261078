import {
  React,
  useState,
  useEffect,
  useForm,
  useDispatch,
  useSelector,
  Http,
  BaseUrl,
  toast,
} from "../../Components/Imports/import";
import Modal from "react-modal";
import { useDropzone } from "react-dropzone";
import CloseIcon from "../../Assets/Svg/close-icon.svg";
import VectorLogo from "../../Assets/Svg/vector-logo.svg";
import EditIcon from "../../Assets/Svg/edit-icon.svg";
import "./PressReleasePopup.css";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
import redCrossIcon from "../../Assets/Images/red-cross.png";
import {
  createPressRelease,
  currentPressReleaseRowData,
  editParticularPressRelease,
  getAllPressRelease,
} from "../../Store/actions/pressReleaseAction";
import {
  DELETE_PRESS_IMAGE,
  GET_PRESS_BY_ID,
} from "../../ApiEndPoints/ApiEndPoints";

// Function to check if a file is valid based on type and size
const isFileValid = (file, maxFileSize) => {
  const allowedTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
  return allowedTypes.includes(file.type) && file.size <= maxFileSize;
};

// FilePreview Component: Renders a preview of a selected file and provides an option to delete it
const FilePreview = ({ file, onDelete, isEditMode, scenarioType }) => {
  const imageUrl = typeof file === "string" ? file : URL.createObjectURL(file);

  return (
    <div className="selected-file-container">
      <img
        src={imageUrl}
        alt=""
        className="selected-file"
        onClick={(event) => event.stopPropagation()}
      />
      {(scenarioType === "createPressRelease" || !isEditMode) && (
        <img
          src={redCrossIcon}
          alt="x"
          style={{ width: "15px", height: "15px" }}
          className="close-icon"
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
        />
      )}
    </div>
  );
};

// PressReleasePopup Component
const PressReleasePopup = ({
  isOpen,
  closeModal,
  formTitle,
  scenarioType,
  userData,
}) => {
  const dispatch = useDispatch();
  const { currentPressReleaseRow, getPressReleaseTableData } = useSelector(
    (state) => state.getPressReleaseInfo
  );

  function getObjectById(id) {
    const result = getPressReleaseTableData.find((item) => item.id === id);
    return result || null;
  }

  const userToken = localStorage.getItem("vms_admin_token");

  const initialID = currentPressReleaseRow?.id || "";
  // 1. Initialize selectedFiles state based on the currentPressReleaseRow.press_url
  const initialImage = currentPressReleaseRow?.press_url || "[]";
  const parsedImages = JSON.parse(initialImage);
  const initialSelectedFiles = parsedImages.map((url) => new File([url], url));
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState();
  const [fileErrorMsg, setFileErrorMsg] = useState("");
  const maxFilesLimit = 12;
  const maxFileSize = 1000000; // 1 MB

 

  //  //api
  //  const [data, setData] = useState({});
  // //end

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onDropRejected = (fileRejections) => {
    const firstFileRejection = fileRejections[0];
    if (firstFileRejection) {
      const { errors } = firstFileRejection;
      const errorMessage = errors[0].message;
      if (errorMessage.includes("maxSize")) {
        // Custom error message for file size exceeding the limit
        setFileErrorMsg(`File size exceeds the maximum allowed limit of 1 MB`);
      } else {
        setFileErrorMsg(`File size exceeds the maximum allowed limit of 1 MB`);
      }
    }
  };

  const onDropAccepted = (acceptedFiles) => {
    let errorMsg = "";

    const oldFileCount =
      scenarioType === "editPressRelease" ? initialSelectedFiles.length : 0;

    const totalFilesCount =
      selectedFiles.length + acceptedFiles.length + oldFileCount;

    if (totalFilesCount > maxFilesLimit) {
      errorMsg = `Max number of files: ${maxFilesLimit}`;
      acceptedFiles = acceptedFiles.slice(
        0,
        maxFilesLimit - selectedFiles.length
      );
    }

    acceptedFiles.forEach((file) => {
      if (!isFileValid(file, maxFileSize)) {
        errorMsg = `Invalid file: ${file.name}`;
      }
      // } else {
      //   setSelectedFiles((prevFiles) => [...prevFiles, file]);
      // }
    });
    if (errorMsg === "") {
      // If there are no errors, update the selectedFiles state
      setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }
    setFileErrorMsg(errorMsg);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/gif, image/jpeg, image/jpg, image/png",
    maxFiles: maxFilesLimit - selectedFiles.length,
    maxSize: maxFileSize,
    onDropAccepted,
    onDropRejected,
  });

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const removeOldFile = async (url) => {
 
    const newUrl = url?.name?.toString();
   
    const afterCom = newUrl.split(".com/")[1];
   
    const formData = { press_id: initialID, image_name: afterCom };
    
    try {
      const response = await Http.post(
        `${BaseUrl}${DELETE_PRESS_IMAGE}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      // if api get success call get press by id api below
      if (response.status === 200) {
        // Create the queryParams object with the necessary query parameters
        const queryParams = {
          press_id: initialID,
        };

        // Use the Http.get method to make the GET request
        Http.get(`${BaseUrl}${GET_PRESS_BY_ID}`, queryParams, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then((result) => {
            if (result.status === 200) {
              const rowData = result?.data[0];
              const modifiedApiData = {
                ...rowData, // Copy all properties from the original object
                Title: rowData.title,
                Description: rowData.description,
              };
              delete modifiedApiData.title;
              delete modifiedApiData.description;
            
              dispatch(currentPressReleaseRowData(modifiedApiData));
              dispatch(getAllPressRelease());
              toast.success("Image deleted successfully");
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) =>  toast.error(error?.message || "Something went wrong!"));
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

 

  const onSubmit = (data, e) => {
    e.preventDefault();
    setFileErrorMsg("");


    // Create a new instance of FormData
    const formData = new FormData();

    // Append form fields to FormData
    formData.append("title", data.title);
    formData.append("description", data.description);

    // based on scenarios appending id to formData
    if (scenarioType === "editPressRelease") {
      formData.append("press_id", initialID);
    }

    // Append selected files to FormData
    selectedFiles.forEach((file, index) => {
      formData.append("images", file);
    });

    setShowAreYouSurePopup(true);
    if (scenarioType === "createPressRelease") {

      dispatch(createPressRelease(formData));
    } else if (scenarioType === "editPressRelease") {
      dispatch(editParticularPressRelease(formData));
      setIsEditMode(true);
    }
  };
  const closeAreYouSure = () => {
    setShowAreYouSurePopup(false);
  };

  const onClickEdit = () => {
    setIsEditMode(false);
  };

  const onClickCancel = async () => {
    if (scenarioType !== "createPressRelease") {
      setIsEditMode(true);
    }
    setSelectedFiles([]);
    closeModal();
  };
  useEffect(() => {
    // 2. Update selectedFiles state whenever currentPressReleaseRow.press_url changes
    // Check if currentPressReleaseRow.press_url is an array
    if (scenarioType === "editPressRelease") {
      const newSelectedFiles = Array.isArray(currentPressReleaseRow?.press_url)
        ? currentPressReleaseRow?.press_url.map((url) => url)
        : [];
      setSelectedFiles(newSelectedFiles);
    }
  }, [currentPressReleaseRow, scenarioType]);

  useEffect(() => {
    if (scenarioType === "editPressRelease") {
      setValue("title", currentPressReleaseRow?.Title);
      setValue("description", currentPressReleaseRow?.Description);
    }
  }, [currentPressReleaseRow, setValue]);

  useEffect(() => {
    if (scenarioType === "createPressRelease") {
      setSelectedFiles([]);
      setIsEditMode(false);
      reset();
    } else {
      setIsEditMode(true);
    }
  }, [scenarioType]);

 
  return (
    <>
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className="create-user-modal create-press-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="create-user-title">
            <div className="d-flex">
              <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
              {isEditMode && (
                <button
                  type="button"
                  onClick={onClickEdit}
                  className="user-edit-btn"
                >
                  <img src={EditIcon} alt="" /> Edit
                </button>
              )}
            </div>
            <img src={CloseIcon} alt="" onClick={() => closeModal()} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">
            <div className="press-photos">
              <input
                {...getInputProps({
                  accept: "image/gif, image/jpeg, image/jpg, image/png",
                  id: "file", // Make sure to assign an id to the input
                })}
                disabled={isEditMode}
                id="file"
              />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <>
                  <label>Photos</label>
                  {selectedFiles.length > 0 && (
                    <div className="selected-files">
                      {selectedFiles.map((file, index) => (
                        <FilePreview
                          key={index}
                          file={file}
                          scenarioType={"createPressRelease"}
                          isEditMode={isEditMode}
                          onDelete={() => removeFile(index)}
                        />
                      ))}
                    </div>
                  )}

                  {scenarioType !== "createPressRelease" &&
                    initialSelectedFiles.length > 0 && ( // Render initial images here
                      <div className="selected-files">
                        {initialSelectedFiles.map((url, index) => (
                          <FilePreview
                            key={index}
                            file={url.name}
                            scenarioType={"editPressRelease"}
                            onDelete={() => removeOldFile(url)}
                            isEditMode={isEditMode}
                          />
                        ))}
                      </div>
                    )}

                  <label
                    htmlFor="file"
                    className="choose-file-button"
                    style={
                      isEditMode
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                  >
                    <img src={VectorLogo} alt="" className="choose-file-icon" />
                    <span
                      className="press-choose-file-txt"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Choose File
                    </span>
                  </label>

                  {fileErrorMsg && (
                    <span className="error-msg">{fileErrorMsg}</span>
                  )}
                  <p className="press-photos-p">
                    Max number of files: {maxFilesLimit}, maximum file size:{" "}
                    {maxFileSize / 1000} KB. Supported file formats: gif, jpeg,
                    jpg, png.
                  </p>
                </>
              )}
            </div>
            {/* {!isEditMode && errors.Photos && (
              <span className="error-msg">Please upload a file</span>
            )} */}
            <div>
              <label>Title</label>
              <br />
              <input
                className="press-title"
                type="text"
                {...register("title", {
                  required: "Please enter the Title",
                  minLength: {
                    value: 5,
                    message: "Title must be at least 5 characters",
                  },
                  maxLength: {
                    value: 100,
                    message: "Title must not exceed 100 characters",
                  },
                })}
                disabled={isEditMode}
              />
              {errors.title && (
                <span className="error-msg">{errors.title.message}</span>
              )}
            </div>
            <label>Description</label>
            <br />
            <textarea
              className="press-description"
              {...register("description", {
                required: "Please enter the Description",
                minLength: {
                  value: 10,
                  message: "Description must be at least 10 characters",
                },
                maxLength: {
                  value: 3000,
                  message: "Description must not exceed 3000 characters",
                },
              })}
              disabled={isEditMode}
            />
            <br />
            {errors.description && (
              <span className="error-msg">{errors.description.message}</span>
            )}
            <br />
            <div className="press-pt-3 create-btns-div">
              <button
                type="button"
                className="um-create-btn"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              {!isEditMode && (
                <button
                  type="submit"
                  className="um-submit-btn"
                  // disabled={!!fileErrorMsg || Object.keys(errors).length > 0}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </Modal>
      </div>

      <CustomPopup
        isOpenPopup={showAreYouSurePopup}
        closePopup={closeAreYouSure}
        image={areIcon}
        title="Are you sure!"
        text={
          userData
            ? "You want to Update this Press Release ?"
            : "You want to Create this Press Release ?"
        }
        btn1="Cancel"
        btn2="Confirm"
        successTitle="Done!"
        successText={
          userData
            ? "Press Release Details Updated Successfully"
            : "Create Press Release Successfully"
        }
        successIcon={successIcon}
        btn3="Ok"
        closeFormPopup={closeModal}
        reset={reset}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        scenarioType={userData ? "editPressRelease" : "createPressRelease"}
      />
    </>
  );
};

export default PressReleasePopup;
