import {
  React,
  useEffect,
  useForm,
  useDispatch,
  useSelector,
  useState,
  useTranslation,
  Controller,
} from "../../Imports/import";
import { updatedDataSecond } from "../../../Store/Reducer/registrationReducers";
import { MultiSelect } from "react-multi-select-component";
import "./BiddingCategories.css";
import { handleKeyDown, subCategoryOptions } from "../../../Utils/Utils";
import useIsFieldMandatory from "../../../CustomHooks/useIsFieldMandatory";
import dropDownIcon from "../../../Assets/Svg/drop-down-icon.svg";

const BiddingCategories = ({ setType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFieldMandatory = useIsFieldMandatory();
  const onClickBack = () => {
    setType("General");
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const vendorData = useSelector((state) => state.registration.secondObject);


  const initialSelected =
    vendorData?.sub_category?.length > 0
      ? vendorData?.sub_category?.map((value) => ({
          label: value,
          value,
        }))
      : [];

  const [selectedSubCategories, setSelectedSubCategories] =
    useState(initialSelected);
  // Define the maximum number of allowed selections
  const maxAllowedSelections = 3;

  const onSubmit = (data) => {
    // Include selected values in the data being dispatched

    data.sub_category = Array.isArray(data?.sub_category)
      ? data.sub_category.map((option) =>
          typeof option === "string" ? option : option?.value
        )
      : data?.sub_category;
    dispatch(updatedDataSecond(data));
    setType("Financial");
  };

  // function for check sub categories lengths

  const onChangeSubCategory = (value) => {
    const selectedValues = value.slice(0, maxAllowedSelections);
    setValue("sub_category", selectedValues);
    setSelectedSubCategories(selectedValues);
  };

  useEffect(() => {
    Object.entries(vendorData)?.forEach(([name, value]) => {
      setValue(name, value);
    });
  }, [vendorData]);

  const ArrowRenderer = ({ expanded }) => (
    <>
      {expanded ? (
        <img src={dropDownIcon} alt="" />
      ) : (
        <img src={dropDownIcon} alt="" />
      )}
    </>
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="pb-5">
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Type of Business")}{" "}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration custom-select"
                {...register("type_of_business", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select business type")}
                </option>
                <option value="Manufacturer"> {t("Manufacturer")}</option>
                <option value="Service">{t("Service")}</option>
                <option value="Trading">{t("Trading")}</option>
                <option value="Transportation">{t("Transportation")}</option>
                <option value="Rawmaterial">{t("Raw material")}</option>
                <option value="other">{t("Other, Please specify")}</option>
              </select>
              {errors.type_of_business && (
                <span className="error-msg">
                  {t("Please select the type of business")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">{t("Experience")} </label>
              <br />

              <select
                type="text"
                className="input-border registration  custom-select"
                {...register("experience", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {t("select Experience")}{" "}
                </option>
                <option value="Less than">{t("Less than 1 year ")}</option>
                <option value="Up to ">{t("Up to 3 years")}</option>
                <option value="Over">{t("Over 3 years")}</option>
              </select>
              {errors.experience && (
                <span className="error-msg">
                  {t("Please select the experience.")}
                </span>
              )}
            </div>
          </div>
          {watch("type_of_business") === "other" && (
            <div className="textbox-container">
              <div className="left-container-hidden-input">
                <br />

                <input
                  type="text"
                  className="input-border registration-left "
                  {...register("business_specify", {
                    required: true,
                    maxLength: 80,
                  })}
                />
                {errors.business_specify && (
                  <span className="error-msg">
                    {t("Please enter other specific data")}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="textbox-container">
            <div className="left-container">
              <label className="vendor-input-label">{t("Working Day")}</label>
              <br />

              <select
                type="text"
                className="input-border registration  custom-select"
                {...register("working_day", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {t("select working day")}
                </option>
                <option value="1day">{t("1 day")}</option>
                <option value="2day">{t("2 day")}</option>
                <option value="3day">{t("3 day")}</option>
                <option value="4day">{t("4 day")}</option>
                <option value="5day">{t("5 day")}</option>
                <option value="6day">{t("6 day")}</option>
                <option value="7day">{t("7 day")}</option>
              </select>
              {errors.working_day && (
                <span className="error-msg">
                  {t("Please select the working days.")}
                </span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label">{t("Working Time")} </label>
              <br />

              <select
                type="text"
                className="input-border registration  custom-select"
                {...register("working_time", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {t("select working time")}
                </option>
                <option value="1hour">{t("1 hour")}</option>
                <option value="3hours">{t("3 hours")}</option>
                <option value="6hours">{t("6 hours")}</option>
                <option value="8hours">{t("8 hours")}</option>
                <option value="10hours">{t("10 hours")}</option>
                <option value="12hours">{t("12 hours")}</option>
              </select>
              {errors.working_time && (
                <span className="error-msg">
                  {t("Please select the working Time.")}
                </span>
              )}
            </div>
          </div>
          <div className="textbox-container" style={{ alignItems: "baseline" }}>
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Bidding Category")}
              </label>
              <br />
              <select
                type="text"
                className="input-border registration  custom-select"
                {...register("binding_category", {
                  required: true,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {t("select bidding category")}
                </option>
                <option value="Goods">{t("Goods")}</option>
                <option value="Services">{t("Services")}</option>
                <option value="Other Please specify">
                  {t("Other Please specify")}
                </option>
              </select>
              {errors.binding_category && (
                <span className="error-msg">
                  {t("Please select the bidding category.")}
                </span>
              )}
            </div>

            <div className="right-container">
              <label className="vendor-input-label">
                {t("Sub-Category")} (maximum of select list is 3 items)
                {isFieldMandatory && <span className="staricon">*</span>}
              </label>
              <br />

              <div className="dropdown-multi">
                <Controller
                  name="sub_category"
                  control={control}
                  rules={{
                    required: isFieldMandatory
                      ? t("Please select at least one subcategory.")
                      : false,
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <MultiSelect
                        className="multi-select-drop-down mb-3"
                        options={subCategoryOptions}
                        {...field}
                        value={selectedSubCategories}
                        onChange={(value) => {
                          const selectedValues = value.slice(
                            0,
                            maxAllowedSelections
                          );
                          field.onChange(selectedValues);
                          onChangeSubCategory(selectedValues);
                        }}
                        labelledBy={t("select sub category")}
                        hasSelectAll={false}
                        ArrowRenderer={ArrowRenderer}
                      />
                      {fieldState.error && (
                        <span className="error-msg mt-3">
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="textbox-container" style={{ alignItems: "baseline" }}>
            <div className="textbox-container">
              {watch("binding_category") &&
                watch("binding_category").includes("Other Please specify") && (
                  <div
                    className="left-container"
                    style={{ marginRight: "10px" }}
                  >
                    <br />

                    <input
                      type="text"
                      className="input-border registration-left "
                      {...register("binding_category_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.binding_category_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </div>
                )}
            </div>
            <div className="textbox-container">
              {selectedSubCategories?.some(
                (option) => option?.value === "Other"
              ) && (
                <div className="right-container" style={{ marginLeft: "10px" }}>
                  <br />

                  <input
                    type="text"
                    className="input-border registration-left "
                    {...register("sub_category_specify", {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  {errors.sub_category_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="textbox-container" style={{ alignItems: "baseline" }}>
            <div className="left-container">
              <label className="vendor-input-label">
                {t("Name of your top 3 customers (Reference)")}:
              </label>
              <br />

              <input
                type="text"
                className="input-border registration"
                {...register("name_of_top3_customers", {
                  required: false,
                })}
              ></input>
              {errors.name_of_top3_customers && (
                <span className="error-msg">{t("Please enter the value")}</span>
              )}
            </div>
            <div className="right-container">
              <label className="vendor-input-label-anylitigation">
                {t(
                  "Any litigation/arbitration proceedings currently in progress or pending"
                )}
              </label>
              <br />

              <select
                type="text"
                className="input-border registration  custom-select"
                {...register("process_litigation", {
                  required: isFieldMandatory,
                })}
              >
                <option value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="No">{t("No")}</option>
                <option value="Yes">{t("Yes")}</option>
              </select>
              {errors.process_litigation && (
                <span className="error-msg">
                  {t(
                    "Please select litigation /arbitration prceeding currently in progress or pending"
                  )}
                </span>
              )}
            </div>
          </div>
          {watch("process_litigation") === "Yes" && (
            <div className="textbox-container-specify">
              <div className="right-container-specify">
                <br />

                <input
                  type="text"
                  className="input-border registration-left "
                  {...register("litigation", {
                    required: true,
                    maxLength: 80,
                  })}
                />
                {errors.litigation && (
                  <span className="error-msg">
                    {t("Please enter other specific data")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="buttons pt-5">
          <div className="button-submit-container-back">
            <input
              className="button-submit"
              type="submit"
              value={t("Back")}
              onClick={onClickBack}
            />
          </div>
          <div className="button-submit-container">
            <input className="button-submit" type="submit" value={t("Next")} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BiddingCategories;
