import { useSelector } from "react-redux";

const useIsFieldMandatory = () => {
  const generalInfoData = useSelector(
    (state) => state.registration.firstObject
  );
  // from the first object getting the commercial registration type

  const commercialRegType =
    generalInfoData && generalInfoData.commercial_reg_type;

  //     from the commercialRegType extracting 1st index value
  const extractedValue =
    commercialRegType && commercialRegType.toString().match(/\((.*?)\)/)?.[1];
  const isFieldMandatory = extractedValue === "L";

  return isFieldMandatory;
};

export default useIsFieldMandatory;
