import {
  React,
  useState,
  useEffect,
  useForm,
  Controller,
  useDispatch,
  useSelector,
  useTranslation,
} from "../../Components/Imports/import";
import Modal from "react-modal";
import Select, { components } from "react-select";
import CloseIcon from "../../Assets/Svg/close-icon.svg";
import EditIcon from "../../Assets/Svg/edit-icon.svg";
// import "./PurchasingGroupPopup.css";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
import Up from "../../Assets/Images/Up.png";
import Down from "../../Assets/Images/Down.png";

import {
  createPurchaseGroup,
  editParticularPurchaseGroup,
} from "../../Store/actions/purshaseGroupAction";

const PurchasingGroupPopup = ({
  isOpen,
  closeModal,
  formTitle,
  scenarioType,
  userData,
}) => {
  const dispatch = useDispatch();
  const { currentPurchaseGroupRow } = useSelector(
    (state) => state.getPurchaseGroupInfo
  );

  const { LoginDetails } = useSelector((state) => state.loginDetails);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsBoxOpen, setOptionsBoxOpen] = useState(false);

  const isAssignerIsBuyer = LoginDetails?.assigner_type == "Buyer Group";

  const DropdownIndicator = (props) => {
    const { selectProps } = props;
    let arrow = selectProps.menuIsOpen ? (
      <img src={Up} alt="Up Arrow" />
    ) : (
      <img src={Down} alt="Down Arrow" />
    );
    return (
      <components.DropdownIndicator {...props}>
        {arrow}
      </components.DropdownIndicator>
    );
  };

  const options = [
    { value: "Goods", label: "Goods" },
    { value: "Service", label: "Service" },
    { value: "Project", label: "Project" },
  ];

  const onSubmit = (data, e) => {
    e.preventDefault();

    data.category = data.category.value;

    if (scenarioType === "editPurchasingGroup") {
      data.purchasinggroup_id = userData?.id;
    }

    setShowAreYouSurePopup(true);
    if (scenarioType === "createPurchasingGroup") {
      dispatch(createPurchaseGroup(data));
    } else if (scenarioType === "editPurchasingGroup") {
      dispatch(editParticularPurchaseGroup(data));
      setIsEditMode(true);
    }
  };

  const closeAreYouSure = () => {
    setShowAreYouSurePopup(false);
  };

  const onClickEdit = () => {
    setIsEditMode(false);
  };

  const onClickCancel = async () => {
    if (scenarioType !== "createPurchasingGroup") {
      setIsEditMode(true);
    }

    closeModal();
  };

  useEffect(() => {
    if (scenarioType === "editPurchasingGroup") {
      setValue(
        "purchasinggroup_name",
        currentPurchaseGroupRow?.purchasinggroup_name
      );
      setValue("meaning", currentPurchaseGroupRow?.meaning);
      const categoryOption = {
        value: currentPurchaseGroupRow?.category,
        label: currentPurchaseGroupRow?.category,
      };
      setSelectedOption([categoryOption]);
      setValue("category", categoryOption);
    }
    
  }, [currentPurchaseGroupRow, setValue]);

  useEffect(() => {
    if (scenarioType === "createPurchasingGroup") {
      setIsEditMode(false);
      reset();
    } else {
      setIsEditMode(true);
    }
  }, [scenarioType]);


  return (
    <>
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className="create-user-modal"
          overlayClassName="create-modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="create-user-title">
            <div className="d-flex">
              <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
              {isEditMode && !isAssignerIsBuyer && (
                <button
                  type="button"
                  onClick={onClickEdit}
                  className="user-edit-btn"
                >
                  <img src={EditIcon} alt="" /> {t("Edit")}
                </button>
              )}
            </div>
            <img src={CloseIcon} alt="" onClick={() => closeModal()} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">
            <div>
              <label>{t("Purchasing Group")}</label>
              <br />
              <input
                className="press-title"
                type="text"
                {...register("purchasinggroup_name", {
                  required: "Please enter the Purchasing Group",
                  maxLength: {
                    value: 50,
                    message: "Maximum length is 50 characters",
                  },
                })}
                disabled={isEditMode}
              />
              {errors.purchasinggroup_name && (
                <span className="error-msg">
                  {errors.purchasinggroup_name.message}{" "}
                </span>
              )}
            </div>
            <label>{t("Meaning")}</label>
            <br />
            <input
              className="press-title"
              type="text"
              {...register("meaning", {
                required: "Please enter the Meaning",
                maxLength: {
                  value: 50,
                  message: "Maximum length is 50 characters",
                },
              })}
              disabled={isEditMode}
            />
            {errors.meaning && (
              <span className="error-msg">{errors.meaning.message}</span>
            )}

            <br />
            <label>{t("Category")}</label>
            <br />

            <div
              style={{
                width: "100%",
                marginBottom: optionsBoxOpen ? "60px" : "0", // Adjust the margin based on options box state
              }}
            >
              <Controller
                name="category"
                control={control}
                defaultValue=""
                rules={{ required: "Please select the category" }}
                render={({ field }) => (
                  <Select
                    options={options.map((option) => ({
                      ...option,
                    }))}
                    {...field}
                    value={selectedOption}
                    placeholder="Select Category"
                    onChange={(option) => {
                      setSelectedOption(option);
                      field.onChange(option);
                    }}
                    components={{ DropdownIndicator }}
                    onMenuOpen={() => setOptionsBoxOpen(true)} // Update options box state
                    onMenuClose={() => setOptionsBoxOpen(false)} // Update options box state
                    // styles={customStyles}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    isDisabled={isEditMode}
                  />
                )}
              />
              {errors.category && (
                <span className="error-msg">{errors.category.message}</span>
              )}
            </div>
            <br />
            <div className="press-pt-3 create-btns-div">
              <button
                type="button"
                className="um-create-btn"
                onClick={onClickCancel}
                style={{
                  marginTop: optionsBoxOpen ? "8%" : "0", // Adjust the margin based on options box state
                }}
              >
                {t("Cancel")}
              </button>
              {!isEditMode && (
                <button
                  type="submit"
                  className="um-submit-btn"
                  style={{
                    marginTop: optionsBoxOpen ? "8%" : "0", // Adjust the margin based on options box state
                  }}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </form>
        </Modal>
      </div>
      {
        <CustomPopup
          isOpenPopup={showAreYouSurePopup}
          closePopup={closeAreYouSure}
          image={areIcon}
          title="Are you sure!"
          text={
            userData
              ? "You want to Update this Purchase Group ?"
              : "You want to Create this Purchase Group ?"
          }
          btn1="Cancel"
          btn2="Confirm"
          successTitle="Done!"
          successText={
            userData
              ? "Purchase Group Details Updated Successfully"
              : "Create Purchase Group Successfully"
          }
          successIcon={successIcon}
          btn3="Ok"
          closeFormPopup={closeModal}
          reset={reset}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          scenarioType={
            userData ? "editPurchasingGroup" : "createPurchasingGroup"
          }
        />
      }
    </>
  );
};

export default PurchasingGroupPopup;
