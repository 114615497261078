import {
  React,
  useEffect,
  useTranslation,
  useForm,
  useState,
  useDispatch,
  useSelector,
} from "../Imports/import";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import lockIcon from "../../Assets/Svg/lock-icon.svg";
import useSetFormValues from "./../../CustomHooks/useSetFormValues";
import { getAllCountryList } from "../../Store/Reducer/countryList";
import { bankName, currencyData, handleKeyDown } from "../../Utils/Utils";

const VpSummary = ({ editMode, setEditMode, data }) => {
  const {
    t,
    // i18n
  } = useTranslation();
  const { CountryList } = useSelector((state) => state.countryList);
  const dispatch = useDispatch();
  const [bankKey, getBankKey] = useState(null);
  const [countryShortForm, getCountryShortForm] = useState(null);
  const {
    register,
    handleSubmit,
    // getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const CurrentPaymentMethod =
    data?.payment_method == "Other please specify"
      ? "payment_specify"
      : "payment_method";

  const bankLabel = data?.bank_name;

  const match = bankLabel?.match(/\d{3}$/);

  const language = watch("country") == "Thailand" ? "TH" : "EN";

  const purchasingDataCreditTerms =
    watch("credit_term") === "30Days"
      ? "px30"
      : watch("credit_term") === "Other"
      ? "OTH"
      : "NOT AVAILABLE";

  const textAfterCreditTerms =
    watch("credit_term") === "30Days"
      ? "(โอนเงิน30วันนับจากวันที่ในอินวอยซ์(ค่าธรรมเนียมหักsupplier))"
      : watch("credit_term") === "Other"
      ? ""
      : "NOT AVAILABLE";

  const reconAccountBasedOnTypeOfBusiness =
    watch("type_of_business") === "Rawmaterial"
      ? "2122000000"
      : data?.type_of_business?.length > 0 &&
        watch("type_of_business") !== "Rawmaterial"
      ? "2151900000"
      : "NOT AVAILABLE";

  const countryMapping = {
    Thailand: "TH",
    "United Kingdom": "UK",
    "United States of America": "USA",
    Japan: "JP",
    Taiwan: "TW",
    Germany: "DE",
    "Hong Kong and Shanghai": "HK/SH",
    France: "FR",
    China: "CN",
    "Australia and New Zealand": "AU/NZ",
    OTH: "OTH",
  };

  useEffect(() => {
    // Check if a match was found

    const bankCountry = bankLabel?.match(/\(([^)]+)\)/);

    if (bankCountry && bankCountry[1]) {
      const countryName = bankCountry[1].trim(); // Extract and trim the text within parentheses
     
      const shortForm = countryMapping[countryName] || "N/A";
      getCountryShortForm(shortForm);
    } 

    if (match) {
      // Extract the matched numbers
      const lastThreeNumbers = match[0];
      getBankKey(lastThreeNumbers);
    } 
  }, []);

  const onSubmit = (data) => {
    setEditMode(false);
  };
  const setDataValues = useSetFormValues(setValue);

  useEffect(() => {
    setDataValues(data);
  }, [data, setDataValues]);

  useEffect(() => {
    dispatch(getAllCountryList());
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="genaral-main-div">
          <div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Vendor Code")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("vendor_code", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">
                {t("Commercial Register Type")} :
              </label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("commercial_reg_type", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input" style={{ gap: "20px" }}>
              <label className="vp-sum-label">Pur.Organiz.1</label>
              <select className="vp-input vp-sum-dropdown" disabled>
                <option value="" hidden>
                  select
                </option>
                <option value="test">test</option>
              </select>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("test", {
                    required: false,
                  })}
                />
                {errors.test && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
              <select className="vp-input vp-sum-dropdown" disabled>
                <option value="" hidden>
                  select
                </option>
                <option value="test">test</option>
              </select>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("test2", {
                    required: false,
                  })}
                />
                {errors.test2 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input" style={{ gap: "20px" }}>
              <label className="vp-sum-label">Pur.Organiz.2</label>
              <select className="vp-input vp-sum-dropdown" disabled>
                <option value="" hidden>
                  select
                </option>
                <option value="test">test</option>
              </select>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("test", {
                    required: false,
                  })}
                />
                {errors.test && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
              <select className="vp-input vp-sum-dropdown" disabled>
                <option value="" hidden>
                  select
                </option>
                <option value="test">test</option>
              </select>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("test2", {
                    required: false,
                  })}
                />
                {errors.test2 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input vp-sum-check-div">
              <div>
                <label className="vp-sum-label" for="myCheckBox">
                  V_General
                </label>
                <input
                  type="checkbox"
                  className="vp-sum-checkbox"
                  id="myCheckBox"
                />
              </div>
              <div>
                <label className="vp-sum-label" for="Block">
                  Block
                </label>
                <input type="checkbox" className="vp-sum-checkbox" id="Block" />
              </div>
              <div>
                <label className="vp-sum-label" for="Delete">
                  Delete
                </label>
                <input
                  type="checkbox"
                  className="vp-sum-checkbox"
                  id="Delete"
                />
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Name</h2>
          <div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Name 1 ")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register("company_name_eng", {
                  //   required: false,
                  // })}
                  value={watch("company_name_eng")?.toUpperCase() || ""}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Name 2")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  value={watch("name2")?.toUpperCase() || ""}
                  // {...register("name2", {
                  //   required: false,
                  // })}
                />
                {errors.name2 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Name 3")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register("name3", {
                  //   required: false,
                  //   // maxLength: 10,
                  // })}
                  value={watch("name3")?.toUpperCase() || ""}
                />
                {errors.name3 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Name 4")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register("name4", {
                  //   required: false,
                  //   // maxLength: 10,
                  // })}
                  value={watch("name4")?.toUpperCase() || ""}
                />
                {errors.name4 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input mb-3">
              <label className="vp-label">{t("Search Name")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("company_name_eng", {
                    required: false,
                  })}
                  value={watch("company_name_eng")?.toUpperCase() || ""}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div
              className="vp-sum-label-input vp-sum-check-div"
              style={{ width: "30%" }}
            >
              <div>
                <label className="vp-sum-label" for="Blocked">
                  Blocked
                </label>
                <input
                  type="checkbox"
                  className="vp-sum-checkbox"
                  id="Blocked"
                />
              </div>
              <div>
                <label className="vp-sum-label" for="flgDeleted">
                  flgDeleted
                </label>
                <input
                  type="checkbox"
                  className="vp-sum-checkbox"
                  id="flgDeleted"
                />
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Registered Address </h2>
          <div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Street/House Number")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("street_house_no", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Province/State/City")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register("district", {
                  //   required: false,
                  // })}
                  value={watch("district")?.toUpperCase() || ""}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("District")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register("city", {
                  //   required: false,
                  // })}
                  value={watch("city")?.toUpperCase() || ""}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input mb-3">
              <label className="vp-label">{t("Postal Code")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("postal_code", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Contact person </h2>
          <div>
            <div className="vp-sum-label-input" style={{ gap: "20px" }}>
              <label className="vp-sum-label">Language</label>
              <select className="vp-input vp-sum-dropdown" disabled>
                <option value={language}>{language}</option>
              </select>
              <label className="vp-sum-label">Country</label>

              <select
                className="vp-input vp-sum-dropdown"
                disabled
                {...register("country", {
                  required: false,
                })}
              >
                <option value="" hidden>
                  select
                </option>
                {CountryList.map((country, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={country.country}
                    >
                      {country.country}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Mobile phone")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("contact_phone", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("E-mail address 1 ")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("contact_email", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("E-mail address 2")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("contact_email2", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input mb-3">
              <label className="vp-label">{t("Fax")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("fax", {
                    required: false,
                    minLength: 9,
                    maxLength: 12,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
          </div>

          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Account Control</h2>
          <div className="vp-sum-label-input">
            <label className="vp-label">{t("Authorization")} :</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                className="vp-input"
                {...register("Authorization", {
                  required: false,
                })}
              />
              {errors.Authorization && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          <div className="vp-sum-label-input">
            <label className="vp-label">{t("Trading Partner")} :</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                className="vp-input"
                {...register("Trading", {
                  required: false,
                })}
              />
              {errors.Trading && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          <div className="vp-sum-label-input mb-3">
            <label className="vp-label">{t("Corperate Group")} :</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                className="vp-input"
                {...register("corperate", {
                  required: false,
                })}
              />
              {errors.corperate && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Tax Infomation</h2>
          <div>
            <div className="vp-sum-label-input" style={{ gap: "20px" }}>
              <label className="vp-sum-label">Bank Country</label>
              <select
                className="vp-input vp-sum-dropdown"
                disabled
                value={countryShortForm}
              >
                <option value="" hidden>
                  select
                </option>
                <option value={countryShortForm}>{countryShortForm}</option>
              </select>
              <label className="vp-sum-label">Bank Key</label>

              <select
                className="vp-input vp-sum-dropdown"
                disabled
                style={{ width: "230px" }}
                value={bankKey}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>

                <option className="dropdown-country" value={bankKey}>
                  {bankKey}
                </option>
              </select>
              <label className="vp-sum-label">TransferM </label>
              <input type="text" className="vp-input" disabled />
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Tax Number 1")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("Tax1", {
                    required: false,
                  })}
                />
                {errors.Tax1 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Tax Number 2")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("Tax2", {
                    required: false,
                  })}
                />
                {errors.Tax2 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Tax Number 3")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("Tax3", {
                    required: false,
                  })}
                />
                {errors.Tax3 && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("VAT Reg. NO")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("registration_no", {
                    required: false,
                  })}
                />
                {errors.registration_no && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Type of Business")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register("type_of_business", {
                  //   required: false,
                  // })}
                  value={watch("type_of_business")?.toUpperCase() || ""}
                />
                {errors.type_of_business && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Type of Industry")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("industry", {
                    required: false,
                  })}
                />
                {errors.industry && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Bank Account")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("account_number", {
                    required: false,
                  })}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Account name")} :</label>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register("bank_account_holder", {
                  //   required: false,
                  // })}
                  value={watch("bank_account_holder")?.toUpperCase() || ""}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Cntl")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("cntl", {
                    required: false,
                  })}
                />
                {errors.cntl && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input mb-3">
              <label className="vp-label">{t("Bank Type")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("bank_type", {
                    required: false,
                  })}
                />
                {errors.bank_type && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Payment Transaction</h2>
          <div className="vp-sum-label-input">
            <label className="vp-label">{t("Instrucion Key")} :</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                className="vp-input"
                {...register("insturcion", {
                  required: false,
                })}
              />
              {errors.insturcion && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          <div className="vp-sum-label-input mb-3">
            <label className="vp-label">{t("Permitted Payee")} :</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                className="vp-input"
                {...register("permit", {
                  required: false,
                })}
              />
              {errors.permit && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Purchasing Data </h2>
          <div>
            <p className="vp-sum-title">Conditions</p>
            <div className="vp-sum-label-input" style={{ gap: "20px" }}>
              <label className="vp-sum-label">Order Currency</label>
              <select
                className="vp-input vp-sum-dropdown"
                disabled
                {...register("currency", {
                  required: false,
                })}
              >
                <option value="" hidden>
                  select
                </option>
                {currencyData?.map((Currency, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={currencyData?.value}
                    >
                      {Currency?.label}
                    </option>
                  );
                })}
              </select>
              <label className="vp-sum-label">Terms Of Payment</label>

              <select
                className="vp-input vp-sum-dropdown"
                disabled
                {...register(purchasingDataCreditTerms, {})}
                style={{ width: "200px" }}
              >
                {/* <option className="dropdown-country" value="" hidden>
                    {" "}
                    {t("select")}
                  </option> */}
                <option>{purchasingDataCreditTerms}</option>
              </select>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  value={textAfterCreditTerms}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input mb-3" style={{ gap: "20px" }}>
              <label className="vp-sum-label">Incoterms 1/2</label>
              <select className="vp-input vp-sum-dropdown" disabled>
                <option value="" hidden>
                  select
                </option>
                <option value="test">test</option>
              </select>

              <div
                className="vp-input-div vp-sum-input-div"
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("TE", {
                    required: false,
                  })}
                />
              </div>
            </div>
            <p className="vp-sum-title">Sales Data</p>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Sales Person")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("sales_person", {
                    required: false,
                  })}
                />
                {errors.sales_person && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Number")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("number", {
                    required: false,
                  })}
                />
                {errors.number && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <p className="vp-sum-title">Control Data</p>
            <div
              className="vp-sum-label-input vp-sum-check-div"
              style={{ width: "70%" }}
            >
              <div>
                <label className="vp-sum-label" for="gr">
                  GR-based Inv Verif.
                </label>
                <input type="checkbox" className="vp-sum-checkbox" id="gr" />
              </div>
              <div>
                <label className="vp-sum-label" for="auto">
                  Auto EvalGRSetmt Del
                </label>
                <input type="checkbox" className="vp-sum-checkbox" id="auto" />
              </div>
              <div>
                <label className="vp-sum-label" for="ret">
                  Auto EvalGRSetmt Ret
                </label>
                <input type="checkbox" className="vp-sum-checkbox" id="ret" />
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">
            Partner Function : For Invoice to Address (Default Invoice to
            Address){" "}
          </h2>
          <div>
            <p className="vp-sum-title pt-2">Partner Function</p>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Partner Function")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("partner", {
                    required: false,
                  })}
                />
                {errors.partner && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Number")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("Number", {
                    required: false,
                  })}
                />
                {errors.Number && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">Maker information </h2>
          <textarea className="vp-textarea" disabled />

          <hr className="line"></hr>
          <h2 className="sum-title pb-2">2.1 Accounting</h2>
          <p className="vp-sum-title ">Accounting Information</p>
          <div
            className="vp-sum-label-input vp-sum-check-div"
            style={{ width: "100%" }}
          >
            <div>
              <label className="vp-sum-label px-2">Recon. Account</label>
              <select
                className="vp-input vp-sum-dropdown"
                disabled
                style={{ width: "330px" }}
                {...register(reconAccountBasedOnTypeOfBusiness, {})}
              >
                <option>{reconAccountBasedOnTypeOfBusiness}</option>
              </select>
            </div>
            <div>
              <label className="vp-sum-label" for="material">
                Material, Utility, Electricity
              </label>
              <input
                type="checkbox"
                className="vp-sum-checkbox"
                id="material"
              />
            </div>
            <div>
              <label className="vp-sum-label" for="Others">
                Others
              </label>
              <input type="checkbox" className="vp-sum-checkbox" id="Others" />
            </div>
          </div>

          <hr className="line"></hr>
          <h2 className="sum-title pb-2">
            2.2 Payment Transactions Accounting
          </h2>
          <div>
            <p className="vp-sum-title ">Payment Data</p>
            <div className="vp-sum-label-input" style={{ gap: "20px" }}>
              <label className="vp-sum-label">Order Currency</label>

              <select
                className="vp-input vp-sum-dropdown"
                disabled
                {...register("currency", {
                  required: false,
                })}
              >
                <option value="" hidden>
                  select
                </option>
                {currencyData?.map((Currency, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={currencyData?.value}
                    >
                      {Currency?.label}
                    </option>
                  );
                })}
              </select>

              <label className="vp-sum-label">Terms Of Payment</label>

              <select
                className="vp-input vp-sum-dropdown"
                style={{ width: "200px" }}
                disabled
                {...register(purchasingDataCreditTerms, {})}
              >
                {/* <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option> */}
                <option>{purchasingDataCreditTerms}</option>
              </select>
              <div className="vp-input-div vp-sum-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  value={textAfterCreditTerms}
                />
                <span className="vp-lock">
                  <img className="lock-icon" src={lockIcon} alt="" />
                </span>
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Credit Memo Term")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("memo", {
                    required: false,
                  })}
                />
                {errors.memo && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <p className="vp-sum-title ">Automatic Payment Transactions</p>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Payment Method")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  // {...register(CurrentPaymentMethod, {
                  //   required: false,
                  // })}
                  value={watch(CurrentPaymentMethod)?.toUpperCase() || ""}
                />
                {errors.payment_method && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("House Bank")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("house_bank", {
                    required: false,
                  })}
                />
                {errors.house_bank && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Payment Block")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("payment_block", {
                    required: false,
                  })}
                />
                {errors.payment_block && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">2.3 Correspondence Accounting</h2>
          <div>
            <p className="vp-sum-title ">Dunning Data & Correspondence</p>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Dunn. Procedure")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("dunn", {
                    required: false,
                  })}
                />
                {errors.dunn && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Dunning Level")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("dunning_level", {
                    required: false,
                  })}
                />
                {errors.dunning_level && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Act.clk tel. no.")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("act_clk", {
                    required: false,
                  })}
                />
                {errors.act_clk && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Clrk’s Internet")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("clrk_internet", {
                    required: false,
                  })}
                />
                {errors.clrk_internet && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Account Memo")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("account_memo", {
                    required: false,
                  })}
                />
                {errors.account_memo && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">2.4 Withholding Tax Accounting </h2>
          <div>
            <p className="vp-sum-title ">With/Tax Information</p>
            <div className="vp-sum-label-input vp-sum-check-div mb-0">
              <div>
                <label className="vp-sum-label" for="Liable">
                  Liable
                </label>
                <input
                  type="checkbox"
                  className="vp-sum-checkbox"
                  id="Liable"
                />
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("WH Tax Country")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("tax_country", {
                    required: false,
                  })}
                />
                {errors.tax_country && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("WH Tax Type")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("tax_type", {
                    required: false,
                  })}
                />
                {errors.tax_type && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("WH Tax Code")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("tax_code", {
                    required: false,
                  })}
                />
                {errors.tax_code && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Recipient Type")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("recipient_type", {
                    required: false,
                  })}
                />
                {errors.recipient_type && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
          </div>
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">2.5 Subcontracting Supplement </h2>
          <textarea className="vp-textarea" disabled />
          <hr className="line"></hr>
          <h2 className="sum-title pb-2">2.6 Thailand Branch Details </h2>
          <div>
            <p className="vp-sum-title ">Default Branch </p>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Branch Code")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("Branch_Code", {
                    required: false,
                  })}
                />
                {errors.Branch_Code && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Branch Description")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("branch_desc", {
                    required: false,
                  })}
                />
                {errors.branch_desc && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <div className="vp-sum-label-input">
              <label className="vp-label">{t("Branch Code")} :</label>
              <div className="vp-input-div">
                <input
                  type="text"
                  disabled
                  className="vp-input"
                  {...register("branch_code", {
                    required: false,
                  })}
                />
                {errors.branch_code && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
          </div>
          <hr className="line"></hr>

          <CancelAndUpdateBtn
            editMode={editMode}
            setEditMode={setEditMode}
            reset={reset}
          />
        </div>
      </form>
    </>
  );
};

export default VpSummary;
