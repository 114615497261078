import React, { useCallback, useEffect, useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import DataTable from "react-data-table-component";
import CustomPagination from "../../CustomPagination/CustomPagination";
import CSVDownloadButton from "../../CsvDownload/CsvDownload";
import Nav from "react-bootstrap/Nav";
import {
  PageLoader,
  toast,
  useDispatch,
  useSelector,
  useTranslation,
} from "../../../../src/Components/Imports/import";
import AttachFilePopup from "../../../Models/AttachFilePopup/AttachFilePopup";
import PrReleasePopup from "../../../Models/PrReleasePopup/PrReleasePopup";
import PrDescriptionPopup from "../../../Models/PrReleasePopup/PrDescriptionPopup";
import PrReleaseFilterPopup from "../../../Models/PrReleasePopup/PrReleaseFilterPopup";
import "./PrReleaseTable.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import arrowIcon from "../../../Assets/Svg/arrow-icon.svg";
import { tableStyles } from "../../../Utils/TableStyles";
import areIcon from "../../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../../Assets/Svg/success-icon.svg";

import {
  deletePr,
  getAllPrAttachmentsAction,
  getAllPrFilterDropdown,
  getAllPrRelease,
  getAllPrReleaseGroupCount,
  getAllPrStatusCount,
} from "../../../Store/actions/prReleaseActions";
import {
  DateGenerator,
  formatNumber,
  transformPrReleaseTableDataResponse,
} from "../../../Utils/Utils";
import ItemsPerPageDropdown from "../../Common/ItemsPerPageDropdown";
import NavLink from "../../Common/NavLink";
import CustomPopup from "../../../Models/CustomPopup/CustomPopup";

const PrReleaseTable = ({selectedDateRange,setSelectedDateRange}) => {
  const { filterList,prReleaseTableData, getAllPrAttachments, prStatusCounts,loading,error} =
    useSelector((state) => state.getPrReleaseInfo);
  const { Items, TotalCount } = prReleaseTableData || {};
  const tableData = transformPrReleaseTableDataResponse(Items) || [];
  const [appliedFilters, setAppliedFilters] = useState([]);
  const navLinks = [
    {
      eventType: "all",
      label: "All",
      totalCount: prStatusCounts?.reduce((total, item) => {
        if (item.PR_VMS_Status === 1 || item.PR_VMS_Status === 3) {
          return total + (item.count || 0);
        }
        return total;
      }, 0) || 0,
    },
    {
      eventType: "New",
      label: "New PR",
      totalCount: prStatusCounts?.find(item => item.PR_VMS_Status === 1)?.count || 0,
    },
    {
      eventType: "Release",
      label: "Released",
      totalCount: prStatusCounts?.find(item => item.PR_VMS_Status === 2)?.count || 0,
    },
    {
      eventType: "Deleted",
      label: "Deleted",
      totalCount: prStatusCounts?.find(item => item.PR_VMS_Status === 3)?.count || 0,
    },
  ];
 
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowData, setRowData] = useState({});
  
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [orderDir, setOrderDir] = useState('DESC');
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  const [count,setCount]=useState(prStatusCounts?.[0]?.count + prStatusCounts?.[1]?.count || 0)
  const [attachFileModalOpen, setAttachFileIsModalOpen] = useState(false);
  const [showDeletePopup,setShowDeletePopup]=useState(false)
  useEffect(()=>{
    setCount(prReleaseTableData?.TotalCount)
  },[prReleaseTableData])
  useEffect(() => {
    dispatch(
      getAllPrRelease(
        currentPage,
        itemsPerPage,
        orderBy,
        orderDir,
        type,
        selectedDateRange[0],
        selectedDateRange[1],
        searchValues,
        appliedFilters,
      )
    );
    dispatch(getAllPrStatusCount());
    setCount(prReleaseTableData?.TotalCount)
  }, [currentPage,itemsPerPage,orderBy,orderDir,type,selectedDateRange[0],selectedDateRange[1],searchValues,appliedFilters]);


  const columnWidths = {
    PR_Date: "10%",
    PR_Group: "5%",
    PR_Company: "7%",
    PR_ID: "9%",
    PR_Description: "10%",
    PR_QTY: "8%",
    PR_Unit: "5%",
  };

  const columnPadding = {
    PR_Date: "3%",
    PR_Group: "5%",
    PR_Company: "3%",
    PR_ID: "4%",
    PR_Description: "2%",
    PR_QTY: "2%",
    PR_Unit: "4%",
  };

  const columns = [
    {
      id: "checkbox",
      name: "",
      searchable: false,
      cell: (result) => (
        <div className="pr-release-table-check">
          <input
            type="checkbox"
            checked={selectedRows.some(row => row.PrCreatedId === result.PrCreatedId)}
            onClick={() => {
              handleCheckBox(result);
            }}
            disabled={result?.Delete_Flag === 1 || result?.PR_VMS_Status === 2}
          />
        </div>
      ),
      width: "4%",
    },
    {
      id: "PR_Date",
      name: "Date",
      selector: (row) => DateGenerator(row?.PR_Date),
      searchable: true,
      sortable: true,
      width: "11%",
    },
    {
      id: "PR_Group",
      name: "Purchasing Group",
      selector: (row) => row?.PR_Group,
      searchable: true,
      sortable: true,
      width: "7%",
    },
    {
      id: "PR_Company",
      name: "Company",
      selector: (row) => row?.PR_Company,
      searchable: true,
      sortable: true,
      width: "8%",
    },
    {
      id: "PR_ID",
      name: "Purchase Requisition",
      // allowOverflow: true,
      selector: (row) => row?.PR_ID,
      searchable: true,
      sortable: true,
      width: "12%",
    },
    {
      id: "PR_description_Short",
      name: "Description",
      // grow: 2,
      // allowOverflow: true,
      // selector: "PR_Description",
      searchable: true,
      width: "12%",
      cell: (row) => (
        <div
          className="description-cell"
          onClick={() =>{

            PrDescriptionOpenModal(row)
          }}
        >
          {row?.PR_description_Short}
        </div>
      ),
    },
    {
      id: "PR_QTY",
      name: "Qty",
      // allowOverflow: true,
      selector: (row) => formatNumber(row.PR_QTY),
      searchable: true,
      width: "10%",
    },
    {
      id: "PR_Unit",
      name: "Unit",
      selector: (row) => row?.PR_Unit,
      searchable: true,
      width: "8%",
    },
    {
      id: "Status",
      name: "Status",
      width: "10%",
      // selector: "PR_VMS_Status",
      searchable: false,
      cell: (result) => (
        <div
          className={
            result?.PR_VMS_Status === 3
              ? "table-status Deleted"
              : result?.PR_VMS_Status === 1 
              ? "table-status NewPR"
              : result?.PR_VMS_Status === 2
              ? "table-status Released"
              : ""
          }
        >
          {result?.PR_VMS_Status === 3
            ? "Deleted"
            : result?.PR_VMS_Status === 1
            ? "NewPR"
            : result?.PR_VMS_Status === 2
            ? "Released"
            : ""}
        </div>
      ),
    },
    {
      id: "Action",
      width: "30%",
      name: "Action",
      cell: (result) => (
        <div className="d-flex">
          {result.Delete_Flag !== 1 && result.status === 1 &&  result?.PR_VMS_Status !== 2 && (
            <>
              <button
                className="prr-af-btn mx-3"
                onClick={async () => {
                  await setRowData(result);
                  const response = await dispatch(
                    getAllPrAttachmentsAction(result?.PrCreatedId)
                  );
                  if (response.status === 200) {
                    setAttachFileIsModalOpen(true);
                  } else {
                    toast.error("Something went wrong");
                  }
                }}
              >
                {"Attach File"}
              </button>

              {selectedRows && selectedRows.length <= 1 && (
                <button
                  className="prr-rel-btn mx-3"
                  onClick={() => {
                    setRowData(result);
                    setPrReleaseIsModalOpen(true);
                  }}
                >
                  {"Release"}
                </button>
              )}
            </>
          )}
          {result?.Delete_Flag !== 1  &&  result?.PR_VMS_Status !== 2  && (
            <button
              className="prr-del-tbl-btn"
              onClick={() => {
                setRowData(result);
                handleDelete();
              }}
            >
              {"Delete"}
            </button>
          )}
        </div>
      ),
      searchable: false,
    },
  ];

  //AttachFile Popup
  const AttachFileOpenModal = () => {
    setAttachFileIsModalOpen(true);
  };
  const AttachFileCloseModal = () => {
    setAttachFileIsModalOpen(false);
  };

  //prpopup
  const [prReleaseIsModalOpen, setPrReleaseIsModalOpen] = useState(false);
  const PrReleaseOpenModal = () => {
    setPrReleaseIsModalOpen(true);
  };
  const PrReleaseCloseModal = () => {
    setSelectedRows([])
    setPrReleaseIsModalOpen(false);
    dispatch(getAllPrReleaseGroupCount());
    dispatch(getAllPrStatusCount());
    dispatch(getAllPrFilterDropdown());
    dispatch(getAllPrRelease(
      currentPage,
      itemsPerPage,
      orderBy,
      orderDir,
      type,
      selectedDateRange[0],
      selectedDateRange[1],
      searchValues,
      appliedFilters,
    ))
  };

  //PrReleaseFilterPopup
  const [prReleaseFilterModalOpen, setPrReleaseFilterModalOpen] =
    useState(false);
  const PrReleaseFilterOpenModal = () => {
    setPrReleaseFilterModalOpen(true);
  };
  const PrReleaseFilterCloseModal = () => {
    setPrReleaseFilterModalOpen(false);
  };

  const toggleFilter = () => {
    setPrReleaseFilterModalOpen(!prReleaseFilterModalOpen);
  };
  // toggle for filter button
  // const togglePrReleaseFilterModal = () => {
  //   setPrReleaseFilterModalOpen((prev) => !prev);
  // };

  //description popup
  const [selectedRow, setSelectedRow] = useState(null);

  // prDescriptionpopup
  const [prDescriptionModalOpen, setPrDescriptionModalOpen] = useState(false);
  const PrDescriptionOpenModal = (row) => {
    setSelectedRow(row);
    setTimeout(() => {
      setPrDescriptionModalOpen(true);
    }, 0);
  };

  const PrDescriptionCloseModal = () => {
    setPrDescriptionModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  const handleApplyFilter = (selectedFilters) => {
    setAppliedFilters(selectedFilters);
    
  };
  const handleSearchChange = (event, columnName) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [columnName]: value,
    }));
  };


  const handleTypeSelect = (FilterStatus) => {
    setType(FilterStatus);
    setCurrentPage(1)
  };

  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const handleDateRangeClean = () => {
    if (selectedDateRange && selectedDateRange.length > 0) {
      setSelectedDateRange([]);
    }
  };

  const closeDeletePopup=()=>{
    setShowDeletePopup(false)
    dispatch(
          getAllPrRelease(
            currentPage,
            itemsPerPage,
            orderBy,
            orderDir,
            type,
            selectedDateRange[0],
            selectedDateRange[1],
            searchValues,
          )
        );
        
  }

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, TotalCount), 
  };

  // csv handlers
  const headerNames = columns.map((column) => column.name);
  const trimmedHeaders = headerNames.slice(0, headerNames.length - 1);
  const csvData = tableData.map((item) =>
    columns.map((column) => {
      if (column.id === "Status") {
        return item?.Delete_Flag === 1
        ? "Deleted"
        : item?.status === 1
        ? "NewPR"
        : item?.status === 2
        ? "Release"
        : "N/A"
      }
      else if (column.id === "PR_QTY"){
        return formatNumber(item?.PR_QTY)
      }
      else{
        return item[column.id];
      }
      
    })
  );


  const handleCheckBox = (row) => {
    const selectedIndex = selectedRows.findIndex(
      (selectedRow) => selectedRow.PrCreatedId === row.PrCreatedId
    );
    if (selectedIndex === -1) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter(
        (selectedRow) => selectedRow.PrCreatedId !== row.PrCreatedId
      );
      setSelectedRows(updatedSelectedRows);
    }
  };

  const handleDelete = async () => {
    setShowDeletePopup(true)
    
  };

 
  useEffect(()=>{
    dispatch(getAllPrFilterDropdown());
  },[])

  return (
    <>
    {loading && <PageLoader />}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="table-navbar">
            <Nav activeKey={type} onSelect={handleTypeSelect} className="">
              {navLinks.map((link) => (
                <NavLink
                  key={link.eventType}
                  eventType={link.eventType}
                  label={link.label}
                  totalCount={type==link.eventType?count:link.totalCount}
                  activeKey={type}
                  handleTypeSelect={handleTypeSelect}
                />
              ))}
            </Nav>

            <DateRangePicker
              appearance="default"
              loading={false}
              format={"yyyy-MM-dd"}
              placement="bottomEnd"
              placeholder="Start date ~ End date"
              value={selectedDateRange.length > 0 ? selectedDateRange : null}
              onChange={handleDateRangeChange}
              onClean={handleDateRangeClean}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <button className="prr-filter-button" onClick={toggleFilter}>
                <TuneIcon /> {"Filter"}
              </button>
              <ItemsPerPageDropdown
                value={itemsPerPage}
                onChange={handleDropdownChange}
              />
            </div>
            <PrReleaseFilterPopup
              PrReleaseFilterIsOpen={prReleaseFilterModalOpen}
              PrReleaseFilterCloseModal={PrReleaseFilterCloseModal}
              filterList={filterList}
              onApplyFilter={handleApplyFilter}
              setPrReleaseFilterModalOpen={setPrReleaseFilterModalOpen}
              // onApplyFilter={applyFilter}

              // applyFilter={applyFilter} // Pass the applyFilter function
              // filterCriteria={filterCriteria} // Pass the filterCriteria
            />
            <div>
              {selectedRows && selectedRows.length > 1 && (
                <button
                  className="prr-rel-btn mx-3"
                  onClick={() => {
                    PrReleaseOpenModal();
                  }}
                >
                  {"Release"}
                </button>
              )}
              <CSVDownloadButton
                data={csvData}
                headers={trimmedHeaders}
                filename="pr_table"
              />
            </div>
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={tableData.length ? "um-data-table" : "um-empty-data-table"}
        >
          <DataTable
            columns={columns}
            data={tableData}
            customStyles={tableStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              padding: "4px",
            }}
            className="prr-table-search-div"
          >
            <div style={{ marginLeft: "15px" }}>
              {columns.map((column) =>
                column.searchable !== false ? (
                  <>
                    <input
                      key={column.id}
                      type="text"
                      value={searchValues[column.id] || ""}
                      style={{
                        width: column.width,
                        marginRight:"0.5%",
                      }}
                      onChange={(event) => handleSearchChange(event, column.id)}
                    />
                  </>
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of {TotalCount}{" "}
          entries
        </p>
        <CustomPagination
          totalItems={TotalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>

      <AttachFilePopup
        AttachFileIsOpen={attachFileModalOpen}
        AttachFileFormTitle={"Attachments"}
        AttachFileCloseModal={AttachFileCloseModal}
        scenarioType="PrRelease"
        rowData={rowData}
        filesData={getAllPrAttachments}
      />

      {/*PrReleasePopup */}
      <PrReleasePopup
        PrReleaseIsOpen={prReleaseIsModalOpen}
        PrReleaseFormTitle={"Release ?"}
        PrReleaseCloseModal={PrReleaseCloseModal}
        rowData={rowData}
        selectedRows={selectedRows}
      />

      {/* prDescriptionpopup */}
      <PrDescriptionPopup
        PrDescriptionIsOpen={prDescriptionModalOpen}
        PrDescriptionCloseModal={PrDescriptionCloseModal}
        PrDescriptionFormTitle={"PR Description"}
        shortDescription={selectedRow ? selectedRow?.PR_description_Short : ""}
        longDescription={selectedRow ? selectedRow?.PR_description_Long : ""}
      />
      <CustomPopup
            isOpenPopup={showDeletePopup}
            closePopup={closeDeletePopup}
            title="Are you sure!"
            image={areIcon}
            text={"You want to Delete this PR ?"}
            btn1="Cancel"
            btn2="Confirm"
            successTitle="Done!"
            successText={"Delete successfully"}
            successIcon={successIcon}
            btn3="Ok"
            scenarioType="deletePR"
            // handleChangeColumn={handleChangeColumn}
            rowData={rowData}
          />
    </>
  );
};

export default PrReleaseTable;
