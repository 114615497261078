import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_LOGIN_DETAILS } from "../../ApiEndPoints/ApiEndPoints";

export const getAllLoginDetails = createAsyncThunk("LoginDetails", async () => {
  const userToken = localStorage.getItem("vms_admin_token");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      `${BaseUrl}${GET_LOGIN_DETAILS}`,
      requestOptions
    );
    const result = await response.json();
    return result.data;
  } catch (error) {
    
    throw error;
  }
});

export const myReducer = createSlice({
  name: "LoginDetails",
  initialState: {
    loading: false,
    LoginDetails: {},
  },
  reducers: {
    updateSelectLoginDetails: (state, action) => {
      state.selectedGroupId = action.payload;
    },
  },
  extraReducers: {
    [getAllLoginDetails.pending]: (state) => {
      state.loading = true;
    },
    [getAllLoginDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.LoginDetails = payload;
    },
  },
});

export const { updateSelectLoginDetails } = myReducer.actions;

export default myReducer.reducer;
